<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="timeline__header">
            <div>
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.task_map') }}</span>
            </div>
            <div class="filter_home flex-center">
                <!-- <div>
                    <a @click="$emit('showFullWindow'), timelineGraph = true" class="full__item">
                        <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3"
                                    d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z"
                                    fill="currentColor" />
                                <path
                                    d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z"
                                    fill="currentColor" />
                            </svg>
                        </span>
                    </a>
                </div> -->
                <el-radio-group v-model="clientChartPeriod" size="medium">
                    <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                    <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                    <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                </el-radio-group>
                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>
        </div>

        <!-- full window -->
        <!-- <div class="fixed__pos">
            <div v-show="timelineGraph" :class="[timelineGraph ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                <div class="graph__header">
                    <div class="filter_home">
                        <el-radio-group v-model="clientChartPeriod" size="medium">
                            <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                            <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                            <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div>
                        <a @click="timelineGraph = false" class="graph__close"
                            :class="mode ? 'graph__closeday' : 'graph__closenight'">
                            <span class="graph__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1"
                                        transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                    <rect x="8.46447" y="7.05029" width="12" height="2" rx="1"
                                        transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                                </svg>
                            </span>
                        </a>
                    </div>
                </div>
                <time-line :key="update" />
            </div>
        </div> -->
        <time-line :key="update" @showItem="showItem"/>
            <!-- <el-dialog
                class="modal-style-m tash-select"
                :visible.sync="taskUpdateDrawer"
                ref="drawerUpdate"
                :show-close="false"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :append-to-body="true"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
                :fullscreen="true"
                >
                <AppModalUpdate
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                />
            </el-dialog> -->
        
            <el-drawer
                class="modal-style-m  tash-select taskMapModal"
                :visible.sync="taskUpdateDrawer"
                ref="drawerUpdate"
                :show-close="false"
                :with-header="false"
                size="100%"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                :append-to-body="true"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
                >
                    <AppModalUpdate
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    />
            </el-drawer>
    
    </div>
</template>

<script>
import TimeLine from '@/components/chart/timeline.vue';
import AppModalUpdate from "@/views/tasks/components/add-modal-update";

export default {
    components: {
        TimeLine,
        AppModalUpdate,
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },
        update: Number,
    },
    data() {
        return {
            timelineGraph: false,
            clientChartPeriod: "weekly",
            taskUpdateDrawer: false,
            selectedItem: {}
        }
    },
    watch: {
        allPeriodChange(val) {
            this.clientChartPeriod = val
        },
    },
    methods: {
        showItem(item){
            this.selectedItem = item;
            this.taskUpdateDrawer = true;
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
            this.$refs[ref].closed()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        closeDrawer(drawer) {
            this.taskUpdateDrawer = false;
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
            }
        },
    }
}
</script>

<style lang="scss" scoped></style>