<template>
 
  <div>
    <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0">
                {{ $t("message.childProducts") }}
            </p>
            <div class="d-flex">
                <div class="mr-2 mt-1" v-if="!disable_product_quantity">
                    <el-button
                      @click="saveUpkeepAsDefault()" 
                      :loading="loadingButton"
                      type="primary" 
                      size="medium">
                        {{ $t('message.save_as_default') }}
                    </el-button>
                </div>
                <crm-store-update-close
                    :permission="$options.name"
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>
    </div>
    <div class="product-add s m-3">
      <p class="font-weight-bold"> {{ $t('message.calculations') }} </p>
     
      <el-form ref="form" label-position="top" :model="form" :rules="rules">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item class="mb-0" prop="calculation_type_id">
                  <select-calculation-types
                    :size="'mini'"
                    :placeholder="$t('message.calculation_types')"
                    :id="form.calculation_type_id"
                    v-model="form.calculation_type_id"
                  >
                  </select-calculation-types>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item
                  class="mb-0"
                  :label="$t('message.remainder')"
                  prop="last_quantity"
                >
                    <el-input :disabled="disable_product_quantity" :placeholder="$t('message.remainder')" v-model="form.last_quantity" size="medium"></el-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item
                class="mb-0"
                  :label="$t('message.selling_price')"
                  prop="last_price"
                >
                  <el-input :placeholder="$t('message.selling_price')" v-model="form.last_price" disabled size="medium"></el-input>
                </el-form-item>
            </el-col>
            <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item
                class="mb-0"
                  :label="$t('message.price_net')"
                  prop="last_price"
                >
                  <el-input :placeholder="$t('message.price_net')" v-model="form.last_price_net" disabled size="medium"></el-input>
                </el-form-item>
            </el-col>
            <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item
                  class="mb-0"
                  :label="$t('message.currency')"
                >
                  <span>{{ (selectedRow.currency_id || selectedRow.selling_price_currency_id || selectedRow.buy_price_currency_id) ? getCurrencyCodeById(selectedRow) : '' }}</span>
                </el-form-item>
            </el-col>
            <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-form-item
                  class="mb-0"
                  :label="$t('message.profit') + ' ( % )'"
                  prop="profit"
                >
                  <crm-only-number-input
                    v-model="form.profit"
                    :inputValue="form.profit"
                    @c-change="checkMinProfit"
                    :size="'mini'"
                    :maxValue="99"
                  >
                  </crm-only-number-input>
                </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6" class="color-w">
                <el-form-item
                class="mb-0"
                  :label="$t('message.auto_calculate')"
                  prop="profit"
                >
                    <el-switch
                        v-model="form.calculation"
                        :active-text="$t('message.auto')"
                        :inactive-text="$t('message.manual')">
                    </el-switch>
                </el-form-item>
            </el-col>
          </el-row>
      </el-form>
    </div>
    <div class="product-add s m-3">
      <span class="font-weight-bold"> <p>{{ $t('message.additional_fees') }}</p> </span>
      <el-table
        :data="productFeeList"
        :summary-method="getSummariesForFees"
        show-summary
        border 
        style="width: 100%">
        <el-table-column
            type="index"
            width="50">
        </el-table-column>
        <el-table-column
            prop="name"
            :label="$t('message.name')"
            >
        </el-table-column>
        <el-table-column
            prop="percentage"
            :label="$t('message.percentage')"
            >
            <template slot-scope="scope">
                <el-input
                    :placeholder="$t('message.percentage')"
                    v-model="scope.row.percentage"
                    @input="updatePercentage(scope.row)"
                    type="number"
                    size="medium"
                ></el-input>
            </template>
        </el-table-column>

        <el-table-column
            prop="unit_price"
            :label="$t('message.unit_price')"
            >
            <template slot-scope="scope">
                <el-input
                    :placeholder="$t('message.unit_price')"
                    v-model="scope.row.unit_price"
                    @input="updateUnitPrice(scope.row)"
                    @focus="unitPriceFocus()"
                    @change="unitPriceChange()"
                    focus
                    type="number"
                    size="medium"
                ></el-input>
            </template>
        </el-table-column>

        <el-table-column
            prop="money_amount"
            :label="$t('message.money_amount')"
            >
            <template slot-scope="scope">
                <el-input
                    disabled
                    :placeholder="$t('message.money_amount')"
                    v-model="scope.row.money_amount"
                    @input="updateMoneyAmount(scope.row)"
                    type="number"
                    size="medium"
                ></el-input>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="product-add s m-3">
      <div class="d-flex" style="justify-content: space-between;">
        <span class="font-weight-bold d-block"> <p> {{ $t('message.upkeep') }} </p> </span>
        <div>
          <el-dropdown style="display:block" class="setting-cheek" :hide-on-click="false">
            <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
            ></el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="(column, index) in columns"
                    :key="'drop' + index"
                >
                    <el-checkbox
                        :checked="column.show"
                        @change="check(column.column, $event)"
                        >{{ column.title }}
                    </el-checkbox>
                </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <!-- PRODUCT LIST TABLE AND SELECT -->
      <el-row>    
          <el-col :span="24" class="mt-2">
            <el-table 
              :data="dealProductsListChild" 
              show-summary 
              :summary-method="getSummaries" 
              class="mm-child_prod_list_table"
              :row-class-name="tableRowClassName"
              border  
              style="width: 100%">
              <el-table-column :label="$t('message.name')" min-width="200" v-if="columns.name.show">
                <template slot-scope="scope">
                  <span @click="openCompositionDrawer(scope.row, scope.$index)" class="mm-pointer">
                    {{ scope.row.product ? scope.row.product.name : '' }}
                    <span v-if="scope.row.composition && scope.row.composition.length > 0">
                      (
                        <span v-for="item in scope.row.composition" class="d-block pl-3">
                          {{item.percentage + '% - ' + item.name + ' ('+ item.quantity + ' ' + item.measurement + ')' }}
                        </span>
                      )
                    </span>
                  </span>
                </template>
              </el-table-column>
              <el-table-column v-if="form.calculation_type_id == 1" :label="$t('message.remainder')">
                <template slot-scope="scope">
                  {{  (scope.row.quantity * ((100-scope.row.waste)/100)) | formatNumber(1) }}
                </template>
              </el-table-column>
              <el-table-column v-if="form.calculation_type_id == 1" :label="columns.quantity.title">
                <template slot-scope="scope">
                  <el-input
                    :min="0"
                    type="number"
                    size="mini"
                    @input="updateQuantity(scope.row, scope.$index)"
                    v-model="scope.row.quantity"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column :label="columns.measurement_id.title" width="60" v-if="columns.measurement_id.show">
                <template slot-scope="scope">
                  {{ scope.row.product.measurement.name }}
                </template>
              </el-table-column>
              <el-table-column :label="columns.price.title" v-if="columns.price.show">
                <template slot-scope="scope">
                  <el-input
                    :min="0"
                    type="number"
                    size="mini"
                    v-model="scope.row.price"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column :label="columns.currency_rate.title" v-if="columns.currency_rate.show">
                <template slot-scope="scope">
                  <el-input
                    :min="0"
                    type="number"
                    size="mini"
                    v-model="scope.row.currency_rate"
                    :disabled="checkCurrency(scope.row)"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column :label="columns.currency_id.title" v-if="columns.currency_id.show">
                <template slot-scope="scope">
                  <select-currency
                    :size="'mini'"
                    :placeholder="columns.currency_id.title"
                    :id="scope.row.currency_id"
                    v-model="scope.row.currency_id"
                    @input="changeCurrency(scope.row)"
                  ></select-currency>
                </template>
              </el-table-column>

              <el-table-column :label="$t('message.waste') + ' %'" width="80">
                <template slot-scope="scope">
                  <el-input
                    :min="0"
                    :max="99"
                    type="number"
                    size="mini"
                    @input="updateQuantity(scope.row, scope.$index)"
                    v-model="scope.row.waste"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column :label="$t('message.service_sum_for_unit')" >
                <template slot-scope="scope">
                  {{ productRowServiceTotalPerUnit(scope.row) | formatMoney(2)}}
                </template>
              </el-table-column>

              <el-table-column cell-class-name="mm-badge-in-table" :label="columns.services.title" v-if="columns.services.show" min-width="90">
                <template slot-scope="scope" class="birnim">
                    <div class="d-flex">
                      <el-button @click="showServices(scope.row, scope.$index)" :disabled="checkRowServices(scope.row)" type="primary" icon="el-icon-edit" size="mini"></el-button>
                      <el-badge :value="scope.row.serviceList ? scope.row.serviceList.length : 0" class="ml-1" type="danger">
                        <el-button @click="serviceListOpen(scope.row, scope.$index)" type="primary" icon="el-icon-s-unfold" size="mini"></el-button>
                      </el-badge>
                    </div>
                </template>
              </el-table-column>
              <el-table-column :label="columns.color.title" v-if="columns.color.show">
                <template slot-scope="scope">
                    <select-color
                      :size="'mini'"
                      :placeholder="columns.color.title"
                      :id="scope.row.color_id"
                      :allowed_colors="scope.row.colors"
                      :model_id="scope.row.product_model_id"
                      v-model="scope.row.color_id"
                    >
                    </select-color>
                </template>
              </el-table-column>

              <el-table-column :label="columns.package.title" v-if="columns.package.show">
                <template slot-scope="scope">
                  <select-package
                    :size="'mini'"
                    :placeholder="columns.package.title"
                    :id="scope.row.package_id"
                    :allowed_packages="scope.row.packages"
                    v-model="scope.row.package_id"
                  ></select-package>
                </template>
              </el-table-column>
              
              <el-table-column v-if="form.calculation_type_id == 1" :label="$t('message.service')" >
                <template slot-scope="scope">
                  {{ productRowServiceTotal(scope.row) | formatMoney(2)}}
                </template>
              </el-table-column>

              <el-table-column v-if="form.calculation_type_id == 1" :label="$t('message.product')">
                <template slot-scope="scope">
                  {{ productRowProductTotal(scope.row) | formatMoney(2)}}
                </template>
              </el-table-column>

              <el-table-column :label="columns.total.title">
                <template slot-scope="scope">
                  {{ productRowTotal(scope.row,  scope.$index) | formatMoney(2)}}
                </template>
              </el-table-column>
              <el-table-column width="50" v-if="columns.delete.show">
                <template slot-scope="scope">
                  <i
                    @click="removeFromDealProductsListChild(scope.row.id, scope.$index)"
                    class="el-icon-delete icon__delete"
                  ></i>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="12" class="mt-2">
            <select-product
              :size="'medium'"
              :placeholder="$t('message.search_by_product_and_category')"
              :id="product_id"
              v-model="product_id"
              @get_selected_product="get_selected_product"
            >
            </select-product>
          </el-col>
      </el-row>
    </div>
    <div class="app-modal app-modal__full modal-color-bg">
      <el-drawer
      :class="mode ? 'l-modal-style' : 'd-modal-style'"
        :with-header="false"  
        :visible.sync="drawerServices" 
        size="60%"
        :append-to-body="true"
        >
          <div class="app-modal__box">
            <div class="app-modal__in">
              <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0"> {{ $t("message.services") }}</p>
                <div>
                  <crm-store-update-close
                      :permission="$options.name"
                      :button_type="'store'"
                      :loading="loadingButton"
                      @c-submit="submitService(true)"
                      @c-close="closeService()"
                  ></crm-store-update-close>
                </div>
              </div>
            </div>
            <!-- app-modal__header end -->
            
            <div class="app-modal__body p-5 pb-0">
              <div class="product-add s rounded-sm w-100 p-4">
                  <el-switch
                    class="mb-2"
                    v-model="selectedProductRow.service_for_unit"
                    :active-text="$t('message.for_unit')"
                    :inactive-text="$t('message.overall')">
                  </el-switch>
                  
                  <el-table
                    :data="selectedProductServices"
                    show-summary 
                    :summary-method="getSummariesForServices" 
                    border
                    style="width: 100%">
                      <el-table-column
                        prop="name"
                        :label="$t('message.name')"
                        width="180">
                        <template slot-scope="scope">
                          {{ scope.row.name }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="money_amount"
                        :label="$t('message.price_for_unit')">
                        <template slot-scope="scope">
                          <el-input
                            :min="0"
                            :disabled="!selectedProductRow.service_for_unit"
                            type="number"
                            size="mini"
                            @input="updateServiceRow(scope.row, scope.$index)"
                            v-model="scope.row.money_amount"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="money_amount_for_all"
                        :label="$t('message.price_for_all')">
                        <template slot-scope="scope">
                          <el-input
                            :min="0"
                            :disabled="selectedProductRow.service_for_unit"
                            type="number"
                            size="mini"
                            @input="updateServiceRow(scope.row, scope.$index)"
                            v-model="scope.row.price_for_all"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="currency_rate"
                        :label="$t('message.rate')">
                        <template slot-scope="scope">
                          <el-input
                            :min="0"
                            type="number"
                            size="mini"
                            v-model="scope.row.currency_rate"
                            :disabled="checkCurrency(scope.row)"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="currency_id"
                        :label="$t('message.currency')">
                        <template slot-scope="scope">
                          {{ getCurrencyCodeById(scope.row) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="money_amount"
                        :label="$t('message.money_amount')">
                        <template slot-scope="scope">
                          {{ (parseFloat(scope.row.money_amount) * scope.row.currency_rate * parseFloat(selectedProductRow.quantity || 0)) | formatMoney(3)}}
                        </template>
                      </el-table-column>
                  </el-table>
              </div>
            </div>
            <!-- end app-modal__body -->
          </div>
      </el-drawer>
      
      <el-drawer
          :class="mode ? 'l-modal-style' : 'd-modal-style'"
          :append-to-body="true"
          size="70%"
          :with-header="false"
          :visible.sync="drawerProductComposition"
          custom-class="modal-style-new-v"
          ref="drawerProductComposition"
      >
          <product-composition
            @updateProductComposition="updateProductComposition"
            :productComposition="selectedProductComposition"
            ref="drawerProductCompositionChild"
            drawer="drawerProductComposition"
          ></product-composition>
      </el-drawer>
    </div>

    <el-drawer
      :class="mode ? 'l-modal-style' : 'd-modal-style'"
      :with-header="false"
      size="70%"
      :visible.sync="drawerServiceTree"
      @close="closeServiceListDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      append-to-body>
      <div class="">
        <div class="app-modal__in">
            <div class="app-modal__header d-flex f-between p-5 m-title-modal">
              <p class="large--title m-0"> 
                  {{  $t('message.services')+showProductServiceName() }}
              </p>
              <div>
                  <crm-store-update-close
                      :permission="$options.name"
                      :button_type="'asdasd'"
                      :loading="loadingButton"
                      @c-submit="submit(true)"
                      @c-close="drawerServiceTree=false"
                  ></crm-store-update-close>
              </div>
            </div>
        </div>
        <select-price-list
              ref="servicePriceList"
              class="product-add p-0 m-3"
              :size="'large'"
              :placeholder="$t('message.price_list')"
              :id="price_list_id"
              @input="updatePriceListId"
              v-model="price_list_id"
          >
        </select-price-list>

        <div class="product-add p-0 m-3" v-loading="loadingTree">
          
          <el-tree
            ref="serviceTree"
            :data="updatedTreeServices"
            show-checkbox
            node-key="custom_id"
            default-expand-all
            :props="defaultProps">
          </el-tree>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import selectProduct from "@/components/inventory/select-product";
import selectCalculationTypes from "@/components/inventory/select-calculation-types";
import selectCurrency from "@/components/inventory/select-currency";
import selectPackage from "@/components/inventory/select-package";
import selectColor from "@/components/inventory/select-color";
import selectKpTemplate from "@/components/inventory/select-kp-template";
import productComposition from "./product-composition";
import selectPriceList from "@/components/inventory/select-service-price-list";

import { formatMoney } from "@/filters/index";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import show from "@/utils/mixins/show";
import deal_product_childs from "@/utils/mixins/views/deal_product_childs";

import CrmOnlyNumberInput from '../../../../components/crm/crm-only-number-input';

export default {
  components: {
    selectCalculationTypes,
    selectProduct,
    selectCurrency,
    selectPackage,
    selectColor,
    selectKpTemplate,
    CrmOnlyNumberInput,
    productComposition,
    selectPriceList
  },
  mixins: [show, drawer, deal_product_childs],
  props: {
    filial_id: {
      default: null,
    },
    deal_calculate_money: {
      default: 'deal',
    },
    disable_product_quantity: {
      default: false,
    },
  },
  data() {
    return {
      price_list_id: null,
      loadingTree: false,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
      // save: "dealProducts/store",
    }),

    updatePriceListId(val){
      this.loadingTree = true;
      this.updateServiceCategoryTree({price_list_id: this.price_list_id})
        .then(async res => {
            this.updatedTreeServices = JSON.parse(JSON.stringify(this.treeInventory));
            this.updatedTreeServices = this.updatedTreeServices.filter(el => el.name);
            await this.updateTree(this.updatedTreeServices);
            this.loadingTree = false;
        });
    }
    
  }
};
</script>
<style>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .mm_custom_tree .is-expanded {
    width: 100%;
  }
  .mm-child_prod_list_table .cell{
    overflow: inherit !important;
  }
  .mm-pointer {
    cursor: pointer;
  }
  .d-modal-style .el-tree-node:focus>.el-tree-node__content{
    background-color: #0b1d35;
  }
  .el-table .light__green{
    background: rgb(124, 245, 124);
  }
</style>
