import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/checkListMiniTasks',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/checkListMiniTasks',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/checkListMiniTasks/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/checkListMiniTasks/${id}`,
        method: 'get',
    })
}
export function update(data) {
    return request({
        url: `/checkListMiniTasks/${data.id}`,
        method: 'put',
        data
    })
}

export function deleteMiniTaskFiles(params) {
    return request({
        url: `/checkListMiniTasks/deleteMiniTaskFile/${params.mini_task_id}/${params.file}`,
        method: 'delete',
        params
    })
}
export function destroy(id) {
    return request({
        url: `/checkListMiniTasks/${id}`,
        method: 'delete',
    })
}