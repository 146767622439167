import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { filter } from "./properties/filter";
import { model } from "./properties/model";

export const mutations = {
    SET_LIST: (state, managerReport) => { 
		if (!_.isEmpty(managerReport.clients)) {
			managerReport.clients.forEach((client, index) => {
				let all_deals = JSON.parse(JSON.stringify(client.deals));
				all_deals = all_deals.concat(client.deals_except_filter_date);
				if (_.isArray(all_deals) && all_deals.length > 0) {
					let debt_deals = all_deals.reduce((acc, obj) => acc + (obj.money_amount - parseFloat(obj.paid_money)), 0);
					client.all_debt = debt_deals;
				}
			});
		}

        state.list = managerReport;        
    },
    SET_INVENTORY: (state, departments) => (state.inventory = departments),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, department) => {
        state.model = department
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.model = permissions
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model)); 
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },
    EMPTY_LIST: (state) => {
        state.list = [];
    },
	 EMPTY_INVENTORY: (state) => {
		state.inventory = [];
  	}
};
