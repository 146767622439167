export const getters = {
    list: state => state.list,
    pagination: state => state.pagination,
    filter: state => state.filter,
    columns: state => state.columns,
    phoneCallList: state => state.phoneCallList,
    totalStatistics: state => state.totalStatistics,
    trunckDiagrams: state => state.trunckDiagrams,
    userDiagrams: state => state.userDiagrams,
  };
  