export const model = {
    id: null,
    name: '', 
    money_amount: 0, 
    percentage: 0, 
    calculation_by: null, 
    active: true, 
    currency_id: null, 
    created_at: '',
    updated_at: '',
  };
  