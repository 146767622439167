export const mutations = {
	SET_NOTIFICATIONS: (state, notifications) => {
		state.posts = notifications;
	},

    SET_REASONS_DEALS: (state, reasons) => {              
        state.reasonsDeals = reasons;        
    },

    SET_TIME_LINE_TASKS: (state, res) => {
        state.timeLineTasks = res.data.tasks;
        state.timeLineTaskGroups = res.groups;
    },

    SET_ACTIVITY_DIAGRAM: (state, activities) => {
        state.activities = activities;   
    },

    SET_SALE_PERIOD_DIAGRAM: (state, saleByPeriod) => {
        state.saleByPeriod = saleByPeriod;   
    },

    SET_COUNTRY_DEALS: (state, countryDeals) => {
        state.countryDeals = countryDeals;   
    },

    SET_DEAL_GOALS: (state, dealGoals) => {
        state.dealGoals = dealGoals;   
    },

    SET_DEAL_PAYMENTS: (state, dealPayments) => {
        state.dealPayments = dealPayments;   
    },

    SET_TASK_COUNTS: (state, taskCounts) => {
        state.tasksByCompleted = taskCounts;   
    },

    SET_COUNTRY_PAYMENT: (state, countryPayments) => {
        state.dealPaymentByCountry = countryPayments;   
    },

    SET_DEAL_PAYMENT_SELECTED_POINT: (state, dealPayments) => {
        state.dealPaymentSelectedPoint = dealPayments;   
    },

    SET_DEAL_PAYMENT_TERMS: (state, dealPaymentTerms) => {
        state.dealPaymentTerms = dealPaymentTerms;   
    },

    PRODUCT_SELL_STATISTICS: (state, productSellStatistics) => {
        state.productSellStatistics = productSellStatistics;   
    },

    SHIPMENT_TERMS: (state, shipmentTerms) => {
        state.shipmentTerms = shipmentTerms;   
    },

    TOP_SUCCESSFUL_DEALS: (state, topSuccessfulDeals) => {
        state.topSuccessfulDeals = topSuccessfulDeals;   
    },

    CATEGORY_STATISTIC_BY_SELLING_PRODUCT: (state, categoryStatisticBySellingProduct) => {
        state.categoryStatisticBySellingProduct = categoryStatisticBySellingProduct;   
    },

    DEAL_TYPES_CHART: (state, dealTypesChart) => {
        state.dealTypesChart = dealTypesChart;   
    },

    STAGE_DEALS: (state, stageDeals) => {
        state.stageDeals = stageDeals;   
    },

    USERS_DEALS: (state, usersDeals) => {
        state.usersDeals.users = usersDeals;
        state.usersDeals.deals_count = 0;
        state.usersDeals.deal_money_amount = 0;
        usersDeals.forEach(user => {
            state.usersDeals.deals_count += parseFloat(user.count);
            state.usersDeals.deal_money_amount += parseFloat(user.money_amount);
        });
        let total = parseFloat(state.usersDeals.deal_money_amount);
        let devition  = (total && total > 0) ? total : 1;

        state.usersDeals.users.forEach(user => {
            user.percentage = Math.round(parseFloat(user.money_amount) * 100 / parseFloat(devition))
        });

    },

    CLIENT_STATISTICS: (state, clientStatistics) => {
        state.clientStatistics = clientStatistics;   
    },

    MANAGERS_PROFIT: (state, managersProfit) => {
        state.managersProfit = managersProfit;   
    },
    
    EMPTY_LIST: (state) => {
        state.list = [];
    },

    UPDATE_STAGE_DEAL_PAGINATION: (state, stageDealPagination) => {
        state.stageDealPagination[stageDealPagination.key] = stageDealPagination.value
    },
};
