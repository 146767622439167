<template>
    <div class="timeline-wrap" v-loading="loadingData">
        <div
            v-for="message in list"
            :key="message.id"
            class="timeline-card p-relative"
        >
            <div class="timeline-items d-flex f-wrap mb-5">
                <div class="timeline-items__right rounded-sm">
                    <div class="timeline-card-header p-4">
                        <p class="m-0">
                            <strong class="mr-2">
                                {{ message.type.name }}
                            </strong>
                            <span>{{ message.created_at }}</span>
                        </p>
                    </div>

                    <div v-if="message.text" class="p-4 timeline-card-header">
                        <p class="lh-base font-medium m-0">
                            {{ message.text }}
                        </p>
                    </div>

                    <div class="p-4">
                        <div
                            @click="fileDownload(message.id, file.name)"
                            class="d-flex align-center mir font-medium pb-4"
                            v-for="file in message.files"
                            :key="file.id"
                        >
                            <span class="text-descr__text  text-reset">
                            <i class="el-icon-document-checked"></i> {{
                            file ? file.name : ""
                            }}
                            </span>
                            <span class="text-descr__text  text-reset"
                                >
                                <i class="el-icon-download"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <el-button
                v-if="count !== list.length"
                @click="getMore()"
                type="success"
                size="small"
                round
                >{{ $t("message.more")}}</el-button
            >
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
    mixins: [form, drawer],
    props: {
        deal_id: {
            default: null,
        },
    },
    data() {
        return {
            loadingData: false,
            selected_deal_id: null,
            activeName: "first1",
            fileList: [],
            updateImage: [],
            blobType: "",
            fileName: "",
            fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
        };
    },
    computed: {
        ...mapGetters({
            list: "dealCorrespondences/list",
            count: "dealCorrespondences/count",
            model: "dealCorrespondences/model",
            rules: "dealCorrespondences/rules",
            types: "dealCorrespondences/types",
            last_id: "dealCorrespondences/last_id",
        }),
    },
    watch: {
        deal_id: function (val) {
            if (val) {
                this.selected_deal_id = val;
                this.getTypes();
                let data = { deal_id: val, last_id: this.last_id };
                this.loadingData = true;
                this.updateList(data)
                    .then(() => {
                        this.loadingData = false;
                    })
                    .catch((err) => {
                        this.loadingData = false;
                    });
            }
        },
    },
    methods: {
        ...mapActions({
            save: "dealCorrespondences/store",
            updateList: "dealCorrespondences/getAll",
            getTypes: "dealCorrespondences/getTypes",
            empty: "dealCorrespondences/empty",
            emptyDealCorrespondences: "dealCorrespondences/emptyList",
            downloadDealCorrespondence:"dealCorrespondences/downloadDealCorrespondence",
        }),
        beforeRemove(file, fileList) {
            this.form.file_path = file.raw;
        },
        handleExceed(files, fileList) {
            this.$message.warning("Can only choose 1 file!");
        },
        handleClick(tab, event) {
            this.form = {};
        },
        submit(type_id) {
            let refName = "form-" + type_id.toString();
            if (!this.form.text && !this.form.file_path) {
                return this.$message({
                    type: "warning",
                    message: "Введите один из текста или файла",
                });
            }

            this.form.type_id = type_id;
            this.form.deal_id = this.selected_deal_id;
            let formData = new FormData();
            for (const key in this.form) {
                formData.append(key, this.form[key]);
            }
            this.loadingButton = true;
            this.dataSaved = true;
            this.save(formData)
                .then((res) => {
                    this.loadingButton = false;
                    this.$alert(res);

                    this.emptyDealCorrespondences().then(() => {
                        this.fileList = [];
                        this.getMore();
                    });
                    this.empty();
                })
                .catch((err) => {
                    this.loadingButton = false;
                    this.$alert(err);
                });
        },
        getMore() {
            if (this.list.length !== this.count) {
                let data = {
                    deal_id: this.selected_deal_id,
                    last_id: this.last_id,
                };
                this.updateList(data)
                    .then(() => {
                        this.loadingData = false;
                    })
                    .catch((err) => {
                        this.loadingData = false;
                    });
            }
            if (this.count == 0) {
                let data = {
                    deal_id: this.selected_deal_id,
                    last_id: this.last_id,
                };
                this.updateList(data);
            }
        },
        createImageList(file) {
            if (file.size > 5000000) {
                this.fileList = [];
                return this.$message({
                    type: "warning",
                    message: "загрузите файл размером менее 5 МБ",
                });
            }
            this.form.file_path = file.raw;
        },
        fileDownload(dealCorrespondence_id, fileName) {
            let data = { dealCorrespondence_id: dealCorrespondence_id, file: fileName };
            this.downloadDealCorrespondence(data)
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));

                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
    },
};
</script>


<style>
</style>
