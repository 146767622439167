export const filter = {
    id : '',  
    company_id: null, 
    client_id: null, 
    user_id: null,
    filial_id: null,
	 project_id: null,
    created_range: null,
    showDealsWithOffer: false,  
  };
  