import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },

    filial_ids : {
        show: true,
        title: i18n.t('message.filials'),
        sortable: true,
        column: 'filial_ids'
    },

    is_default : {
        show: true,
        title: i18n.t('message.is_default'),
        sortable: true,
        column: 'is_default'
    },

    active : {
        show: true,
        title: i18n.t('message.active'),
        sortable: true,
        column: 'active'
    },

    approved : {
        show: true,
        title: i18n.t('message.approved'),
        sortable: true,
        column: 'approved'
    },

    begin_date : {
        show: true,
        title: i18n.t('message.begin_date'),
        sortable: true,
        column: 'begin_date'
    },

    end_date : {
        show: true,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },

    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },

    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },

    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
