import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    task_reason_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.reason')}), trigger: 'change' },
    ],
    task_board_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.board')}), trigger: 'change' },
    ],
	 project_id: [
		{ required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.project')}), trigger: 'change' },
 	 ],
};
