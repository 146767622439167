<template>
    <div class="shadow task-scroll-khan-product h453">
        <div class="mt-4" v-if="productSellStatistics.length > 0">
            <div class="itme-pragress-big" v-for="product in productSellStatistics" :key="product.id">
                <span class="t-color itme-pragress-user-name"> 
                    {{ product.name }} - {{ product.money_amount | formatMoney(2) }}
                </span>
                <span class="itme-pragress">
                    <el-progress
                        class="psum"
                        :text-inside="true"
                        :stroke-width="26"
                        :percentage="100"
                        :format="format"
                    ></el-progress>
                    <el-progress
                        class="top_R"
                        :text-inside="true"
                        :stroke-width="24"
                        :percentage="Math.round(product.profit*100/product.money_amount)"
                        status="success"
                    ></el-progress>
                </span>
                <ul class="rezultat">
                    <li>{{ $t("message.Total amount") }}: <span>{{ product.money_amount | formatMoney(2) }}</span></li>
                    <li>{{ $t("message.profit_m") }}: <span>{{ product.profit | formatMoney(2) }}</span></li>
                </ul>
            </div>
            
        </div>
        <div v-else class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t('message.no_data_yet') }}
            </h1>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";

export default {
    components: {},
    props: {
        period: {
            default: "weekly",
        },
        filterForm: {
            default: null,
        },
    },
    data() {
        return {
            radio1: "Неделя",
            expectedSum: "100 000 000",
            count: 1,
            counts: [],
            items: [],
        };
    },
    watch: {
        period: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({
                        period: this.period,
                        filter: this.filterForm,
                    });
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            productSellStatistics: "home/productSellStatistics",
        }),
    },
    methods: {
        ...mapActions({
            productSellStatisticsData: "home/productSellStatistics",
        }),
        format(percentage) {
            return '';
        },
        updateChartData(data) {
            this.productSellStatisticsData(data)
                .then(res => {
                    this.items = JSON.parse(JSON.stringify(this.productSellStatistics));

                })
        }
    },
};
</script>
<style>
.h453 {
    height: 453px;
}
</style>
