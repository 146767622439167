import request from '@/utils/request';

export function taskCounts(params) {
    return request({
        url: '/tasks/taskCounts',
        method: 'get',
        params
    })
}

export function index(params) {
    return request({
        url: '/tasks',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/tasks',
        method: 'post',
        data
    })
}
export function storeFiles(data) {
    return request({
        url: '/tasks/storeFiles',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/tasks/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/tasks/${id}`,
        method: 'get',
    })
}

export function downloadFile(task_id, file) {
    return request({
        url: `/tasks/downloadFile/${task_id}/${file}`,
        method: 'get',
        responseType: 'blob'
    })
}

export function deleteFile(task_id, file) {
    return request({
        url: `/tasks/deleteFile/${task_id}/${file}`,
        method: 'get',
    })
}

export function update(data) {
    return request({
        url: `/tasks/${parseInt(data.get('id'))}`,
        method: 'post',
        data
    })
}

export function changeIndex(data) {
    return request({
        url: `/tasks/changeIndex/${data.moved_task_id}`,
        method: 'put',
        data
    })
}

export function archivedTasks(params) {
    return request({
        url: '/tasks/archivedTasks',
        method: 'get',
        params
    })  
}

export function deletedTasks(params) {
    return request({
        url: '/tasks/deletedTasks',
        method: 'get',
        params
    })
}

export function archivateTask(id) {
    return request({
        url: `/tasks/archivateTask/${id}`,
        method: 'get'
    })
}

export function restoreTask(id) {
    return request({
        url: `/tasks/restoreTask/${id}`,
        method: 'get'
    })
}

export function deleteTask(task_id, task_reason_id) {
    return request({
        url: `/tasks/deleteTask/${task_id}/${task_reason_id}`,
        method: 'delete'
    })
}

export function destroy(id) {
    return request({
        url: `/tasks/${id}`,
        method: 'delete',
    })
}

export function getTasksForCalendar(params) {
    return request({
        url: '/tasks/getTasksForCalendar',
        method: 'get',
        params
    })
}