export const model = {
    id: null,
    name: '',
    color: "#409EFF",
    index: null,
    priority: '',
    user_id: '',
    filial_id: null,
    created_at: '',
    updated_at: ''
};  