import _ from 'lodash';
export default {
  props:{
    selectedItem:{
      type: Object
    }
  },   
  data() {
    return {
      loadingData: false
    }
  },  
  methods:{    
    afterOpen() {        
      this.fetchData()
    },
    fetchData() {
      if (!this.loadingData && this.selectedItem) {  
          this.loadingData=true        
          this.show(this.selectedItem.id).then(res => {   
              this.loadingData = false              
          }).catch(err => {
              this.loadingData = false
          });
      }
    },
  }
}
