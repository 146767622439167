<template>
  <div v-loading="loadingData">
    <div id="chart" v-if="dealSources.ids && dealSources.ids.length > 0">
      <!-- <apexchart type="radialBar" :height="size" ref="chart" :options="chartOptions" :series="series" :labels="labels">
      </apexchart> -->
      <v-chart class="e-chart" :option="option" autoresize />
    </div>
    <div v-else class="eror">
      <img src="/img/dashboard.png" alt="Logo" class="d-block" />
      <h1>
        {{ $t('message.no_data_yet') }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  PolarComponent,
  TooltipComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  PolarComponent,
  TooltipComponent,
  BarChart,
  CanvasRenderer
]);
import VChart, { THEME_KEY } from "vue-echarts";

export default {
  components: {
    VChart
  },
  provide() {
    return {
      [THEME_KEY]: "dark"
    };
  },
  props: {
    period: {
      type: String,
      default: 'weekly'
    },
    size: {
      default: '417'
    },
    filterForm: {
      default: null
    }
  },
  data() {
    return {
      loadingData: false,
      option: {
        backgroundColor: "transparent",
        polar: {
          radius: [30, '80%']
        },
        angleAxis: {
          max: null,
          startAngle: 90
        },
        radiusAxis: {
          type: 'category',
          data: []
        },
        tooltip: {},
        series: {
          type: 'bar',
          data: [],
          coordinateSystem: 'polar',
          label: {
            show: true,
            position: 'middle',
            formatter: '{b}: {c}%'
          }
        }
      }
    };
  },
  watch: {
    watchAll: {
      handler: async function (val) {
        this.loadingData = true;
        this.debouncedupdateChartData({ period: this.period, filter: this.filterForm }, this);
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapGetters({
      dealSources: "sources/dealSources"
    }),
    watchAll() {
      return (this.period + JSON.stringify(this.filterForm));
    }
  },
  methods: {
    ...mapActions({
      updateDealSources: "sources/dealSources"
    }),

    updateChartData(data) {
      this.loadingData = true;
      this.updateDealSources(data)
        .then((res) => {
          // Echart
          if (this.dealSources && this.dealSources.percents && this.dealSources.percents.length) {
            this.option.series.data = this.dealSources.percents
            this.option.angleAxis.max = Math.max(...this.dealSources.percents)
          }
          if (this.dealSources && this.dealSources.names && this.dealSources.names.length) {
            this.option.radiusAxis.data = this.dealSources.names
          }
          this.loadingData = false;
        }).catch(err => {
          this.loadingData = false;
        });
    },
    debouncedupdateChartData: _.debounce((data, self) => {
        if (_.isFunction(self.updateChartData)) {
          self.updateChartData(data)
        }
    }, 500),
  }
};
</script>


<style>
.eror {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.eror h1 {
  color: #fff;
  font-size: 20px;
}

.eror img {
  width: 160px;
}
</style>
