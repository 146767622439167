import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";

export const state = {
    list: [],
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules,
    default_list_for_loader: [
        {
          id: 1,
          name: '11 - board',
          color: '#19D328',
          filial_id: 1,
          deals: [
            {
              id: 1,
              name: '11 -deal',
            },
            {
              id: 2,
              name: '22 - deal',
            }
          ]
        },
        {
          id: 2,
          name: '22 - board',
          color: '#19D328',
          filial_id: 1,
          deals: [
            {
              id: 1,
              name: '11 -deal',
            },
            {
              id: 2,
              name: '22 -deal',
            }
          ]
        },
        {
            id: 3,
            name: '33 - board',
            color: '#19D328',
            filial_id: 1,
            deals: [
              {
                id: 1,
                name: '11 -deal',
              },
              {
                id: 2,
                name: '22 -deal',
              }
            ]
        },
    ]  
};
