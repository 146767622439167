<template>
    <div class="d-flex">
        <div :class="selected_prior.priorro" class="el-select-priprrp boder-none-w w-100">
            <el-select
                size="medium"
                v-model="selected"
                @input="dispatch"
                :placeholder="$t('message.priority')"
                class="w-100"
                clearable
                 >
                    <el-option v-for="item in priorities" :key="item.id" :label="item.id" :value="item.id">
                        <div :class="item.priorro" class="w-100 m-1 text-center">
                            {{ item.id }}
                        </div>
                    </el-option>
            </el-select>
        </div>
        <!-- <div :class="selected_prior.priorro" class="ml-2 w-100 text-center rounded">
            {{ selected_prior.id }}
        </div> -->
    </div>
  </template>
  <script>
    import {mapGetters, mapActions} from "vuex";
    export default {
      props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        }
      },
      watch:{
        id:{
          handler: function() {
              this.selected = this.id
          },
          immediate: true
        },
        selected:{
          handler: function() {
              if(this.selected){
                let prior = this.priorities.find(el => el.id == this.selected);
                if(prior){
                    this.selected_prior = prior;
                }
              }else{
                this.selected_prior = {}
              }
          },
          immediate: true
        }
      },
      data() {
        return {
            selected: null,
            selected_prior: {},
            priorities: [
                {
                    id: 1,
                    priorro: "prior1"
                },
                {
                    id: 2,
                    priorro: "prior2"
                },
                {
                    id: 3,
                    priorro: "prior3"
                },
                {
                    id: 4,
                    priorro: "prior4"
                },
                {
                    id: 5,
                    priorro: "prior5"
                },
                {
                    id: 6,
                    priorro: "prior6"
                },
                {
                    id: 7,
                    priorro: "prior7"
                },
                {
                    id: 8,
                    priorro: "prior8"
                },
                {
                    id: 9,
                    priorro: "prior9"
                },
                {
                    id: 10,
                    priorro: "prior10"
                }
            ],
        }
      },
      mounted() {
        if (this.reasons && this.reasons.length === 0) this.updateInventory()
      },
      computed: {
      },
      methods: {
        dispatch(e) {
            this.$emit('input', e);
            this.selected = e
        }
      },
    }

  </script>
