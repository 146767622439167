import { index, show, store, getTypes, downloadDealCorrespondence, getAll, update, destroy, getEmails,
		 saveEmailToDeal, getEmailsCount, getDetailMeetingForCalendar } from '@/api/deals/dealCorrespondences';

export const actions = {
    
    index({commit}, params = {}){
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit('SET_LIST', res.data.result.data.dealCorrespondences)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })  
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getTypes({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getTypes(params).then(res => {
                commit("SET_TYPES", res.data.result.data.types);
                resolve(res.data.result);
            }).catch(err => {
                reject(err.response.data);
            })
        })
    },

    downloadDealCorrespondence({ commit }, data){
        const { dealCorrespondence_id, file } = data;
        return new Promise((resolve, reject) => {
            downloadDealCorrespondence(dealCorrespondence_id, file).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    
    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.result.data.dealCorrespondence);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

	 getDetailMeetingForCalendar({ commit }, id) {
		return new Promise((resolve, reject) => {
			getDetailMeetingForCalendar(id).then(res => {
				//   commit("SET_MODEL", res.data.result.data.dealCorrespondence);
				  resolve(res.data.result.data);
			 }).catch(err => {
				  reject(err.response.data)
			 })
		})
  },

    getAll({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getAll(params).then(res => {
                commit('SET_LIST', res.data.result.data.dealCorrespondences)
                commit('SET_LAST_ID', res.data.result.data.dealCorrespondences)
                commit('SET_COUNT', res.data.result.count)
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    getEmailsCount({commit}, params = {}){
        return new Promise((resolve, reject) => {
            getEmailsCount(params).then(res => {
                commit('SET_EMAIL_COUNT', {total_email_message_count: res.data.result.data.total_email_message_count, all_emails_messages: res.data.result.data.all_emails_messages});
                commit('SET_EMAIL_LIST', {all_emails_messages: res.data.result.data.all_emails_messages, search_message: res.data.result.data.search_message});
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    // getEmails({commit}, params = {}){
    //     return new Promise((resolve, reject) => {
    //         getEmails(params).then(res => {
    //             commit('SET_EMAIL_LIST', res.data.result.data.all_emails_messages);
    //             resolve(res.data.result)
    //         }).catch(err => {
    //             reject(err.response.data)
    //         })  
    //     })
    // },

    saveEmailToDeal({ commit }, data) {
        return new Promise((resolve, reject) => {
            saveEmailToDeal(data)
                .then(res => {
                    commit('SET_EMAIL_COUNT', {total_email_message_count: res.data.result.data.total_email_message_count, all_emails_messages: res.data.result.data.all_emails_messages});
                    commit('SET_EMAIL_LIST', {all_emails_messages: res.data.result.data.all_emails_messages, search_message: res.data.result.data.search_message});
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    
    emptyList({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_LIST");
            resolve()
        })
    },

    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    

}

