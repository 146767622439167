import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: true,
        column: 'money_amount'
    },
    percentage : {
        show: true,
        title: i18n.t('message.percentage'),
        sortable: true,
        column: 'percentage'
    },
    active : {
        show: true,
        title: i18n.t('message.active'),
        sortable: true,
        column: 'active'
    },
    currency_id : {
        show: true,
        title: i18n.t('message.currency_id'),
        sortable: true,
        column: 'currency_id'
    },
    calculation_by : {
        show: true,
        title: i18n.t('message.calculation_by'),
        sortable: true,
        column: 'calculation_by'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
