import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/clients',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/clients',
        method: 'post',
        data
    })
}
export function storeProviderProductServices(data) {
	return request({
		 url: '/clients/storeProviderProductServices',
		 method: 'post',
		 data
	})
}
export function storeClientComment(data) {
	return request({
		 url: '/clients/storeClientComment',
		 method: 'post',
		 data
	})
}
export function inventory(params) {
    return request({
        url: "/clients/inventory",
        method: "get",
        params
    });
}

export function freeClients(params) {
    return request({
        url: "/clients/freeClients",
        method: "get",
        params
    });
}

export function show(id) {
    return request({
        url: `/clients/${id}`,
        method: 'get',
    })
}

export function clientDeals(id) {
    return request({
        url: `/clients/clientDeals/${id}`,
        method: 'get',
    })
}

export function getProviderProductServices(id) {
	return request({
		 url: `/clients/getProviderProductServices/${id}`,
		 method: 'get',
	})
}

export function filterClientsByType(params) {
    return request({
        url: `/clients/filterClientsByType`,
        method: 'get',
        params
    })
}

export function getTypes(params) {
    return request({
        url: '/clients/get/types',
        method: 'get',
        params
    })
}

export function getGenders(params) {
    return request({
        url: '/clients/get/genders',
        method: 'get',
        params
    })
}
export function companyClients(company_id) {
    return request({
        url: `/clients/getCompanyClients/${company_id}`,
        method: 'get',
    })
}
export function personClients(params) {
    return request({
        url: "/clients/getPersonClients",
        method: "get",
        params
    });
}
export function update(data) {
    return request({
        url: `/clients/${parseInt(data.get('id'))}?_method=PUT`,
        method: 'post',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/clients/${id}`,
        method: 'delete',
    })
}
export function clientDashboard(params) {
    return request({
        url: `/clients/clientDashboard/${params.client_id}`,
        method: 'get',
        params
    })
}