import {i18n} from '@/utils/i18n';

export const rules = {
    type_id: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.notifationTypes')}), trigger: 'change' },
    ],
	 user_ids: [
		{ type: 'array', required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.users')}), trigger: 'blur' },
		],
		text: [
			{ required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.text_t')}), trigger: 'change' },
		],
};
