export const model = {
    id: null,
    real_participants: [],
	begin_time: '',
	end_time: '',
	description: '',
	conclusion: '',
    created_at: '',
    updated_at: '',
  };
  