export const filter = {
  id : '',  
  deal_id: null, 
  user_id: null, 
  status: '',
  priority: '',
  filial_id : null,  
  label_id : null,  
  task_reason_id: '', 
  created_at: '',
  updated_at: ''  
};
  