<template>
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">{{$t('message.client')}}</p>
          <div>
            <el-button  type="info" size="medium" @click="close()" >{{$t('message.close')}}</el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body mt-5 p-5 pb-0" >
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-tabs v-model="activeName" @tab-click="handleClick" >
            <el-tab-pane :label="$t('message.dashboard')" name="dashboard">
                <div class="card-table-header table-crm-smart" v-loading="loadTab">
                    <div class="dashboard__flex dashboard__photo-wq mb-4">
                        <div class="dashboard__photo">
                            <img :src="model.avatar && model.avatar[0] ? (model.image_url + '/' + model.avatar[0].path) : '/img/default-user-icon.png'" alt="">
                        </div>
                        <div class="dashboard__info">
                            <!-- <h5>"REAL EVRO TRADE" МЧЖ</h5> -->
                            <h5>{{ model.full_name }}</h5>
                            <div>
                                <p><span class="dashboard__margin el-icon-phone"></span><span>{{ model.phone }}</span></p>
                                <p><span class="dashboard__margin el-icon-message"></span><span>{{ model.email ? model.email : $t('message.no_email') }}</span></p>
                                <p><span class="dashboard__margin el-icon-location"></span><span>{{ model.city ? model.city.name + ',' : '' }} {{ model.country ? model.country.name : ''}}</span></p>
                            </div>
									<div class="dashboard__info" v-if="model.client_contact_people && model.client_contact_people.length > 0 && model.client_contact_people[0].full_name">
									 	<h5>{{ $t('message.contact_person') }}</h5>
										<ul>
											<li v-for="(contact_person, index) in model.client_contact_people" :key="'contact_person-'+index">
												{{++index + ') '}}
												{{contact_person.full_name ? contact_person.full_name : ''}},
												{{contact_person.position ? contact_person.position : ''}},
												{{contact_person.birth_date ? contact_person.birth_date : ''}}
												<!-- {{contact_person.datediff ? contact_person.datediff : ''}} -->
											</li>
										</ul>
									</div>
                        </div>
                    </div>
                    <el-row :gutter="10" class="mb-2">
                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-card class="mm-payment-box">
                          <div class="mm-payment-status">
                            {{ $t('message.payment_status') }}: 
                            <span class="mm-font-700 ml-2">{{ $t('message.bad') }}</span>
                          </div>
                        </el-card>
                      </el-col>

                      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                        <el-card class="mm-avarage_volume-box">
                          <div class="mm-payment-status">
                            {{ $t('message.avarage_volume') }}: 
                            <span class="mm-font-700 ml-2">{{ model.avarage_volume ? model.avarage_volume : 0 }}</span>
                          </div>
                        </el-card>
                      </el-col>
                    </el-row>
                    <!-- START FILTER BY PERIOD  -->
                    <el-row :gutter="10" class="mb-2">
                        
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                            <div class="filter_home cilent-show-zn">
                                <el-radio-group
                                    v-model="clientDashboardPeriod"
                                    size="medium"
                                    class="zindex-100"
                                >
                                    <el-radio-button label="weekly">
                                        {{
                                            $t("message.weekly")
                                        }}</el-radio-button
                                    >
                                    <el-radio-button label="monthly"
                                        >{{ $t("message.monthly") }}
                                    </el-radio-button>
                                    <el-radio-button label="yearly"
                                        >{{ $t("message.yearly") }}
                                    </el-radio-button>
                                </el-radio-group>
                            </div>
                        </el-col>
                    </el-row>
                    <!-- END FILTER BY PERIOD  -->

                    <el-row :gutter="10">
                        <!-- START CHART ВСЕГО СДЕЛОК -->
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div class="dashboard__item mb-4">
                                <h5 class="board__title"> {{ $t('message.all_deals') }} </h5>
                                <div class="dashboard__space">
                                    <div class="dashboard__left">
                                        <Chart :percent="clientDashboard.deal ? (clientDashboard.deal.fact > 0 ? clientDashboard.deal.successful_deals/clientDashboard.deal.fact : 0) : 0"/>
                                    </div>
                                    <div class="dashboard__right">
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.succesfull') }}:</span>
                                            <span> {{ clientDashboard.deal ? clientDashboard.deal.successful_deals : 0 }} </span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.fact') }}:</span>
                                            <span>{{ clientDashboard.deal ? clientDashboard.deal.fact : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.difference') }}:</span>
                                            <span>{{ clientDashboard.deal ? (clientDashboard.deal.fact -  clientDashboard.deal.successful_deals) : 0}}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.active_d') }}:</span>
                                            <span>{{ clientDashboard.deal ? clientDashboard.deal.active : 0 }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <!-- END CHART ВСЕГО СДЕЛОК -->

                        <!-- START CHART ПОКАЗАТЕЛИ -->
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div class="dashboard__item mb-4">
                                <div>
                                    <h5 class="board__title1">{{ $t('message.indicators') }}</h5>
                                </div>
                                <div>
                                <productBySumm :products="clientDashboard.products ? clientDashboard.products : []"/>
                                </div>
                            </div>
                        </el-col>
                        <!-- END CHART ПОКАЗАТЕЛИ -->
                    </el-row>

                    <el-row :gutter="10">
                        <!-- START CHART ВСЕГО ЗАДАЧ -->
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div class="dashboard__item mb-4">
                                <h5 class="board__title">{{ $t('message.all_tasks') }}</h5>
                                <div class="dashboard__space">
                                    <div class="dashboard__left">
                                        <Chart :percent="clientDashboard.task ? (clientDashboard.task.fact > 0 ? clientDashboard.task.completed_tasks/clientDashboard.task.fact : 0) : 0"/>
                                    </div>
                                    <div class="dashboard__right">
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.completed_tasks') }}:</span>
                                            <span>{{ clientDashboard.task ? clientDashboard.task.completed_tasks : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.fact') }}:</span>
                                            <span>{{ clientDashboard.task ? clientDashboard.task.fact : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.difference') }}:</span>
                                            <span>{{ clientDashboard.task ? (clientDashboard.task.fact - clientDashboard.task.completed_tasks) : 0 }}</span>
                                        </div>
                                        <div class="dashboard__list">
                                            <span>{{ $t('message.active_d') }}:</span>
                                            <span>{{ clientDashboard.task ? clientDashboard.task.active : 0 }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <!-- END CHART ВСЕГО ЗАДАЧ -->

                        <!-- START CHART ПОКАЗАТЕЛИ -->
                        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                            <div class="dashboard__item mb-4">
                                <div>
                                    <h5 class="board__title1">{{ $t('message.indicator_by_quantities') }}</h5>
                                </div>
                                <div>
                                <productByQuantity :products="clientDashboard.products ? clientDashboard.products : []"/>
                                </div>
                            </div>
                        </el-col>
                        <!-- END CHART ПОКАЗАТЕЛИ -->
                      </el-row>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('message.client')" name="client">
              <el-row :gutter="10" v-loading="loadingData">
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.full_name') }}:</div>
                    <div><b>{{ model.full_name }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.phone') }}:</div>
                    <div><b>{{ model.phone }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.type') }}:</div>
                    <div><b>{{ model.type ?  model.type.name : ''}}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.inn') }}:</div>
                    <div><b>{{ model.inn }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.gender') }}:</div>
                    <div><b>{{ model.gender ? model.gender.name : '' }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.country') }}:</div>
                    <div><b>{{ model.country ? model.country.name : '' }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.city') }}:</div>
                    <div><b>{{ model.city ? model.city.name : '' }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.actual_address') }}:</div>
                    <div><b>{{ model.actual_address }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.company') }}:</div>
                    <div><b>{{ model.company ? model.company.name : '' }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.email') }}:</div>
                    <div><b>{{ model.email }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.client_type') }}:</div>
                    <div><b class="ml-2" v-for="client_type in model.clientTypes" :key="'type' + client_type.id" >{{ client_type.name }} ,</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.passport_serial_number') }}:</div>
                    <div><b>{{ model.passport_serial_number }}</b></div>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                  <div class="user-info">
                    <div class="u-i-lable">{{ $t('message.passport_validity') }}:</div>
                    <div><b>{{ model.passport_validity }}</b></div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="10" v-if="model.comments && model.comments.length > 0" class="mt-4">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <h2 class="text-start">{{$t('message.comment')}}</h2>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <ul>
                    <li v-for="(comment, index) in model.comments" :key="'comment-'+index">
                      {{++index}}) {{ comment.comment }}
                    </li>
                  </ul>
                </el-col>
              </el-row>
            </el-tab-pane>

            <el-tab-pane :label="$t('message.deals')" name="deals">
              <div class="card-table-header table-crm-smart" v-loading="loadTab">
                <table class="table-my-code table-bordered table__mynight">
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>{{ $t('message.name') }}</th>
                      <th>{{ $t('message.user') }}</th>
                      <th>{{ $t('message.filial') }}</th>
                      <th>{{ $t('message.Total amount') }}</th>
                      <th>{{ $t('message.currency') }}</th>
                      <th>{{ $t('message.begin_date') }}</th>
                      <th>{{ $t('message.end_date') }}</th>
                      <th>{{ $t('message.link') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="deal in deals" :key="deal.id">
                      <th> {{ deal.id }} </th>
                      <th> {{ deal.name }} </th>
                      <th> {{ deal.user ? deal.user.name : '' }} </th>
                      <th> {{ deal.filial ? deal.filial.name : '' }} </th>
                      <th> {{ deal.money_amount }} </th>
                      <th> {{ deal.currency ? deal.currency.symbol : '' }} </th>
                      <th> {{ deal.begin_date }} </th>
                      <th> {{ deal.end_date }} </th>
                      <th>
                        <el-button
                          @click="showSelectedDeal(deal)"
                          type="success"
                          class="w-100"
                          size="mini"
                          round
                          >{{ $t('message.view') }}</el-button
                        >
                      </th>
                    </tr>

                  </tbody>
                </table>
              </div>
            </el-tab-pane>

            <el-tab-pane :label="$t('message.tasks')" name="tasks">
              <div v-loading="loadTab">
                <div>
                    <select-deal
                        :size="'medium'"
                        :placeholder="$t('message.deal')"
                        :id="deal_id"
                        v-model="deal_id"
                        :client_id="model.id"
                    >
                    </select-deal>
                </div>
                <el-button
                  @click="showSelectedTask(task)"
                  type="success"
                  class="my-links"
                  v-for="task in dealTasks" :key="task.id"
                >
                  {{$t('message.task')}} № {{ task.id }}
                  <i class="el-icon-arrow-left"></i>
                </el-button>
                <h2 v-if="dealTasks.length === 0">{{$t('message.no_task')}}</h2>
              </div>
            </el-tab-pane>

            <el-tab-pane :label="$t('message.products')" name="fourth"> products </el-tab-pane>

            <el-tab-pane :label="$t('message.additional_client_data')" name="fifth">
              <ClientAdditional :client="model"></ClientAdditional>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>

    <div class="app-modal app-modal__full modal-color-bg">

      <el-drawer
          :with-header="false"
          :visible.sync="newModal"
          :append-to-body="true"
          size="80%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
      >
          <crm-show-task
              :selectedItem="selectedTask"
              ref="drawerUpdateChild"
              drawer="drawerUpdate"
          ></crm-show-task>
      </el-drawer>
    </div>
    <div class="app-modal app-modal__full bg-seriq">
      <el-drawer 
        ref="drawerDealCreate" 
        :with-header="false" 
        :class="mode ? 'l-modal-style' : 'd-modal-style'"
        class="create-task-modal" 
        :visible.sync="dealDrawer" 
        @opened="drawerOpened('drawerDealCreateChild')"
        @closed="drawerClosed('drawerDealCreateChild')" 
        :append-to-body="true"
        size="96%">

        <AppModal ref="drawerDealCreateChild" :selectedDeal="selectedDeal" :dealCreate="dealCreate"
          @addModalEdit="dealDrawer = false" drawer="drawerDealCreate" />
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import selectDeal from "@/components/inventory/select-deal";
import CrmShowTask from "./showTask/show";
import AppModal from "@/views/deals/components/add-modal";
import Chart from '../components/chart/circle.vue'
import productBySumm from '../components/chart/product-by-summ.vue'
import productByQuantity from '../components/chart/product-by-quantity.vue'
import ClientAdditional from "./client-additional";



export default {
  mixins: [form, drawer, show],
  components: {
    selectDeal,
    CrmShowTask,
    AppModal,
    Chart,
    productBySumm,
    productByQuantity,
    ClientAdditional
  },
  data() {
    return {
      activeName: "dashboard",
      region: '',
      newModal: false,
      dealDrawer: false,
      deal_id: null,
      selectedTask: {},
      selectedDeal: null,
      loadTab: false,
      dealCreate: false,
      clientDashboardPeriod: 'yearly'
    };
  },
  watch: {
      clientDashboardPeriod: {
          handler: async function(newVal) {
              if(this.model.id){
                  this.loadTab = true
                  this.updateClientDashboard({client_id: this.model.id, period: newVal})
                    .then(res => {
                      this.loadTab = false
                    })
              }
          },
          deep: true,
          immediate: true
      },
      activeName: {
          handler: async function(newVal) {
              if(newVal === 'deals' && this.model.id){
                  this.loadTab = true
                  this.udpateClientDeals(this.model.id)
                    .then(res => {
                      this.loadTab = false
                    })
              }
              if(newVal === 'dashboard' && this.model.id){
                  this.loadTab = true
                  this.updateClientDashboard({client_id: this.model.id})
                    .then(res => {
                      this.loadTab = false
                    })
              }
          },
          deep: true,
          immediate: true
      },
      deal_id: {
          handler: async function(newVal) {
              if(newVal){
                this.loadTab = true
                this.udpateDealTasks(newVal)
                  .then(res => {
                    this.loadTab = false
                  })
              }else{
                this.emptyDealTask();
                this.deal_id = null;
              }
          },
          deep: true,
          immediate: true
      },
  },
  computed: {
    ...mapGetters({
      mode: "MODE",
      model: "clients/model",
      columns: "clients/columns",
      deals: "clients/deals",
      dealTasks: "deals/dealTasks",
      clientDashboard: "clients/clientDashboard",
    }),
  },
  methods: {
    handleClick(tab, event) {
    //   console.log(tab, event);
    },
    ...mapActions({
      show: "clients/show",
      udpateClientDeals: "clients/clientDeals",
      udpateDealTasks: "deals/dealTasks",
      emptyDealTask: "deals/emptyDealTask",
      updateClientDashboard: "clients/clientDashboard",
    }),
    afterOpen() {
      if (!this.loadingData) {
          this.loadingData=true
          this.show(this.selectedItem.id).then(res => {
              this.loadingData = false
              if(this.model.id){
                  this.loadTab = true
                  this.updateClientDashboard({client_id: this.model.id})
                    .then(res => {
                      this.loadTab = false
                    })
              }
          }).catch(err => {
              this.loadingData = false
          });
      }

    },
    showSelectedTask(task){
      this.selectedTask = task;
      this.newModal = true;
    },
    showSelectedDeal(deal){
      this.selectedDeal = {id: deal.id};
      this.dealDrawer = true;
    },

    closeDrawer(drawer) {
        if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
        }
    },
    drawerClosed(ref) {
        if (this.$refs[ref]) {
            this.$refs[ref].closed()
        }
        if (this.reloadList === true) {
            this.fetchData();
            this.afterFetchData();
        }
        if (_.isFunction(this.empty)) {
            this.empty()
        }
    },
    drawerOpened(ref) {
        if (this.$refs[ref]) {
            if (_.isFunction(this.$refs[ref].opened)) {
                this.$refs[ref].opened()
            }
        }
    },
    afterLeave() {
        this.deal_id = null;
        this.activeName = "dashboard";
        this.emptyDealTask();
        this.selectedDeal = null;
    },
    empty(){

    }

  },
};
</script>
<style scoped>
.dashboard__box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard__scroll {
    overflow-y: auto;
    height: 100%;
}
.dashboard__flex {
    display: flex;
    flex-direction: row;
}
.dashboard__photo img  {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
}
.dashboard__info h5 {
    margin: 0;
    font-size: 16px;
}
.dashboard__info {
    margin-left: 20px;
}
.dashboard__info p {
    margin: 4px 0;
    font-size: 12px;
}
.dashboard__margin {
    margin-right: 5px;
}
.dashboard__space {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard__item {
    background: #409EFF;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    height: 280px;
    position: relative;
}
.board__right {
    width: 60%;
    padding: 0 30px;
}
.board__left {
    width: 40%;
    padding: 0 30px;
}
.board__flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
}
.board__flex1 {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.dashboard__right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 0px;
}
.dashboard__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}
.dashboard__list span{
    margin-top: 5px;
    font-size: 14px;
}
.board__title {
    margin: 0;
    font-size: 16px;
}
.board__title1 {
    margin: 0;
    font-size: 16px;
    position: absolute;
}
.bg-seriq .el-drawer{
  background-color: #eef2f4;
}
.bg-seriq  .el-collapse-item__header{
  background-color: #fff;
  margin-left: 0px !important;
  padding-left: 20px;
}
.mm-avarage_volume-box{
  background: #409EFF;
  border-radius: 10px;
}
.mm-payment-box{
  background: #fc2e2e;
  border-radius: 10px;
}
.mm-payment-status{
  font-size: 16px;
  color: #fff;
}
.mm-font-700{
  font-weight: 700;
}
</style>
