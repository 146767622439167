<template>
	<div>
		<h3 class="date-tileq">{{ selectedMoreModules.formattedDate }}</h3>
		
		<ul class="calendar-modal-info">
			<li class="itme-calendar-modal-info" v-for="item in items" :key="item.value + '-' + item.id" @click="detailItem(item)">
				<div class="status-calendar-modal-info" :class="item.className" >{{ $t('message.'+item.label) }}</div>
				<div class="text-calendar-modal-info"> 
					{{ item.title }}
				</div>
				<div  class="date-itme-calendar-modal-info">
					<span v-if="['task', 'deal', 'exhibition', 'workMeeting'].includes(item.value)">
						{{ item.start ? item.start : '' }} - {{ item.end ? item.end : ''}}
					</span>
					<span v-else>{{ item.start ? item.start : '' }}</span>
					<!-- 11 июля 2023, 10:15AM – 20 июля 2023, 11:15AM -->
				</div>
				<div class="date-calendar-modal-info">
					<!-- За 30 минут до начала -->
				</div>
				<div class="user-calendar-modal-info">
					{{ item.user ? item.user.name : ''}}
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
   name: 'GoCrmFrontedMoreModal',
	props: ['selectedMoreModules'],
	computed: {
		items() {
			if (this.selectedMoreModules.hasOwnProperty('hiddenSegs')){
				return this.selectedMoreModules.hiddenSegs;
			}
			return [];
		}
	},
	data() {
		return {
			
		};
	},

	methods: {
		detailItem(item){
			this.parent().handleEventClick(item, true);
		},
		parent(){
			return this.$parent.$parent;
		}
	},
};
</script>

<style lang="scss" scoped>
.date-tileq{
	margin: 0px;
    padding-left: 20px;
    font-weight: 600;
}
.calendar-modal-info{
    padding: 20px;
}
.itme-calendar-modal-info{
    border: 1px solid #dddddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border-left: 5px solid #3788d8;
	 cursor: pointer;
}
.d-modal-style .itme-calendar-modal-info{
    border: 1px dashed #2a2f34;  
    border-left: 2px solid #3788d8; 
}
.status-calendar-modal-info{
    color: #fff;
    display: initial;
    padding: 4px;
    font-size: 13px;
    border-radius: 4px;
}
.text-calendar-modal-info{
    margin: 10px 0 2px 0;
    font-size: 16px;
}
.user-calendar-modal-info,
.date-calendar-modal-info,
.date-itme-calendar-modal-info {
    font-size: 12px;
    color: #959595;
    margin-bottom: 3px;
}
</style>