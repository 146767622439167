<template>
    <div v-if="cloak" :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="filterblock_s">
            <div class="filter_home flex-center">
                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>
        </div>

        <Calendar v-if="autoCloak" :filterForm="filterForm" style="height: 100%"></Calendar>
    </div>
</template>

<script>
import Calendar from "@/views/calendar/index";

export default {
    components: { Calendar },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        filterForm: Object,
        cloak: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            paymentPeriod: "weekly",
            autoCloak: false
        }
    },
    watch: {
        allPeriodChange(val) {
            this.paymentPeriod = val
        },
        cloak(val) {
            if (val) {
                setTimeout(() => {
                    this.autoCloak = val
                }, 500);
            } else {
                this.autoCloak = val
            }
        }
    }
}
</script>

<style lang="scss" scoped>
[v-cloak] {
    display: none;
}
</style>