<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.company')" filterable clearable
      :multiple="multiple" :size="size" class="w-100">
      <el-option v-for="(company,index) in companies" :key="'companies-' + index" :label="company.name" :value="company.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      multiple:{
        default: false
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.companies && this.companies.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        companies: 'company/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'company/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
