<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{ $t("message.notifications_nn")}}</p>

            <crm-store-update-close
                :button_type="'close'"
                @c-close="close()"
            ></crm-store-update-close>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0 message__all_exxpres">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <div>
                <ul v-loading="loadingData"
                    element-loading-text="Loading..."
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)"
                >
                    <li v-for="note in notificationList" :key="note.id" class="message__li">
                            <a>
                                <div class="message__info modas">
                                    <!-- <img class="message__image" src="/images/cat.jpg" alt=""> -->
                                    <i v-if="note.review" class="fa-solid fa-flag success-bg"></i>
                                    <i v-else class="fa-solid fa-flag warning-bg"></i>
                                    <!-- <i v-if="c.status == 3" class="fa-solid fa-flag danger-bg"></i> -->
                                    <div class="message__about w-100">
                                        <div class="message__date">
                                            <span class="date__text">{{ note.activity_time }}</span>
                                            <div class="message__animation">
                                                <transition name="slide-fade">
                                                    <span v-if="note.review" class="message__readed">Прочитано</span>
                                                </transition>

                                                <transition name="slide-fade">
                                                    <span v-if="!note.review" class="message__unreaded">Не прочитано</span>
                                                </transition>
                                            </div>
                                        </div>
                                        <div class="message-title w-100">
                                            <span>{{ note.type_name }}</span>
                                        </div>
													 <div class="font-bold" v-if="note.notificationable_type">
														<span>{{ $t('message.' + note.notificationable_type) + ' ' + $t('message.n') + ' ' + note.notificationable_id}}</span>
													 </div>
                                        <div>
                                            <p v-if="!note.readmore">
                                                {{ note.text }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                </ul>
            </div>
        </div>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
          <!-- <Pagination /> -->
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";

export default {
    mixins: [drawer],
    data() {
        return {
            notificationList: [],
            drawerNotification: false,
            loadingData: false,
        };
    },
    watch: {
        'pagination.page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
        'pagination.per_page': {
            handler: async function(newVal, oldVal) {
                if (newVal != oldVal && _.isFunction(this.debouncedFetchData)) {
                    this.debouncedFetchData();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            authUser: "auth/user",
            list: "systeamNotification/inventory",
            pagination: "systeamNotification/pagination_for_notes",
        }),
    },
    methods: {
        ...mapActions({
            updatePagination: "systeamNotification/updatePaginationNotes",
            updateList: "systeamNotification/getAllNotifications",
            // updateNotification: "systemNotifications/update",
        }),
			afterOpen(){
				this.debouncedFetchData();
			},
        updateNotificationStatus(notification){
            if(notification.type !== 4){
                notification.status = 3;
                this.updateNotification(notification)
                    .then(res => {
                        notification.unread = true;
                    }).catch(err => { })
            }
        },
        debouncedFetchData: _.debounce(
        function(val) {
          this.fetchData();
        }, 300),

        fetchData(first_page = false) {
            this.loadingData = true;
            let query = {};
            if(!first_page){
                query = this.pagination;
            }
            this.updateList(query)
            .then(res => {
                this.loadingData = false;
					 this.notificationList = JSON.parse(JSON.stringify(this.list));
            })
            .catch(err => { this.loadingData = false; })
        },

        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        listChanged() {
            this.debouncedFetchData();
        },
		  afterLeave(){
			this.$store.commit('systeamNotification/EMPTY_INVENTORY');
			this.notificationList = [];
            // this.fetchData(true);
        },
    },
};
</script>
<style scoped lang="scss">
    .d-modal-style{
        .message__box {
            background: #0c0e27;
        }
        .message__li{
            border-bottom: 1px dashed #2a2f34 !important;
        }
        .message-title{
            color: #ffffff;
        }
        // .message__about p{
        //     color: #a7a7a7  !important;
        // }
    }
    .message__box {
        width: 350px;
        margin-left: auto;
        background: white;
        border-radius: 13px;
        margin-right: 170px;
        margin-top: 60px;
        position: relative;
    }

    .message__allday {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        position: fixed;
        background: rgba(0,0,0,0.1);
        width: 100%;
        height: 100%;
    }
    .message__allnight {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        position: fixed;
        background: rgba(0,0,0,0.1);
        width: 100%;
        height: 100%;
    }

  .bounce-enter-active {
        animation: bounce-in 0.5s;
  }
  .bounce-leave-active {
        animation: bounce-in 0.5s reverse;
  }
  @keyframes bounce-in {
        0% {
        transform: scale(0);
        }
        50% {
        transform: scale(1.10);
        }
        100% {
        transform: scale(1);
        }
    }
    .message__header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        padding: 15px 20px;
        border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .message__header h5 {
        margin: 0;
    }
    .message__image {
        width: 38px;
        height: 38px;
        object-fit: cover;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 10px;
    }
    .message__info {

        width: 100%;
    }
    .modas{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .message__li {
        width: 100%;
        margin-left: 0 !important;
        padding: 10px 10px;
        border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .message__li:last-child {
        border: none;
    }
    .message__li a {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .message__dot {
        display: block;
        width: 8px;
        height: 8px;
        padding: 5px;
        border-radius: 50%;
        background: #0d67c9;
    }
    .message__about p {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: #a7a7a7 !important;
    }
    .message__ul {
        display: flex;
        flex-direction: column;
        height: 350px;
        overflow-y: auto;
        padding-left: 0;
    }
    .message__ul::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
        }

        .message__ul {
        scrollbar-width: thin;
        scrollbar-color: #8d969d;
        }

        .message__ul::-webkit-scrollbar-track {
        background: #ffffff;
        }

        .message__ul::-webkit-scrollbar-thumb {
        background-color: #8d969d;
        border-radius: 10px;
        border: 2px solid #fff0;
        }
    .message__footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
    }
    .message__footer a {
        padding: 10px 0;
        font-size: 15px;
        color: #718096;
        cursor: pointer;
    }
    .message__date {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
    }

    .message__footer a:hover {
        color: #0d67c9;
    }
    .message__tracker {
        display: flex;
        flex-direction: row;
        border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
        padding: 0 20px;
    }
    .message__tracker a {
        cursor: pointer;
        padding: 10px 0;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }
    .message__tracker a:hover {
        color: #0d67c9;
    }
    .message__read {
        color: #0d67c9;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
    .message__hide {
        color: #0d67c9;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
    }
    .date__text {
        font-size: 11px;
        color: #718096;
    }
    .message__readed {
        font-size: 11px;
        color: #718096;
        display: flex;
        align-items: center;
    }
    .message__unreaded {
        font-size: 11px;
        color: #0d67c9;
        cursor: pointer;
    }
    .message__unreaded:hover {
        color: #718096;
    }
    .slide-fade-enter-active {
        transition: all .2s ease-in-out;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 3.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(10px);
        opacity: 0;
    }
    .message__animation {
        display: flex;
        flex-direction: column;
        position: absolute;
        text-align: right;
        right: 0;
    }
    .warning-bg, .danger-bg, .success-bg {
        display: inline;
        border-radius: 50%;
        padding: 10px;
        margin-right: 10px;
    }
    .success-bg {
        color: #67C23A;
        background: #67c23a38;
    }
    .warning-bg {
        color: #E6A23C;
        background: #e6a23c38;
    }
    .danger-bg {
        color: #F56C6C;
        background: #f56c6c24;
    }
</style>
