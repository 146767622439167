<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="task-home-two-center h__responssive">
            <div class="filterblock_s">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.categories') }} </span>
                </h4>
                <div class="filter_home flex-center">
                    <!-- <div>
                        <a @click="$emit('showFullWindow'), catGraph = true" class="full__item">
                            <span class="icon__color svg-icon svg-icon-muted svg-icon-2hx">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path opacity="0.3"
                                        d="M2 9.09998V3C2 2.4 2.4 2 3 2H9.10001L2 9.09998ZM22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998ZM2 14.9V21C2 21.6 2.4 22 3 22H9.10001L2 14.9ZM14.9 22H21C21.6 22 22 21.6 22 21V14.9L14.9 22Z"
                                        fill="currentColor" />
                                    <path
                                        d="M19.2 17.8L13.4 12L19.2 6.20001L17.8 4.79999L12 10.6L6.2 4.79999L4.8 6.20001L10.6 12L4.8 17.8L6.2 19.2L12 13.4L17.8 19.2L19.2 17.8Z"
                                        fill="currentColor" />
                                </svg>
                            </span>
                        </a>
                    </div> -->
                    <el-radio-group v-model="categoryStatistic" size="medium">
                        <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                        <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                        <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                    </el-radio-group>
                    <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                        style="font-size: 18px; color: #dc2626; cursor: pointer;">
                    </i>
                </div>
            </div>

            <!-- full window -->
            <!-- <div v-if="catGraph" class="fixed__pos">
                <div :class="[catGraph ? 'graph__full' : '', mode ? 'graph__day' : 'graph__night']">
                    <div class="graph__header">
                        <div class="filter_home">
                            <el-radio-group v-model="categoryStatistic" size="medium">
                                <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                                <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                                <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                            </el-radio-group>
                        </div>
                        <div>
                            <a @click="catGraph = false" class="graph__close"
                                :class="mode ? 'graph__closeday' : 'graph__closenight'">
                                <span class="graph__icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none">
                                        <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1"
                                            transform="rotate(-45 7.05025 15.5356)" fill="currentColor" />
                                        <rect x="8.46447" y="7.05029" width="12" height="2" rx="1"
                                            transform="rotate(45 8.46447 7.05029)" fill="currentColor" />
                                    </svg>
                                </span>
                            </a>
                        </div>
                    </div>

                    <el-row>
                        <el-col :span="12">
                            <categoryClothes :filterForm="filterForm" :period="categoryStatistic" />
                        </el-col>
                        <el-col :span="12">
                            <div class="tech__box">
                                <div class="tech__item"
                                    v-for="categ in categoryInventory.filter(item => item.parent_id == null)"
                                    :key="categ.id">
                                    <div class="tech__title"
                                        v-if="$refs['category_' + categ.id] && $refs['category_' + categ.id][0]['category'].length > 0">
                                        <span
                                            v-if="$refs['category_' + categ.id] && $refs['category_' + categ.id][0].child_category"
                                            @click="backToParentCategory(categ)" class="el-icon-arrow-left">
                                        </span>
                                        <h4
                                            v-if="$refs['category_' + categ.id] && $refs['category_' + categ.id][0].child_category">
                                            {{ $refs['category_' + categ.id][0].child_category.name }}
                                        </h4>
                                        <h4 v-else>{{ categ.name }}</h4>
                                    </div>
                                    <div class="tech__flex">
                                        <categoryChildClothes :key="update" :filterForm="filterForm"
                                            :period="categoryStatistic" :ref="'category_' + categ.id"
                                            :parent_id="categ.id" />
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div> -->


            <el-row>
                <el-col :span="12">
                    <categoryClothes :filterForm="filterForm" :period="categoryStatistic" />
                </el-col>
                <el-col :span="12">
                    <div class="tech__box">
                        <div class="tech__item" style="flex-shrink: 0; flex-grow: 1;"
                            v-for="categ in categoryInventory.filter(item => item.parent_id == null && item.children && item.children.length > 0)"
                            :key="categ.id">
                            <el-button @click="showRef(categ.id)">submit</el-button>
                            <div class="tech__title"
                                v-if="$refs['category_' + categ.id] && $refs['category_' + categ.id][0].category.length > 0">
                                <span
                                    v-if="$refs['category_' + categ.id] && $refs['category_' + categ.id][0].child_category"
                                    @click="backToParentCategory(categ)" class="el-icon-arrow-left">
                                </span>
                                <h4 v-if="$refs['category_' + categ.id] && $refs['category_' + categ.id][0].child_category">
                                    {{ $refs['category_' + categ.id][0].child_category.name }}</h4>
                                <h4 v-else>{{ categ.name }}</h4>
                            </div>
                            <categoryChildClothes :filterForm="filterForm" :period="categoryStatistic"
                                :ref="'category_' + categ.id" :parent_id="categ.id" />
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import categoryClothes from '@/components/chart/category/index.vue';
import categoryChildClothes from '@/components/chart/category/index_childs.vue';

export default {
    components: {
        categoryClothes, categoryChildClothes
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },
        update: Number,
        filterForm: Object,
    },
    data() {
        return {
            catGraph: false,
            categoryStatistic: "weekly",
        }
    },
    watch: {
        allPeriodChange(val) {
            this.categoryStatistic = val
        }
    },
    computed: {
        ...mapGetters({
            categoryInventory: "categories/inventory",
        })
    },
    methods: {
        backToParentCategory(cat) {
            let ref = 'category_' + cat.id;

            if (this.$refs[ref]) {
                this.$refs[ref][0].child_category = null;
                this.$refs[ref][0].updateChartData({
                    parent_id: cat.id,
                    period: this.categoryStatistic,
                    filter: this.filterForm,
                });
            }

        },

        showRef() {
            console.log(this.$refs['category_' + categ.id][0]['category']);
        }
    },
}
</script>

<style lang="scss" scoped></style>