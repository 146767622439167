<template>
    <div class="deal-progress-wrap d-flex">

      <el-tooltip
        class="item"
        :class="stage.color"
        effect="dark"
        :content="stage.name"
        placement="top"
        v-for="(stage, index) in stages"
        :key="stage.id"

      >
        <div

            :class="changeClass(stage, index)"
            class="
            deal-progress-items
            p-relative
            mr-2
            pointer
            transition
            text-white
            font-medium
          "
          @click="changeDealStage(stage.id)"
       @mouseover="setMouseOver(stage.id)"
       @mouseout="setMouseOut"
        >
          <span class="deal-progress-text d-block text-truncate">
            {{ stage.name }}
          </span>
        </div>
      </el-tooltip>

      <el-dialog
        class="KP_modal"
        :title="$t('message.end_date')"
        :visible.sync="centerDialogVisible"
        :append-to-body="true"
        width="30%"
        center
      >
        <div>
          <el-form ref="form" :model="form">
            <el-form-item :label="$t('message.date')">
              <el-date-picker
                      class="w-100"
                      size="medium"
                      v-model="finish_date"
                      type="date"
                      :placeholder="$t('message.date')"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                    ></el-date-picker>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">{{$t('message.cancel')}}</el-button>
            <el-button @click="submit(true)" type="primary"
            >{{$t('message.confirm')}}</el-button>
          </span>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions, createLogger } from "vuex";

export default {
  data() {
    return {
      centerDialogVisible: false,
      selectedStageId: null,
      stages: [],
      form: {},
      finish_date: new Date(),
      stage_id: null,
    isHovering: false,
    until_id: null
    };
  },
  props: {
    deal_id: {
      default: null,
    },
  },
  created: function () {
    this.allStages().then(() => {
      this.stages = JSON.parse(JSON.stringify(this.getStages));
    });
  },
  watch: {
    deal_id(val) {
      if (val.deal_stage) {
        this.addActiveToStage(val.deal_stage.id);
        this.selectedStageId = val.deal_stage.id;
      }
      if (val.deal_stage_id) {
        this.addActiveToStage(val.deal_stage_id);
        this.selectedStageId = val.deal_stage_id;
      }
    },
  },
  computed: {
    ...mapGetters({
      getStages: "dealStages/list",
    }),
  },

methods: {
    changeClass(stage, index){
        return [(this.isHovering && this.until_id >= stage.id) ? 'class1' : '',
        (this.stages.length == (index+1) ) ?  'offAfter' : '', ]
    },
    ...mapActions({
      allStages: "dealStages/index",
      updateDealStage: "deals/changeDealStage"
    }),
    addActiveToStage(val) {
      this.stages.forEach((element) => {
        if (val && element.id <= val) {
          element.color = "action5";
        } else {
          element.color = "action1";
        }
      });
    },
    changeDealStage(stage_id){
      if(this.deal_id.id && stage_id){
        if(this.stages[this.stages.length-1]['id'] == stage_id){
          this.centerDialogVisible = true;
          this.stage_id = stage_id;
        }else{
          this.$confirm(
                this.$t('message.do_you_really_want_to_do_this'),
                this.$t('message.warning'), {
                  confirmButtonText: this.$t('message.yes'),
                  cancelButtonText: this.$t('message.no'),
                  type: "warning"
                }
              )
              .then(() => {
                this.updateDealStage({deal_id: this.deal_id.id, stage_id: stage_id})
                .then(() => {
                    this.$emit('stageChanged', this.deal_id.id);
                });
              })
              .catch(() => {
                this.$message({
                  type: "warning",
                  message: this.$t('message.operation_canceled')
                });
              });
        }

      }
    },

    submit() {
        this.updateDealStage({deal_id: this.deal_id.id, stage_id: this.stage_id, finish_date: this.finish_date})
            .then(() => {
                this.centerDialogVisible = false;
                this.$emit('stageChanged', this.deal_id.id);
            });
    },

   setMouseOver(id){
    this.isHovering = true;
    this.until_id = id;
   },

   setMouseOut(){
    this.isHovering = false;
    this.until_id = null;
   }

  },
};
</script>
<style lang="scss">
.deal-progress-items::after,
.deal-progress-items::before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 18px solid #abc937;
    z-index: 2;
    // transition: border-color 0.2s ease;
}
.deal-progress-items::before {
    right: auto;
    left: 0;
    border-left: 17px solid #eef2f4;
    z-index: 0;
}
.deal-progress-items:first-child::before {
    border: none;
}
.deal-progress-items::after:last-child{
  display: none ;
}

.class1 .deal-progress-text {
    background: #67C23A !important;
    border-color: #67C23A !important;
    color: #fff;
}
.class1.action1.deal-progress-items::after{
    border-color: transparent transparent transparent #67C23A !important;
}
</style>
