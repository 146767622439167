import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/systeamNotification',
        method: 'get',
        params
    })
}
export function getAllNotifications(params) {
	return request({
		 url: '/systeamNotification/getAllNotifications',
		 method: 'get',
		 params
	})
}
export function store(data) {
    return request({
        url: '/systeamNotification',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/systeamNotification/inventory",
        method: "get",
        params
    });
}
export function getSamplesForMessages(params) {
	return request({
		 url: "/systeamNotification/getSamplesForMessages",
		 method: "get",
		 params
	});
}
export function show(id) {
    return request({
        url: `/systeamNotification/${id}`,
        method: 'get'
    })
}
export function getNotificationUsers(id) {
	return request({
		 url: `/systeamNotification/getNotificationUsers/${id}`,
		 method: 'get'
	})
}
export function update(data) {
    return request({
        url: `/systeamNotification/${data.id}`,
        method: 'put',
        data
    })
}
export function updateSysteamNotificationUser(id) {
	return request({
		 url: `/systeamNotification/updateSysteamNotificationUser/${id}`,
		 method: 'get',
	})
}
export function destroy(id) {
    return request({
        url: `/systeamNotification/${id}`,
        method: 'delete'
    })
}