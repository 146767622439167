<template>
    <div id="chart" >
        <div v-if="products.length > 0">
            <apexchart type="bar" height="220" :options="chartOptions" :series="series"></apexchart>
        </div>
        <div v-else class="error mt-5">
            <h2 class="mt-5">
                {{ $t("message.no_data_yet") }}
            </h2>
        </div>
        
    </div>
</template>

<script>
import { formatNumber } from "@/filters/index";

export default {
    props: {
        products: {
            default: Array,
        },
    },
    data: () => ({
        series: [
            {
                data: [],
                name: '',
                color: 'rgba(0, 38, 114, 0.85)'
            }, 
        ],
        chartOptions: {
            tooltip: {
                theme: false
            },
            legend: {
                show: true,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: 'top',
                horizontalAlign: 'left', 
                floating: false,
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: 30,
                tooltipHoverFormatter: undefined,
                customLegendItems: [],
                offsetX: 120,
                offsetY: -5,
                labels: {
                    colors: '#fff',
                    useSeriesColors: false
                },
                markers: {
                    width: 20,
                    height: 20,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: '5px'
                },
                itemMargin: {
                    horizontal: 20,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                },
            },
            chart: {
                type: 'bar',
                height: 220,
                background: 'none',
                background: 'transparent',
                toolbar: {
                    show: false
                },
                foreColor: '#fff'
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    dataLabels: {
                        position: 'bottom',
                    },
                    borderRadius: 8,
                    endingShape: 'rounded' 
                }
            },
            dataLabels: {
            enabled: false,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
            },
            stroke: {
                colors: ["transparent"],
                width: 2
            },
            tooltip: {
                enabled: true,
                style: {
                    fontSize: '12px',
                },
                x: {
                    show: true,
                    format:'HH:mm'
                },
                y: {
                    formatter:(value) => formatNumber(value, 2)
                },
                marker: {
                    show: false,
                },
                theme:'dark'
            },
            xaxis: {
                categories: [],
                color: '#fff',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                }, 
                labels: {
                    show: false,
                },     
            },
            grid: {
                show: false,      // you can either change hear to disable all grids
                xaxis: {
                    lines: {
                        show: true  //or just here to disable only x axis grids
                    },
                    labels: {
                        show: false
                    }
                },  
                yaxis: {
                    lines: { 
                    show: true  //or just here to disable only y axis
                    }
                },   
            },
        },

        
    }),
    watch: {
        products: {
            handler: function (val) {
                if(this.products.length > 0) {
                    this.chartOptions = {...this.chartOptions, ...{
                        xaxis: {
                            categories: this.products.map(item => item.name),
                        }
                    }}
                    this.series[0].data = this.products.map(item => parseInt(item.quantity));
                    this.series[0].name = this.$t('message.quantity');
                }
            },
            deep: true,
            immediate: true,
        },
    },

        
}
</script>

<style scoped>
    #chartContainer .apexcharts-tooltip {
        color: #1e156b;
    }

    #chartContainer .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
        background: #ffffff !important;
    }
</style>
