import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
	 notification_type : {
		show: true,
		title: i18n.t('message.notificationType'),
		sortable: true,
		column: 'notification_type'
  },
	user : {
		show: true,
		title: i18n.t('message.user'),
		sortable: true,
		column: 'user'
	},
	text : {
		show: true,
		title: i18n.t('message.text'),
		sortable: true,
		column: 'text'
	},
	users : {
		show: true,
		title: i18n.t('message.users'),
		sortable: true,
		column: 'user'
	},
	activity_time : {
		show: true,
		title: i18n.t('message.activity_time'),
		sortable: true,
		column: 'activity_time'
	},
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
