<template>
    <div class="khan-chart-a">
        <v-chart class="e-chart" :option="option" autoresize />
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import VueApexCharts from "vue-apexcharts";

import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);

export default {
    components: {
        apexchart: VueApexCharts, VChart
    },
    provide() {
        return {
            [THEME_KEY]: "dark"
        };
    },
    props: {
        period: {
            default: "weekly",
        },
        filterForm: {
            default: null,
        },
    },
    data() {
        return {
            updated_money_amounts: [],

            option: {
                // title: {
                //   text: 'Stacked Line'
                // },
                backgroundColor: "transparent",
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    show: false,
                    data: []
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '',
                        type: 'line',
                        stack: 'Total',
                        data: [],
                        smooth: true,
                    }
                ]
            }
        };
    },
    watch: {
        watchAll: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({
                        period: this.period,
                        filter: this.filterForm,
                    });
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            dealPayments: "home/dealPayments",
        }),
        watchAll() {
            return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            dealPaymentsDiagram: "home/dealPayments",
        }),

        updateChartData(data) {
            this.dealPaymentsDiagram(data).then((res) => {
                // Echart
                this.option.legend.data.push(this.$t('message.total_amount_t'))
                this.option.xAxis.data = this.dealPayments.dates;
                this.option.series[0].name = this.$t('message.total_amount_t')
                this.option.series[0].data = this.dealPayments.money_amounts
            });
        },
    },
};
</script>

<style></style>
