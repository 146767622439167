<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="filterblock_s">
            <h4 class="dashboard-tile__item-title short">
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.selling_product_statistic') }}</span>
            </h4>
            <div class="filter_home flex-center">
                <el-radio-group @change="$emit('vmodel', filterByDate)" v-model="filterByDate" size="medium">
                    <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                    <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                    <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                </el-radio-group>
                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>
        </div>
        <ProductSellStatisticsChart :filterForm="filterForm" :period="productSellStatisticsPeriod">
        </ProductSellStatisticsChart>
    </div>
</template>

<script>
import ProductSellStatisticsChart from "@/components/chart/productSellStatisticsChart";
export default {
    components: {
        ProductSellStatisticsChart,
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        productSellStatisticsPeriod: String,
        filterForm: Object,
    },
    data() {
        return {
            filterByDate: "weekly"
        }
    },
}
</script>

<style lang="scss" scoped></style>