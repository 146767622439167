<template>
    <div class="workMeetingInfo p-3" v-loading="loading">

        <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            {{ $t('message.meeting_goal') }}
                        </span>
                        <div class="d-flex align-center font-medium "> 
                            {{ model.meeting_goal }}
                        </div>
                    </div>
                </el-card>
            </el-col>
            <!-- end col -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            {{ $t('message.type') }}
                        </span>
                        <div class="d-flex align-center font-medium "> 
                            {{ model.type ? model.type.name : '' }}
                        </div>
                    </div>
                </el-card>
            </el-col>
            <!-- end col -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            {{ $t('message.responsible') }}
                        </span>
                        <div class="d-flex align-center font-medium "> 
                            {{ model.user ? model.user.name : '' }}
                            {{ (model.responsibles && model.responsibles.length > 0) ? ', ' : '' }}
                            {{ makeMultiString(model.responsibles) }}
                        </div>
                    </div>
                </el-card>
            </el-col>
            <!-- end col -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            {{ $t('message.filials') }}
                        </span>
                        <div class="d-flex align-center font-medium "> 
                            {{ makeMultiString(model.filials) }}
                        </div>
                    </div>
                </el-card>
            </el-col>
            <!-- end col -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            Дата и время начала собрания
                        </span>
                        <div class="d-flex align-center font-medium "> 
                           {{ model.start_date.date }} <b class="ml-2">{{ model.start_date.time }}</b>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <!-- end col -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            Дата и время завершение собрания
                        </span>
                        <div class="d-flex align-center font-medium "> 
                            {{ model.finish_date.date }} <b class="ml-2">{{ model.finish_date.time }}</b>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <!-- end col -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            Напомнить
                        </span>
                        <div class="d-flex align-center font-medium "> 
                            {{ model.notification_time ? model.notification_time + ' минут' : 'Отклоненный' }} 
                        </div>
                    </div>
                </el-card>
            </el-col>
            <!-- end col -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class="text-descr">
                        <span class=" text-descr__label d-block"> 
                            {{ $t('message.comment')}} 
                        </span>
                        <div class="d-flex align-center font-medium "> 
                            {{ model.comment}} 
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-divider content-position="left">{{ $t('message.participants')}} </el-divider>
        <el-row :gutter="10">
            <el-col v-for="participant in model.participants" :key="'participant-'+participant.id" :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card shadow="hover" class="gc-card__body mb-3">
                    <div class=" users-dropdown-items  d-flex rounded transition">
                            <div class="user-items-left mr-2">
                                <div class=" user-item-avatar w-100 h-100 rounded-pill hidden ">
                                <img :src="participant.avatar ? participant.avatar : '/img/default-user-icon.png'" alt="">
                            </div>
                        </div>
                        <div class="user-items-right">
                            <h4 class="my-1"> {{ participant.name }} </h4>
                            <p class="m-0"> {{ participant.phone }} </p>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-divider content-position="left">{{ $t('message.files')}} </el-divider>
        <el-row :gutter="10">
            <el-col v-for="uploaded_file in model.files" :key="'files-'+uploaded_file.key" :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
                <el-card  shadow="hover" class="gc-card__body mb-3">
                    <div class=" users-dropdown-items  d-flex rounded transition">
                        <div class="user-items-left mr-2">
                            <div @click="showImage(uploaded_file)"  class="user-item-avatar w-100 h-100">
                                <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(uploaded_file.type || (/[^.]*$/.exec(uploaded_file.name)[0]))"
                                    style="max-width:100%; max-height:100%; position: relative; top: 50%; transform: translateY(-50%);"
                                    class="p-1"
                                    :src="uploaded_file.url"
                                    fit="fill">
                                </el-image>
                                <div v-else class="text-center">    
                                    <i class="el-icon-document-copy mt-2" style="font-size: 50px;"></i>
                                    <span class="d-block mt-2 font-weight-bold" >{{/[^.]*$/.exec(uploaded_file.name)[0]}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="user-items-right">
                            <h4 class="my-1"> {{ fileNameTruncate(uploaded_file.name) }} </h4>
                            <div class="d-flex">
                                <a :href="uploaded_file.url" target="_blank" :download="uploaded_file.name" class="mr-3">
                                    <el-button type="primary"  icon="el-icon-download" size="mini"></el-button>
                                </a>
                                <!-- <el-button @click="destroyFile(uploaded_file, index, model.file)" class="mr-3" type="danger"  icon="el-icon-delete-solid" size="mini"></el-button> -->
                            </div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div>
            <el-dialog
                v-if="selectedImage.name"
                :title="selectedImage.name"
                :append-to-body="true"
                width="85%"
                custom-class="mm-picture-show"
                :visible.sync="dialogPictureVisible">
                <div ref="ImageContainer" class="text-center w-100">
                    <el-image v-if="['image/png', 'image/jpeg', 'jpg', 'jpeg', 'png'].includes(selectedImage.type || (/[^.]*$/.exec(selectedImage.name)[0])) ||
                        (selectedImage.raw && ['image/png', 'image/jpeg'].includes(selectedImage.raw.type))"
                    style="object-fit: contain;"
                    class="p-1"
                    :src="selectedImage.url"
                    fit="fill">
                    </el-image>
                </div>
            </el-dialog>
        </div>

    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from '@/utils/mixins/drawer';

export default {
   name: 'GoCrmFrontedWorkMeetingInfo',
	mixins: [drawer],
	props:{
		selectedItem: {
			type: Object,
			default: null
		}
	},
    data() {
        return {
            selectedImage: {},
            dialogPictureVisible: false,
        };
    },
    computed: {
        ...mapGetters({
            model: "workMeeting/model",
            authUser: "auth/user",
        }),
    },

    mounted() {
        if(!this.selectedItem) this.fetchData();
    },

    methods: {
        ...mapActions({
            show: "workMeeting/show",
        }),
		  afterOpen(){
				if (this.selectedItem) this.fetchData();
		  },
        fetchData() {
				let work_meeting_id = this.selectedItem ? this.selectedItem.id : this.$route.params.id;

            if (!this.loading && work_meeting_id) {  
                this.loading = true;        
                this.show(work_meeting_id).then(res => {
                    this.loading = false;              
                }).catch(err => {
                    this.loading = false;
                });
            }
        },

        fileNameTruncate(file_name){
            return  _.truncate(file_name, {length: 40, omission: '...'})
        },
        makeMultiString(data){
            let text = '';
            if(data && data.length > 0){
                data.forEach((element, index) => {
                    if(index > 0){
                        text += ', ' 
                    }
                    text += element.name;
                });
            }
            
            return text;
        },
        showImage(file){
            if(file.name){
                if(['image/png', 'image/jpeg', 'jpeg', 'jpg', 'png'].includes(file.type || (/[^.]*$/.exec(file.name)[0])) || (file.raw && ['image/png', 'image/jpeg'].includes(file.raw.type))){
                this.selectedImage = file;
                this.dialogPictureVisible = true;
                }else{
                let link = document.createElement('a');
                link.href = file.url;
                link.target = '_blank';
                link.download = file.name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                }
            }
        },
		  afterLeave(){
				this.$store.commit('workMeeting/EMPTY_MODEL');
				this.selectedImage = {};
		  }
    },
};
</script>

<style lang="scss">
.workMeetingInfo .users-dropdown-items:hover, 
.workMeetingInfo .users-dropdown-items.active {
    background: none;
}
.d-modal-style  .workMeetingInfo .el-card{
    border: 1px dashed #2a2f34 !important;
    background-color: #080c24 !important;

}
.d-modal-style  .workMeetingInfo .el-card__body{
    // border: 1px dashed #2a2f34 !important;
    background-color: #080c24 !important;
}
</style>