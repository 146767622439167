export default [{
  path: '/exhibitionInfo',
  name: 'exhibitionInfo',
  redirect: "/exhibitionInfo/exhibitionHome/:main_type/:exhibition_id",
  children: [
    {
        path: "/exhibitionInfo/exhibitionHome/:main_type/:exhibition_id", 
        name: "exhibitionHome",
        meta: {
            layout: "main",
        },
        component: () => import("@/views/exhibitionHome/index"),
    },
   
    {
      path: "/exhibitionInfo/exhibitionEmployees/:main_type/:exhibition_id", 
      name: "exhibitionEmployees",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/exhibitionEmployees/index"),
    },
    {
      path: "/exhibitionInfo/exhibitionDeals/:main_type/:exhibition_id", 
      name: "exhibitionDeals",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/exhibitionDeals/index"),
    },
    {
      path: "/exhibitionInfo/exhibitionTasks/:main_type/:exhibition_id", 
      name: "exhibitionTasks",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/exhibitionTasks/index"),
    },
    {
      path: "/exhibitionInfo/exhibitionClients/:main_type/:exhibition_id", 
      name: "exhibitionClients",
      meta: {
          layout: "main",
      },
      component: () => import("@/views/clients/index"),
    },

    
  ],
  meta: {
    layout: 'main'
  },
  component: () => import('@/views/exhibitionInfo/index')
}]



