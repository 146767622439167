import {i18n} from '@/utils/i18n';

export const rules = {
	project_id: [
		{ required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
   ],
	header: [
		{ required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
	],
	content: [
		{ required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
	],
	footer: [
		{ required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
	],
};
