<template>
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0"> 
                    {{  $t("message.product_composition") }}
                </p>
                <div>
                    <crm-store-update-close
                        :permission="$options.name"
                        :button_type="'store'"
                        :loading="loadingButton"
                        @c-submit="submit(true)"
                        @c-close="close()"
                    ></crm-store-update-close>
                </div>
                </div>
            </div>
            <!-- app-modal__header end -->
            <div class="app-modal__body p-5 pb-0">
                <div class="product-add s rounded-sm w-100 p-4">
                    <el-table
                        :data="compositionList"
                        :summary-method="getSummaries"
                        show-summary
                        border
                        style="width: 100%">
                        <el-table-column
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            :label="$t('message.name')">
                        </el-table-column>
                        <el-table-column
                            prop="percentage"
                            :label="$t('message.percentage')">
                            <template slot-scope="scope">
                                <el-input
                                    :min="0"
                                    :max="100"
                                    type="number"
                                    size="mini"
                                    v-model="scope.row.percentage"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="price"
                            :label="$t('message.price')">
                            <template slot-scope="scope">
                                <el-input
                                    :min="0"
                                    :max="100"
                                    type="number"
                                    size="mini"
                                    v-model="scope.row.price"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="quantity"
                            :label="$t('message.quantity')">
                            <template slot-scope="scope">
                                <el-input
                                    :min="0"
                                    :max="100"
                                    type="number"
                                    size="mini"
                                    v-model="scope.row.quantity"
                                ></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="measurement"
                            :label="$t('message.measurement')">
                            <template slot-scope="scope">
                                {{ scope.row.measurement }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="total"
                            :label="$t('message.total')">
                            <template slot-scope="scope">
                                {{ rowTotal(scope.row) | formatMoney(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column width="80">
                            <template slot="header" slot-scope="scope">
                                <i class="el-icon-s-tools"></i>
                            </template>
                            <template slot-scope="scope">
                                <el-button @click="removeFromList(scope.row, scope.$index)" type="primary" icon="el-icon-delete" size="mini"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <select-product
                        class="mt-4"
                        :size="'medium'"
                        :placeholder="$t('message.product_id')"
                        :id="product_id"
                        v-model="product_id"
                        @get_selected_product="get_selected_product"
                        >
                    </select-product>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";
  import selectProduct from "@/components/inventory/select-product";
  import { formatMoney } from "@/filters/index";

  export default {
    components: {
      selectProduct,
    },
    mixins: [form, drawer],
    props: {
      productComposition: {
        default: Array
      }
    },
    data() {
      return {
          compositionList: [],
          totalPercentage: 0,
          totalPrice: 0,
          product_id: null,
          product: null,
      };
    },
    watch: {
        productComposition: {
            handler: async function(newVal, oldVal) {
                this.compositionList = JSON.parse(JSON.stringify(newVal));
            },
            deep: true,
            immediate: true
        },
        compositionList: {
            handler: async function(newVal, oldVal) {
                this.totalPercentage = 0;
                this.totalPrice = 0;
                newVal.forEach(el => {
                    this.totalPercentage += _.round(parseFloat(el.percentage), 2);
                    this.totalPrice += _.round((parseFloat(el.price || 0) * parseFloat(el.percentage || 0) / 100), 2);
                })

            },
            deep: true,
            immediate: true
        },
    },
    created() {},
    computed: {
        ...mapGetters({
            currencies: "currency/inventory",
            getProduct: "products/model",
            permissions: "auth/permissions",
        }),
    },
    methods: {
        ...mapActions({
            showProduct: "products/show",
        }),
        rowTotal(row){
            return _.round((parseFloat(row.price || 0) *  parseFloat(row.percentage || 0) / 100), 2);
        },
        get_selected_product(selected_product_id){
            if(selected_product_id){
                this.showProduct(selected_product_id)
                    .then(() => {
                        let currency_id = this.getProduct.selling_price_currency.id;
                        let rate = null;
                        if(currency_id){
                            let currency = this.currencies.find(el => el.id === currency_id)
                            if(currency){
                                rate = currency.rate;
                            }
                        }
                        let price = parseFloat(this.getProduct.selling_price || 0) * parseFloat(rate || 0);
                        this.compositionList.push({
                            product_id: this.getProduct.id,
                            name: this.getProduct.name,
                            percentage: 0,
                            price: price,
                            quantity: 1,
                            measurement: this.getProduct.measurement ? this.getProduct.measurement.name : '',
                        })
                    });
               
            }
        },
        removeFromList(row, index){
            this.compositionList.splice(index, 1);
        },
        submit(close = true) {
            let percentages = this.compositionList.map(el => el.percentage);
            let invalid_percentages = percentages.filter(per => (per <= 0 || per > 100)).length;
            let total_percentage = percentages.reduce((total, per) => parseFloat(total) + parseFloat(per));
            if(total_percentage == 100 && invalid_percentages == 0){
                this.$emit('updateProductComposition', this.compositionList, this.totalPrice);
                this.compositionList = [];
                this.close();
            }else if(invalid_percentages > 0) {
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.invalid_percentage'),
                });
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.composition_should_be_100'),
                });
            }
        },

        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 1) {
                    sums[index] = this.$t('message.total');
                    return;
                }
                if (column.label == this.$t('message.percentage')) {
                    sums[index] = this.totalPercentage + '%';
                    return;
                }
                if (column.label == this.$t('message.total')) {
                    sums[index] = formatMoney(this.totalPrice, 2);
                    return;
                }
            });
            return sums;
        },
    },
  };
  </script>
  
  
  <style>
  </style>
  