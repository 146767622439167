import { i18n } from '@/utils/i18n';
import { pagination } from "@/store/modules/properties/pagination";
import { filter } from "./properties/filter";

export const mutations = {
    SET_LIST: (state, callHistory) => {              
        state.list = callHistory;
        state.list = state.list.map(call => {
            let status = i18n.t('message.outgoing');
            if (call.cnum.length > 5) {
                status = i18n.t('message.incoming');
            }

            call.caller = call.cnum + ' ' + call.cnum !== call.src ? '( ' + call.src + ' )' : '';
            call.called_to = call.dst + ' ' + call.did  ? '( ' + call.did  + ' )' : '';

            let minutes = Math.floor(call.billsec / 60)
            let seconds = call.billsec % 60

            if (minutes < 10) {
                minutes = '0' + minutes;
            }
            if (seconds < 10) {
                seconds = '0' + seconds;
            }
            let time = minutes + ' : ' + seconds;
            let caller = (call.cnum + (call.cnum !== call.src ? ' ( ' + call.src + ' )' : ''));
            let destination = call.dst.length > 5 ? call.dst.substr(call.dst.length - 9) : call.dst;
            let called_to = (destination + (call.did.length > 0 ? ' ( ' + call.did  + ' )' : ''));
            return {
                status: status,
                uniqueid: call.uniqueid,
                cnum:   call.cnum,
                src:    call.src,
                dst:    call.dst,
                did:    call.did,
                billsec:   time,
                calldate:   call.calldate,
                cnam:       call.cnam,
                disposition:   call.disposition,
                callStatus:   i18n.t('message.' + call.disposition),
                recordingfile:   call.recordingfile,
                caller: caller,
                called_to: called_to,
            }
        });
    },
    SET_PHONE_CALL_HISTORY: (state, callHistory) => {              
        state.phoneCallList = callHistory;        
    },

    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    SET_FILTER: (state, filter) => (state.filter = filter),

    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.pagination = JSON.parse(JSON.stringify(pagination));
    },

    SET_TOTAL_STATISTICS: (state, totalStatistics) => {              
        state.totalStatistics = totalStatistics;        
    },

    SET_TRUNCK_DIAGRAMS: (state, trunckDiagrams) => {              
        state.trunckDiagrams = trunckDiagrams;        
    },

    SET_USER_DIAGRAMS: (state, userDiagrams) => {              
        state.userDiagrams = userDiagrams;        
    },
    
};
