<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="filterblock_s">
            <h4 class="dashboard-tile__item-title short">
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.payments_by_period') }}</span>
            </h4>
            <div class="filter_home flex-center">
                <el-radio-group v-model="dealPaymentTermsPeriod" size="medium">
                    <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                    <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                    <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                </el-radio-group>
                <i v-if="editDashboard" 
                    @click="$emit('remove-chart')" 
                    class="fa-solid fa-trash-can" 
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>
        </div>
        <DealPaymentTerms :filterForm="filterForm" :period="dealPaymentTermsPeriod"></DealPaymentTerms>
    </div>
</template>

<script>
import DealPaymentTerms from "@/components/chart/dealPaymentTerms";

export default {
    components: {
        DealPaymentTerms,
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },
        filterForm: Object,
        update: Number,
    },
    data() {
        return {
            dealTerm: false,
            dealPaymentTermsPeriod: "weekly",
        }
    },
    watch: {
        allPeriodChange(val) {
            this.dealPaymentTermsPeriod = val
        }
    }

}
</script>

<style lang="scss" scoped></style>