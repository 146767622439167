<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.client')" filterable clearable
      :multiple="multiple" :size="size" class="w-100" @change="Cchange">
      <el-option v-for="(client,index) in clients" :key="'clients-' + index" :label="client.full_name" :value="client.id">
        <span class="mm_select_additional_container">
          {{ client.full_name }} <span class="mm_select_additional_info">{{ client.company ? client.company.name : '' }}</span>
        </span>
      </el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      has_provider: {
        default: false
      },
      multiple: {
        default: false
      },
      companies: {
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
      companies: {
          handler: async function(newVal, oldVal) {
            await this.updateInventory({has_provider: this.has_provider, companies: this.companies})
          },
          deep: true,
          immediate: true
      },
    },
    data() {
      return {
        selected: null,
      }
    },
    // mounted() {
    //   if (this.clients && this.clients.length === 0) this.updateInventory({has_provider: this.has_provider, companies: this.companies})
    // },
    computed: {
      ...mapGetters({
        clients: 'clients/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'clients/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      },
		Cchange(e){
			this.$emit('c-change', e);
			this.selected = e;
		}
    },
  }

</script>
