import {i18n} from '@/utils/i18n';

export default [{
    path: '/workMeetingType',
    name: 'workMeetingType',
    meta: {
      layout: 'main',
      title: i18n.t('message.workMeetingType')
    },
    component: () => import('@/views/workMeetingType/index')
}]
  