const TokenKey = 'auth_token';
const GOMAXTokenKey = 'auth_token_gomax';
const HRTokenKey = 'auth_token_hr';

export function setToken(token) {   
    return localStorage.setItem(TokenKey, token)
}

export function getToken() {
    return localStorage.getItem(TokenKey)
}

export function removeToken() {    
    return localStorage.removeItem(TokenKey)
}

export function getTokenByKey(keyName) {
    return localStorage.getItem(keyName)
}

export function setOtherTokens(tokens) {    
    localStorage.setItem(GOMAXTokenKey, tokens.gomax_token)
    localStorage.setItem(HRTokenKey, tokens.hr_token)
}

export function removeOtherTokens() {    
    localStorage.removeItem(GOMAXTokenKey)
    localStorage.removeItem(HRTokenKey)
}
