import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    column_name : {
        show: true,
        title: i18n.t('message.column_name'),
        sortable: true,
        column: 'column_name'
    },
    column_type : {
        show: true,
        title: i18n.t('message.column_type'),
        sortable: true,
        column: 'column_type'
    },

    column_options : {
        show: true,
        title: i18n.t('message.options'),
        sortable: true,
        column: 'column_options'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
