import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    user_id : {
        show: false,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user_id'
    },
    status : {
        show: true,
        title: i18n.t('message.status'),
        sortable: true,
        column: 'status'
    },
    type : {
        show: true,
        title: i18n.t('message.exhibitionType'),
        sortable: true,
        column: 'type'
    },
    clients : {
        show: true,
        title: i18n.t('message.clients'),
        sortable: false,
        column: 'clients'
    },
    deals : {
        show: true,
        title: i18n.t('message.deals'),
        sortable: false,
        column: 'deals'
    },
    costTransactions : {
        show: true,
        title: i18n.t('message.expenses'),
        sortable: false,
        column: 'costTransactions'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: false,
        column: 'money_amount'
    },
    country_id : {
        show: true,
        title: i18n.t('message.country'),
        sortable: true,
        column: 'country_id'
    },
    city_id : {
        show: true,
        title: i18n.t('message.city'),
        sortable: true,
        column: 'city_id'
    },
    begin_date : {
        show: true,
        title: i18n.t('message.begin_date'),
        sortable: true,
        column: 'begin_date'
    },
    end_date : {
        show: true,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },
    comment : {
        show: false,
        title: i18n.t('message.comment'),
        sortable: false,
        column: 'comment'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
