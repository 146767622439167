export const filter = {
    id : '',  
    name: '', 
    url_address: '', 
    login: '', 
    password: '', 
    payment: 0, 
    currency_id: null, 
    created_at: '',
    updated_at: ''  
  };
  