<template>
    <div v-show="message" class="message__all" :class="mode ? 'message__allday' : 'message__allnight'">
        <Transition name="bounce">
            <div v-click-outside="messageOutside" v-show="message"  class="message__box">
                <div class="message__header">
                    <h5>{{ $t('message.notifications_nn') }}</h5>
                </div>
                <div>
                    <el-tabs v-model="activeName" class="message__tabs">
                        <el-tab-pane label="User" name="first">
                            <span slot="label">
                                <el-badge :value="posts_one.length > 0 ? posts_one.length : 0" class="item">
                                    {{ $t('message.notifications_nn') }}
                                </el-badge>
                            </span>
                            <ul class="message__ul">
                                <li v-for="note in posts_one" :key="note.id" class="message__li">
                                    <a>
                                        <div class="message__info">
                                            <!-- <i v-if="c.status == 2" class="fa-solid fa-flag warning-bg"></i>
                                            <i v-if="c.status == 1" class="fa-solid fa-flag success-bg"></i>
                                            <i v-if="c.status == 3" class="fa-solid fa-flag danger-bg"></i> -->
                                            <div class="message__about">
                                                <div class="message__date">
                                                    <span class="date__text">{{ note.activity_date }}</span>
                                                    <div class="message__animation">
                                                        <transition name="slide-fade">
                                                            <span v-if="note.review" class="message__readed">Прочитано</span>
                                                        </transition>

                                                        <transition name="slide-fade">
                                                            <span @click="updateNote(note)" v-if="!note.review" class="message__unreaded">Не прочитано</span>
                                                        </transition>
                                                    </div>
                                                </div>
                                                <div class="message-title">
                                                    <span>{{ note.type_name }}</span>
                                                </div>
                                                <div class="mt-1">
                                                    <p v-if="!note.readmore">
                                                        {{ note.text.slice(0,100) }}
                                                        <span
                                                            class="message__read"
                                                            v-if="!note.readmore && note.text.length > 100" @click="note.readmore = true">
                                                            ...Показать
                                                        </span>
                                                    </p>
                                                    <p v-if="note.readmore">
                                                        {{ note.text }}
                                                        <span class="message__hide"
                                                            v-if="note.readmore && note.text.length > 100" @click="note.readmore = false">
                                                            Свернуть
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="d-flex f-between align-center">
                                                    <div class="days_left"></div>
                                                    <div v-popover:myname.right  class="av_sm av_let9">{{ note.short_name }}</div>
                                                    <popover name="myname"  event="hover" >
                                                        <div class="popover-user">
                                                            <span>{{ note.username + ' ' + note.first_name }}</span>
                                                            <!-- <p>GOOD ONES</p> width="150"-->
                                                        </div>
                                                    </popover>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </el-tab-pane>
                        <el-tab-pane label="Config" name="second">
                            <span slot="label">
                                <el-badge :value="posts_two.length > 0 ? posts_two.length : 0" class="item">
                                    {{$t('message.queues')}}
                                </el-badge>
                            </span>
                            <ul class="message__ul">
                                <li v-for="queue in posts_two" :key="'queue-'+queue.id" class="message__li">
                                    <a>
                                        <div class="message__info">
                                            <!-- <i v-if="c.status == 2" class="fa-solid fa-flag warning-bg"></i>
                                            <i v-if="c.status == 1" class="fa-solid fa-flag success-bg"></i>
                                            <i v-if="c.status == 3" class="fa-solid fa-flag danger-bg"></i> -->
                                            <div class="message__about">
                                                <div class="message__date">
                                                    <span class="date__text">{{ queue.activity_time }}</span>
                                                    <!-- <div class="message__animation">
                                                        <transition name="slide-fade">
                                                            <span v-if="c.unread" class="message__readed">Прочитано</span>
                                                        </transition>

                                                        <transition name="slide-fade">
                                                            <span @click.stop="c.unread = true" v-if="!c.unread" class="message__unreaded">Не прочитано</span>
                                                        </transition>
                                                    </div> -->
                                                </div>
                                                <div class="message-title">
                                                    <span>{{ queue.type_name }}</span>
                                                </div>
                                                <div class="font-bold">
                                                    <p v-if="!queue.readmore">
                                                        {{ queue.text.slice(0,100) }}
                                                        <span
                                                            class="message__read"
                                                            v-if="!queue.readmore && queue.text.length > 100" @click="queue.readmore = true">
                                                            ...Показать
                                                        </span>
                                                    </p>
                                                    <p v-if="queue.readmore">
                                                        {{ queue.text }}
                                                        <span class="message__hide"
                                                            v-if="queue.readmore && queue.text.length > 100" @click="queue.readmore = false">
                                                            Свернуть
                                                        </span>
                                                    </p>
                                                </div>
                                                <div class="d-flex f-between align-center">
                                                    <div class="days_left">{{ $t('message.remaining') }}: <b>{{ queue.date_diff }}</b></div>
                                                    <div v-popover:myname.right  class="av_sm av_let9">{{ queue.short_name }}</div>
                                                    <popover name="myname"  event="hover" >
                                                        <div class="popover-user">
                                                            <span>{{ queue.username + ' ' + queue.first_name }}</span>
                                                            <!-- <p>GOOD ONES</p> -->
                                                        </div>
                                                    </popover>
                                                </div>
																<!-- width="150" -->
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </el-tab-pane>
                    </el-tabs>

                </div>
                <div @click="drawerNotification = true" class="message__footer">
                    <a>{{$t('message.all_notifications')}}</a>
                </div>
            </div>
        </Transition>
		   <div class="app-modal app-modal__full modal-color-bg">
				<el-drawer
				:with-header="false"
				:visible.sync="drawerNotification"
				:append-to-body="true"
				class="bg-se"
				size="70%"
				ref="drawerNotification"
				@opened="drawerOpened('drawerNotificationChild')"
				@closed="drawerClosed('drawerNotificationChild')"
			>
				<AllMessages
					ref="drawerNotificationChild"
					drawer="drawerNotification"
				></AllMessages>
				</el-drawer>
    		</div>
    </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AllMessages from "./AllMessages";
export default {
    props: ["message", "notes"],
    components: { AllMessages },
    data: () => ({
        activeName: "first",
        posts_one: [],
        posts_two: [],
        drawerNotification: false,
    }),
    computed: {
        ...mapGetters({
            mode: "MODE",
        }),
    },
    watch: {
        notes: {
            handler: function (newVal) {
                this.posts_one = JSON.parse(
                    JSON.stringify(newVal.notifications)
                );
                this.posts_two = JSON.parse(JSON.stringify(newVal.notes_queue));
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions({
            updateSysteamNotificationUser:
                "systeamNotification/updateSysteamNotificationUser",
            updateSendAllNotifications: "home/sendAllNotifications",
        }),
        updateNote(note) {
            this.updateSysteamNotificationUser(note.id)
                .then((res) => {
                    // this.$alert(res);
                    this.updateSendAllNotifications();
                })
                .catch((err) => {
                    this.$alert(err);
                });
        },
        messageOutside() {
            this.$emit("outside");
        },
        closeDrawer(drawer) {
            if (
                this.$refs[drawer] &&
                _.isFunction(this.$refs[drawer].closeDrawer)
            ) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed();
            }
            if (_.isFunction(this.empty)) {
                this.empty();
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
    },
};
</script>

<style scoped lang="scss">
.days_left {
    font-size: 10px;
}
.d-modal-style {
    .message__box {
        background: #0c0e27;
    }
    .message__li {
        border-bottom: 1px dashed #2a2f34 !important;
    }
    .message-title {
        color: #ffffff;
    }
    // .message__about p{
    //     color: #a7a7a7  !important;
    // }
}
.message__box {
    width: 350px;
    margin-left: auto;
    background: white;
    border-radius: 13px;
    margin-right: 170px;
    margin-top: 60px;
    position: relative;
}

.message__allday {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
}
.message__allnight {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    position: fixed;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
}

.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
.message__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 15px 20px;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.message__header h5 {
    margin: 0;
}
.message__image {
    width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 10px;
}
.message__info {
   width: 100%;
}
.message__li {
    width: 100%;
    margin-left: 0 !important;
    padding: 10px 10px;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.message__li:last-child {
    border: none;
}
.message__li a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.message__dot {
    display: block;
    width: 8px;
    height: 8px;
    padding: 5px;
    border-radius: 50%;
    background: #0d67c9;
}
.message__about p {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #a7a7a7 !important;
}
.message__ul {
    display: flex;
    flex-direction: column;
    height: 350px;
    overflow-y: auto;
    padding-left: 0;
}
.message__ul::-webkit-scrollbar {
    width: 5px;
    cursor: pointer;
}

.message__ul {
    scrollbar-width: thin;
    scrollbar-color: #8d969d;
}

.message__ul::-webkit-scrollbar-track {
    background: #ffffff;
}

.message__ul::-webkit-scrollbar-thumb {
    background-color: #8d969d;
    border-radius: 10px;
    border: 2px solid #fff0;
}
.message__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-top: 0.0625rem solid rgba(231, 234, 243, 0.7);
}
.message__footer a {
    padding: 10px 0;
    font-size: 15px;
    color: #718096;
    cursor: pointer;
}
.message__date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

.message__footer a:hover {
    color: #0d67c9;
}
.message__tracker {
    display: flex;
    flex-direction: row;
    border-bottom: 0.0625rem solid rgba(231, 234, 243, 0.7);
    padding: 0 20px;
}
.message__tracker a {
    cursor: pointer;
    padding: 10px 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.message__tracker a:hover {
    color: #0d67c9;
}
.message__read {
    color: #0d67c9;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.message__hide {
    color: #0d67c9;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.date__text {
    font-size: 11px;
    color: #718096;
}
.message__readed {
    font-size: 11px;
    color: #718096;
    display: flex;
    align-items: center;
}
.message__unreaded {
    font-size: 11px;
    color: #0d67c9;
    cursor: pointer;
}
.message__unreaded:hover {
    color: #718096;
}
.slide-fade-enter-active {
    transition: all 0.2s ease-in-out;
}
.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 3.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(10px);
    opacity: 0;
}
.message__animation {
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: right;
    right: 0;
}
.warning-bg,
.danger-bg,
.success-bg {
    display: inline;
    border-radius: 50%;
    padding: 10px;
    margin-right: 10px;
}
.success-bg {
    color: #67c23a;
    background: #67c23a38;
}
.warning-bg {
    color: #e6a23c;
    background: #e6a23c38;
}
.danger-bg {
    color: #f56c6c;
    background: #f56c6c24;
}
</style>
<style lang="scss">
.message__tabs {
    .el-tabs__nav {
        width: 100%;
    }
    .el-tabs__item {
        width: 50%;
        text-align: center;
        height: 50px;
        padding-top: 5px;
        .el-badge__content.is-fixed {
            top: 8px;
        }
    }
}
</style>
