export const model = {
  id : '',  
  sending_type: 'all', 
  message_receivers: ['clients'], 
  sending_date: '',
  send_to: {
      companies: [], 
      clients: [], 
      departments: [], 
      users: [], 
      phones: [],
  },
  message: '',
  created_at: '',
  updated_at: ''  
};

  