import { index, inventory, getTypes, getGenders, freeClients, companyClients, filterClientsByType, personClients, show, clientDeals, store, update, destroy, 
	clientDashboard, getProviderProductServices, storeProviderProductServices, storeClientComment } from "@/api/contractors/clients";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.clients);
                    commit("SET_TOTALS", res.data.result.totals);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        }); 
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    freeClients({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            freeClients(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    personClients({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            personClients(params).then(res => {
                commit("SET_PERSON_CLIENTS", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    filterClientsByType({ commit }, data) {
        return new Promise((resolve, reject) => {
            filterClientsByType(data).then(res => {
                commit("SET_PERSON_CLIENTS", res.data.result.data.clients);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
	 
	 getProviderProductServices({ commit }, provider_id) {
		return new Promise((resolve, reject) => {
			getProviderProductServices(provider_id).then(res => {
				  resolve(res.data.result.data)
			 }).catch(err => {
				  reject(err)
			 })
		})
  },

    companyClients({ commit }, company_id) {
        return new Promise((resolve, reject) => {
            companyClients(company_id).then(res => {
                commit("SET_PERSON_CLIENTS", res.data.result.data.clients);

                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.client);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    clientDeals({ commit }, id) {
        return new Promise((resolve, reject) => {
            clientDeals(id)
                .then(res => {
                    commit('SET_DEALS', res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    getTypes({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getTypes(params).then(res => {
                commit("SET_TYPES", res.data.result.data.types);
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },

    getGenders({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getGenders(params).then(res => {
                commit("SET_GENDERS", res.data.result.data.genders);
                resolve(res);
            }).catch(err => {
                reject(err);
            })
        })
    },

	 storeProviderProductServices({ commit }, data) {
		return new Promise((resolve, reject) => {
			storeProviderProductServices(data)
				  .then(res => {
						resolve(res);
				  })
				  .catch(err => {
						reject(err);
				  });
		});
  },

  storeClientComment({ commit }, data) {
	return new Promise((resolve, reject) => {
		storeClientComment(data)
			  .then(res => {
					resolve(res);
			  })
			  .catch(err => {
					reject(err);
			  });
	});
},

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    clientDashboard({ commit }, data) {
        return new Promise((resolve, reject) => {
            clientDashboard(data)
                .then(res => {
                    commit("SET_CLIENT_DASHBOARD", res.data.result.data);
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
