<template >
    <div class="p-2">
        <div class="app-modal__box" v-loading="loadingData">
            <div class="text-right">
                <el-button @click="drawerCreate = true" type="primary" icon="el-icon-plus">{{ $t('message.add_additional_info') }}</el-button>
            </div>
            <div v-if="list && list[0] && list[0].additionalInfo">
                <div v-for="(infos, key) in list[0].additionalInfo" :key="'add_' + key">
                    <p class="font-bold w-100 mm_back_dark p-2">{{ key }}</p>
                    <el-row :gutter="20" class="pl-3">
                        <el-col v-for="(info, index) in infos" :key="'info_' + index" class="p-2" :span="12">
                            <span class="font-bold mr-2">{{ info.name }} :</span> {{ info.value }}
                        </el-col>
                    </el-row>
                </div>
            </div>
            
        </div>

        <div class="app-modal app-modal__full modal-color-bg">

            <el-drawer
                :with-header="false"
                :visible.sync="drawerCreate"
                :append-to-body="true"
                size="80%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <add-client-addition
                    :client="client"
                    :selectedItem="selectedItem"
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                >
                </add-client-addition>
            </el-drawer>
        </div>

    </div>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";

  import AddClientAddition from "./add-client-addition";

  export default {
    mixins: [form, drawer],
    components: {
        AddClientAddition,
    },
    props: {
        client: {
            default: null
        }
    },
    data() {
      return {
        loadingData: false,
        drawerCreate: false,
        selectedItem: {},
      };
    },
    watch: {
        client: {
            handler: async function(newVal, oldVal) {
                this.fetchData();
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            rules: "clientAdditional/rules",
            model: "clientAdditional/model",
            columns: "clientAdditional/columns",
            list: "clientAdditionalInfo/list",
        }),
    },
    methods: {
        ...mapActions({
            save: "clientAdditional/store",
            updateList: "clientAdditionalInfo/index",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        fetchData(){
            if(this.client && this.client.id){
                this.loadingData = true;
                this.updateList({id: this.client.id})
                    .then(res => {
                        this.loadingData = false;
                    })
                    .catch(err => {
                        this.loadingData = false;
                    });
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        afterLeave() {
            this.activeName = "dashboard";
        },
        empty(){

        }
    },
  };
</script>
<style>
    .mm_back_dark{
        background-color: rgb(143, 143, 143);
    }
</style>