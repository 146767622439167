export const model = {
    id: null,
    type_id: null,
    deal_id: null,
    file_path: '',
    files: [],
    text: '',
    send_message: false,
    userEmailId: null,
	 excutation_time: '',
	 notify_check: false,
	 user_ids: [],
	 notification_type_id: null,
    created_at: '',
    updated_at: '',  
};