import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],

    url_address: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.url_address')}), trigger: 'change' },
    ],

    payment: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.payment')}), trigger: 'change' },
    ],

    currency_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.currency')}), trigger: 'change' },
    ],
};
