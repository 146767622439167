import {i18n} from '@/utils/i18n';

export default [{
    path: '/workMeetingInfo/:id',
    name: 'workMeetingInfo',
    meta: {
      layout: 'main',
      title: i18n.t('message.workMeetingInfo')
    },
    component: () => import('@/views/workMeeting/info')
}]
  