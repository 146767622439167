import request from '@/utils/request';

export function reasonsDeals(params) {
    return request({
        url: `/home/reasonsDeals`,
        method: 'get',
        params
    })
}

export function taskTimeLine(params) {
    return request({
        url: `/home/taskTimeLine`,
        method: 'get',
        params
    })
}

export function activityDiagram(params) {
    return request({
        url: `/home/activityDiagram`,
        method: 'get',
        params
    })
}

export function saleByPeriodDiagram(params) {
    return request({
        url: `/home/saleByPeriodDiagram`,
        method: 'get',
        params
    })
}

export function countryDeals(params) {
    return request({
        url: `/home/countryDeals`,
        method: 'get',
        params
    })
}

export function dealGoals(params) {
    return request({
        url: `/home/dealGoals`,
        method: 'get',
        params
    })
}

export function dealPayments(params) {
    return request({
        url: `/home/dealPayments`,
        method: 'get',
        params
    })
}

export function tasksByCompleted(params) {
    return request({
        url: `/home/tasksByCompleted`,
        method: 'get',
        params
    })
}

export function dealPaymentByCountry(params) {
    return request({
        url: `/home/dealPaymentByCountry`,
        method: 'get',
        params
    })
}

export function dealPaymentTerms(params) {
    return request({
        url: `/home/dealPaymentTerms`,
        method: 'get',
        params
    })
}

export function productSellStatistics(params) {
    return request({
        url: `/home/productSellStatistics`,
        method: 'get',
        params
    })
}

export function categoryStatisticBySellingProduct(params) {
    return request({
        url: `/home/categoryStatisticBySellingProduct`,
        method: 'get',
        params
    })
}

export function shipmentTerms(params) {
    return request({
        url: `/home/shipmentTerms`,
        method: 'get',
        params
    })
}

export function topSuccessfulDeals(params) {
    return request({
        url: `/home/topSuccessfulDeals`,
        method: 'get',
        params
    })
}

export function dealTypesChart(params) {
    return request({
        url: `/home/dealTypesChart`,
        method: 'get',
        params
    })
}

export function getStageDeals(params) {
    return request({
        url: '/home/getStageDeals',
        method: 'get',
        params
    })
}

export function clientStatistics(params) {
    return request({
        url: '/home/clientStatistics',
        method: 'get',
        params
    })
}

export function sendAllNotifications(params) {
    return request({
        url: '/home/sendAllNotifications',
        method: 'get',
        params
    })
}

export function managersProfit(params) {
    return request({
        url: '/home/managersProfit',
        method: 'get',
        params
    })
}

export function usersDeals(params) {
    return request({
        url: '/home/usersDeals',
        method: 'get',
        params
    })
}

export function getInformationOfClients(params) {
    return request({
        url: '/home/getInformationOfClients',
        method: 'get',
        params
    })
}
