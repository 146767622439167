<template>
    <div style="height: 428px">
        <div v-show="this.dealTypesChart && this.dealTypesChart.length > 0" id="chart">
            <v-chart class="e-chart" :option="option" autoresize />
        </div>
        <div v-show="this.dealTypesChart && this.dealTypesChart.length === 0" class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t("message.no_data_yet") }}
            </h1>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    components: { VChart },
    provide() {
        return {
            [THEME_KEY]: "dark"
        };
    },
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        },
        size: {
            default: '395'
        }
    },

    data() {
        return {
            chartClone: {},
           

            option: {
                backgroundColor: "transparent",
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    top: 'bottom',
                    left: 'center'
                },
                series: [
                    {
                        name: 'Title',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',

                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 15,
                                fontWeight: 'bold',
                                formatter: function (evt) {
                                    return `${evt.name}: `+ formatMoney(evt.value, 2)
                                },
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            }
        }
    },
    watch: {
        watchAll: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({ period: this.period, filter: this.filterForm });
                }, 500);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            dealTypesChart: "home/dealTypesChart"
        }),
        watchAll() {
            return (this.period + JSON.stringify(this.filterForm));
        },
    },
    methods: {
        ...mapActions({
            updateDealTypesChart: "home/dealTypesChart"
        }),

        updateChartData(data) {

            this.updateDealTypesChart(data)
                .then(() => {
                    if (this.dealTypesChart.length > 0) {
                        // Echart
                        this.option.series[0]['data'] = []
                        this.dealTypesChart.forEach(item => {
                            this.option.series[0]['data'].push({
                                name: item.name,
                                value: item.money_amount
                            })
                        })
                    }
                }).catch(err => console.log(err, 'errrrrrrr'));
        },
    },
};
</script>
