<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="mb-5">
            <div class="d-flex align-center justify-between">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.latest_files') }}</span>
                </h4>
                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>

            <div v-if="latestFiles.length === 0" class="text-block-tr">
                <div class="eror">
                    <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                    <h1>
                        <i class="el-icon-warning"></i>
                        <span> {{ $t('message.no_files') }} </span>
                    </h1>
                </div>
            </div>
        </div>

        <div class="doc-style" v-for="file in latestFiles" :key="file.id">
            <span @click="$emit('downloadLatestFile', file)" class="spancha d-block w50">
                <i class="el-icon-document"></i>
                {{ file ? file.name.substring(0, 20) : "" }}
                <!-- ДокументыСделкиСделки.jpg -->
            </span>
            <span @click="$emit('downloadLatestFile', file)" class="spancha d-block w20">
                {{ $t('message.deals') }} - {{ file.deal_id }}
            </span>
            <span @click="$emit('downloadLatestFile', file)" class="spancha d-block w20">
                {{ file ? file.date : "" }}
            </span>
            <span class="w10">
                <i @click="$emit('showDeal', file.deal_id)" class="el-icon-view style-view"></i>
            </span>
        </div>

        <!-- end doc-style -->
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        latestFiles: {
            type: Array
        }
    }
}
</script>

<style lang="scss" scoped></style>