export const filter = {
    id: null,
    deal_id: null,
    product_id: null,
    price: null,
    currency_rate: null,
    currency_id: null,
    quantity: null,
    profit: null,
    discount: null,
    nds: null,
    package_id: null,
    created_at: '',
    updated_at: '',         
};