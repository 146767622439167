<template>
    <div  class="el-tab-call-mobil">
        <el-tabs tab-position="bottom">
            <el-tab-pane label="User">
                <span slot="label"><i class="el-icon-date"></i><p>Клавиши</p> </span>
                <div class="call-number">
                    <div class="dial-pad">
                        <!-- <audio id="remoteAudio" ref="remoteAudioRef"></audio>

                        <audio v-for="(callsess, index) in callSessionsList" :key="'callsess_'+index" id="remoteAudio" :ref="'remoteAudioRef_'+index"></audio>

                        <audio id="incoming-call-beep" loop>
                            <source src="/audio/telephone-ring-04.mp3" type="audio/mpeg" />
                        </audio>
                        <audio id="sound-beep" loop>
                            <source src="/audio/beep.mp3" type="audio/mpeg" />
                        </audio> -->
                        <div class="statuss" v-show="(addingCall || isTransferCall) && elapsedTime > 0">
                            {{ elapsedTime > 0 ? formattedElapsedTime : ($t('message.calling')+"...") }}
                        </div>
                        <div class="statuss" v-show="(!addingCall && !isTransferCall)">
                            <div v-click-outside="onClickOutside" @click="miniStatus = !miniStatus" class="status__header">
                                <span :class="radio === 'registered' ? 'active__status' : 'notactive__status'"></span>
                                <span>{{ $t(`message.${radio}`) }}</span>
                            </div>
                            <transition name="status-fade">
                                <div v-show="miniStatus" class="status__box">
                                    <div class="status__title">
                                        <span>{{ $t('message.select_status') }}</span>
                                    </div>
                                    <ul class="status__ul">
                                        <li @click.prevent="setActive('registered')" class="call__avialable status__item">
                                            <span class="available__icon">
                                                <span class="el-icon-check"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.register') }}</span>
                                            <span v-show="isActive('registered')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('unregistered')" class="not__disturb status__item">
                                            <span class="disturb__icon">
                                                <span class="el-icon-minus"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.unregistered') }}</span>
                                            <span v-show="isActive('unregistered')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <!-- <li @click.prevent="setActive('available')" class="call__avialable status__item">
                                            <span class="available__icon">
                                                <span class="el-icon-check"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.available') }}</span>
                                            <span v-show="isActive('available')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('NotDisturb')" class="not__disturb status__item">
                                            <span class="disturb__icon">
                                                <span class="el-icon-minus"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.not_disturb') }}</span>
                                            <span v-show="isActive('NotDisturb')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('busy')" class="not__disturb status__item">
                                            <span class="disturb__icon"></span>
                                            <span class="status__text">{{ $t('message.busy') }}</span>
                                            <span v-show="isActive('busy')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('absent')" class="not__disturb status__item">
                                            <span class="disturb__icon">
                                                <span class="el-icon-close"></span>
                                            </span>
                                            <span class="status__text">{{ $t('message.absent') }}</span>
                                            <span v-show="isActive('absent')" class="status__absolute el-icon-check"></span>
                                        </li>
                                        <li @click.prevent="setActive('invisable')" class="call__hidden status__item">
                                            <span class="hidden__icon"></span>
                                            <span class="status__text">{{ $t('message.invisible') }}</span>
                                            <span v-show="isActive('invisable')" class="status__absolute el-icon-check"></span>
                                        </li> -->
                                    </ul>
                                </div>
                            </transition>
                        </div>
                        <el-select v-show="callSessionsList.length < 1" size="mini" class="mt-3 w-100" v-model="selectedPhoneNumberForOutboundCall" placeholder="Select">
                            <el-option
                                v-for="phone_for_outbound_call in (authUser.sip_account ? authUser.sip_account.phones : [])"
                                :key="phone_for_outbound_call.id"
                                :label="phone_for_outbound_call.phone_number"
                                :value="phone_for_outbound_call.prefix">
                            </el-option>
                        </el-select>
                        <div class="dial-screen mb-2 text-center" contenteditable="false">
                            {{ phonet }}
                        </div>
                        <div class="call__numbers">
                            <div class="call__line">
                                <div @click="append('1')" class="call__item">
                                    <span>1</span>
                                </div>
                                <div @click="append('2')" class="call__item">
                                    <span>2</span>
                                </div>
                                <div @click="append('3')" class="call__item">
                                    <span>3</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('4')" class="call__item">
                                    <span>4</span>
                                </div>
                                <div @click="append('5')" class="call__item">
                                    <span>5</span>
                                </div>
                                <div @click="append('6')" class="call__item">
                                    <span>6</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('7')" class="call__item">
                                    <span>7</span>
                                </div>
                                <div @click="append('8')" class="call__item">
                                    <span>8</span>
                                </div>
                                <div @click="append('9')" class="call__item">
                                    <span>9</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('*')" class="call__item">
                                    <span>*</span>
                                </div>
                                <div @click="append('0')" class="call__item">
                                    <span>0</span>
                                </div>
                                <div @click="append('#')" class="call__item">
                                    <span>#</span>
                                </div>
                            </div>
                            <div class="call__line">
                                <div @click="append('+')" class="call__empty">
                                    <span>+</span>
                                </div>
                                <div @click="callingClick" class="call__call">
                                    <span class="el-icon-phone"></span>
                                </div>
                                <div @click="del" class="call__clear">
                                    <span>
                                        <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/clear.png?alt=media&token=47fc68c8-a7b3-4318-a1db-231de3f18454" alt="">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Config">
                <span slot="label"><i class="el-icon-date"></i><p>История </p> </span>
                <el-input
                    :placeholder="$t('message.search')"
                    prefix-icon="el-icon-search"
                    v-model="input2"
                    class="input-call"
                >
                </el-input>
                <div style="overflow-y: scroll; height: 320px" v-if="authUser.sip_account">

                    <div class="call-users" v-for="(callH, index) in callHistoryList" :key="'callH_'+index">
                        <div class="call-users-info">
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                alt=""
                            />
                            <div class="call-users-info-text2">
                                <div class="info-c-u-off">
                                    <span class="name-limit">
                                        {{ removePrefixFromNumber(callH, true) }}
                                        <br>

                                        <i class="el-icon-phone"></i>  <i :class="[showCallDirection(callH), showCallStatus(callH)]"></i> {{ removePrefixFromNumber(callH, false) }}
                                    </span>
                                    <span class="sek-off font_10">
                                        {{ findContactAttribute(callH, 'company') }}
                                    </span>
                                </div>
                                <div class="info-c-u-off">
                                    <span class="sek-off">
                                        {{ setBillsec(callH) }}
                                    </span>
                                    <span class="sek-off">
                                        {{ callH.calldate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </el-tab-pane>
            <el-tab-pane label="Config">
                <span slot="label"><i class="el-icon-date"></i><p>Контакты</p> </span>
                <el-input
                    :placeholder="$t('message.search')"
                    prefix-icon="el-icon-search"
                    v-model="contactSearchInput"
                    class="input-call"
                    @input="findContactInputSearch"
                    @blur="searchInputBlur"
                    @focus="searchInputFocus"
                >
                </el-input>
                <div style="overflow-y: scroll; height: 320px">

                    <div
                        class="call-users"
                        v-for="contact in phoneContacts"
                        :key="contact.id"
                        @click="getPhoneNumber(contact)"
                        @dblclick="callingClick"
                    >
                        <div class="call-users-info">
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                alt=""
                            />
                            <div class="call-users-info-text">
                                <span class="name">
                                    {{
                                        contact.name_title
                                            ? contact.name_title.name
                                            : ""
                                    }}
                                    {{ contact.name }}
                                    {{ contact.surname }}
                                </span>
                                <span class="sek-off font_10 fbig">
                                    {{
                                        contact.company
                                            ? contact.company.name.toUpperCase()
                                            : ""
                                    }}
                                </span>
                                <span class="tel">
                                    {{ contact.phone_number }}
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </el-tab-pane>
            <el-tab-pane label="Task">
                <span slot="label"><i class="el-icon-date"></i><p>Внутренние</p> </span>
                <el-input
                    :placeholder="$t('message.search')"
                    prefix-icon="el-icon-search"
                    v-model="input2"
                    class="input-call"
                    @blur="searchInputBlur"
                    @focus="searchInputFocus"
                >
                </el-input>
                <div style="overflow-y: scroll; height: 320px">

                    <div
                        class="call-users"
                        v-for="contact in sipAccountList.filter(num => (num.username.length < 6 ))"
                        :key="contact.id"
                        @click="getPhoneNumber(contact)"
                        @dblclick="callingClick"
                    >
                        <div class="call-users-info">
                            <img
                                src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2"
                                alt=""
                            />
                            <div class="call-users-info-text">
                                <span class="name">
                                    {{
                                        contact.displayname
                                    }}
                                </span>
                                <span class="sek-off font_10 fbig">
                                </span>
                                <span class="tel">
                                    {{ contact.username }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Role">
                <span slot="label"><i class="el-icon-date"></i><p>Отделы</p> </span>
                <div>
                    <el-collapse accordion v-model="activeNames">
                        <el-collapse-item name="1">
                            <template slot="title">
                                <span class="pl-4"> S.Raximov </span>
                            </template>
                            
                            <div class="sip__item">
                                <ul class="sip__ul">
                                    <li v-for="sip_account in sipAccountList.filter(item => (parseInt(item.username) < 200))" :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                        <div class="sip__into" >
                                            <div class="sip__status">
                                                <span class="sip__indicator" :style="'background-color:'+setSipAccountsState(sip_account.username)"></span>
                                                <span class="sip__infoindicator"></span>
                                            </div>
                                            <div class="sip__img">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2" alt="">
                                            </div>
                                            <div class="sip__name">
                                                <span>{{sip_account.displayname}}</span>
                                            </div>
                                        </div>
                                        <div class="sip__phone">
                                            <a>{{sip_account.username}}</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>

                        <el-collapse-item name="2">
                            <template slot="title">
                                <span class="pl-4"> Sergili Tikuv </span>
                            </template>
                            
                            <div class="sip__item">
                                <ul class="sip__ul">
                                    <li v-for="sip_account in sipAccountList.filter(item => (200 <= parseInt(item.username) && parseInt(item.username) < 300))" :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                        <div class="sip__into">
                                            <div class="sip__status">
                                                <span class="sip__indicator" :style="'background-color:'+setSipAccountsState(sip_account.username)"></span>
                                                <span class="sip__infoindicator"></span>
                                            </div>
                                            <div class="sip__img">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2" alt="">
                                            </div>
                                            <div class="sip__name">
                                                <span>{{sip_account.displayname}}</span>
                                            </div>
                                        </div>
                                        <div class="sip__phone">
                                            <a>{{sip_account.username}}</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>

                        <el-collapse-item name="3">
                            <template slot="title">
                                <span class="pl-4"> Sergili To'quv </span>
                            </template>
                            
                            <div class="sip__item">
                                <ul class="sip__ul">
                                    <li v-for="sip_account in sipAccountList.filter(item => (300 <= parseInt(item.username) && parseInt(item.username) < 400))" :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                        <div class="sip__into">
                                            <div class="sip__status">
                                                <span class="sip__indicator" :style="'background-color:'+setSipAccountsState(sip_account.username)"></span>
                                                <span class="sip__infoindicator"></span>
                                            </div>
                                            <div class="sip__img">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2" alt="">
                                            </div>
                                            <div class="sip__name">
                                                <span>{{sip_account.displayname}}</span>
                                            </div>
                                        </div>
                                        <div class="sip__phone">
                                            <a>{{sip_account.username}}</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>

                        <el-collapse-item name="4">
                            <template slot="title">
                                <span class="pl-4"> Namangan Iplik </span>
                            </template>
                            
                            <div class="sip__item">
                                <div class="sip__cate">
                                    <span></span>
                                </div>
                                <ul class="sip__ul">
                                    <li v-for="sip_account in sipAccountList.filter(item => (400 <= parseInt(item.username) && parseInt(item.username) < 500))" :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                        <div class="sip__into">
                                            <div class="sip__status">
                                                <span class="sip__indicator" :style="'background-color:'+setSipAccountsState(sip_account.username)"></span>
                                                <span class="sip__infoindicator"></span>
                                            </div>
                                            <div class="sip__img">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2" alt="">
                                            </div>
                                            <div class="sip__name">
                                                <span>{{sip_account.displayname}}</span>
                                            </div>
                                        </div>
                                        <div class="sip__phone">
                                            <a>{{sip_account.username}}</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>

                        <el-collapse-item name="5">
                            <template slot="title">
                                <span class="pl-4"> {{ $t('message.department') }} X </span>
                            </template>
                            
                            <div class="sip__item">
                                <ul class="sip__ul">
                                    <li v-for="sip_account in sipAccountList.filter(item => (500 <= parseInt(item.username) && parseInt(item.username) < 600))" :key="sip_account.username" class="sip__li" @click="callToSipAccount(sip_account)">
                                        <div class="sip__into">
                                            <div class="sip__status">
                                                <span class="sip__indicator" :style="'background-color:'+setSipAccountsState(sip_account.username)"></span>
                                                <span class="sip__infoindicator"></span>
                                            </div>
                                            <div class="sip__img">
                                                <img src="https://firebasestorage.googleapis.com/v0/b/vue-store-1.appspot.com/o/user.png?alt=media&token=04ba7c78-0b75-431d-aa5b-3456a8ada2a2" alt="">
                                            </div>
                                            <div class="sip__name">
                                                <span>{{sip_account.displayname}}</span>
                                            </div>
                                        </div>
                                        <div class="sip__phone">
                                            <a>{{sip_account.username}}</a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </el-tab-pane>
        </el-tabs>

        <div
            class="call-big no-modal-kontak"
            :class="{ activeKontak: activeKontakModal }"
            style="background: #fff; border: 1px solid #d7d5d5"
        >
            <i
                class="el-icon-circle-close closeIconPhone-close"
                @click="closeIconKontak"
            >
            </i>

            <callKantentCom ref="callKantentCom" @closeContactModal="closeIconKontak"></callKantentCom>
        </div>
        <!-- end call add Kantent Modal -->

        <div
            class="call-big no-modal-task"
            :class="{ activetask: activeTaskModal }"
        >
            <i
                class="el-icon-circle-close closeIconPhone-close"
                @click="closeIconKontakTask"
            >
            </i>

            <callTaskCom ref="callTaskCom" @addModalEdit="addModalEdit" @createDealFromContact="createDealFromContact"></callTaskCom>
        </div>
        <!-- end call New Task Modal -->
        <div
            class="calling-style"
            :class="{ active3: callingModal, active4: miniPage }"
        >
            <div v-show="callSessionsList.length > 1" class="main__call">

                <div>
                    <ul class="meeting__ul">
                        <li v-for="(call_session, index) in callSessionsList" :key="'call_'+index" class="meeting__li">
                            <span class="meeting__title">{{ (call_session.session.direction === 'incoming' ? $t('message.incoming') : $t('message.outgoing')) + ' ' + $t('message.call') }}</span>
                            <img class="meeting__image" src="/img/default-user-icon.png" alt="" />
                            <h5>{{ call_session.display_name }}</h5>
                            <h5>{{ call_session.username }}</h5>
                            <div class="meeting__buttons">
                                <button class="mute__mic" v-show="call_session.session.status === call_session.session.C.STATUS_CONFIRMED">
                                    <i
                                        class="el-icon-video-pause"
                                        @click="puttCallOnHold(call_session.session)"
                                    >
                                    </i>
                                </button>
                                <button v-show="call_session.session.status === call_session.session.C.STATUS_WAITING_FOR_ANSWER" class="mute__mic">
                                    <i
                                        class="el-icon-phone"
                                        @click="answerCall(call_session.session, index)"
                                    >
                                    </i>
                                </button>
                                <button class="decline__icon">
                                    <i class="el-icon-phone" @click="hangUpCall(call_session.session, index)"></i>
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>


                <div class="calling-icons align__center">
                    <i
                        v-if="elapsedTime"
                        class="el-icon-plus unique__button opacityshow"
                        @click="addPhone('adding')"
                    >
                    </i>
                    <i
                        v-if="elapsedTime && !addingCall"
                        class="el-icon-sort unique__button opacityshow"
                        @click="addPhone('transfering')"
                    >
                    </i>
                    <i
                        @click="microphoneCalling"
                        :class="{ offMicrophone: addMic }"
                        class="el-icon-microphone"
                    >
                    </i>


                    <i class="el-icon-user" v-if="!miniPage" @click="ikontalModal">
                    </i>
                    <i
                        class="el-icon-suitcase"
                        v-if="!miniPage"
                        @click="ikontalModalTask"
                    >
                    </i>
                    <!-- <i class="el-icon-phone" @click="closeCallingClick"></i> -->
                    <!-- <i
                        class="el-icon-phone-outline"
                        @click="phoneOutline"
                        :class="{ noneBlock: noneBlock }"
                    >
                    </i> -->
                    <i class="el-icon-copy-document" @click="miniWidow"></i>
                </div>
            </div>
            <div v-show="callSessionsList.length === 1" class="phone__center">
                <div v-if="callSessionsList.length === 1" class="calling-text waviy">
                    {{(callSessionsList[0].session.direction === 'outgoing' && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_1XX_RECEIVED) ? ($t('message.calling')+'...') : '' }}
                    {{(callSessionsList[0].session.direction === 'incoming' && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_WAITING_FOR_ANSWER) ? 'Входящий звонок' : '' }}
                </div>
                <div class="clling-img">
                    <img src="/img/default-user-icon.png" alt="" />
                </div>
                <div v-if="callSessionsList.length === 1" class="calling-nam-user font18">
                    <i class="el-icon-office-building"></i>
                    {{ callSessionsList[0].company ? callSessionsList[0].company.name.toUpperCase() : "" }}
                </div>
                <div v-if="callSessionsList.length === 1" class="calling-nam-user">
                    <!-- Name -->
                    {{ callSessionsList[0].display_name }}
                </div>
                <div v-if="callSessionsList.length === 1" class="calling-nam-user">
                    <!-- Tell -->
                    {{ callSessionsList[0].username }}
                </div>
                <div v-if="elapsedTime > 0" class="elapsedTime calling-pharom-user">
                    {{ elapsedTime > 0 ? formattedElapsedTime : ($t('message.calling')+"...") }}
                </div>

                <div class="calling-icons">
                    <i
                        v-if="callSessionsList.length > 0 && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_CONFIRMED"
                        class="el-icon-plus unique__button opacityshow"
                        @click="addPhone('adding')"
                    >
                    </i>
                    <i
                        v-if="callSessionsList.length > 0 && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_CONFIRMED"
                        class="el-icon-sort unique__button opacityshow"
                        @click="addPhone('transfering')"
                    >
                    </i>
                    <i
                        v-if="callSessionsList.length > 0 && callSessionsList[0].session.status === callSessionsList[0].session.C.STATUS_CONFIRMED"
                        @click="microphoneCalling(callSessionsList[0].session)"
                        :class="{ offMicrophone: addMic }"
                        class="el-icon-microphone"
                    >
                    </i>
                    <i class="el-icon-user" v-if="!miniPage" @click="ikontalModal">
                    </i>
                    <i
                        class="el-icon-suitcase"
                        v-if="!miniPage"
                        @click="ikontalModalTask"
                    >
                    </i>
                    <i
                        class="el-icon-phone-outline"
                        @click="phoneOutline(callSessionsList[0].session)"
                        :class="{ noneBlock: noneBlock }"
                    >
                    </i>
                    <i class="el-icon-phone" @click="closeCallingClick(callSessionsList[0].session)"></i>
                    <i class="el-icon-copy-document" @click="miniWidow"></i>
                    <!-- <el-button v-if="!miniPage" @click="ikontalModalTask" type="danger" icon="el-icon-delete" circle></el-button> -->
                </div>
            </div>
        </div>
        <!-- end call Call Mini CAll Modal -->

        <div v-if="!activeIconPhone" class="fixed__column fixed-home-pilus">
            <div class="carte" @click="newcontact = true"  @mouseleave="newcontact = false">
                <!-- <div class="carte__button" @mouseover="newcontact = true">
                    <span class="carte__icon">
                        <i class="el-icon-plus"> </i>
                    </span>
                </div> -->
                <transition name="just">
                    <div class="carte__inside" v-show="newcontact">
                        <ul class="carte__ul">
                            <li
                                class="carte__li"
                                @click="drawerDealCreate = true"
                            >
                                <a>{{ $t('message.new_deal') }}</a>
                                <span>С</span>
                            </li>
                            <li
                                class="carte__li"
                                @click="taskModalDrawer = true"
                            >
                                <a>{{ $t('message.new_task') }}</a>
                                <span>З</span>
                            </li>
                            <li
                                class="carte__li"
                                @click="drawerCreateContact = true"
                            >
                                <a>{{ $t('message.new_contact') }}</a>
                                <span>К</span>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>
        </div>
        <!-- end call-big-modal -->

    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";

import JsSIP from "jssip";

export default {
    mixins: [form, drawer],
    props: {
        callRequest: Object
    },
    components: {
    },
    data() {
        return {
            activeNames: [],
            callMobil: false,
            activeCatPhone: false,
            miniStatus: false,
            radio: "registered",
            activeName: "callTab",
            calculation: "",
            contactSearchInput: '',
            input2: "",
            activeIconPhone: false,
            callingModal: false,
            addMic: false,
            noneBlock: false,
            miniPage: false,
            noneIcon: false,
            elapsedTime: 0,
            newcontact: false,
            timer: undefined,
            taskModalDrawer: false,
            reloadList: false,
            drawerDealCreate: false,
            dealCreate: true,
            drawerCreateContact: false,
            pickUpCallIcon: false,
            activeKontakModal: false,
            activeTaskModal: false,
            userAgentOptions: {},
            connection: null,
            phone: "",
            audioStart: true,
            selectedDeal: {},
            board_id: null,
            phoneStatus: '',
            dataSearchIsWorking: false,
            callHistoryInput: '',
            format: '',
            regax: '^',
            template: 'XX XXX XX XX',
            phonet: '',
            sipAccountList: [],
            selectedPhoneNumberForOutboundCall: '',
            isTransferCall: false,
            addingCall: false,
            callDirection: '',
            callSessionsList: [],
            callRequestUpdate: [],
            callHistorySave: false,
        };
    },
    mounted() {
        this.addKeyPressListener();

        // if(this.audioStart){
        //     document.addEventListener("mouseup", e => {
        //         if(this.audioStart){
        //             let playPromise = document.getElementById("incoming-call-beep").play();
        //             if (playPromise !== undefined) {
        //                 playPromise.then(_ => {
        //                     document.getElementById("incoming-call-beep").pause();
        //                 })
        //                 .catch(error => {
        //                 });
        //             }
        //             this.audioStart = false;
        //         }
        //     })
        // }
        let x = 1;
        this.format = this.template.replace(/X+/g, () => '$' + x++);
        this.template.match(/X+/g).forEach((char, key) => {
            this.regax += '(\\d{' + char.length + '})?'
        })
    },
    watch: {
        callRequest: {
            handler(newValue, oldValue) {
                this.callRequestUpdate = JSON.parse(JSON.stringify(newValue));
                if(newValue.phone){
                    this.calculation = this.phoneFilterForCall(newValue.phone);
                    if(newValue.call && newValue.phone_country_code === "UZ"){
                        this.callHistorySave = true;
                        this.callingClick();
                    }
                }

            },
            immediate: true,
            deep: true
        },
        calculation (next, prev) {
            this.phonet = this.calculation
            if (next.length > prev.length) {
                this.phonet = this.phonet.replace(/[^0-9]/g, '')
                    .replace(new RegExp(this.regax, 'g'), this.format)
                    .substr(0, this.template.length)
            }
        }
    },
    computed: {
        ...mapGetters({
            phoneContacts: "phoneContacts/inventory",
            authUser: "auth/user",
            serverSipAccountList: "sipAccounts/serverSipAccountList",
            callHistoryList: "callHistories/phoneCallList",
            ariPhones: "sipAccounts/ariPhones",
            dealModel: "deals/model",
        }),
        formattedElapsedTime() {
            const date = new Date(null);
            date.setSeconds(this.elapsedTime / 1000);
            const utc = date.toUTCString();
            return utc.substr(utc.indexOf(":") - 2, 8);
        },
        exhibition_id: function () {
            return this.$route.params.exhibition_id
        },
    },
    created() {
        setTimeout(() => {
            if(this.authUser.sip_account){
                if(this.authUser.sip_account.phones.length > 0){
                    this.selectedPhoneNumberForOutboundCall = this.authUser.sip_account.phones[0].prefix;
                    this.debouncedRegisterUserAgentJSSIP(this);
                }
            }else{
                this.phoneStatus = ''
            }
        }, 200);
        this.updateContactList();
        let url = 'https://bmcallserver.gomax.uz:8089/ari/endpoints?api_key=go_crm_ari_user:ari_password'
        this.ariConnectionWithServer(url);

        this.sipAccountsFromServer()
            .then(res => {
                this.sipAccountList = JSON.parse(JSON.stringify(this.serverSipAccountList));
            });
        this.updateContactInventory();
        this.debouncedFetchCallHistoryData(this);
    },

    methods: {
        ...mapActions({
            updateContactInventory: "phoneContacts/inventory",
            searchPhoneContact: "phoneContacts/searchPhoneContact",
            updateTaskList: "taskBoards/index",
            updateDealsList: "boards/index",
            updateExhibitionDealsList: "deals/index",
            updateContactList: "phoneContacts/index",
            updatePhoneNumberDeals: "deals/phoneNumberDeals",
            sipAccountsFromServer: "sipAccounts/sipAccountsFromServer",
            ariConnectionWithServer: "sipAccounts/ariConnectionWithServer",
            updateCallHistoryList: "callHistories/getPhoneCalls",
            bindingCallHistory: "callHistories/bindingCallHistory",
            bindingCallHistory: "callHistories/bindingCallHistory",
            updateDeal: "deals/show",
            updateDealCallList: "callHistories/index",
        }),
        phoneFilterForCall(phone){
            if(phone && phone.length === 13){
                return phone.slice(4);
            }
            this.$notify({
                title: this.$t('message.register'),
                type: "error",
                offset: 130,
                message: this.$t('message.phone_number_is_not_valid')
            });
            return '';
        },
        addPhone (event) {
            if (event == 'adding') {
                this.addingCall = true
                this.activeIconPhone = true
                this.callingModal = false
            }
            if (event == 'transfering') {
                this.isTransferCall = true
                this.activeIconPhone = true
                this.callingModal = false
            }
        },
        setSipAccountsState(number){
            let phone_list = JSON.parse(JSON.stringify(this.ariPhones));
            let phone_state = phone_list.find(item => item.resource === number);
            let color = 'red';
            if(phone_state){
                if(phone_state.state === 'online'){
                    color = 'green';
                }
            }
            return color;
        },
        phoneNotRegisteredMessage(){
            this.$notify({
                title: this.$t('message.register'),
                type: "error",
                offset: 130,
                message: this.$t('message.phone_not_registered_please_register_phone_to_make_a_call')
            });
        },
        isActive (menuItem) {
            return this.radio === menuItem
        },
        setActive (menuItem) {
            if(menuItem === 'registered'){
                this.debouncedRegisterUserAgentJSSIP(this);
            }
            if(menuItem === 'unregistered'){
                this.unregisterThePhone();
            }

            this.radio = menuItem
            this.miniStatus = false
        },
        onClickOutside () {
            this.miniStatus = false
        },
        debouncedRegisterUserAgentJSSIP: _.debounce((self) => {
            if (_.isFunction(self.registerUserAgentJSSIP)) {
                self.registerUserAgentJSSIP();
            }
        }, 1000),
        debouncedFetchCallHistoryData: _.debounce((self) => {
            if (_.isFunction(self.fetchCallHistoryData)) {
                self.fetchCallHistoryData();
            }
        }, 3000),
        calculateCallDuration(duration){
            let minutes = Math.floor(duration/60)
            let seconds = duration%60

            if(minutes < 10){
                minutes = '0'+minutes;
            }
            if(seconds < 10){
                seconds = '0'+seconds;
            }
            let time = minutes + ' : ' + seconds;
            return time;

        },
        showCallDirection(callH){
            if(this.authUser.sip_account.sip_number === callH.cnum){
                return "el-icon-top-right";
            }else if(this.authUser.sip_account.sip_number !== callH.cnum && callH.disposition === 'BUSY'){
                return "el-icon-circle-close";
            }else if(this.authUser.sip_account.sip_number !== callH.cnum && callH.disposition === 'NO ANSWER'){
                return "el-icon-check";
            }
            return "el-icon-bottom-left";

        },
        showCallStatus(callH){
            if(this.authUser.sip_account.sip_number === callH.cnum){
                if(callH.disposition === 'ANSWERED'){
                    return "coo1";
                }else if(callH.disposition === 'NO ANSWER'){
                    return "coo2";
                }else{
                    return "coo3";
                }
            }else{
                if(callH.disposition === 'ANSWERED'){
                    return "coo1";
                }else if(callH.disposition === 'NO ANSWER'){
                    return "coo2";
                }else{
                    return "coo3";
                }
            }
        },


        removePrefixFromNumber(call, show_name) {
            let number = '';
            let name = '';
            if(call.dst.length > 5 && call.cnum !== call.src && this.authUser.sip_account.all_phones_for_outbound_calls.find(phone => phone.phone_number.includes(call.src))){
                    this.authUser.sip_account.all_phones_for_outbound_calls.forEach(phone => {
                        if(phone.phone_number.includes(call.src)){
                            number = JSON.parse(JSON.stringify(call.dst));
                            number = number.slice(phone.prefix.length);
                            let cont = this.phoneContacts.find(item => item.phone_number.includes(number))
                            if(cont){
                                name = (cont.name_title ? cont.name_title.name : '') + ' ' + cont.name + ' ' + (cont.surname ? cont.surname : '')
                                name = JSON.parse(JSON.stringify(name))
                            }
                        }
                    });

            }else if(call.dst.length <= 5){
                number = JSON.parse(JSON.stringify(call.dst));
                let cont_t = '';
                if(this.sipAccountList){
                    cont_t = this.sipAccountList.find(item => item.username === call.dst);
                }
                if(cont_t && cont_t !== undefined && cont_t !== null){
                    name = cont_t ? JSON.parse(JSON.stringify(cont_t.displayname)) : '';
                }
            }

            if(this.authUser.sip_account.sip_number === call.dst){
                number = call.src;
                let cont = this.phoneContacts.find(item => item.phone_number.includes(number))
                if(cont){
                    name = (cont.name_title ? cont.name_title.name : '') + ' ' + cont.name + ' ' + (cont.surname ? cont.surname : '')
                    name = JSON.parse(JSON.stringify(name))
                }
            }

            if(show_name){
                return name;
            }
            return number;
        },
        findContactAttribute(callH, atribute_name){
            let number = '';
            if(this.authUser.sip_account){
                if(callH.cnum !== this.authUser.sip_account.sip_number){
                    number = callH.cnum
                }else{
                    number = callH.dst
                }
            }else{
                number = callH.dst
            }

            let account = {};

            if(this.phoneContacts.find(item => item.phone_number === number)){
                account = this.phoneContacts.find(item => item.phone_number === number);
            }
            if(this.serverSipAccountList.find(item => item.username === number)){
                account = this.serverSipAccountList.find(item => item.username === number);
            }

            let string_data = '';

            if(atribute_name === 'name'){
                string_data = account.name ? account.name : (account.displayname ? account.displayname : '')
            }
            if(atribute_name === 'company'){
                string_data = account.company ? account.company.name : ''
            }
            if(atribute_name === 'number'){
                string_data = number.substring(this.selectedPhoneNumberForOutboundCall.length);
            }

            return string_data;

        },
        createDealFromContact(){
            this.dealCreate = true;
            this.activeTaskModal = false;
            this.drawerDealCreate = true;
        },
        addModalEdit({deal, dealCreate}) {
            this.activeTaskModal = false;
            this.board_id = deal.board_id;
            this.selectedDeal = deal;
            this.dealCreate = dealCreate;
            this.drawerDealCreate = true;
        },
        fetchCallHistoryData() {
            this.loadingButton = true;
            this.loadingData = true;
            this.updateCallHistoryList({number_of_data: 20, only_phone_history: true})
                .then(res => {
                    this.loadingButton = false;
                    this.loadingData = false;
                })
                .catch(err => {
                    this.loadingButton = false;
                    this.loadingData = false;
                });
        },
        addKeyPressListener(){
            window.addEventListener("keydown", function(e) {
                if(this.activeIconPhone && !this.dataSearchIsWorking){
                    if(e.key >= 0 || e.key === '+' || e.key === '#' || e.key === '*'){
                        this.calculation += e.key;
                    }
                    if(e.code === 'Backspace' &&  this.calculation.length > 0){
                        this.calculation = this.calculation.slice(0, -1);
                    }
                    if(e.code === 'Enter' &&  this.calculation.length > 0){
                        this.callingClick();
                    }
                }
            }.bind(this));
        },
        registerUserAgentJSSIP() {
            // using jsSIP
            let vm = this;
            let wss_url = "wss://" + vm.authUser.sip_account.server_url + ":" + vm.authUser.sip_account.port + "/ws";
            let socket = new JsSIP.WebSocketInterface(wss_url);
            let sip_uri = this.authUser.sip_account.extension_type + ":" + this.authUser.sip_account.sip_number + "@" + this.authUser.sip_account.server_url;
            let configuration = {
                sockets: [socket],
                uri: sip_uri,
                password: this.authUser.sip_account.password,
            };

            let coolPhone = new JsSIP.UA(configuration);
            coolPhone.start();
            coolPhone.register();

            coolPhone.on("registered", function (e) {
                vm.$notify({
                    title: vm.$t('message.register'),
                    type: "success",
                    offset: 130,
                    message: vm.$t('message.phone_registered_message', {phone: vm.authUser.sip_account.sip_number})
                });
                vm.phoneStatus = 'registered';
            });
            coolPhone.on("unregistered", function (e) {
                vm.$notify({
                    title: vm.$t('message.unregister'),
                    type: "success",
                    offset: 130,
                    message: vm.$t('message.phone_unregistered', {phone: vm.authUser.sip_account.sip_number})
                });

                vm.phoneStatus = 'unregistered';
            });
            coolPhone.on("registrationFailed", function (e) {
                vm.$notify({
                    title: vm.$t('message.register'),
                    type: "error",
                    offset: 130,
                    message: vm.$t('message.phone_not_registered_message', {phone: vm.authUser.sip_account.sip_number}),
                });
                vm.phoneStatus = 'registrationFailed'
            });

            coolPhone.on("newRTCSession", function (data) {
                let session = data.session;
                session.on("peerconnection", function (data) {
                    data.peerconnection.addEventListener('addstream', function (e) {
                        // set remote audio stream
                        vm.$refs["remoteAudioRef"].srcObject = e.stream;
                        vm.$refs["remoteAudioRef"].play();
                    });
                });

                if (session.direction === "outgoing") {
                    session.connection.createOffer().then(function(offer) {
                        try{
                            session.connection.setLocalDescription(offer);
                        }catch(err){
                            console.log(err,'err');
                        }
                    }).catch(err => {
                        console.log(err, 'errrrrr');
                    })
                }

                vm.waitCallDecision(session);
                vm.callDirection = session.direction;

                if (session.direction === "outgoing") {

                    let start_out = true;
                    session.on("progress", function (e) {
                        if(start_out){
                            start_out = false;
                                if(session.remote_identity.uri.user.length <= 5){
                                    document.getElementById("sound-beep").play();
                                }
                        }
                    });
                    session.on("accepted", function (e) {
                        if(vm.callSessionsList.length === 1){
                            vm.start();
                        }
                        document.getElementById("sound-beep").load();
                    });
                    session.on("confirmed", function (e) {
                        if(vm.addingCall){
                            vm.callSessionsList.length > 1 ? vm.conferencing() : '';
                            setTimeout(() => {
                                vm.callSessionsList.forEach(element => {
                                    if(element.session.isOnHold().local){
                                        element.session.unhold();
                                    }
                                });
                            }, 200);

                            // vm.addingCall = false;
                        }
                        document.getElementById("sound-beep").load();
                    });

                    session.on("refer", function (req) {
                    });

                    session.on("failed", function (reason) {
                        vm.callStopped(session);
                    });

                    session.on("ended", function () {
                        if(vm.isTransferCall === true && session.status && session.C.STATUS_TERMINATED){
                            vm.callSessionsList = [];
                        }
                        vm.callStopped(session);
                    });
                    session.on("icecandidate", function (event) {
                        if (
                            event.candidate.type === "srflx" &&
                            event.candidate.relatedAddress !== null &&
                            event.candidate.relatedPort !== null
                        ) {
                            event.ready();
                        }
                    });
                }
                if (session.direction === "incoming") {
                    session.on("progress", function (e) {
                        document.getElementById("incoming-call-beep").play();
                        if(session.remote_identity.uri.user){
                            vm.updatePhoneNumberDeals(session.remote_identity.uri.user);
                        }
                    });
                    session.on("refer", function (req) {
                    });
                    session.on("accepted", function () {
                        if(vm.callSessionsList.length === 1){
                            vm.start();
                        }
                        document.getElementById("incoming-call-beep").load();
                    });
                    session.on("confirmed", function () {
                        if(vm.addingCall){
                            vm.callSessionsList.length > 1 ? vm.conferencing() : '';
                        }
                    });
                    session.on("ended", function () {
                        vm.callStopped(session);
                    });

                    session.on("failed", function (reason) {
                        vm.callStopped(session);
                    });
                    session.on("addstream", function (e) {
                        vm.$refs["remoteAudioRef"].srcObject = e.stream;
                        vm.$refs["remoteAudioRef"].play();
                    });


                }
            });
            this.phone = coolPhone;
            this.$phoneUA = coolPhone;
        },
        unregisterThePhone(){
            if(this.phone.isRegistered()){
                this.phone.unregister()
            }
        },
        conferencing() {
            //take all received tracks from the sessions you want to merge
            let sessions = this.callSessionsList;
            let receivedTracks = [];
            sessions.forEach(function(session) {
                if(session !== null && session !== undefined) {
                    session.session.connection.getReceivers().forEach(function(receiver) {
                        receivedTracks.push(receiver.track);
                    });
                }
            });
            //use the Web Audio API to mix the received tracks
            let context = new AudioContext();
            let allReceivedMediaStreams = new MediaStream();

            sessions.forEach(function(session) {
                if(session !== null && session !== undefined) {

                    let mixedOutput = context.createMediaStreamDestination();

                    session.session.connection.getReceivers().forEach(function(receiver) {
                        receivedTracks.forEach(function(track) {
                            allReceivedMediaStreams.addTrack(receiver.track);
                            if(receiver.track.id !== track.id) {
                                let sourceStream = context.createMediaStreamSource(new MediaStream([track]));
                                sourceStream.connect(mixedOutput);
                            }
                        });
                    });
                //mixing your voice with all the received audio
                    session.session.connection.getSenders().forEach(function(sender) {
                        let sourceStream = context.createMediaStreamSource(new MediaStream([sender.track]));
                        sourceStream.connect(mixedOutput);
                    });
                    session.session.connection.getSenders()[0].replaceTrack(mixedOutput.stream.getTracks()[0]);
                }
            });

            //play all received stream to you
            this.$refs["remoteAudioRef"].srcObject = allReceivedMediaStreams;
            let promiseRemote = this.$refs["remoteAudioRef"].play();

            if(promiseRemote !== undefined) {
                promiseRemote.then(_ => {
                    console.log("playing all received streams to you");
                }).catch(error => {
                    console.log(error);
                });
            }

        },
        setBillsec(callH){
            let min = Math.floor(callH.billsec/60) < 10 ? ('0'+ Math.floor(callH.billsec/60)) : Math.floor(callH.billsec/60);
            let sec = callH.billsec%60 < 10 ? ('0'+ callH.billsec%60) : callH.billsec%60;
            return min+':'+sec;
        },
        addCallToCallSessionsList(session){
            let existing_session = {};
            if(session.direction === 'incoming'){
                if(session.remote_identity.uri.user.length > 5){
                    let find_con = this.phoneContacts.find(el => el.phone_number.includes(session.remote_identity.display_name));
                    existing_session = {
                        session: session,
                        display_name: find_con ? JSON.parse(JSON.stringify(find_con.name)) : session.remote_identity.display_name,
                        username: find_con ? JSON.parse(JSON.stringify(session.remote_identity.uri.user)) : '',
                        company: find_con ? (find_con.company ? find_con.company : '') : '',
                    };
                }else{
                    existing_session = {
                        session: session,
                        display_name: JSON.parse(JSON.stringify(session.remote_identity.display_name)),
                        username: JSON.parse(JSON.stringify(session.remote_identity.uri.user)),
                        company: '',
                    };
                }
            }
            if(session.direction === 'outgoing'){
                if(this.calculation.length > 5){
                    let find_con = this.phoneContacts.find(el => el.phone_number.includes(this.calculation));
                    existing_session = {
                        session: session,
                        display_name: find_con ? JSON.parse(JSON.stringify(find_con.name)) : '',
                        username: JSON.parse(JSON.stringify(this.calculation)),
                        company: find_con ? (find_con.company ? find_con.company : '') : '',
                    };
                }else{
                    let find_con = this.sipAccountList.find(el => el.username === this.calculation);
                    existing_session = {
                        session: session,
                        display_name: find_con ? JSON.parse(JSON.stringify(find_con.displayname)) : '',
                        username: JSON.parse(JSON.stringify(this.calculation)),
                        company: '',
                    };
                }
            }


            if(this.addingCall && this.callSessionsList.length === 1){
                this.callSessionsList.forEach(tell => { tell.session.hold() });
            }
            this.callSessionsList.unshift(existing_session);

        },
        callStopped(session){
            if(session.status !== session.C.STATUS_CONFIRMED){
                this.callSessionsList.forEach((item) => {
                    if(item.session.isOnHold().local){
                        item.session.unhold();
                    }
                })
            }
            this.callSessionsList = this.callSessionsList.filter(item => item.session.status !== item.session.C.STATUS_TERMINATED)

            document.getElementById("sound-beep").load();
            document.getElementById("incoming-call-beep").load();
            this.callingModal = false;
            this.activeIconPhone = true;
            this.noneBlock = false;
            this.addMic = false;
            if(this.callSessionsList.length === 0 || this.isTransferCall){
                if(this.callRequestUpdate && this.callRequestUpdate.callable && this.callHistorySave){
                    this.callHistorySave = false;
                    let outbound = this.authUser.sip_account.phones.find(tell => tell.prefix === this.selectedPhoneNumberForOutboundCall);
                    this.callRequestUpdate.src = this.phoneFilterForCall(outbound.phone_number);
                    this.callRequestUpdate.phone = this.phoneFilterForCall(this.callRequestUpdate.phone);
                    this.bindAndUpdateCall();
                }
                this.stop();
                this.isTransferCall = false;
                this.addingCall = false;
            }
            this.fetchCallHistoryData();
        },
        bindAndUpdateCall(){
            this.bindingCallHistory(this.callRequestUpdate).then(res => {
                if(this.callRequestUpdate.callable.callable_type === 'deals'){
                    this.updateDeal(this.callRequestUpdate.callable.callable_id).then(res => {
                        if(this.dealModel.happyCalls.length > 0){
                            this.updateDealCallList({uniqueIds: this.dealModel.happyCalls});
                        }
                    });
                }

            });
        },

        callToSipAccount(sip_account){
            this.getPhoneNumber(sip_account);
            setTimeout(() => {
                this.callingClick();
            }, 100);
        },
        puttCallOnHold(session){
            if(session.isOnHold().local){
                session.unhold()
            }else{
                session.hold();
            }
        },
        hangUpCall(session, index) {
            if((session.status && session.status !== session.C.STATUS_TERMINATED) || session.status === 0){

                if(!document.getElementById("incoming-call-beep").paused){
                    document.getElementById("incoming-call-beep").load();
                }
                if(!document.getElementById("sound-beep").paused){
                    // document.getElementById("sound-beep").load();
                }

                session.terminate();
                if(session && session.status === session.C.STATUS_TERMINATED){
                    this.callSessionsList.forEach(item => {
                        if(item.session.isOnHold().local){
                            item.session.unhold();
                        }
                    })
                }
            }
            this.fetchCallHistoryData()
        },
        callToOthers(ua) {
            this.noneBlock = true;
            var options = {
                mediaConstraints: { audio: true, video: false },
                pcConfig: {
                    iceServers: [
                        {
                            urls: [
                                "stun:stun.l.google.com:19302",
                                // "stun:stun.zoiper.com:3478"
                            ],
                        },
                    ],
                    iceTransportPolicy: "all",
                    rtcpMuxPolicy: "negotiate"
                },
            };
            this.pickUpCallIcon = false;
            let vm = this;

            let number = "";
            if (this.calculation.length > 0 && this.calculation.length <= 5 && this.authUser.sip_account) {
                number = this.authUser.sip_account.extension_type + ":" + this.calculation + "@" + this.authUser.sip_account.server_url;
            } else {
                number = this.calculation;
            }
            this.updatePhoneNumberDeals(number);
            if(ua){
                if(this.calculation.length > 5){
                    number = this.selectedPhoneNumberForOutboundCall.toString() + number.toString();
                }
                if(this.isTransferCall && this.callSessionsList.length === 1){
                    this.transferCall(number, options)
                }else{
                    let session = ua.call(number, options);
                    session.connection.addEventListener("addstream", (e) => {
                        if(session.status && session.status === session.C.STATUS_TERMINATED)
                        {

                        }else{
                            vm.$refs["remoteAudioRef"].srcObject = e.stream;
                            vm.$refs["remoteAudioRef"].play();
                        }
                    });
                }

            }

        },
        transferCall(num, options){
            this.callSessionsList[0].session.refer(num, options);
            if(this.callSessionsList[0].session.status && this.callSessionsList[0].session.status === this.callSessionsList[0].session.C.STATUS_TERMINATED){
                this.callSessionsList = [];
            }
        },
        answerCall(session, index) {
            if(Object.keys(session)){
                let callOptions = {
                    mediaConstraints: {
                        audio: true, // only audio calls
                        video: false,
                    },
                    rtcOfferConstraints: {
                        audio: true, // only audio calls
                        video: false,
                    },
                    sessionTimersExpires: 3600,
                    pcConfig: {
                        iceServers: [
                            {
                                urls: [
                                    "stun:stun.l.google.com:19302",
                                    // "stun:stun.zoiper.com:3478"
                                ],
                            },
                        ],
                        iceTransportPolicy: "all",
                        rtcpMuxPolicy: "negotiate",
                    },
                };

                if(!this.addingCall){
                    this.callSessionsList.forEach(element => {
                        if(element.session.status === element.session.C.STATUS_CONFIRMED){
                            element.session.hold();
                        }
                    });
                }

                session.answer(callOptions);

                if(session && session.status === session.C.STATUS_CONFIRMED && this.addingCall){
                        this.callSessionsList.forEach(element => {
                            if(element.session.isOnHold().local){
                                element.session.unhold();
                            }
                        });
                }
            }
        },
        waitCallDecision(session) {
            if(Object.keys(session)){
                this.addCallToCallSessionsList(session);
                this.callingModal = true;
                this.pickUpCallIcon = true;
                this.activeIconPhone = false;
                this.miniPage = false;
            }
        },
        listChanged() {
            this.reloadList = true;
        },
        closeDrawer(drawer) {
            this.taskModalDrawer = false;
            if (
                this.$refs[drawer] &&
                _.isFunction(this.$refs[drawer].closeDrawer)
            ) {
                this.$refs[drawer].closeDrawer();
            }
        },
        closeAddModel() {
            this.drawerDealCreate = false;
            if (this.reloadList === true) {
                this.updateDealsList().then((res) => this.reloadList === false);
                if(this.exhibition_id && this.exhibition_id !== undefined && this.exhibition_id !== NaN){
                    const query = {...this.filter, ...this.pagination, ...this.sort, exhibition_id: this.exhibition_id };
                    this.updateExhibitionDealsList(query);
                }
                if(this.calculation.length > 0){
                    this.updatePhoneNumberDeals(this.calculation).then((res) => this.reloadList === false);
                }
            }
            if(!this.activeTaskModal && this.miniPage){
                this.activeTaskModal = true;
            }
            setTimeout(() => {
                this.selectedDeal = {};
                this.board_id = null;
                this.dealCreate = true;
            }, 100);
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed();
            }
            if (this.reloadList === true) {
                if (ref === "drawerCreateContactChild") {
                    this.updateContactList().then(
                        (res) => this.reloadList === false
                    );
                }
                if (ref === "drawerCreateTaskChild") {
                    this.updateTaskList().then(
                        (res) => this.reloadList === false
                    );
                }
            }
            if (_.isFunction(this.empty)) {
                this.empty();
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
        searchInputBlur(){
            this.dataSearchIsWorking = false;
        },
        searchInputFocus(){
            this.dataSearchIsWorking = true;
        },
        findCallHistorySearch() {

        },
        findContactInputSearch() {
            if (this.contactSearchInput) {
                this.searchContacts(this.contactSearchInput, this);
            } else {
                setTimeout(() => {
                    this.updateContactInventory();
                }, 1000);
            }
        },
        searchContacts: _.debounce((search, self) => {
            if (_.isFunction(self.searchPhoneContact)) {
                self.searchPhoneContact({ text: search });
            }
        }, 1000),
        getPhoneNumber(contact) {
            if(contact.phone_number){
                this.calculation = this.phoneFilterForCall(JSON.parse(JSON.stringify(contact.phone_number)));
            }else{
                this.calculation = JSON.parse(JSON.stringify(contact.username));
            }
        },
        start() {
            this.timer = setInterval(() => {
                this.elapsedTime += 1000;
            }, 1000);
        },
        stop() {
            clearInterval(this.timer);
            setTimeout(() => {
                this.elapsedTime = 0;
            }, 300);

        },
        handleClose(done) {
            this.$confirm(this.$t('message.Are you sure to close this window'))
                .then((_) => {
                    done();
                })
                .catch((_) => {});
        },

        /****************Phone Numer***********************/
        append(value) {
            this.calculation += value.toString();
        },
        del() {
            if (this.calculation.toString().length > 1) {
                this.calculation = this.calculation
                    .toString()
                    .substring(0, this.calculation.toString().length - 1);
            } else {
                this.calculation = "";
            }
        },
        /****************End Phone Numer***********************/

        /****************icon Phone Icon Modal***********************/
        iconPhone(e) {

            this.activeIconPhone = true;
            this.noneIcon = true;
        },
        closeIconPhone(e) {
            if(this.callSessionsList.length < 1){
                this.activeIconPhone = false;
                this.noneIcon = false;
            }else{
                this.isTransferCall = false;
                this.callingModal = true;
                this.activeIconPhone = false;
            }
        },
        closeCatPhone(e) {
            this.activeCatPhone = false;
            this.activeIconPhone = true;
        },
        openCatPhone(e) {
            this.activeCatPhone = true;
            this.activeIconPhone = false;
        },
        /****************icon Phone Icon Modal***********************/

        ikontalModal(e) {
            this.activeKontakModal = true;
            this.miniPage = true;
            this.$refs.callKantentCom.afterOpen();
        },

        closeIconKontak(e) {
            this.activeKontakModal = false;
            this.miniPage = !this.miniPage;
        },

        ikontalModalTask(e) {
            this.activeTaskModal = true;
            this.miniPage = true;
            this.$refs.callTaskCom.afterOpen();
        },

        closeIconKontakTask(e) {
            this.activeTaskModal = false;
            this.miniPage = !this.miniPage;
        },

        /****************icon Phone Icon Modal***********************/
        callingClick(e) {
            if(this.phone.isRegistered()){
                if (this.calculation.length > 0 && this.calculation !== this.authUser.sip_account.sip_number) {
                    this.closeCatPhone();
                    this.callingModal = true;
                    this.activeIconPhone = false;
                    this.miniPage = false;
                    this.callToOthers(this.phone);
                    this.elapsedTime = 0;
                    this.noneIcon = true;
                } else if (this.calculation.length === 0) {
                    this.$notify({
                        title: this.$t('message.no_phone'),
                        type: "warning",
                        offset: 130,
                        message: this.$t('message.enter_phone_number_to_call'),
                    });
                }else{
                    this.$notify({
                        title: this.$t('message.no_phone'),
                        type: "warning",
                        offset: 130,
                        message: this.$t('message.you_can_not_call_yourself'),
                    });
                }
            }else{
                this.phoneNotRegisteredMessage();
                this.phoneStatus = 'unregistered';
            }

        },
        closeCallingClick(session) {
            this.activeKontakModal = false;
            this.activeTaskModal = false;
            this.callingModal = false;
            this.activeIconPhone = true;
            this.noneBlock = false;
            this.hangUpCall(session, 0);
        },
        /****************icon Phone Icon Modal***********************/

        microphoneCalling(session) {
            this.addMic = !this.addMic;
            if(this.addMic){
                session.mute({audio: true});
            }else{
                session.unmute({audio: true});
            }
        },

        /****************qong'iroq bo'lganda ko'tarish iconni***********************/
        phoneOutline(session) {
            this.noneBlock = true;
            this.answerCall(session, 0);
        },

        miniWidow(e) {
            this.activeKontakModal = false;
            this.activeTaskModal = false;
            this.miniPage = !this.miniPage;
        },

        /****************end qong'iroq bo'lganda ko'tarish iconni***********************/
    },
};
</script>
<style lang="scss">
.el-dialo-gcallMobil .el-dialog__body{
    padding: 0px !important;
}
.el-tab-call-mobil{
    .el-tabs__nav {
        width: 100%;
    }
    .el-tabs__active-bar{
        display: none;
    }
    .el-tabs__item{
        padding: 0 5px;
        width: 20%;
        span{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        p{
            padding: 0px;
            margin: 0px;
            line-height: 20px;
        }
    }
    .el-tabs__item.is-bottom.is-active p,
    .el-tabs__item.is-bottom.is-active{
        color: #409EFF !important;
    }
    .el-tabs__item.is-bottom.is-active span{
        background: #409eff42;
        margin: 7px;
        padding: 5px;
        border-radius: 5px;
    }
    .el-tabs__nav-wrap::after {
        content: initial;
    }
    .call-number{
        display: flex;
        justify-content: center;
        margin: 20px 0;
        padding: 0;
    }
    .el-tabs__content{
        height: calc(100vh - 118px);
        overflow-y: auto;
    }
    .el-tabs__header{
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 55px;
        height: 55px;
        background-color: #fff;
        z-index: 9;
    }
    .el-dialog__body{
        position: relative;
    }
    .no-modal-task,
    .calling-style {
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        top: 0;
    }
    .closeIconPhone-close {
        position: absolute;
        top: -1px;
        right: -1px;
    }

    @media only screen and (max-width: 400px) {
        .no-modal-kontak {
            width: 95%;
        }
    }
}
.d-modal-style .call__empty,
.d-modal-style .call__item{
    background: #0e2c46;
}
.el-dialo-gcallMobil{
    overflow: hidden !important;
}
.el-dialo-gcallMobil .el-dialog{
    margin-top: 0vh !important;
    height: 100%;
    .el-dialog__body{
        height: 100%;
    }
}
.d-modal-style .el-tab-call-mobil .el-tabs__header{
    background-color: #080c24;
}
</style>
