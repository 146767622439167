export const model = {
    id: null,
    name: '',
	 begin_date: '',
	 end_date: '',
	 date_status: '',
	 plan_hour: null,
	 price: 0,
	 currency_id: null,
	 currency_rate: null,
	 filial_ids: [],
	 leader_ids: [],
	 responsible_ids: [],
	 participant_ids: [],
	 type_ids: [],
    created_at: '',
    updated_at: '',
  };
  