import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/servicePriceList',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/servicePriceList',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/servicePriceList/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/servicePriceList/${id}`,
        method: 'get'
    })
}
export function update(data) {
    return request({
        url: `/servicePriceList/${data.id}`,
        method: 'put',
        data
    })
}

export function sendForConfirmation(id) {
    return request({
        url: `/servicePriceList/sendForConfirmation/${id}`,
        method: 'put',
    })
}

export function confirmPriceList(id) {
    return request({
        url: `/servicePriceList/confirmPriceList/${id}`,
        method: 'put',
    })
}

export function destroy(id) {
    return request({
        url: `/servicePriceList/${id}`,
        method: 'delete'
    })
}