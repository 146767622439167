<template >
    <div>
      <div class="app-modal__box">
        <div class="app-modal__in">
          <div class="app-modal__header d-flex f-between p-5 m-title-modal">
            <p class="large--title m-0"> {{
                $t("message.client_additional")
              }}</p>
            <div>
               <crm-store-update-close
                  :permission="$options.name"
                  :button_type="'store'"
                  :loading="loadingButton"
                  @c-submit="submit(true)"
                  @c-close="close()"
              ></crm-store-update-close>
            </div>
          </div>
        </div>
        <!-- app-modal__header end -->
  
        <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4">
                <h3>{{ $t('message.choose_additional_info') }}</h3>
                <select-client-additional
                    :size="'medium'"
                    :placeholder="$t('message.client_additional')"
                    :id="additional_ids"
                    v-model="additional_ids"
                    @selectedInfo="selectedInfo"
                >
                </select-client-additional>
            </div>
        </div>
        <div class="app-modal__body p-5 pb-0">
            <div class="timeline-items__right rounded-sm w-100 p-4">
                <h3> {{ $t('message.fill_additional_info') }} </h3>

                <el-form ref="form" :model="form" :rules="rules" label-position="top">
                    <div v-for="(item, index) in selectedList" :key="'q_'+index" class="w-100">
                        <el-form-item :label="item.column_name">
                            <div v-if="['el-radio', 'el-checkbox'].includes(item.column_type)" class="w-100">
                                <el-checkbox-group v-model="selectedList[index].value" class="w-100" v-if="item.props && item.props.is_multiple_choice">
                                    <el-row>
                                        <el-col 
                                            :span="6" 
                                            v-for="(c_opt, c_index) in item.column_options" 
                                            :key="'check_col_'+c_index" 
                                            style="margin-right: 10px;"
                                        >
                                            <component
                                                :is="item.column_type" 
                                                :label="c_opt"
                                                border
                                            >
                                                {{ c_opt }}
                                            </component>
                                        </el-col>
                                    </el-row>
                                </el-checkbox-group>
                                <el-radio-group v-model="selectedList[index].value" v-else class="w-100">
                                    <el-row>
                                        <el-col 
                                            :span="6" 
                                            v-for="(c_opt, r_index) in item.column_options" 
                                            :key="'col_radio_'+r_index" 
                                            style="margin-right: 10px;"
                                        >
                                            <component
                                                :is="item.column_type" 
                                                :label="c_opt"
                                                :key="'radio_'+r_index" 
                                                border
                                            >
                                                {{ c_opt }}
                                            </component>
                                        </el-col>
                                    </el-row>
                                </el-radio-group>
                            </div>
                            <div v-else-if="item.column_type == 'el-upload'" class="w-100">
                                <el-upload
                                    class="upload-demo w-50"
                                    action="#"
                                    :file-list="selectedList[index].value"
                                    list-type="picture"
                                    :auto-upload="false"
                                    multiple
                                    :limit="5"
                                    >
                                    <el-button size="small" type="primary">{{ $t('message.UploadFile') }}</el-button>
                                    <div slot="tip" class="el-upload__tip">{{ $t('message.UploadFileText') }}</div>
                                </el-upload>
                            </div>
                            <div v-else class="w-100">
                                <component 
                                    :is="item.column_type" 
                                    v-model="selectedList[index].value"
                                    :placeholder="item.column_name"
                                    v-bind="bindByType(item)"
                                    clearable
                                    class="w-100"
                                >
                                    <div v-if="item.column_type == 'el-select'">
                                        <el-option v-for="(option, o_index) in item.column_options" :key="o_index" :label="option" :value="option"></el-option>
                                    </div>
                                </component>
                            </div>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
        </div>
        <!-- end app-modal__body -->
      </div>
    </div>
</template>
  
<script>
  import { mapGetters, mapActions } from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";
  import SelectClientAdditional from "@/components/inventory/select-client-additional";
  import {Input, Button, Select, DatePicker, Radio, Switch, Checkbox, InputNumber, ElUpload } from 'element-ui';
  
  export default {
    mixins: [form, drawer],
    props: {
        client: {
            default: null
        }
    },
    components: {
        SelectClientAdditional,
        'el-input': Input, 'el-button': Button, 'el-select': Select, 
		'el-datepicker': DatePicker, 'el-radio': Radio, 'el-switch': Switch, 
        'el-checkbox': Checkbox, 'el-input-number': InputNumber, 'el-upload': ElUpload
    },
    data() {
      return {
        additional_ids: [],
        selectedList: [],
  
      };
    },
    created() {
    },
    computed: {
        ...mapGetters({
            rules: "clientAdditionalInfo/rules",
            model: "clientAdditionalInfo/model",
            columns: "clientAdditionalInfo/columns",
        }),
        getMaskFormat: function(){
			return (prop) => {
				if (prop && prop.hasOwnProperty('maskFormat') && prop.maskFormat == 'tel') {
					return '+\\9\\98999999999';
				}
				else return null;
			} 
		}
    },
    methods: {
        ...mapActions({
            save: "clientAdditionalInfo/store",
        }),
        selectedInfo(data){
            this.selectedList = JSON.parse(JSON.stringify(data));
        },
        changeComponent(){
            console.log('changeComponent');
        },
        bindByType(item){
            let params = {};
            if(item.column_type == 'el-switch'){
                params = { ...item.column_options, 'active-value': item.column_options['active-text'], 'inactive-value': item.column_options['inactive-text'] }
            }else{
                params = { ...item.props};
            }
            return params;
        },
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if(this.client && this.client.id){
                        this.form.client_id = this.client.id;
                        this.form.data = this.selectedList.map(el => {return {id: el.id, column_name: el.column_name, value: el.value}});
                        this.loadingButton = true;
                        this.save(this.form)
                            .then((res) => {
                                this.loadingButton = false;
                                this.$alert(res);
                                this.parent().listChanged();
                                if (close) this.close();
                            })
                            .catch((err) => {
                                this.loadingButton = false;
                                this.$alert(err);
                            });
                    }else{
                        this.$notify({
                            title: this.$t('message.error_m'),
                            type: "error",
                            offset: 130,
                            message: this.$t('message.client_not_recognized')
                        });
                    }

                }

            });
        },
        afterLeave(){
            this.additional_ids = [];
            this.selectedList = [];
        }
    },
  };
</script>
  