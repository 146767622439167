import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/dealCorrespondences',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/dealCorrespondences',
        method: 'post',
        data
    })
}
export function getTypes(params) {
    return request({
        url: '/dealCorrespondences/get/types',
        method: 'get',
        params
    })
}

export function show(id) {
    return request({
        url: `/dealCorrespondences/${id}`,
        method: 'get'
    })
}

export function getDetailMeetingForCalendar(id) {
	return request({
		 url: `/dealCorrespondences/getDetailMeetingForCalendar/${id}`,
		 method: 'get'
	})
}

export function downloadDealCorrespondence(dealCorrespondence_id, file) {
    return request({
        url: `/dealCorrespondences/downloadDealCorrespondence/${dealCorrespondence_id}/${file}`,
        method: 'get',
        responseType: 'blob'
    })
}

export function getAll(params) {
    return request({
        url: '/dealCorrespondences/getAll',
        method: 'get',
        params
    })
}
export function update(data) {
    return request({
        url: `/dealCorrespondences/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/dealCorrespondences/${id}`,
        method: 'delete'
    })
}

export function getEmailsCount(params) {
    return request({
        url: '/dealCorrespondences/getEmailsCount',
        method: 'get',
        params
    })
}

export function getEmails(params) {
    return request({
        url: '/dealCorrespondences/getEmails',
        method: 'get',
        params
    })
}

export function saveEmailToDeal(data) {
    return request({
        url: '/dealCorrespondences/saveEmailToDeal',
        method: 'post',
        data
    })
}