<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="mb-5">
            <div class="filterblock_s">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt text-white">
                        Активность
                        {{ $t("message.per") }}
                        {{ activityPeriod === "weekly" ? $t("message.weekly") : "" }}
                        {{ activityPeriod === "monthly" ? $t("message.monthly") : "" }}
                        {{ activityPeriod === "yearly" ? $t("message.yearly") : "" }}
                    </span>
                </h4>
                <div class="filter_home flex-center">
                    <el-radio-group @change="$emit('vmodel', filterByDate)" v-model="filterByDate" size="medium" class="">
                        <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                        <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                        <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                    </el-radio-group><i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                        style="font-size: 18px; color: #dc2626; cursor: pointer;">
                    </i>
                </div>
            </div>
            <div class="d-flex f-between align-center">
                <div class="sausage-chart__item_body_top text-white mt-2 mb-2">+ {{ activityMark }}</div>
            </div>
        </div>

        <div class="itme-task-menjra mb-4 progress-color-lin" v-for="activity in activities" :key="activity.id">
            <span class="input--label d-block">
                <i :class="activity.iconClass"></i>
                {{ activity.name }}
                <b>- {{ activity.count }}</b>
            </span>
            <el-progress class="none-icon-p" :percentage="activity.percentage < 0
                ? -activity.percentage
                : activity.percentage
                " :status="activity.percentage > 0 ? 'success' : 'exception'">
            </el-progress>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        activityPeriod: {
            type: String
        },
        activities: {
            type: Object
        },
        activityMark: {
            type: Number
        },
    },
    data() {
        return {
            filterByDate: 'weekly'
        }
    },
}
</script>

<style lang="scss" scoped></style>