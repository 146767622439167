<template>
    <div class="khan-chart-a">
        <v-chart class="e-chart" :option="option" autoresize />
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import { formatMoney } from "@/filters/index";

import * as echarts from 'echarts/core';
import {
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    MarkLineComponent,
    MarkPointComponent,
    BarChart,
    CanvasRenderer
]);
import VChart, { THEME_KEY } from "vue-echarts";

export default {
    components: {
        VChart
    },
    provide() {
        return {
            [THEME_KEY]: "dark"
        };
    },
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        }
    },
    data() {
        return {
            radio1: "Неделя",

            option: {
                // title: {
                //     text: 'Rainfall vs Evaporation',
                //     subtext: 'Fake Data'
                // },
                backgroundColor: "transparent",
                tooltip: {
                    trigger: 'axis', 
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    left: "right",
                    data: []
                },
                toolbox: {
                    show: true,
                },
                calculable: true,
                xAxis: [
                    {
                        type: 'category',
                        axisPointer: {
                            type: 'shadow'
                        },
                        // prettier-ignore
                        data: [],
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '',
                        type: 'bar',
                        data: [],
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        }
                    },
                    {
                        name: '',
                        type: 'bar',
                        data: [],
                        markPoint: {
                            data: [
                                { type: 'max', name: 'Max' },
                                { type: 'min', name: 'Min' }
                            ]
                        },
                        markLine: {
                            data: [{ type: 'average', name: 'Avg' }]
                        }
                    }
                ]
            }
        };
    },

    watch: {
        watchAll: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({ period: this.period, filter: this.filterForm });
                }, 500);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            saleByPeriod: "home/saleByPeriod"
        }),

        watchAll() {
            return (JSON.stringify(this.filterForm) + this.period);
        }
    },
    methods: {
        ...mapActions({
            saleByPeriodDiagram: "home/saleByPeriodDiagram"
        }),

        updateChartData(data) {
            this.debouncedFetchData(data, this)
        },

        debouncedFetchData: _.debounce((data, self) => {
            if (_.isFunction(self.saleByPeriodDiagram)) {
                self.saleByPeriodDiagram(data)
                    .then(() => {
                        self.option.series[0].name = self.$t('message.sale')
                        self.option.series[0].data = self.saleByPeriod.money_amounts
                        self.option.series[1].name = self.$t('message.profit')
                        self.option.series[1].data = self.saleByPeriod.profit_money_amounts
                        self.option.legend.data = [self.$t('message.sale'), self.$t('message.profit')]
                        self.option.xAxis[0]['data'] = self.saleByPeriod.dates

                    });
            }
        }, 500),
    }
};
</script>
