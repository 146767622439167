import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    reason : {
        show: true,
        title: i18n.t('message.reason'),
        sortable: true,
        column: 'reason'
    },
    user : {
        show: true,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user'
    },
    filial : {
        show: true,
        title: i18n.t('message.filial'),
        sortable: true,
        column: 'filial'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    currency_rate : {
        show: true,
        title: i18n.t('message.rate'),
        sortable: true,
        column: 'currency_rate'
    },
    reception_address : {
        show: true,
        title: i18n.t('message.reception_address'),
        sortable: true,
        column: 'reception_address'
    },
    money_amount : {
        show: true,
        title: i18n.t('message.money_amount'),
        sortable: true,
        column: 'money_amount'
    },

    profit : {
        show: false,
        title: i18n.t('message.profit'),
        sortable: true,
        column: 'profit'
    },

    currency : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency'
    },
    dealStage : {
        show: true,
        title: i18n.t('message.dealStage'),
        sortable: true,
        column: 'dealStage'
    },
    phone : {
        show: true,
        title: i18n.t('message.phone'),
        sortable: true,
        column: 'phone'
    },
    email : {
        show: false,
        title: i18n.t('message.email'),
        sortable: true,
        column: 'email'
    },
    begin_date : {
        show: false,
        title: i18n.t('message.begin_date'),
        sortable: true,
        column: 'begin_date'
    },
    end_date : {
        show: false,
        title: i18n.t('message.end_date'),
        sortable: true,
        column: 'end_date'
    },
    company : {
        show: false,
        title: i18n.t('message.company'),
        sortable: true,
        column: 'company'
    },
    client : {
        show: true,
        title: i18n.t('message.client'),
        sortable: true,
        column: 'client'
    },
    condition : {
        show: false,
        title: i18n.t('message.dealCondition'),
        sortable: true,
        column: 'condition'
    },
    shipmentTerm : {
        show: false,
        title: i18n.t('message.shipmentTerm'),
        sortable: true,
        column: 'shipmentTerm'
    },
    dealType : {
        show: false,
        title: i18n.t('message.dealType'),
        sortable: true,
        column: 'dealType'
    },
    source : {
        show: false,
        title: i18n.t('message.source'),
        sortable: true,
        column: 'source'
    },
    comment : {
        show: false,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
