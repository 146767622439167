import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    currency_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.currency_id')}), trigger: 'change' },
    ],
    money_amount: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.money_amount')}), trigger: 'change' },
    ],
    // categories: [
    //     { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.categories')}), trigger: 'blur' },
    // ],
    // category_ids: [
    //     { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.categories')}), trigger: 'change' },
    // ],
};
