export const model = {
  id: null,  
  user_id: '', 
  content: '', 
  sender_id: '', 
  username: '', 
  message_date: '', 
  date: '',
  timestamp: '',
  read: '',
  created_at: '',
  updated_at: ''
};