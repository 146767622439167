import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/callHistory/getUserCallHistory',
        method: 'get',
        params
    })
}

export function totalStatistics(params) {
    return request({
        url: '/callHistory/totalStatistics',
        method: 'get',
        params
    })
}

export function trunckDiagrams(params) {
    return request({
        url: '/callHistory/trunckDiagrams',
        method: 'get',
        params
    })
}

export function userDiagrams(params) {
    return request({
        url: '/callHistory/userDiagrams',
        method: 'get',
        params
    })
}


export function bindingCallHistory(data) {
    return request({
        url: '/callHistory/bindingCallHistory',
        method: 'post',
        data
    })
}
