<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable ow-scroll-600">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="task-home-two-center h__responssive">
            <div class="filterblock_s mb-5">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.conter_agent') }}</span>
                </h4>
                <div class="filter_home flex-center">
                    <el-radio-group v-model="clientChartPeriod" size="medium">
                        <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                        <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                        <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                    </el-radio-group>
                    <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                        style="font-size: 18px; color: #dc2626; cursor: pointer;">
                    </i>
                </div>
            </div>

            <div class="agents__box agents__box-mobil">
                <div class="agents__half mw-100">
                    <CounterColumn :filterForm="filterForm" :period="clientChartPeriod"
                        @getClientColors="getClientColors" />
                </div>
                <div class="agents__half task-scroll-khan mw-100">
                    <table class="agents__table">
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>{{ $t('message.deals') }}</th>
                                <th>{{ $t('message.tasks') }}</th>
                                <th>{{ $t('message.money_amount') }}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(client, index) in clientStatistics" :key="client.id">
                                <td>
                                    <span class="agents__circle" :style="'background:' + clientColors[index%clientColors.length]"></span>
                                </td>
                                <td>
                                    <span> {{ client.full_name }} </span>
                                </td>
                                <td class="agents__center">{{ client.deal_count }}</td>
                                <td class="agents__center">{{ client.task_count }}</td>
                                <td class="agents__center">{{ client.money_amount | formatMoney(2) }}
                                </td>
                                <td class="agents__view">
                                    <span @click="showSelectedClient(client)" class="el-icon-view"></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <el-drawer :with-header="false" :visible.sync="crmShowClient" size="80%" :append-to-body="true"
            class="my-bgd body_scroll_80" ref="drawerClientShow" @opened="drawerOpened('drawerClientShowChild')"
            @closed="drawerClosed('drawerClientShowChild')">
            <crm-show-client :selectedItem="selectedClient" ref="drawerClientShowChild" drawer="drawerClientShow">
            </crm-show-client>
        </el-drawer>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CounterColumn from "@/components/chart/CounterColumn.vue";
import crmShowClient from "@/views/clients/components/show";

export default {
    components: {
        CounterColumn,
        crmShowClient
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },
        update: Number,
        filterForm: Object,
    },
    data() {
        return {
            counterGraph: false,
            clientChartPeriod: "weekly",
            clientColors: [],
            crmShowClient: false,
            selectedClient: {},
        }
    },
    watch: {
        allPeriodChange(val) {
            this.clientChartPeriod = val
        }
    },
    computed: {
        ...mapGetters({
            clientStatistics: "home/clientStatistics",
        })
    },
    methods: {
        getClientColors(value) {
            this.clientColors = value;
        },
        showSelectedClient(client) {
            this.selectedClient = client;
            this.crmShowClient = true;
        },

        drawerClosed(ref) {
            if (this.$refs[ref]) {
                this.$refs[ref].closed()
            }
            if (this.reloadList === true) {
                this.fetchData();
                this.afterFetchData();
            }
            if (_.isFunction(this.empty)) {
                this.empty()
            }
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened()
                }
            }
        },
        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>