<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="filterblock_s mb-3">
            <h4 class="dashboard-tile__item-title short">
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.goals') }}</span>
            </h4>
            <div class="filter_home flex-center">
                <el-radio-group @change="$emit('input', filterByDate)" v-model="filterByDate" size="medium">
                    <el-radio-button label="monthly">{{ $t("message.monthly") }}
                    </el-radio-button>
                    <el-radio-button label="yearly">{{ $t("message.yearly") }}
                    </el-radio-button>
                </el-radio-group>

                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>
        </div>
        <div class="d-flex align-center justify-center mb-3">
            <el-radio-group @change="$emit('dealGoalsByAmounts', filterDealGoalsByAmounts)"
                v-model="filterDealGoalsByAmounts">
                <el-radio-button class="goal__button" label="by_count">{{ $t("message.by_count") }}</el-radio-button>
                <el-radio-button class="goal__button" label="by_money_amount">{{ $t("message.by_money_amount")}}</el-radio-button>
                <el-radio-button class="goal__button" label="by_profit">{{ $t("message.by_profit") }}</el-radio-button>
                <el-radio-button class="goal__button" label="by_meetings">{{ $t("message.by_meetings") }}</el-radio-button>
            </el-radio-group>
        </div>
        <!-- <chartMultiple /> -->
        <div class="el-progress-ceter">
            <el-progress type="dashboard" :percentage="goalPercentage((dealGoalsForm.percentage ? dealGoalsForm.percentage : 0))" :color="colors" :stroke-width="16"></el-progress>
        </div>
        <div class="summa-progress text-center">
            <div class="sausage-chart__item_body_top font18 mt-2 mb-2 colorGr">
                {{
                    dealGoalsForm.amount +
                    " " +
                    (dealGoalsForm.symbol ? dealGoalsForm.symbol : '')
                }}
            </div>
            <div class="sausage-chart__item-header-color pl-5 pr-5" style="background-color: #99ccff;width: 70%;"></div>
            <div class="sausage-chart__item_body_top font18 mt-2 mb-2 colorGr">
                {{ $t('message.goal_in') }}
                {{
                    $t("message." + dealGoalsPeriod) +
                    " " +
                    dealGoalsForm.goal_amount +
                    " " +
                    (dealGoalsForm.symbol ? dealGoalsForm.symbol : '')
                }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dealGoalsPeriod: {
            type: String
        },
        dealGoalsByAmounts: {
            type: String
        },
        dealGoalsForm: {
            type: Object
        },
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
    },
    data() {
        return {
            filterByDate: 'monthly',
            filterDealGoalsByAmounts: 'by_count',

            colors: [
                { color: "#f56c6c", percentage: 20 },
                { color: "#e6a23c", percentage: 40 },
                { color: "#5cb87a", percentage: 60 },
                { color: "#1989fa", percentage: 80 },
                { color: "#6f7ad3", percentage: 100 },
            ],
        }
    },
    methods: {
        // START ROW TOTAL METHODS
        goalPercentage(percentage){
            let main_percentage = percentage ? percentage : 0;
            main_percentage = percentage < 0 ? 0 : percentage;
            main_percentage = percentage > 100 ? 100 : percentage;
            main_percentage = parseInt(main_percentage);
            return  main_percentage;
        }

    }
}
</script>

<style lang="scss" scoped></style>