<template>
    <div>
        <div v-if="option.series[0]['data'].length">
            <!-- <h1 style="color: red;">19</h1> -->
            <v-chart class="e-chart" :option="option" autoresize />
        </div>
        <div v-else class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t('message.no_data_yet') }}
            </h1>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    components: { VChart },
    provide() {
        return {
            [THEME_KEY]: "dark"
        };
    },
    props: {
        labels: {
            default: Array
        },
        chart_size: {
            default: 550
        },
        chart_height: {
            default: 450
        },
        period: {
            default: "weekly",
        },
        filterForm: {
            default: null,
        },
        parent_id: {
            default: null,
        },

    },
    data: () => ({
        category: [],
        child_category: null,

        option: {
            backgroundColor: "transparent",
            label: {
                show: true,
                formatter(param) {
                    // correct the percentage
                    return `${param.name} ${param.percent}%`;
                }
            },
            tooltip: {
                trigger: 'item',
            },
            legend: {
                orient: 'vertical',
                top: '15%',
                left: 'right'
            },
            series: [
                {
                    name: 'Title',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    data: []
                }
            ]
        }
    }),
    watch: {
        update_data: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({
                        parent_id: this.parent_id,
                        period: this.period,
                        filter: this.filterForm,
                    });
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            categoryStatistics: "home/categoryStatisticBySellingProduct",
        }),
        update_data() {
            return this.period + this.parent_id;
        }
    },
    methods: {
        ...mapActions({
            categoryStatisticsData: "home/categoryStatisticBySellingProduct",
        }),
        updateChartData(data) {
            this.categoryStatisticsData(data)
                .then(res => {
                    this.category = JSON.parse(JSON.stringify(this.categoryStatistics));

                    // Echart 
                    this.option.series[0]['data'] = []
                    this.category.forEach(item => {
                        this.option.series[0]['data'].push({
                            name: item.name,
                            value: item.money_amount
                        })
                    })
                })
        }
    },
}
</script>

<style></style>
