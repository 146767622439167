export const filter = {
    id : '',  
    name: '', 
	 notification_type_id: null,
	 user_id: null,
	 text: '',
	 activity_time: '',
    created_at: '',
    updated_at: ''  
  };
  