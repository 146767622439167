<template>
    <div class="d-flex">
      <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.price_list')" filterable clearable
        :size="size" class="w-100">
        <el-option v-for="(price_list, index) in servicePriceList" :key="'servicePriceList-' + index" :label="price_list.name" :value="price_list.id">
          <div class="mm_option_with_additional_info">
            <span> {{ price_list.name }} </span>
            <span> {{ price_list.approved_obj ? price_list.approved_obj.name : '' }} </span>
          </div>
        </el-option>
      </el-select>
      <el-button v-if="selectedItemStatus" 
        class="ml-3" disabled
        :type="selectedItemStatus.type ? selectedItemStatus.type : 'primary'"
      >
        {{ selectedItemStatus.name }}
      </el-button>
    </div>
  </template>
  <script>
    import {mapGetters, mapActions} from "vuex";
    export default {
      props: {
        size: {
          default: 'small'
        },
        placeholder: {
          default: null,
        },
        id:{
          default: null
        },
        auto_select_default:{
          default: true
        }
      },
      watch:{
        id:{
          handler: function() {
              this.selected = this.id;
          },
          immediate: true
        },
        servicePriceList:{
          handler: function() {
            this.loadDefault();
          },
          immediate: true,
          deep: true
        }
      },
      data() {
        return {
          selected: null,
        }
      },
      mounted() {
        if (this.servicePriceList && this.servicePriceList.length === 0) this.updateInventory();
        if(this.auto_select_default && this.servicePriceList.length > 0){
          let default_list = this.servicePriceList.find(el => el.is_default == true);
          if(default_list){
            this.dispatch(default_list.id);
          }
        }
      },
      computed: {
        ...mapGetters({
          servicePriceList: 'servicePriceList/inventory'
        }),
        selectedItemStatus(){
          let item = this.selected ? this.servicePriceList.find(el => el.id == this.selected) : '';
          this.$emit('updateSelectedPriceList', item);
          let status = item ? item.approved_obj : '';
          return status;
        }
      },
      methods: {
        ...mapActions({
          updateInventory: 'servicePriceList/inventory'
        }),
        loadDefault(){
          if(this.auto_select_default && this.servicePriceList.length > 0){
            let default_list = this.servicePriceList.find(el => el.is_default == true);
            if(default_list){
              this.dispatch(default_list.id);
            }
          }
        },
        dispatch(e) {
          this.$emit('input', e)
          this.selected = e
        }
      },
    }
  
</script>

<style>
  .mm_option_with_additional_info{
    display: flex;
    justify-content: space-between;
  }
</style>
  