import { login, verifyCode, logout, refresh, getAuth, checkStatus } from "@/api/auth";
import { setToken, getTokenByKey, removeToken, setOtherTokens, removeOtherTokens } from "@/utils/auth";
import axios from "axios";

export const actions = {

    login({ commit }, credentials) {
        const { phone, password } = credentials;
        return new Promise((resolve, reject) => {
            login({ phone: phone.trim(), password: password })
				.then(response => {
					const { data } = response.data.result;
					if (data.verified_required == true) {
						resolve(response.data.result); //response.data.result
				  	}
					else {
						if (data.token) {
							commit('SET_TOKEN', data.token)
							setToken(data.token)
							setOtherTokens(data.other_tokens)
							resolve(response.data.result)
					    }
					}
            }).catch(error => {
                reject(error)
            })
        })
    },

	verifyCode({ commit }, data) {
		return new Promise((resolve, reject) => {
			verifyCode(data).then(response => {
				const { data } = response.data.result;
				// code ni togri kiritgan bolsa token qaytaradi
				if (data.token) {
					commit('SET_TOKEN', data.token)
					setToken(data.token)
					resolve(response.data.result)
				}
			}).catch(error => {
				reject(error)
			});
		});
	},

    checkStatus({ commit }) {
        return new Promise((resolve, reject) => {
            checkStatus().then(res => {
                resolve(res.data.result.data.status);
            }).catch(error => {
                reject(error)
            })
        })
    },
    getAuth({ commit, state }, payload) {
        return new Promise((resolve, reject) => {
            getAuth(state.token).then(res => {
                commit('SET_GOMAX_TOKEN', getTokenByKey('auth_token_gomax'))
                commit('SET_HR_TOKEN', getTokenByKey('auth_token_hr'))
                const { data } = res.data.result
                if (!data) {
                    reject('Проверка не удалась, пожалуйста, войдите снова.')
                }
                const { role, name, phone, goodone_admin_slug } = data
                if (!role) {
                    reject('Роль не может быть пустой')
                }
                commit('SET_SLUG', goodone_admin_slug)
                commit('SET_ROLE', role)
                commit('SET_NAME', name)
                commit('SET_PHONE', phone)
                commit('SET_USER',res.data.result.data)
                console.log('success in permissions js')
                resolve(data)
            }).catch(error => {
                console.log('error in permissions js')
                commit('SET_TOKEN', '');
                removeToken();

                removeOtherTokens();

                commit('SET_GOMAX_TOKEN', '');
                commit('SET_HR_TOKEN', '');

                reject(error);
            })
        })
    },
    refresh({ commit }) {
        refresh().then(res => {
            const { data } = res.data.result;
            if (data.token) {
                commit('SET_TOKEN', data.token)
                setToken(data.token)
            }
        }).catch(err => {
            removeToken()
        })

    },
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            if(getTokenByKey('auth_token_gomax')){
                try{
                    const params = { phone_number: state.user.phone };
                    const headers = {
                        "Authorization": 'Bearer ' + getTokenByKey('auth_token_gomax'),
                    };
                    axios.post(process.env.VUE_APP_GOMAX_BASE_URL+"/api/auth/logoutFromOtherDomain", params, { headers: headers })
                        .then(res => {})
                        .catch(err => console.log(err, 'errorrrrrrrrrr'));
                }catch(error){
                    console.log(error, 'gomax logout error' );
                }
            }

            if(getTokenByKey('auth_token_hr')){
                try{
                    const params = { phone_number: state.user.phone };
                    const headers = {
                        "Authorization": 'Bearer ' + getTokenByKey('auth_token_hr'),
                    };
                    axios.post(process.env.VUE_APP_HR_BASE_URL_BACKEND+"/api/auth/logout", params, { headers: headers })
                        .then(res => {})
                        .catch(err => console.log(err, 'errorrrrrrrrrr'));
                }catch(error){
                    console.log(error, 'hr logout error' );
                }
            }

            removeOtherTokens();
            commit('SET_GOMAX_TOKEN', '');
            commit('SET_HR_TOKEN', '');

            logout().then(() => {
                removeToken();
                commit('SET_TOKEN', '');
                commit('SET_ROLE', '');
                window.location.reload();
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    resetToken({ commit }) {
        return new Promise((resolve) => {
            removeToken()
            commit('SET_TOKEN', '')

            removeOtherTokens();


            commit('SET_GOMAX_TOKEN', '');
            commit('SET_HR_TOKEN', '');

            resolve()
        })
    },
}
