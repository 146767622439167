<template>
    <div>
        <el-select
            :value="selected"
            @input="dispatch"
            :placeholder="placeholder || $t('message.country')"
            filterable
            clearable
            :size="size"
            class="w-100"
        >
            <el-option
                v-for="(country, index) in countries"
                :key="'countries-' + index"
                :label="country.name"
                :value="country.id"
            ></el-option>
        </el-select>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
    props: {
        size: {
            default: "small",
        },
        placeholder: {
            default: null,
        },
        id: {
            default: null,
        },
    },
    watch: {
        id: {
            handler: function () {
                this.selected = this.id;
            },
            immediate: true,
        },
    },
    data() {
        return {
            selected: null,
        };
    },
    mounted() {
        if (this.countries && this.countries.length === 0) this.updateInventory();
    },
    computed: {
        ...mapGetters({
            countries: "countries/inventory",
        }),
    },
    methods: {
        ...mapActions({
            updateInventory: "countries/inventory",
        }),
        dispatch(e) {
            this.$emit("input", e);
            this.selected = e;
        },
    },
};
</script>
