import {i18n} from '@/utils/i18n';

export const rules = {
    column_name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.column_name')}), trigger: 'change' },
    ],

    column_type: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.column_type')}), trigger: 'change' },
    ],
};
