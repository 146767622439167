import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.name'),
        sortable: true,
        column: 'name'
    },
    url_address : {
        show: true,
        title: i18n.t('message.url_address'),
        sortable: true,
        column: 'url_address'
    },
    login : {
        show: true,
        title: i18n.t('message.login_name'),
        sortable: true,
        column: 'login'
    },
    password : {
        show: true,
        title: i18n.t('message.password'),
        sortable: true,
        column: 'password'
    },
    payment : {
        show: true,
        title: i18n.t('message.payment'),
        sortable: true,
        column: 'payment'
    },
    currency_id : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency_id'
    },


    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
