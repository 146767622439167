<template>
  <div>
    <el-select :value="selected" @input="dispatch"
      :placeholder="placeholder || $t('message.product')" 
      filterable clearable remote 
      :remote-method="searchProduct"
      :loading="loading"
      :size="size" class="w-100">
      <el-option v-for="(product,index) in products" :key="'products-' + index" :label="product.name" :value="product.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },  
      placeholder: {
        default: null,
      },
      id:{
        default: null
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
        loading: false,
      }
    },
    mounted() {
      if (this.products && this.products.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        products: 'products/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'products/inventory'
      }),
      searchProduct(serachValue){
        this.loading = true;
        this.debouncedSearch({search: serachValue}, this);
      },
      debouncedSearch: _.debounce((filter, self) => {
        if (_.isFunction(self.updateInventory)) {
          self.updateInventory(filter)
            .then((res) => {
              self.loading = false;
            })
            .catch((err) => { 
              self.loading = false; 
            });
        }
      }, 600),
      dispatch(e) {
        this.$emit('get_selected_product', e)
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
