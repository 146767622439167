<template>
  <el-select
    :value="selected"
    multiple
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.productService')"
    :size="size"
    class="w-100"
  >
    <el-option
      v-for="(productService, index) in serviceList"
      :key="'productService-' + index"
      :label="productService.name"
      :value="productService.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    productService_ids: {
      default: Array,
    },
    category_ids: {
      default: Array,
    }
  },
  watch: {
    productService_ids: {
      handler: function () {
        this.selected = this.productService_ids;
      },
      immediate: true,
    },
    category_ids: {
      handler: async function (newVal) {
        if (this.productServices && this.productServices.length === 0) await this.updateInventory();

        if(newVal && newVal.length > 0){
          this.updateService();
        }
      },
      immediate: true,
    },

  },
  data() {
    return {
        selected: [],
        serviceList: []
    };
  },

  // mounted() {
  //   if (this.productServices && this.productServices.length === 0) this.updateInventory();
  // },
  computed: {
    ...mapGetters({
      productServices: "productServices/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "productServices/inventory",
    }),
    updateService(){
      this.serviceList = this.productServices.filter(el => {
          let old_arr = [...el.category_ids, ...this.category_ids];
          let new_arr = old_arr.filter((value, index, self) => self.indexOf(value) === index);
          if(old_arr.length !== new_arr.length) return true;
          return false;
      });
    },
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>