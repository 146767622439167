import { index, inventory, filialProducts, show, store, update, printBarcode, destroy, getProducts, deleteImage, updateComposition, excelFileDownload } from "@/api/settings/products";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.products);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.products);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.products);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.product);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

	 excelFileDownload({commit}){
		return new Promise((resolve, reject) => {
			 excelFileDownload().then(res => {
				  resolve(res)
			 }).catch(err => {
				  reject(err)
			 })  
		})
  },

    printBarcode({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            printBarcode(params)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    deleteImage({ commit }, data) {
        return new Promise((resolve, reject) => {
            deleteImage(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            });
        });
    },

    getProducts({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            getProducts(params)
                .then(res => {
						commit("SET_LIST", res.data.result.data.products);
                  resolve(res.data.result.data);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    filialProducts({ commit }, params) {
        return new Promise((resolve, reject) => {
            filialProducts(params)
                .then(res => {
                    commit('SET_FILIAL_PRODUCTS', res.data.result.data.products);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    updateComposition({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateComposition(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    setItemValues({ commit }, item) {
        commit('UPDATE_ITEM_VALUES', item)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
