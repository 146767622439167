<template >
    <div>
        <div class="app-modal__box">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0">{{ $t('message.notifications') }}</p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4">
                    <el-form ref="form" :model="form" :rules="rules">
                        <el-row :gutter="20">
                            <el-col :span="12">
										<el-form-item :label="$t('message.notificationTypes')" prop="type_id">
											<select-notification-types
												v-model="form.type_id"
												:id="form.type_id"
												size="large"
											></select-notification-types>
										</el-form-item>
									</el-col>
									<el-col :span="12">
											<el-form-item :label="$t('message.activity_time')">
												<el-date-picker type="datetime" :placeholder="$t('message.activity_time')" v-model="form.activity_time" style="width: 100%;" format="yyyy-MM-dd HH:mm"
													value-format="yyyy-MM-dd HH:mm">
												</el-date-picker>
											</el-form-item>
                            </el-col>
                            <!-- end-col -->
                        </el-row>
								<el-row :gutter="20">
									<el-col :span="24">
										<el-form-item :label="$t('message.text')" prop="text">
											<el-input type="textarea" v-model="form.text"></el-input>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="12">
										<el-form-item :label="$t('message.filials')" class="label_mini">
											<select-filials
												ref="Filials"
												v-model="form.filials"
												:filial_ids="form.filials"
												:size="'medium'"
												:placeholder="$t('message.filials')"
												@c-change="updateUsersByFilials"
											>
											</select-filials>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item
											prop="role_id"
											:label="$t('message.role_id')"
											class="label_mini"
										>
										<select-roles
											:role_ids="form.role_ids"
											v-model="form.role_ids"
											:size="'medium'"
											:placeholder="$t('message.role_id')"
											@c-change="updateUsersByRole"
										></select-roles>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="24">
											<el-form-item :label="$t('message.users')" prop="user_ids" v-if="visible_users">
												<el-select
													v-model="form.user_ids"
													multiple
													collapse-tags
													filterable
													:placeholder="$t('message.users')"
													:loading="loading"
													class="w-100"
												>
													<el-option
														v-for="item in usersList"
														:key="item.id"
														:label="item.name"
														:value="item.id">
													</el-option>
												</el-select>
											<!-- <div v-if="activeList.length">
												<el-row>
													remote
													:remote-method="searchUsersWithLoad"
													<el-col :span="8" v-for="(active, index) in activeList" :key="'activeList-' + active.id">{{ ++index + ': ' + active.name }}</el-col>
												</el-row>
											</div> -->
											</el-form-item>
									</el-col>		
								</el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import SelectNotificationTypes from '../../../components/inventory/select-notification-types';
import selectFilials from "@/components/multiSelects/multi-select-filials";
import selectRoles from "@/components/multiSelects/multi-select-roles";
export default {
    mixins: [form, drawer],
	 components: {SelectNotificationTypes, selectFilials, selectRoles},
    data() {
      return {
			usersList: [],
			activeList: [],
			visible_users: true
			};
    },
    computed: {
        ...mapGetters({
            rules: "systeamNotification/rules",
            model: "systeamNotification/model",
            columns: "systeamNotification/columns",
				users: 'users/inventory',
				authUser: "auth/user",
        }),
    },
	 watch:{
		//  'form.user_ids': {
		// 	handler: function (newVal, oldVal){
		// 		if (!_.isEmpty(this.usersList)) {
		// 			if (newVal.length > oldVal.length) {
		// 				let last_id = _.last(newVal);
		// 				let obj = _.find(this.usersList, {'id': last_id});
		// 				this.activeList.push(obj);
		// 			}
		// 			else if(newVal.length < oldVal.length) {
		// 				let removed_id = _.difference(oldVal, newVal)[0];
		// 				this.activeList.splice(_.findIndex(this.activeList, {'id': removed_id}), 1);
		// 			}
		// 		}
		// 	},
		// 	deep: true,
		// }
	 },
    methods: {
        ...mapActions({
            save: "systeamNotification/store",
				usersInventory: 'users/inventoryForFilter',
        }),
		  afterOpen(){
			this.usersInventory({all_users: 'all_users'}).then((res) => {
				this.usersList = JSON.parse(JSON.stringify(this.users));
			});
		  },
        submit(close = true) {
			if (!this.form.activity_time){
				this.$message({
					showClose: true,
					message: 'must be active time',
					type: 'warning'
				});
			}
			else {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loadingButton = true;
						this.save(this.form)
							.then((res) => {
									this.loadingButton = false;
									this.$alert(res);
									this.parent().listChanged();
									if (close) this.close();
							})
							.catch((err) => {
									this.loadingButton = false;
									this.$alert(err);
							});
					}
				});
			}
        },
		  updateUsersByFilials(filial_ids){
			if (!_.isEmpty(filial_ids)){
				let query = {filial_ids: filial_ids};
				if (!_.isEmpty(this.form.role_ids)) {
					query['role_ids'] = this.form.role_ids;
				}
				this.usersInventory(query).then((res) => {
					let user_ids = JSON.parse(JSON.stringify(res.users));
						this.form.user_ids = user_ids.sort((a, b) => a - b);
				}).catch((err) => {
					console.log(err);
				});
			}else{
				if (!_.isEmpty(this.form.role_ids)) {
					this.usersInventory({role_ids: this.form.role_ids}).then((res) => {
						let user_ids = JSON.parse(JSON.stringify(res.users));
						this.form.user_ids = user_ids.sort((a, b) => a - b);
					}).catch((err) => {
						console.log(err);
					});
				}
				else {
					this.form.user_ids = [];
				}
			}
		  },
		  updateUsersByRole(role_ids){
				if (!_.isEmpty(role_ids)) {
					let query = {role_ids: role_ids};
					if (!_.isEmpty(this.form.filial_ids)) {
						query['filial_ids'] = this.form.filial_ids;
					}
					this.usersInventory(query).then((res) => {
						let user_ids = JSON.parse(JSON.stringify(res.users));
						this.form.user_ids = user_ids.sort((a, b) => a - b);
					}).catch((err) => {
						console.log(err);
					});
				}else {
					if (!_.isEmpty(this.form.filials)) {
						this.usersInventory({filial_ids: this.form.filials}).then((res) => {
							let user_ids = JSON.parse(JSON.stringify(res.users));
							this.form.user_ids = user_ids.sort((a, b) => a - b);
						}).catch((err) => {
							console.log(err);
						});
					}else{
							this.form.user_ids = [];
					}
				}
		  },
		   searchUsersWithLoad(val){
				let ids = this.form.user_ids;
				if (val){
					this.loading = true;
					this.searchUsers(val, ids);
				}
				else {
					this.loadingData = true;
					this.usersInventory({user_ids: ids}).then((res) => {
						this.loading = false;
						this.usersList = JSON.parse(JSON.stringify(this.users));
					}).catch((err) => {
					console.log(err);
				});
				}
			},
			searchUsers: _.debounce(function (val, ids){
				this.usersInventory({search_val: val, ids: ids}).then(() => {
						this.loading = false;
						this.usersList = JSON.parse(JSON.stringify(this.users));
				}).catch((err) => {
					console.log(err);
				});
			}, 
			1000
			),
			afterLeave(){
				this.$store.commit('systeamNotification/EMPTY_MODEL');
				this.usersList = [];
				this.activeList = [];
			},
    },
};
</script>
