<template>
    <div>
        <div class="exhibitionInfo bg-pr mb-4" v-loading="loadingTop">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                            {{ this.$route.params.main_type == 2 ? $t('message.meeting') : $t('message.exhibition') }}: <b> {{ exhibition.name }}</b>
                        </h4>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                            {{ this.$route.params.main_type == 2 ? $t('message.meetingType') : $t('message.exhibitionType') }} : <b>  {{ exhibition.type ? exhibition.type.name : '' }} </b>
                        </h4>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                            {{ $t('message.clients') }}: <b>{{ exhibition.clients }}</b>
                        </h4>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                            {{ $t('message.deals') }}: <b>{{ exhibition.deals }}</b>
                        </h4>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                             {{ $t('message.country') }} : <b>  {{ exhibition.country ? exhibition.country.name : '' }} </b>
                        </h4>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                            {{ $t('message.city') }} : <b>  {{ exhibition.city ? exhibition.city.name : '' }} </b>
                        </h4>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                            {{ $t('message.remaining') }}:
                            <b style="color: red;"> {{ dayDiff(exhibition.end_date) }} дней</b>
                        </h4>
                    </div>
                </el-col>

                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo">
                        <h4>
                            {{ $t('message.date') }}: <b>{{ exhibition.begin_date }}
                                <i class="fa-solid fa-square-caret-right"></i>
                            {{ exhibition.end_date }}</b>
                        </h4>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="itme-exhibitionInfo" >
                        <h4 >
                            {{ $t('message.status') }}: 
                                <b :style="exhibition.status && exhibition.status == 1 ? 'color: rgb(4, 182, 4)' : 'color: red'">
                                    {{ exhibition.statusName ? exhibition.statusName.name : '' }}
                                </b>
                        </h4>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'" v-if="!is_calendar">
            <el-row :gutter="5"  class="nav-item-top">
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
                    <router-link
                        class="nav-item-top-link"
                        :to="{ name: 'exhibitionHome' }"
                    >
                        {{$t('message.dashboard')}}
                    </router-link>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
                    <router-link
                        class="nav-item-top-link"
                        :to="{ name: 'exhibitionEmployees' }"
                    >
                        {{$t('message.managers')}}
                    </router-link>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
                    <router-link
                        class="nav-item-top-link"
                        :to="{ name: 'exhibitionDeals' }"
                    >
                        {{$t('message.deals')}}
                    </router-link>
                </el-col>
                <el-col :xs="12" :sm="8" :md="8" :lg="5" :xl="5">
                    <router-link
                        class="nav-item-top-link"
                        :to="{ name: 'exhibitionTasks' }"
                    >
                        {{$t('message.tasks')}}
                    </router-link>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="4" :xl="4">
                    <router-link
                        class="nav-item-top-link"
                        :to="{ name: 'exhibitionClients' }"
                    >
                        {{$t('message.clients')}}
                    </router-link>
                </el-col>
            </el-row>

            <div>
                <router-view />
            </div>

        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";
import drawer from '@/utils/mixins/drawer';
export default {
    name: "exhibitionInfo",
	 mixins: [drawer],
	 props:{
		selectedItem: {
			type: Object,
			default: null
		},
		is_calendar: {
			type: Boolean,
			default: false
		}
	},
    data() {
        return {
            exhibition: {},
            loadingTop: false,
            loadingData: false,
        }
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            list: "exhibitions/list",
            model: "exhibitions/model",
        }),
        exhibition_id: function () {
				if (!this.selectedItem){
					return this.$route.params.exhibition_id
				}
				else return this.selectedItem.id; 
        },
    },
    watch: {
        exhibition_id: {
            handler: async function(newVal, oldVal) {

            },
            deep: true,
            immediate: true
        },
    },

    created(){
        if(!this.selectedItem) this.fetchData();
    },
    methods: {
        ...mapActions({
            show: "exhibitions/show",
        }),
		afterOpen(){
			if (this.selectedItem) this.fetchData();
		},
		fetchData(){
			let work_meeting_id = this.selectedItem ? this.selectedItem.id : this.$route.params.exhibition_id;

			if (!this.loadingTop && work_meeting_id){
				this.loadingTop = true;
				this.show(work_meeting_id)
					.then(res => {
						this.exhibition = this.model;
						this.loadingTop = false;
					}).catch(err => {
						this.loadingTop = false;
					});
			}
		},
		dayDiff(date) {
			let current_date = new Date();
			let end_date = new Date(date);
			if (!isNaN(current_date) && !isNaN(end_date)) {
					let timeDifference = Math.round((end_date.getTime() - current_date.getTime())/(1000 * 3600 * 24));
					return timeDifference >= 0 ? timeDifference : 0;
			} else {
					return '';
			}
		},
		afterLeave(){
			this.$store.commit('exhibitions/EMPTY_MODEL');
			this.$store.commit('exhibitions/EMPTY_LIST');
			this.exhibition = {};
		}
    }
};
</script>
<style lang="scss">
.nav-item-top{
    // display: flex;
    li{
        margin: 10px 10px 10px 20px;
        flex: 1;
    }
    .nav-item-top-link{
        background: #459ef7;
        padding: 10px 20px;
        margin-bottom: 10px;
        display: block;
        width: 100%;
        text-align: center;
        border-radius: 5px;
        color: #fff;
    }
    .nav-item-top-link.router-link-exact-active.router-link-active{
        background: #fff;
        color: #459ef7;
    }
}
.itme-exhibitionInfo{
    color: #fff;
    h4{
        margin: 0px;
        margin-bottom: 10px;
        color: #4c4c4c;
        font-weight: 300;
        b{
            color: #000;
            font-weight: 600;
        }
    }
}
.d-modal-style .itme-exhibitionInfo h4 {
    color: #fff;
}
.d-modal-style .itme-exhibitionInfo h4 b {
      color: #fff;
}
.l-modal-style .chart-exhibitionHome .apexcharts-datalabel{
    fill: #000;
    filter: none;
}
</style>
