import Vue from 'vue'
import store from '../store'
window._ = require('lodash');
/**
 * timeline component
 */

import { Timeline } from 'vue2vis'
Vue.component('timeline', Timeline)
import 'vue2vis/dist/vue2vis.css'
 
 /**
  * end vue modal components
  */

/**
 * vue modal component
 */

 import VModal from 'vue-js-modal'
 Vue.use(VModal);
 
 /**
  * end vue modal components
  */

/*
    Progress Bar
*/
import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: store.getters.getLockTimer, // 3 seconds
  startAtIdle: false,
  events:['mousemove', 'keydown', 'mousedown', 'touchstart',"scroll"]
});
/**
 * end progress bar
 */


import money from 'v-money'
Vue.use(money, { precision: 2, decimal: ',', thousands: ' ', masked: false, prefix: '', suffix: '' });
/**
 * Element UI
 */
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ru-RU'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI,{locale});
/**
 * end element ui
 */
/**
 * Vue moment
 */
// import moment from 'moment'
// import 'moment/locale/uz'

// Vue.use(require('vue-moment'), {
//     moment
// })

// /**
//  * money format
//  */
// import money from 'v-money'
// Vue.use(money, {precision: 2})


// /**
//  * For export excel
//  */
// import excel from 'vue-excel-export'
// Vue.use(excel);

/**
 * Updatable Global Phone variable
 */
const phoneUA = Vue.observable({ phoneUA: {} })

Object.defineProperty(Vue.prototype, '$phoneUA', {
  get () {
    return phoneUA.phoneUA
  },
  set (value) {
    phoneUA.phoneUA = value
  }
})

/**
 * For alert
 */
import { notify, error__message, warning__message, info__message} from './index'
Vue.prototype.$alert = notify;
Vue.prototype.$error_message = error__message;
Vue.prototype.$warning_message = warning__message;
Vue.prototype.$info_message = info__message;

/**
 * For cursor
 */
import { loadingCursor } from './index'
Vue.prototype.$loadingCursor = loadingCursor;



Vue.mixin({
    data() {
        return {
            base_url: process.env.VUE_APP_URL,
            date_format: 'yyyy-MM-dd',
            date_time_format: 'yyyy-MM-dd HH:mm',
				date_time_min_format: 'yyyy-MM-dd HH:mm:ss'
        }
    },
    methods: {
        formatDate(date) {
            if(date){
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
        
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
        
                return [year, month, day].join('-');
            }else{
                return '';
            }
            
        },
        dateTimeFormat(dt) {
            let dt_s = Date.parse(dt);
            if (!Number.isNaN(dt_s)) {
                let date = new Date(dt_s)
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let year = date.getFullYear();
                if (day < 10) {
                    day = '0' + day;
                }
                if (month < 10) {
                    month = '0' + month;
                }
                let min = date.getMinutes() >= 10 ? date.getMinutes() : ('0' + date.getMinutes());
                let hour = date.getHours() >= 10 ? date.getHours() : ('0' + date.getHours());
                return (year + '-' + month + '-' + day + ' ' + hour + ':' + min);
            }
            return '';
        }
    }
});
