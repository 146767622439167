import _ from 'lodash';
import { mapActions, mapGetters } from "vuex";
import { formatMoney } from "@/filters/index";

export default {
    props: {
        deal_id: {
            default: null,
        }, 
        selectedRow: {
            default: Object,
        }, 
        product_calculation_type_id: {
            default: null,
        },
    },

    data() {
        return {
            CALCULATION_BY_PERCENTAGE: 1,
            CALCULATION_BY_MONEY: 2,
            servicesTotal: 0,
            servicesUnitTotal: 0,
            productsTotal: 0,
            drawerServiceTree: false,
            dealProductsListChild: [],
            productFeeList: [],
            product_id: null,
            drawerOpened: false,
            updateProducts: false,
            drawerServices: false,
            drawerProductComposition: false,
            selectedProductComposition: [],
            selectedProductServices: [],
            selectedProductIndex: null,
            selectedProductRow: {},
            loadingButton: false,
            form: {
                last_quantity: 1,
                product_price: 0,
                last_price: 0,
                last_price_net: 0,
                profit: 0,
                calculation: false,
                products: [],
                feeList: []
            },
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            unit_price_changing: false,
            updatedTreeServices: [],
        }
    },
    watch: {
        selectedRow: {
            handler: function (newVal) {
                if(newVal && Object.keys(newVal).length > 0){
                    if(newVal.calculation_type_id){
                      this.$set(this.form, 'calculation_type_id', newVal.calculation_type_id);
                    }else{
                      this.$set(this.form, 'calculation_type_id',  JSON.parse(JSON.stringify(this.product_calculation_type_id)));
                    }
                    this.$set(this.form, 'last_quantity', newVal.quantity);
                    this.$set(this.form, 'profit', newVal.profit);
                    this.dealProductsListChild = newVal.child_products ? JSON.parse(JSON.stringify(newVal.child_products)) : [];
                    if(newVal.feeList && newVal.feeList.length == 0){
                      setTimeout(() => {
                        this.productFeeList = JSON.parse(JSON.stringify(this.setFeeByCalculation));
                      }, 200);
                    }else{
                      setTimeout(() => {
                        this.productFeeList = newVal.feeList ? JSON.parse(JSON.stringify(newVal.feeList)) : [];
                        this.productFeeList.forEach(el => {
                          this.updatePercentage(el);
                        })
                      }, 200);
                    }
                }
            },
            immediate: true,
            deep: true,
        },
        "form.last_quantity": {
            handler: function (newVal) {
                if(newVal > 0 && (this.form.calculation || (this.form.calculation_type_id && this.form.calculation_type_id != 1))){
                    let val = newVal;
                    this.updateCalculationsByType(val);
                    this.productFeeList.forEach(element => {
                      this.updatePercentage(element);
                    });

                }
            },
            immediate: true,
            deep: true,
        },
        "form.last_price": {
            handler: function (newVal) {
              if(this.productFeeList.length > 0 && !this.unit_price_changing && this.drawerOpened){
                this.productFeeList.forEach(el => {
                  this.updatePercentage(el);
                })
              }
            },
            immediate: true,
            deep: true,
        },
        "form.calculation": {
          handler: function (newVal) {
            if(this.form.last_quantity > 0 && newVal){
              let val = this.form.last_quantity;
              this.updateCalculationsByType(val);
            }
          },
          immediate: true,
          deep: true,
        },
        "form.calculation_type_id": {
          handler: function (newVal) {
            this.calculateTotals();
            this.productFeeList.forEach(element => {
              this.updatePercentage(element);
            }); 
          },
          immediate: true,
          deep: true,
        },
        "productFeeList": {
          handler: function (newVal) {
            if(newVal.length > 0){
              this.calculateTotals();
            }
          },
          immediate: true,
          deep: true,
        },
        
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            permissions: "auth/permissions",
            getProduct: "products/model",
            model: "dealProducts/model",
            dealProducts: "dealProducts/dealProducts",
            rules: "dealProducts/rules",
            columns: "dealProducts/columns",
            currencies: "currency/inventory",
            services: "productServices/inventory",
            treeInventory: "productServices/treeInventory",
            getFeeList: "dealProductAdditionalFeeType/list",
        }),
    
        setFeeByCalculation(){
          let feeList =  JSON.parse(JSON.stringify(this.getFeeList));
          feeList.forEach(fee => {
              fee.fee_type_id = fee.id;
              if(fee.calculation_by == this.CALCULATION_BY_MONEY){
                  fee.money_amount = _.round(parseFloat(fee.money_amount || 0) * parseFloat(fee.currency.rate || 0), 2);
                  fee.percentage = _.round((this.productsTotal > 0 ? parseFloat(fee.money_amount || 0) * 100 / parseFloat(this.productsTotal) : 0), 2);
              }else{
                  fee.money_amount = _.round((parseFloat(this.productsTotal || 0) * parseFloat(fee.percentage || 0)/100), 2);
              }
              fee.unit_price = this.form.last_quantity > 0 ? (parseFloat(fee.money_amount || 0) / parseFloat(this.form.last_quantity || 0)) : 0;
          });
          return feeList;
        },
        totalPriceSum: function () {
          let total = 0;
          this.dealProductsListChild.forEach((product) => {
            total += parseFloat(product.price || 0) * parseFloat(product.currency_rate || 0);
          });
          return total;
        },
    
        totalAmountWithoutNDSAndDiscount: function () {
          let total = 0;
          this.dealProductsListChild.forEach((product) => {
            total += parseFloat(product.quantity || 0) * parseFloat(product.price || 0) * parseFloat(product.currency_rate || 0);
          });
          return total;
        },
        caculateFeeTotal: function () {
          let total = this.productFeeList.length > 0 ? this.productFeeList.map(el => el.money_amount).reduce((total, fee) => parseFloat(total) + parseFloat(fee || 0)) : 0;
          return total;
        },
        caculateFeeTotalForUnit: function () {
          let total = this.productFeeList.length > 0 ? this.productFeeList.map(el => el.unit_price).reduce((total, fee) => parseFloat(total) + parseFloat(fee || 0)) : 0;
          return total;
        },
        totalAmount: function () {
          return this.calculateTotals();
        },
        
    },
    methods: {
        ...mapActions({
          save: "dealProducts/store",
          update: "dealProducts/update",
          updateColumn: "dealProducts/updateColumn",
          show: "dealProducts/show",
          showProduct: "products/show",
          updateProduct: "products/update",
          empty: "dealProducts/empty",
          destroy: "dealProducts/destroy",
          updateCurrencyInventory: "currency/inventory",
          updateServiceInventory: "productServices/inventory",
          updateServiceCategoryTree: "productServices/serviceCategoryTree",
          updateFeeInventory: "dealProductAdditionalFeeType/index",
        }),
        calculateTotals(){
            let total = 0;
            this.servicesTotal = 0;
            this.servicesUnitTotal = 0;
            this.productsTotal = 0;
            let last_price_net = 0;
            let last_price = 0;
            this.dealProductsListChild.forEach((product) => {
                let prodcutServiceCost = 0;
                if(product.serviceList && product.serviceList.length > 0){
                    let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount || 0)*parseFloat(el.currency_rate || 0)));
                    prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price || 0));
                }
                this.servicesUnitTotal += parseFloat(prodcutServiceCost);
                this.servicesTotal += (parseFloat(product.quantity || 0) * parseFloat(prodcutServiceCost));
                this.productsTotal += (parseFloat(product.quantity || 0) * parseFloat(product.currency_rate || 0) * parseFloat(product.price || 0));

                if(this.form.calculation_type_id != 1){ // this.form.calculation_type_id == 2
                  last_price_net += ((parseFloat(product.price || 0) * parseFloat(product.currency_rate || 0)) + parseFloat(prodcutServiceCost || 0));
                  last_price_net = last_price_net * (1 + parseFloat(product.waste || 0) / 100)
                 
                }else{
                  total += parseFloat(product.quantity || 0) * (parseFloat(prodcutServiceCost || 0) + parseFloat(product.currency_rate || 0) * parseFloat(product.price || 0));
                }
            });
            if(this.form.calculation_type_id != 1){ // this.form.calculation_type_id == 2
              total = parseFloat(last_price_net || 0);
            }
            if(this.selectedRow && Object.keys(this.selectedRow).length > 0){

                if(this.form.calculation_type_id == 1){
                  let fee_total = this.productFeeList.length > 0 ? this.productFeeList.map(el => el.money_amount).reduce((total, fee) => parseFloat(total) + parseFloat(fee || 0)) : 0;
                  last_price = (parseFloat(total) + parseFloat(fee_total)) / parseFloat(this.form.last_quantity);
                  last_price = _.round(parseFloat(last_price)*(1+(parseFloat(this.form.profit || 0)/100)), 2);
                  last_price = _.round((parseFloat(last_price)/parseFloat(this.selectedRow.currency_rate)), 2);
                
                  last_price_net =  (parseFloat(total) + parseFloat(fee_total)) / parseFloat(this.form.last_quantity);
                  last_price_net = _.round((parseFloat(last_price_net)/parseFloat(this.selectedRow.currency_rate)), 2);

                  this.$set(this.form, 'last_price', last_price);
                  this.$set(this.form, 'last_price_net', last_price_net);
                
                }else { 
                  // this.form.calculation_type_id == 2 
                  let fee_total = this.productFeeList.length > 0 ? this.productFeeList.map(el => parseFloat(el.unit_price || 0)).reduce((total, fee) => parseFloat(total || 0) + parseFloat(fee || 0)) : 0;
                  let product_price = JSON.parse(JSON.stringify(last_price_net));
                  this.$set(this.form, 'product_price', product_price);
                  last_price_net += parseFloat(fee_total || 0);
                  last_price_net = _.round((parseFloat(last_price_net || 0) / parseFloat(this.selectedRow.currency_rate)), 2);
                  last_price = _.round(parseFloat(last_price_net)*(1+(parseFloat(this.form.profit || 0)/100)), 2);

                  this.$set(this.form, 'last_price', last_price);
                  this.$set(this.form, 'last_price_net', last_price_net);
                }
            }
            
            return total;
        },
        checkMinProfit(profit){
          if ((parseFloat(profit) < this.parent().min_profit) || !profit){
            this.form.profit = this.parent().min_profit;
          }
        },
        parent(){
          return this.$parent.$parent;
        },
        showProductServiceName() {
          if(this.dealProductsListChild && this.dealProductsListChild.length > 0 && this.selectedProductIndex !== null && this.selectedProductIndex >= 0){
            return this.dealProductsListChild[this.selectedProductIndex] ? (': ' + this.dealProductsListChild[this.selectedProductIndex].product.name) : '';
          }
          return '';
        },
        updateProductComposition(composition, compositionTotal){
          let rate = this.dealProductsListChild[this.selectedProductIndex].currency_rate;
          let price = rate > 0 ? parseFloat(compositionTotal || 0) / parseFloat(rate) : 0;
    
          this.$set(this.dealProductsListChild[this.selectedProductIndex], 'composition', JSON.parse(JSON.stringify(composition)));
          this.$set(this.dealProductsListChild[this.selectedProductIndex], 'price', _.round(price, 2));
        },
        openCompositionDrawer(row, index){
          this.selectedProductIndex = index;
          this.selectedProductComposition = row.composition;
          this.drawerProductComposition = true
        },
        async serviceListOpen(row, index){
            this.selectedProductIndex = index;
            this.updatedTreeServices = JSON.parse(JSON.stringify(this.updatedTreeServices));
            this.drawerServiceTree = true;
            
            if(this.$refs['servicePriceList'] && _.isFunction(this.$refs['servicePriceList'].loadDefault)){
              this.$refs['servicePriceList'].loadDefault();
            }  
            let services = JSON.parse(JSON.stringify(row.services));
        
            if(!this.$refs['serviceTree']){
              await setTimeout(() => {  }, 200);
            }
            if(this.$refs['serviceTree']){
              this.$refs['serviceTree'].setCheckedKeys(services);
            }   
        },
        closeServiceListDialog() {
          if(this.$refs['serviceTree'] && this.selectedProductIndex >= 0){
            let data = this.$refs['serviceTree'].getCheckedKeys();
            let new_data = JSON.parse(JSON.stringify(data));
            new_data = new_data.filter((value, index, self) => value && self.indexOf(value) === index);
            this.$set(this.dealProductsListChild[this.selectedProductIndex], 'services', JSON.parse(JSON.stringify(new_data)));
    
            let getCheckedNodes = this.$refs['serviceTree'].getCheckedNodes();
            getCheckedNodes = getCheckedNodes.filter(value => value.custom_id);
    
            this.$refs['serviceTree'].setCheckedKeys([]);
            this.productServiceChange(this.dealProductsListChild[this.selectedProductIndex].services, JSON.parse(JSON.stringify(this.selectedProductIndex)), JSON.parse(JSON.stringify(getCheckedNodes)));
          }
        },
        async afterOpen(){
          // if(this.services && this.services.length === 0) await this.updateServiceInventory({price_list_id: this.price_list_id});
          this.loadingTree = true;
          await this.updateServiceCategoryTree({price_list_id: this.price_list_id})
            .then(async res => {
              this.updatedTreeServices = JSON.parse(JSON.stringify(this.treeInventory));
              this.updatedTreeServices = this.updatedTreeServices.filter(el => el.name);
              this.updateTree(this.updatedTreeServices);
              this.loadingTree = false;
            });
          this.drawerOpened = true;
        },
        updateTree(data){
            data.forEach((element, index) => {
                if(element.children && element.children.length > 0){
                  element.children = element.children.filter(el => el.name);
                  this.updateTree(element.children);
                }
            });
        },
        updateQuantity(row, index){
            if(row.waste >= 100 || row.waste < 0){
                this.$set(row, 'waste', 0);
            }
            if(this.form.calculation || (this.form.calculation_type_id && this.form.calculation_type_id != 1)){
                let val = this.form.last_quantity;
                this.updateCalculationsByType(val);
            }
    
            if(!row.service_for_unit){
              row.serviceList.forEach(el => {
                el.money_amount = _.round(row.quantity > 0 ? parseFloat(el.price_for_all || 0) / parseFloat(row.quantity) : 0, 3); 
              })
            }
        },
        checkCurrency(row){
          let currency = this.currencies.find(curr => curr.id === row.currency_id);  
          if(currency && currency.active){
            return true;
          }
          return false;
        },
    
        check: function (column, event) {
          this.updateColumn({key: column, value: event,})
        },
    
        changeCurrency(row){
          if(row.currency_id){
            let currency = this.currencies.find(el => el.id === row.currency_id);
            if(currency){
              let price = _.round((parseFloat(row.currency_rate) * parseFloat(row.price) / parseFloat(currency.rate)), 2);
              this.$set(row, 'currency_rate', currency.rate);
              this.$set(row, 'price', price);
            }
          }
        },

        tableRowClassName({row, rowIndex}){
          let my_class = '';
          if((rowIndex + 1) == this.dealProductsListChild.length){
            my_class = 'light__green';
          }
          return my_class;
        },
        saveUpkeepAsDefault(){
          this.loadingButton = true;
    
          this.showProduct(this.selectedRow.product_id)
          .then(() => {
            if(this.getProduct && this.getProduct.child_products && this.getProduct.child_products.length > 0){
              this.$confirm(
                this.$t('message.product_already_has_recipe_wanna_continue'),
                this.$t('message.warning'),
                {
                  confirmButtonText: this.$t('message.yes'),
                  cancelButtonText: this.$t('message.no'),
                  type: "warning",
                }
              )
              .then(() => {
                this.updateProductUpkeep();
              })
              .catch(() => { 
                this.loadingButton = false; 
              });
            }else{
              this.updateProductUpkeep();
            }
          })
          .catch(() => {
            this.loadingButton = false;
          });
          
        },
        updateProductUpkeep(){
          let updatableProduct = JSON.parse(JSON.stringify(this.getProduct));
          let dealProductsListChild = JSON.parse(JSON.stringify(this.dealProductsListChild));
          dealProductsListChild.forEach(element => {
            element.id = null;
          });
          let productFeeList = JSON.parse(JSON.stringify(this.productFeeList));
          productFeeList.forEach(element => {
            element.id = null;
            element.money_amount = _.round((parseFloat(element.money_amount || 0)/parseFloat(this.form.last_quantity || 1)), 4);
          });
    
          this.$set(updatableProduct, 'calculation_type_id', this.form.calculation_type_id);
          this.$set(updatableProduct, 'selling_price', parseFloat(this.form.last_price));
          this.$set(updatableProduct, 'profit', parseFloat(this.form.profit));
          this.$set(updatableProduct, 'child_products', dealProductsListChild);
          this.$set(updatableProduct, 'feeList', productFeeList);
          this.$set(updatableProduct, 'removeOldChildren', true);
    
          let formData = new FormData();
    
          for (const key in updatableProduct) {
              if (key != 'is_default' && key != 'names' && key != 'child_products' && key != 'feeList') {
                  if (updatableProduct[key]) {
                      formData.append(key, updatableProduct[key]);
                  }else {
                      formData.append(key, '');
                  }
              } else if (key == 'is_default') {
                  formData.append(key, updatableProduct[key]);
              } else if (key == 'names' || key == 'child_products' || key == 'feeList') {
                  formData.append(key, JSON.stringify(updatableProduct[key]));
              }
          }
    
          this.updateProduct(formData)
                .then((res) => {
                  this.loadingButton = false;
                  this.$alert(res);
                })
                .catch((err) => {
                  this.loadingButton = false;
                  this.$alert(err);
                });
        },
    
        // START ROW TOTAL METHODS
        productRowServiceTotalPerUnit(product){
          let total = 0; 
          if(product.serviceList && product.serviceList.length > 0){
            let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
            total = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
          }
          return _.round(parseFloat(total), 2);
        },
    
        productRowServiceTotal(product){
          let prodcutServiceCost = 0;
          let total = 0;
          
          if(this.form.calculation_type_id == 1){
            if(product.serviceList && product.serviceList.length > 0){
              let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
              prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
            }
            total = parseFloat(prodcutServiceCost) * parseFloat(product.quantity);
          }else{  // this.form.calculation_type_id == 2
            if(product.serviceList && product.serviceList.length > 0){
              let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
              prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
            }
            total = parseFloat(prodcutServiceCost) * parseFloat(product.quantity);
            total = total * (1 + product.waste/100);
          }
          
    
          return total;
        },
    
        productRowProductTotal(product){
          let total = 0;
    
          if(this.form.calculation_type_id == 1){
            total = _.round(parseFloat(product.currency_rate) * parseFloat(product.price) * parseFloat(product.quantity), 9);
          }else{  // this.form.calculation_type_id == 2
            total = _.round(parseFloat(product.currency_rate) * parseFloat(product.price) * parseFloat(product.quantity) * (1 + product.waste/100), 9);
          }
    
          return total;
        },
    
        productRowTotal(product, index) {
          let prodcutServiceCost = 0;
          let rowTotal = 0;
          let currentRowTotal = 0;
          if(this.form.calculation_type_id == 1){
            if(product.serviceList && product.serviceList.length > 0){
              let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount) * parseFloat(el.currency_rate)));
              prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
            }
            rowTotal = _.round((parseFloat(product.currency_rate) * parseFloat(product.price) + parseFloat(prodcutServiceCost)) * parseFloat(product.quantity), 9);
    
          }else { // this.form.calculation_type_id == 2
            // rowTotal
            this.dealProductsListChild.forEach((product, prod_index) => {
                if(product.serviceList && product.serviceList.length > 0){
                  let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
                  prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
                }
                if(index > prod_index){
                  rowTotal += ((parseFloat(product.price || 0) * parseFloat(product.currency_rate)) + parseFloat(prodcutServiceCost || 0));
                  rowTotal = rowTotal * (1 + parseFloat(product.waste || 0) / 100);
                }else if(index == prod_index){
                  currentRowTotal += ((parseFloat(product.price || 0) * parseFloat(product.currency_rate)) + parseFloat(prodcutServiceCost || 0)) * (1+parseFloat(product.waste || 0) / 100);
                  currentRowTotal += rowTotal * (parseFloat(product.waste || 0) / 100);
                }
            });
          }
    
          return currentRowTotal;
        },
        // END ROW TOTAL METHODS
        
        getSummaries(param) {
            const { columns } = param;
            const sums = [];
            columns.forEach((column, index) => {
              if (index === 0) {
                sums[index] = this.$t('message.total');
                return;
              }
              if (column.label === this.columns.price.title) {
                sums[index] = formatMoney(this.totalPriceSum, 2);
                return;
              }

              if (column.label == this.$t('message.service') ) {
                sums[index] = formatMoney(this.servicesTotal, 2);
                return;
              }
    
              if (column.label == this.$t('message.product')) {
                sums[index] = formatMoney(this.productsTotal, 2);
                return;
              }
           
              if (column.label === this.columns.total.title) {
                sums[index] = formatMoney(this.totalAmount, 2);
                return;
              }
            });
    
            return sums;
        },
        getSummariesForFees(param) {
          const { columns } = param;
          const sums = [];
          columns.forEach((column, index) => {
              if (index === 1) {
                  sums[index] = this.$t('message.total');
                  return;
              }
              if (column.label == this.$t('message.money_amount')) {
                  sums[index] = formatMoney(this.caculateFeeTotal, 2);
                  return;
              }
              if (column.label == this.$t('message.unit_price')) {
                  sums[index] = formatMoney(this.caculateFeeTotalForUnit, 2);
                  return;
              }
          });
          return sums;
        },
        getSummariesForServices(param){
          const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
              if (index === 0) {
                sums[index] = this.$t('message.total');
                return;
              }
              if(column.label === this.$t('message.money_amount')){
                let total = data.map(serv => parseFloat(serv.money_amount*serv.currency_rate));
                if(total.length > 0){
                  sums[index] = formatMoney(
                      total.reduce((all_money, money) => parseFloat(all_money)+parseFloat(money)) * parseFloat(this.selectedProductRow.quantity || 0)
                    , 3);
                }else{
                  sums[index] = 0;
                }
                return;
              }
          });
    
          return sums;
        },
    
        checkRowServices(row){
          if(row.services && row.services.length > 0){
            return false;
          }
          return true;
        },
        showServices(row, index){
          this.drawerServices = true;
          this.selectedProductRow = row;
          row.serviceList.forEach(service => {
            service.price_for_all = _.round(parseFloat(service.money_amount || 0) * parseFloat(row.quantity || 0), 3);
          });
          this.selectedProductServices = JSON.parse(JSON.stringify(row.serviceList));
          this.selectedProductIndex = index;
        },
    
        updateServiceRow(row, index){
          if(this.selectedProductRow.service_for_unit){
            row.price_for_all = _.round(parseFloat(row.money_amount || 0) * parseFloat(this.selectedProductRow.quantity || 0), 3);
          }else{
            row.money_amount = _.round(this.selectedProductRow.quantity > 0 ? parseFloat(row.price_for_all || 0) / parseFloat(this.selectedProductRow.quantity) : 0, 3);
          }
        },
    
        getCurrencyCodeById(row){
          let currency = this.currencies.find(el => el.id === (row.currency_id || row.selling_price_currency_id || row.buy_price_currency_id));
          return currency ? currency.symbol : '';
        },
        productServiceChange(data, index, nodes){
          // this.$set(this.dealProductsListChild[this.selectedProductIndex], 'services', data);
          if(this.dealProductsListChild[index].serviceList && this.dealProductsListChild[index].serviceList.length > 0){
            this.$set(this.dealProductsListChild[index], 'serviceList', this.dealProductsListChild[index].serviceList.filter(el => data.includes(el.custom_id)));
          }else{
            this.$set(this.dealProductsListChild[index], 'serviceList', []);
          }
            
              let list_ids = this.dealProductsListChild[index].serviceList.map(el => el.custom_id);
          let newServices = data.filter(item => !list_ids.includes(item) )
          if(newServices.length > 0){
            newServices.forEach(el => {
              let service = nodes.find(e => e.custom_id === el);
                    
              if(service){
                service = JSON.parse(JSON.stringify(service));
                let currency = this.currencies.find(el => el.id === service.currency_id);
                currency = currency ? currency.rate : 0; 
                this.$set(service, 'currency_rate', currency);
                this.$set(service, 'price_for_all', 0);
                this.dealProductsListChild[index].serviceList.push(service);
              }
            });
          }
          // this.dealProductsListChild[index].serviceList.sort((a, b) => a.id - b.id);
          this.selectedProductIndex = null;
        },
    
        submitService(close){
          if(this.selectedProductIndex >= 0){
            this.$set(this.dealProductsListChild[this.selectedProductIndex], 'serviceList', this.selectedProductServices);
          }
          this.closeService();
        },
        closeService(){
          this.selectedProductServices = [];
          this.selectedProductIndex = null;
          this.drawerServices = false;
        },
     
        get_selected_product(selected_product_id){
          if(selected_product_id){
            this.addToDealProductsListChild(selected_product_id);
          }
        },
        dealOffersCreate() {
          if(this.dealProductsListChild.length > 0){
            this.$emit('dealOffersCreate', {drawer: true, productList: this.dealProductsListChild})
          }else{
            this.$message({
                type: "warning",
                message: this.$t('message.product_list_is_empty'),
            });
          }
        },
    
        // START ROW TOTAL METHODS
        // productRowServiceTotal(product){
        //   let prodcutServiceCost = 0;
        //   if(product.serviceList && product.serviceList.length > 0){
        //     let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
        //     prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
        //   }
        //   return parseFloat(prodcutServiceCost) * parseFloat(product.quantity);
        // },
        // productRowProductTotal(product){
        //   return _.round(parseFloat(product.currency_rate) * parseFloat(product.price) * parseFloat(product.quantity), 9);
        // },
        // productRowTotal(product) {
        //   let prodcutServiceCost = 0;
        //   if(product.serviceList && product.serviceList.length > 0){
        //     let moneyList = product.serviceList.map(el => (parseFloat(el.money_amount)*parseFloat(el.currency_rate)));
        //     prodcutServiceCost = moneyList.reduce((acc, price) => parseFloat(acc) + parseFloat(price));
        //   }
        //   let rowTotal = _.round((parseFloat(product.currency_rate) * parseFloat(product.price) + parseFloat(prodcutServiceCost)) * parseFloat(product.quantity), 9);
        //   return rowTotal;
        // },
        // END ROW TOTAL METHODS
    
        async addToDealProductsListChild(product_id) {
          await this.showProduct(product_id).then(() => {
            let item = {};
            let currency_id = this.getProduct.selling_price_currency.id;
            let rate = null
            if(currency_id){
              let currency = this.currencies.find(el => el.id === currency_id)
              if(currency){
                rate = currency.rate;
              }
            }
            item.id = null;
            item.deal_id = this.deal_id;
            item.product_id = product_id;
            item.price = this.getProduct.selling_price;
            item.currency_id = currency_id;
            item.currency_rate = rate;
            item.product = JSON.parse(JSON.stringify(this.getProduct));
            item.quantity = 1;
            item.waste = 0;
            item.color_id = null;
            item.services = [];
            item.serviceList = [];
            item.colors = this.getProduct.allowed_colors;
            item.category_ids = this.getProduct.category_ids;
            item.package_id = null;
            item.packages = this.getProduct.allowed_packages;
            item.service_for_unit = true;
            item.composition = this.setExistingCompositions();
    
            this.dealProductsListChild.push(item);
          });
          if(this.form.calculation || (this.form.calculation_type_id && this.form.calculation_type_id != 1)){
              let val = this.form.last_quantity;
              this.updateCalculationsByType(val);
          }
        },
        setExistingCompositions(){
          let composition = [];
          if(this.getProduct.composition){
            composition = this.getProduct.composition.map(el => {
              return {
                name: el.name,
                price: el.price, 
                quantity: el.quantity,
                product_id: el.product_id,
                percentage: el.percentage,
                measurement: el.measurement,
              }
            })
          }
          return composition;
        },
    
        removeFromDealProductsListChild(id, index) {
          if (id) {
            this.$confirm(
                this.$t('message.Are you sure you want to uninstall the product?'),
                this.$t('message.warning'),
                {
                confirmButtonText: "Да",
                cancelButtonText: "Нет",
                type: "warning",
                }
            )
            .then(() => {
                this.destroy(id)
                  .then((res) =>{
                    this.dealProductsListChild.splice(index, 1);
                    this.$alert(res);
                    setTimeout(() => {
                      this.$emit('updateParentPriceAndRemoveProduct',{price: this.form.last_price, id: id, index: index});
                    }, 200);
                  })
                  .catch((err) => {
                      this.$alert(err);
                  })
            })
            .catch(() => {
                this.$message({
                    type: "warning",
                    message: this.$t('message.deleting_product_canceled'),
                });
            });
          }else{
              this.dealProductsListChild.splice(index, 1);
          }
        },
        afterLeave(){
            this.form = {
                last_quantity: 1,
                last_price: 0,
                profit: 0,
                calculation_type_id: null,
                calculation: false,
                products: [],
                feeList: []
            }
            this.productFeeList = [];
            this.dealProductsListChild = [];
            this.drawerOpened = false;
        },
        unitPriceFocus(){
          this.unit_price_changing = true;
        },
        
        unitPriceChange(){
          this.unit_price_changing = false;
        },
    
        updateUnitPrice(fee){
            if(fee.unit_price >= 0){
              if(this.form.calculation_type_id == 1){
                fee.money_amount = _.round((parseFloat(fee.unit_price || 0) * parseFloat(this.form.last_quantity || 0)), 2);
                fee.percentage = _.round((this.productsTotal > 0 ? parseFloat(fee.money_amount || 0) * 100 / parseFloat(this.productsTotal) : 0), 2);
              }else{
                fee.money_amount = _.round((parseFloat(fee.unit_price || 0) * parseFloat(this.form.last_quantity || 0)), 2);
                fee.percentage = _.round((this.form.product_price > 0 ? parseFloat(fee.unit_price || 0) * 100 / parseFloat(this.form.product_price) : 0), 2);
              }
                
            }else{
                fee.percentage = 0;
                fee.money_amount = 0;
                fee.unit_price = 0;
            }
        },
    
        updateMoneyAmount(fee){
            if(fee.money_amount >= 0){
                if(this.form.calculation_type_id == 1){
                  fee.percentage = _.round((this.productsTotal != 0 ? parseFloat(fee.money_amount || 0) * 100 / parseFloat(this.productsTotal) : 0), 2);
                  fee.unit_price = this.form.last_quantity > 0 ? _.round((parseFloat(fee.money_amount || 0) / parseFloat(this.form.last_quantity)), 2) : 0;
                }else{
                  fee.percentage = _.round((this.form.product_price != 0 ? (parseFloat(fee.money_amount || 0) / parseFloat(this.form.last_quantity)) * 100 / parseFloat(this.form.product_price) : 0), 2);
                  fee.unit_price = this.form.last_quantity > 0 ? _.round((parseFloat(fee.money_amount || 0) / parseFloat(this.form.last_quantity)), 2) : 0;
                }
            }else{
                fee.percentage = 0;
                fee.money_amount = 0;
                fee.unit_price = 0;
            }
        },
    
        updatePercentage(fee){
            if(fee.percentage >= 0){
                if(this.form.calculation_type_id == 1){
                  fee.money_amount = _.round((parseFloat(this.productsTotal || 0) * parseFloat(fee.percentage || 0)/100), 2);
                  fee.unit_price = this.form.last_quantity > 0 ? _.round((parseFloat(fee.money_amount || 0) / parseFloat(this.form.last_quantity)), 2) : 0;
                }else{
                  fee.money_amount = _.round((parseFloat(this.form.product_price || 0) * parseFloat(this.form.last_quantity || 0) * parseFloat(fee.percentage || 0)/100), 2);
                  fee.unit_price = _.round((parseFloat(fee.percentage || 0) * parseFloat(this.form.product_price || 0) / 100), 2);
                }
            }else{
                fee.percentage = 0;
                fee.money_amount = 0;
                fee.unit_price = 0;
            }
        },
    
        submit(close = true) {
            this.$set(this.form, 'products', this.dealProductsListChild);
            this.$set(this.form, 'feeList', this.productFeeList);
            this.$emit('setChildProducts', this.form);
            this.close();
        },
    
        closeDrawer(drawer) {       
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
              this.$refs[drawer].closeDrawer();
            }
        },
    
        updateCalculationsByType(val){
          // this.dealProductsListChild.forEach(el => {
          //     val = (val * 100 / (100 - el.waste));
          //     this.$set(el, 'quantity', _.round(val, 2));
          // });
          
          this.dealProductsListChild.slice().reverse().forEach(el => {
            val = (val * 100 / (100 - el.waste));
            this.$set(el, 'quantity', _.round(val, 2));
          });
            
        }
    },
}