import {i18n} from '@/utils/i18n';

export default [{
    path: '/workMeeting',
    name: 'workMeeting',
    meta: {
      layout: 'main',
      title: i18n.t('message.workMeeting')
    },
    component: () => import('@/views/workMeeting/index')
}]
  