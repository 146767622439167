<template>
	<div class="app-modal__body p-5 pb-0">
		<div class="timeline-items__right w-100 rounded-sm p-4">
			<el-collapse accordion class="collapseno-border">
				<el-collapse-item name="1">
					<template slot="title">
						<span>{{ $t('message.checking_account') }}</span>
						<i class="ml-2 el-icon-coin"></i>
					</template>
					<div v-for="(value, index) in object" :key="index">
						<el-row :gutter="20">
							<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
								<div class="app-form__group mb-4">
									<span class="input--label d-block mb-2"> {{ $t('message.bank') }}  </span>
									<el-input
									:placeholder="$t('message.bank')"
									v-model="value.bank"
									size="medium"
									></el-input>
								</div>
							</el-col>
							<!-- end col -->
							<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
								<div class="app-form__group mb-4">
									<span class="input--label d-block mb-2">{{ $t('message.actual_address') }}</span>
									<el-input
									:placeholder="$t('message.actual_address')"
									v-model="value.address"
									size="medium"
									></el-input>
								</div>
							</el-col>
							<!-- end col -->
							<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
								<div class="app-form__group mb-4">
									<span class="input--label d-block mb-2"> {{ $t('message.corr_account') }}</span>
									<el-input
									:placeholder="$t('message.corr_account')"
									v-model="value.correspondent_account"
									size="medium"
									></el-input>
								</div>
							</el-col>
							<!-- end col -->
							<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
								<div class="app-form__group mb-4">
									<span class="input--label d-block mb-2">
									{{ $t('message.checking_account') }}
									</span>
									<el-input
									:placeholder="$t('message.checking_account')"
									v-model="value.checking_account"
									size="medium"
									></el-input>
								</div>
							</el-col>
							<!-- end col -->
							<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="right-block">
								<el-button @click="deleteForm(index)" type="danger" size="medium" >
									{{ $t('message.delete_field') }}
								</el-button>
								<el-button @click="addForm()" type="primary" size="medium" >
									{{ $t('message.add_field') }}
								</el-button>
							</el-col>
							<!-- end col -->
						</el-row>
						<hr />
					</div>
				</el-collapse-item>
			</el-collapse>
		</div>
	</div>
</template>

<script>
export default {
	name: "contact-account",
	mixins: [],
	data() {
		return {
			object: [],
		};
	},
	computed: {},
	created: function () {
		let value = {
			id: "",
			bank: "",
			address: "",
			correspondent_account: "",
			checking_account: "",
			created_at: "",
			updated_at: "",
		};
		this.object.push(value);
	},
	methods: {
		addForm() {
			let value = {
			id: "",
			bank: "",
			address: "",
			correspondent_account: "",
			checking_account: "",
			created_at: "",
			updated_at: "",
			};
			this.object.push(value);
		},
		deleteForm(index) {
			if (this.object.length === 1) {
			this.$message({
				type: "warning",
				message: this.$t("message.unable_to_delete_last_form"),
			});
			} 
			else {
				this.object.splice(index, 1);
			}
		},
	},
};
</script>

