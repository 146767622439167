import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/workMeeting',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/workMeeting',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/workMeeting/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/workMeeting/${id}`,
        method: 'get'
    })
}

export function archivateWorkMeeting(id) {
	return request({
		 url: `/workMeeting/archivateWorkMeeting/${id}`,
		 method: 'get'
	})
}

export function update(data) {
    return request({
        url: `/workMeeting/${parseInt(data.get('id'))}`,
        method: 'post',
        data
    })
}

export function deleteFile(meeting_id, file) {
    return request({
        url: `/workMeeting/deleteFile/${meeting_id}/${file}`,
        method: 'delete',
    })
}

export function destroy(id) {
    return request({
        url: `/workMeeting/${id}`,
        method: 'delete'
    })
}