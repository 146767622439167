<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="filterblock_s">
            <h4 class="dashboard-tile__item-title short ">
                <span class="dashboard-tile__item-title-txt text-white">ПРИБЫЛЬ МЕНЕДЖЕРОВ</span>
            </h4>
            <div class="filter_home flex-center f-wrap">
                <div class="block">
                    <el-date-picker
                        size="medium"
                        format="yyyy-MM-dd" 
                        value-format="yyyy-MM-dd"
                        v-model="date_range.begin"
                        type="date"
                        placeholder="от">
                    </el-date-picker>
                </div>
                <div class="block">
                    <el-date-picker
                        size="medium"
                        format="yyyy-MM-dd" 
                        value-format="yyyy-MM-dd"
                        v-model="date_range.end"
                        type="date"
                        placeholder="до">
                    </el-date-picker>
                </div>
                <el-radio-group v-model="paymentPeriod" size="medium">
                    <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                    <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                    <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                </el-radio-group>
                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>
        </div>
        <div v-if="managersProfit && managersProfit.length > 0" style="height: 100%;" class="khan-chart-a">
            <v-chart class="h-full"  :option="option" autoresize :loading="loading" />
        </div>
        <div v-else>
            <div class="eror">
                <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                <h1>
                    {{ $t("message.no_data_yet") }}
                </h1>
            </div>
        </div>
        
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import * as echarts from 'echarts/core';
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";
import VueApexCharts from "vue-apexcharts";

echarts.use([
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);


export default {
    components: {
        apexchart: VueApexCharts, VChart
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },
        filterForm: Object,
        update: Number,
    },

    data() {
        return {
            loading: false,
            date_range: {
                begin: '',
                end: '',
            },
            paymentPeriod: "weekly",
            option: {
                legend: {},
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '4%',
                    containLabel: true
                },
                tooltip: {
                    valueFormatter: (value) => formatMoney(value, 1)
                },
                dataset: {
                    source: [
                        [this.$t('message.first_name'), this.$t('message.managers_expected_profit'), this.$t('message.paid_to_managers')],
                    ]
                },
                xAxis: { 
                    type: 'category',
                    axisLabel: {
                        rotate: 40,
                    } 
                },
                yAxis: {
                    axisLabel: {
                        formatter: function (value, index) {
                            return formatMoney(value);
                        }
                    } 
                },
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: [{ type: 'bar' }, { type: 'bar' }]
            }
        }
    },
    computed: {
        ...mapGetters({
            managersProfit: "home/managersProfit",
        }),
    },
    watch: {
        allPeriodChange(val) {
            this.paymentPeriod = val;
        },
        paymentPeriod: {
            handler: async function(newVal, oldVal) {
                this.updateChartData(this, {date_range: this.date_range, period: newVal});
            },
            deep: true,
            immediate: true
        },
        date_range: {
            handler: async function(newVal, oldVal) {
                this.updateChartData(this, {date_range: newVal, period: this.paymentPeriod});
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        ...mapActions({
            updateManagersProfit: "home/managersProfit",
        }),
        updateChartData: _.debounce((self, data) => {
            self.loading = true;
            self.updateManagersProfit(data).then((res) => {
                self.option = {
                    ...self.option, 
                    ...{
                        dataset: {
                            source: [
                                [self.$t('message.first_name'), self.$t('message.managers_expected_profit'), self.$t('message.paid_to_managers')],
                                ...self.managersProfit
                            ]
                        }
                    }
                }
                self.loading = false;
            }).catch(err => self.loading = false);
        }, 500),
    }
}
</script>

<style lang="scss" scoped></style>