export const model = {
    id: null,
    name: '',
    filial_ids: [],
    is_default: false,
    active: true,
    begin_date: '',
    end_date: '',
    created_at: '',
    updated_at: '',
  };
  