<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.project')"
      filterable
      clearable
      :size="size"
      class="w-100"
		@change="Cchange"
    >
      <el-option
        v-for="(project, index) in projects"
        :key="'projects' + index"
        :label="project.name"
        :value="project.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
   if (this.projects && this.projects.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      projects: "projects/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "projects/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
	 Cchange(e){
		this.$emit("c-change", e);
      this.selected = e;
	 }
  },
};
</script>
