<template>
    <div class="todo-status h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mini__scuer">
            <div class="sausage-chart__item_body_top text-white mt-2 mb-2">{{ taskCounts.delayed }}</div>
            <h4 class="dashboard-tile__item-title short">
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.overdue_tasks') }}</span>
            </h4>
        </div>

        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="mini__scuer style-scuer">
            <div class="d-flex" style="justify-content: space-between;">
                <div class="sausage-chart__item_body_top text-white mt-2 mb-2">{{ taskCounts.finishedToday }}</div>

                <!-- DELETE COMPONENT FROM DASHBOARD -->
                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can text-right"
                    style="font-size: 18px; color: #dc2626; cursor: pointer; margin-left: 14px;">
                </i>
            </div>
            
            <h4 class="dashboard-tile__item-title short">
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.completed_tasks_for_today') }}</span>
            </h4>
        </div>

        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="mini__scuer style-scuer">
            <div class="sausage-chart__item_body_top text-white mt-2 mb-2">{{ taskCounts.unFinished }}</div>
            <h4 class="dashboard-tile__item-title short">
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.task_to_complete') }}</span>
            </h4>
        </div>

        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class="mini__scuer style-scuer">
            <div class="sausage-chart__item_body_top text-white mt-2 mb-2">{{ taskCounts.finished }}</div>
            <h4 class="dashboard-tile__item-title short">
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.completed_tasks') }}</span>
            </h4>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
    },
    computed: {
        ...mapGetters({
            taskCounts: "home/tasksByCompleted",
        })
    },
}
</script>

<style lang="scss" scoped>
.todo-status {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px
}
</style>