import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],

    filial_ids: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filials')}), trigger: 'change' },
    ],

    inspectors: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.inspectors')}), trigger: 'change' },
    ],

    begin_date: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.begin_date')}), trigger: 'change' },
    ],

    end_date: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.end_date')}), trigger: 'change' },
    ],
};
