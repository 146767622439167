export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    model: state => state.model,
	 notification_model: state => state.notification_model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort, 
    form: state => {
        return {
            id: state.model.id,
            name: state.model.name,
            color: state.model.color,
            filial_id: state.model.filial_id,
            index: state.model.index,
        }
    },
}