export const filter = {
    id : '',  
    meeting_goal: '',
    user_id: '',
    type_id: '',
    notification_type_id: null,
	status_archived: false,
	filial_id: null,
	department_id: null,
	meeting_format: '',
    created_at: '',
    updated_at: ''  
  };
  