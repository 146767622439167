<template>
  <el-select
    :value="selected"
    multiple
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.role')"
    :size="size"
    class="w-100"
    filterable 
    clearable
	 @change="Cchange"
  >
    <el-option
      v-for="(role, index) in roles"
      :key="'role-' + index"
      :label="role.name"
      :value="role.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    role_ids: {
      default: Array,
    },
  },
  watch: {
    role_ids: {
      handler: function () {
        this.selected = this.role_ids;
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
    };
  },

  mounted() {
    if (this.roles && this.roles.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      roles: "roles/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "roles/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
	 Cchange(e){
		this.$emit('c-change', e);
		this.selected = e;
	 }
  },
};
</script>