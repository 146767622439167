import request from '@/utils/request'

export function index(params) {
	return request({
		url: '/managerReport/index',
		method: 'get',
		params: params
	})
}

export function show(id) {
  return request({
      url: `/users/${id}`,
      method: 'get'
  })
}

export function getUserTelegramStatuses(params) {
  return request({
      url: '/users/getUserTelegramStatuses',
      method: 'get',
      params
  })
}

export function checkEmailConnection(params) {
  return request({
      url: '/userEmails/checkEmailConnection',
      method: 'get',
      params: params
  })
}

export function deleteUserEmail(id) {
  return request({
      url: `/userEmails/${id}`,
      method: 'delete',
  })
}

export function userEmailInventory(params) {
  return request({
      url: "/userEmails/inventory",
      method: "get",
      params
  });
}

export function usersDeals(params) {
  return request({
      url: `/users/usersDeals`,
      method: 'get',
      params
  })
}

export function inventory(params) {
  return request({
      url: "/users/inventory",
      method: "get",
      params
  });
}

export function inventoryForFilter(params) {
  return request({
      url: "/users/inventoryForFilter",
      method: "get",
      params
  });
}

export function search(text) {
  return request({
      url: `/users/search/${text}`,
      method: "get",
  });
}

export function store(data) {
  return request({
      url: '/users',
      method: 'post',
      data
  })
}

export function storeSettings(data) {
	return request({
		 url: '/users/storeSettings',
		 method: 'post',
		 data
	})
 }

export function update(data) {
  return request({
      url: `/users/${parseInt(data.get('id'))}`,
      method: 'post',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/users/${id}`,
      method: 'delete',
  })
}

export function validation(data) {
    return request({
        url: '/users/validation',
        method: 'post',
        data
    })
}

export function passwordResetValidation(data) {
  return request({
      url: '/users/passwordResetValidation',
      method: 'post',
      data
  })
}

export function getUserPermissionTypes(params) {
  return request({
      url: "/users/getUserPermissionTypes",
      method: "get",
      params
  });
}

export function checkUserData(params) {
  return request({
      url: '/users/checkUserData',
      method: 'get',
      params
  })
}
