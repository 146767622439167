import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";
import { notification_model } from './properties/notification_model';

export const state = {
    list: [],
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
	 notification_model: JSON.parse(JSON.stringify(notification_model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules   
};
