import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
    filial_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filial')}), trigger: 'change' },
    ],
    token: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.token')}), trigger: 'change' },
    ],
};
