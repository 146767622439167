export const model = {
  id : null,  
  year: '', 
  currency_id: null, 
  filial_id: null, 
  role_id: null, 
  months: [], 
  created_at: '',
  updated_at: ''
  };
  