export const model = {
    id : null,  
    name: '', 
    money_amount: 0, 
    currency_id: null, 
    comment: '', 
    categories: [], 
    created_at: '',
    updated_at: ''
  };
  