import { loginInstagram } from "@/api/social/instagram";

export const actions = {
    loginInstagram({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            loginInstagram(credentials)
				.then(response => {
                    console.log(response, 'response'); 
                    // resolve(response)
                }).catch(error => {
                    console.log(error, 'error'); 
                    // reject(error)
                })
        })
    },
};
