<template>
  <div>
    <el-select :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.package')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(packagee,index) in allowed_packages" :key="'packages-' + index" :label="packagee.name" :value="packagee.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
      allowed_packages:{
        default: Array
      }
    },
    watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      },
    },
    data() {
      return {
        selected: null,
        filtered_pacakages: [],
      }
    },
    // mounted() {
    //   if (this.packages && this.packages.length === 0) this.updateInventory()
    //     .then(res => {
          
    //     })
    // },
    computed: {
      ...mapGetters({
        packages: 'packages/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'packages/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
