export const model = {
    id: null,
	 project_id: null,
	 header: '',
	 content: '',
	 footer: '',
	 type_form: false,
    created_at: '',
    updated_at: '',
  };
  