export const model = {
  id : null,  
  name: '', 
  code: null, 
  vendor_code: '',
  profit: 0, 
  buy_price: 0, 
  currency_rate: null, 
  buy_price_currency_id: '', 
  selling_price: 0, 
  selling_price_currency_id: '', 
  production: null, 
  measurement_id: '', 
  product_model_id: null, 
  color_id: null, 
  is_default: false, 
  categories: [], 
  packages: [], 
  images: [], 
  quantity: 1, 
  feeList: [], 
  child_products: [], 
  names: {
    uz: '',
    ru: '',
    en: '',
    tr: '',
  },
  created_at: '',
  updated_at: ''  
};
  