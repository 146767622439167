<template>
    <div>
        <div id="chart" v-if="reasonsDeals.ids && reasonsDeals.ids.length > 0">
            <v-chart class="e-chart" :option="option" autoresize />
        </div>
        <div v-else class="eror">
            <img src="/img/dashboard.png" alt="Logo" class="d-block" />
            <h1>
                {{ $t("message.no_data_yet") }}
            </h1>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as echarts from 'echarts/core';
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
]);

export default {
    components: {
        VChart
    },
    provide() {
        return {
            [THEME_KEY]: "dark"
        };
    },
    props: {
        period: {
            default: 'weekly'
        },
        filterForm: {
            default: null
        },
        size: {
            default: 423
        }
    },

    data() {
        return {
            deletTask: {
                series: [],
                chartOptions: {
                    chart: {
                        width: 380,
                        type: "pie"
                    },
                    legend: {
                        show: false
                    },
                    responsive: [
                        {
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: "bottom"
                                }
                            }
                        }
                    ]
                }
            },
            option: {
                // title: {
                //     text: 'Referer of a Website',
                //     subtext: 'Fake Data',
                //     left: 'center'
                // },
                backgroundColor: "transparent",
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show: false,
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: this.$t('message.reason'),
                        type: 'pie',
                        radius: '70%',
                        data: [
                            { name: 'asdfsdf', value: 5,  },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        };
    },

    watch: {
        watchAll: {
            handler: async function (val) {
                this.updateChartData({ period: this.period, filter: this.filterForm });
            },
            deep: true,
            immediate: true
        },

    },
    computed: {
        ...mapGetters({
            reasonsDeals: "home/reasonsDeals"
        }),
        watchAll() {
            return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            updateReasonsDeals: "home/reasonsDeals"
        }),

        updateChartData(data) {
            this.updateReasonsDeals(data)
                .then(() => {
                    if (this.reasonsDeals.names && this.reasonsDeals.names.length > 0) {
                        let merged_data = this.reasonsDeals.names.map((name, index) => {
                            return {value: this.reasonsDeals.counts[index], name: name};
                        });
                        this.option = {
                            ...this.option,
                            ...{
                                series: [
                                    {
                                        name: this.$t('message.reason'),
                                        type: 'pie',
                                        radius: '70%',
                                        data: merged_data,
                                        emphasis: {
                                            itemStyle: {
                                                shadowBlur: 10,
                                                shadowOffsetX: 0,
                                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                            }
                                        }
                                    }
                                ]
                            }
                        }
                    }
                });
        },

    }
};
</script>
<style>
.eror {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}

.eror h1 {
    color: #fff;
    font-size: 20px;
}

.eror img {
    width: 160px;
}
</style>
