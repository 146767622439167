<template>
    <div class="khan-chart-a">
        <v-chart class="e-chart" :option="option" autoresize />
    </div>
</template>
<script>
import ApexCharts from "apexcharts";
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';
import { formatMoney } from "@/filters/index";

import * as echarts from 'echarts/core';
import {
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    CanvasRenderer
]);

export default {
    components: {
        ApexCharts, VChart
    },
    provide() {
        return {
            [THEME_KEY]: "dark"
        };
    },
    props: {
        filterForm: {
            default: Object,
        },
    },
    data() {
        return {
            option: {
                backgroundColor: "transparent",
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    show: false,
                    data: []
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        // name: 'Precipitation',
                        min: 0,
                        max: null,
                        // interval: 50,
                        axisLabel: {
                            formatter: '{value} $'
                        }
                    }
                ],
                series: [
                    {
                        name: '',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' ml';
                            }
                        },
                        data: []
                    }
                ]
            }
        };
    },
    watch: {
        filterForm: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateDealPaymentByCountry(val)
                        .then(res => {
                            this.fetchData();
                        })
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.updateDealPaymentByCountry()
            .then(res => {
                this.fetchData();
            })
    },
    computed: {
        ...mapGetters({
            dealPaymentByCountry: "home/dealPaymentByCountry",
        }),
    },
    methods: {
        ...mapActions({
            updateDealPaymentByCountry: "home/dealPaymentByCountry",
        }),
        fetchData() {
            // Echart
            this.option.legend.data.push(this.$t('message.total_amount'))
            this.option.series[0].name = this.$t('message.total_amount')
            this.option.series[0]['data'] = this.dealPaymentByCountry.map(item => item.money_amount)
            this.option.yAxis[0]['max'] = Math.max(...this.dealPaymentByCountry.map(item => item.money_amount))
            this.option.xAxis[0]['data'] = this.dealPaymentByCountry.map(item => item.name)
        }
    }

};
</script>

<style>
/* .eror{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}
.eror h1{
    color: #fff;
    font-size: 20px;
}
.eror img{
  width: 160px;
} */
</style>
