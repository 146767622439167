import {i18n} from '@/utils/i18n';

export const rules = {
    file_name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
	start_time: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.from')}), trigger: 'change' },
    ],
	end_time: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.before')}), trigger: 'change' },
    ],
	description: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.description')}), trigger: 'change' },
    ],
	description: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.description')}), trigger: 'change' },
    ],
};
