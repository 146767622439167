<template>
  <el-row class="button-filter text-right">
    <el-button class="w-33" size="medium"
      :class="[activeType == 'weekly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
      @click="setActive('weekly')"> {{ $t("message.weekly") }}
    </el-button>
    <el-button class="w-33" size="medium"
      :class="[activeType == 'monthly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
      @click="setActive('monthly')">{{ $t("message.monthly") }}
    </el-button>
    <el-button class="w-33" size="medium"
      :class="[activeType == 'yearly' ? 'action' : '', mode ? 'text__day3' : 'text__night3']"
      @click="setActive('yearly')">{{ $t("message.yearly") }}
    </el-button>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: [],
  data: () => ({
    activeType: 'weekly'
  }),
  computed: {
    ...mapGetters({
      mode: "MODE"
    }),
  },
  methods: {
    // isActive (menuItem) {
    //     return this.activeType === menuItem
    // },
    setActive(type) {
      this.activeType = type
      this.$emit('input', type)
    },
  }
}
</script>

<style>
.team__ul {
  display: flex;
  padding: 0;
}

.team__item {
  display: flex;
}

.team__itemnight a {
  color: #fff;
}

.team__itemday {
  border: 1px solid #bdc2c7;
}

.team__itemday:first-child {
  border-radius: 4px 0 0 4px;
  border-right: 0px;
}

.team__itemday:last-child {
  border-radius: 0 4px 4px 0;
  border-left: 0px;
}

.team__itemday a {
  color: #606266;
}

.team__item {
  border: 1px solid #bdc2c7;
}

.team__item:first-child {
  border-radius: 4px 0 0 4px;
  border-right: 0px;
}

.team__item:last-child {
  border-radius: 0 4px 4px 0;
  border-left: 0px;
}

.team__item a {
  font-size: 12.075px;
  font-weight: 600;
  padding: 6px 12px;
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.isActive {
  color: #ffffff;
  background: #00b2ffbf;
  transition: opacity 0.1s ease-out;
}

.isActiveday {
  color: #fff !important;
  background: #00b2ffbf;
  transition: opacity 0.1s ease-out;
}

.button-filter .el-button {
  background: inherit;
  color: #ffffff;
  border-radius: 8px;
  height: 32px !important;
  line-height: 10px !important;
}

/* .el-button {
    border-radius: 10px !important;
} */
.button-filter .action {
  background: #00b2ffbf;
  border-color: #00b2ffbf !important;
  color: #fff !important;
}</style>
