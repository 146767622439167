<template>
  <div :class="mode ? 'l-modal-style' : 'd-modal-style'">
    <div class="app-modal_In">
      <div class="app-modal__header d-flex f-between p-5 m-title-modal">
        <p class="large--title m-0">{{ $t('message.update_deal') }}</p>
        <div>
          <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="checkBeforeSubmit(true)"
            @c-close="close()"
          ></crm-store-update-close>
        </div>
      </div>
    </div>
    <!-- app-modal__header end -->
    <div
      class="step-block-deals-from pr-3"
      ref="showChangeContent"
      v-loading="loadingData"
    >

    <el-steps :active="currentStep" finish-status="success" simple class="mb-4">
      <el-step title="Step 1" ></el-step>
      <el-step title="Step 2" ></el-step>
      <el-step title="Step 3" ></el-step>
    </el-steps>
    
      <el-form ref="form"  class="my-style-label-s" :model="form" :rules="rules" label-position="top">
        
        <div v-show="currentStep === 1" class="step-itme-1">
			<!-- 	v-if="!this.board_id" -->
				<el-row :gutter="10" v-if="selectedItem.client_form_id">
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
						<el-form-item
							:label="$t('message.task_board_id')"
							prop="board_id"
							class="label_mini mb-0"
						>
							<select-board
								:size="'medium'"
								:placeholder="$t('message.task_board_id')"
								:id="form.board_id"
								v-model="form.board_id"
							>
							</select-board>
						</el-form-item>
					</el-col>
            </el-row>
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.filial')"
                prop="filial_id"
                class="label_mini mb-0"
              >
                <select-filial
                  :size="'medium'"
                  :placeholder="columns.filial.title"
                  :id="form.filial_id"
                  :board_id="form.board_id"
                  v-model="form.filial_id"
                >
                </select-filial>
              </el-form-item>
            </el-col>
            
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.name')"
                prop="name"
                class="label_mini mb-0"
              >
                <el-input
                  :placeholder="$t('message.deal')"
                  v-model="form.name"
                  size="medium"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.dealType')" class="label_mini mb-0">
                <select-deal-type
                  :size="'medium'"
                  :placeholder="columns.dealType.title"
                  :id="form.deal_type_id"
                  v-model="form.deal_type_id"
                >
                </select-deal-type>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <select-company
                :size="'medium'"
                :placeholder="columns.company.title"
                :id="form.company_id"
                v-model="form.company_id"
              >
              </select-company>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <select-client
                :size="'medium'"
                :placeholder="columns.client.title"
                :id="form.client_id"
                :company_id="form.company_id"
                :model="getForm"
                v-model="form.client_id"
					      @c-change="checkClientID"
              >
              </select-client>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <crm-phone-input
                  :inputValue="form.phone" 
                  v-model="form.phone"
                  @getPhoneStatus="getPhoneStatus"
                  :form_prop="'phone'"
                  :country_code="form.phone_country_code"
              ></crm-phone-input>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
             <el-form-item :label="$t('message.source')" class="label_mini mb-0">
                <select-source
                  :size="'medium'"
                  :placeholder="columns.source.title"
                  :id="form.source_id"
                  v-model="form.source_id"
                >
                </select-source>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.dealStage')" class="label_mini mb-0">
                <select-deal-stage
                  :size="'medium'"
                  :placeholder="columns.dealStage.title"
                  :id="form.deal_stage_id"
                  v-model="form.deal_stage_id"
                  @stage_id="getStageId"
                >
                </select-deal-stage>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                  :label="$t('message.begin_date')"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.begin_date"
                    type="date"
                    :placeholder="$t('message.begin_date')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.end_date')"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.end_date"
                    type="date"
                    :placeholder="$t('message.end_date')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.exhibition')"
                prop="exhibition_id"
                class="label_mini mb-0"
              >
                <select-exhibition
                  :size="'medium'"
                  :placeholder="$t('message.exhibition')"
                  :id="form.exhibition_id"
                  v-model="form.exhibition_id"
                >
                </select-exhibition>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.comment')" class="label_mini mb-0">
                <el-input
                  type="textarea"
                  autosize
                  :placeholder="$t('message.comment')"
                  v-model="form.comment">
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <div style="margin-bottom: 100px;">
                <div class="text-descr mb-0">
                  <span class="text-descr__label d-block">{{
                    $t("message.responsible")
                  }}</span>
                  <div
                    class="
                      responsible-block
                      d-flex
                      align-center
                      font-medium
                      p-2
                      rounded-sm
                    "
                  >
                    <div
                      class="gc-small-block rounded-circle hidden mr-3"
                      style="border: 1px solid gray"
                    >
                      <img
                        :src="
                          form.user
                            ? (form.user.avatar
                              ? form.user.avatar
                              : '/img/default-user-icon.png')
                            : '/img/default-user-icon.png'
                        "
                        alt="Default Avatar"
                        class="d-block"
                      />
                    </div>
                    <div class="f-fill">
                      <span class="text-descr__text f-fill">{{
                        form.user ? form.user.name : ''
                      }}</span>
                    </div>
                  </div>
                </div>

                <div class="text-descr mb-4 mt-3">
                  <span class="text-descr__label d-block">{{ $t('message.participants') }}</span>
                  <div class="v p-relative ">
                    <div class="responsible-block  d-flex align-center  p-2  font-medium rounded-sm pointer">
                      <div
                        class="gc-small-block rounded-circle hidden mr-3"
                        @click="getSelectedUsers"
                      >
                        <img
                          src="/img/default-user-icon.png"
                          alt="Default Avatar"
                          class="d-block"
                        />
                      </div>
                      <div
                        class="f-fill d-flex align-center"
                        @click="getSelectedUsers"
                      >
                        <span class="text-descr__text f-fill"
                          >{{ $t('message.add_participant') }}</span
                        >
                        <i class="el-icon-plus ml-auto"></i>
                      </div>
                    </div>

                    <div class="responsible-block-dropdown  aer p-relative w-100 rounded p-3">
                      <div class="responsible-block-search">
                        <div class="app-form__group">
                          <div class="responsible-block-input">
                            <input
                              type="text"
                              class="gc-country-input"
                              :placeholder="$t('message.search')"
                              @input="findResponsibleUsers"
                              ref="responsibleUsersInput"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        class="responsible-users-dropdown rounded p-3 mt-2"
                        ref="usersDropdown"
                      >
                        <perfect-scrollbar style="height: 200px">
                          <div
                            v-for="user in usersList"
                            :key="user.id"
                            @click="selectUsers(user)"
                            class="
                              users-dropdown-items
                              d-flex
                              p-2
                              rounded
                              transition
                            "
                          >
                            <div class="user-items-left mr-2">
                              <div
                                class="
                                  user-item-avatar
                                  w-100
                                  h-100
                                  rounded-pill
                                  hidden
                                "
                              >
                                <img
                                  :src="
                                    user
                                      ? (user.avatar
                                        ? user.avatar
                                        : '/img/default-user-icon.png')
                                      : '/img/default-user-icon.png'
                                  "
                                  alt
                                />
                              </div>
                            </div>
                            <div class="user-items-right">
                              <h4 class="my-1">
                                {{ user.name }}
                              </h4>
                              <p class="m-0">
                                {{ user.phone }}
                              </p>
                            </div>
                          </div>
                        </perfect-scrollbar>

                        <div class="custom__button mt-3">
                          <el-button
                            @click="setUsers"
                            type="primary"
                            icon="el-icon-user"
                            size="medium"
                            >{{$t('message.add')}} </el-button
                          >
                        </div>
                      </div>

                      <div
                        v-for="user in selectedUsersList"
                        :key="user.id"
                        class="users-dropdown-items d-flex p-2 rounded transition"
                      >
                        <div class="user-items-left mr-2">
                          <div
                            style="border: 1px solid gray"
                            class="user-item-avatar w-100 h-100 rounded-pill hidden"
                          >
                            <img
                              :src="
                                user
                                  ? (user.avatar
                                    ? user.avatar
                                    : '/img/default-user-icon.png')
                                  : '/img/default-user-icon.png'
                              "
                              alt
                            />
                          </div>
                        </div>
                        <div class="user-items-right">
                          <h4 class="my-1"> {{ user.name }} </h4>
                          <p class="m-0"> {{ user.phone }} </p>
                        </div>
                        <div
                          class="f-fill d-flex align-center"
                          @click="removeFromSelectedUsers(user)"
                        >
                          <i class="el-icon-delete icon__delete ml-auto"></i>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </el-col>
          </el-row>

          <div class="step-btn">
            <el-button
                class="asosy-btn-d"
                @click="nextStep"
                type="primary"
                icon="el-icon-right"
            >
                {{ $t('message.next') }}
            </el-button>
          </div>
        </div>
        <div v-show="currentStep === 2" class="step-itme-1">
          <el-row :gutter="10">
             <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.calculate_money')"
                prop="calculate_money"
                class="label_mini mb-0 ml-5"
              >
                <el-switch
                  style="display: block"
                  class="mt-2"
                  v-model="form.calculate_money"
                  inactive-value="deal"
                  active-value="product"
                  active-color="#6ba2fa"
                  inactive-color="#6ba2fa"
                  :inactive-text="$t('message.deal')"
                  :active-text="$t('message.product')">
                </el-switch>
              </el-form-item>
            </el-col>
            <!-- end col -->
          </el-row>
          
          <el-row v-if="form.calculate_money == 'deal'" :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.money_amount')"
                class="label_mini mb-0"
              >
                <crm-money-input
                  v-model="form.money_amount"
                  :old="form.money_amount"
                  :size="'medium'"
                >
                </crm-money-input>
              </el-form-item>
            </el-col>
            <!-- end col -->

            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.rate')"
                class="label_mini mb-0"
              > 
                <el-input :disabled="isCurrencyActive" :placeholder="$t('message.rate')" v-model="form.currency_rate" size="medium" type="number"></el-input>
              </el-form-item>
            </el-col>
            <!-- end col -->
            
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item
                prop="currency_id" 
                :label="$t('message.currency')"
                class="label_mini mb-0"
              >
                <select-currency
                  :size="'medium'"
                  ref="currencySelect"
                  :placeholder="columns.currency.title"
                  :id="form.currency_id"
                  v-model="form.currency_id"
                >
                </select-currency>
              </el-form-item>
            </el-col>
            <!-- end col -->

            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.profit_in_percents')"
                class="label_mini mb-0"
              >
				  	<crm-only-number-input
						v-model="form.profit"
						:inputValue="form.profit"
						@c-change="checkMinProfit"
					>
					</crm-only-number-input>
              </el-form-item>
            </el-col>
            <!-- end col -->

            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <el-form-item
                :label="$t('message.profit_in_money')"
                class="label_mini mb-0"
              >
                <el-input
                  :placeholder="$t('message.profit_in_money')"
                  size="medium"
                  v-model="calculateProfit"
                  style="font-weight: bold"
                  :disabled="true">
                </el-input>
              </el-form-item>
            </el-col>
            <!-- end col -->

				<el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
              <div class="app-form__group pl-3 f-fill">
                <el-form-item
                  :label="$t('message.profit_user')"
                  class="label_mini"
                >
                  <el-input
                    placeholder="Please input"
                    size="medium"
                    v-model="calculateProfitManager"
                    style="font-weight: bold"
                    :disabled="true">
                  </el-input>
                </el-form-item>
              </div>
            </el-col>
            <!-- end col -->
            
          </el-row>
          <!-- end row -->

          <div v-if="form.calculate_money == 'product'">
            <deal-products
                v-can="'dealProducts.index'"
                ref="dealProducts"
                @listChanged="parent().listChanged()"
                :filial_id="form.filial_id"
                :deal_id="form.id"
					      :min_profit="min_profit"
                :change_in_deal_modal="true"
                v-model="form.products"
                :deal_calculate_money="form.calculate_money"
                :product_calculation_type_id="form.product_calculation_type_id"
                @updateProdsOnClose="updateProdsOnClose"
            ></deal-products>
          </div>

          <el-divider class="mt-4 mb-4"><i class="el-icon-star-on"></i></el-divider>

          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item :label="$t('message.shipmentTerm')" class="label_mini mb-0">
                  <select-shipment-term
                    :size="'medium'"
                    :placeholder="columns.shipmentTerm.title"
                    :id="form.shipment_term_id"
                    v-model="form.shipment_term_id"
                  >
                  </select-shipment-term>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item
                :label="$t('message.reception_address')"
                class="label_mini"
              >
                <el-input
                  :placeholder="$t('message.reception_address')"
                  v-model="form.reception_address"
                  size="medium"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div class="app-form__group mb-0">
            <el-form-item :label="$t('message.files_of_deal_conditions')" class="label_mini">
              <el-upload
                v-model="form.files_of_deal_conditions"
                class="upload-demo"
                action="/"
                :limit="file_limit"
                :accept="fileAccepts"
                :on-exceed="handleExceed"
                :on-change="createPaymentImageList"
                :on-remove="handlePaymentFileRemove"
                :file-list="paymentFileList"
                :auto-upload="false"
              >
                <el-button size="small" type="primary"> {{ $t('message.files_of_deal_conditions') }} </el-button>
              </el-upload>
            </el-form-item>
          </div>

          <el-divider content-position="left">{{ $t('message.dealCondition') }}</el-divider>

          <div class="app-form__group mb-0">
            <el-progress class="mt-3" v-if="totalPaymentPercentage > 0" :stroke-width="13" :percentage="totalPaymentPercentage" :color="customColors"></el-progress>
            
            <div class="app-form__group mb-0 mt-3">
              <div class="input-group-wrap p-3 rounded mb-2"  v-for="(payment, index) in form.deal_payments" :key="index">
                
                <el-form-item :label="$t('message.dealCondition')" class="label_mini mb-0">
                    <select-deal-condition
                      :size="'medium'"
                      :placeholder="columns.condition.title"
                      :id="payment.payment_condition_id"
                      v-model="payment.payment_condition_id"
                    >
                    </select-deal-condition>
                </el-form-item>
                
                <div v-if="payment.payment_condition_id">
                  <div class="form-group-wrap d-flex">
                    <div class="app-form__group f-fill">
                      <div>
                      <el-form-item
                        :label="$t('message.payment_in_percents')"
                        class="label_mini mb-2"
                      >
                        <crm-money-input
                          v-model="payment.percentage"
                          :old="payment.percentage"
                          :size="'medium'"
                        >
                        </crm-money-input>
                      </el-form-item>
                      </div>

                    </div>

                    <div class="app-form__group pl-3 f-fill">
                      <el-form-item
                        :label="$t('message.payment_in_money')"
                        class="label_mini mb-2"
                      >
                        <el-input
                          :placeholder="$t('message.payment_in_money')"
                          :value="calculatePaymentPercentage(payment)"
                          style="font-weight: bold"
                          :disabled="true">
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>

                  <div class="app-form__group mb-0 d-flex">
                    <div class="app-form__group f-fill mr-2">
                      <el-form-item
                        :label="$t('message.payment_start_date')"
                        class="label_mini mb-2"
                      >
                        <el-date-picker
                          class="w-100"
                          size="medium"
                          v-model="payment.begin_date"
                          type="date"
                          :placeholder="$t('message.payment_start_date')"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                        ></el-date-picker>
                      </el-form-item>
                    </div>

                    <div class="app-form__group f-fill ml-2">
                      <el-form-item
                        :label="$t('message.payment_end_date')"
                        class="label_mini mb-2"
                      >
                        <el-date-picker
                          class="w-100"
                          size="medium"
                          v-model="payment.end_date"
                          type="date"
                          :placeholder="$t('message.payment_end_date')"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                        ></el-date-picker>
                      </el-form-item>
                    </div>
                  </div>
                
                  <!-- <el-form-item :label="$t('message.comment')" class="label_mini mb-0">
                    <el-input
                      type="textarea"
                      autosize
                      :placeholder="$t('message.comment')"
                      v-model="payment.comment">
                    </el-input>
                  </el-form-item> -->
                  <el-button v-if="form.deal_payments.length > 1" class="mt-5" size="mini" type="danger" @click="removePayment(index)"><i class="el-icon-delete"></i> удалить платеж</el-button>
                </div>
              </div>
            </div>
            <el-button v-if="paymentPercentage < 100" class="mt-5" size="mini" type="primary" @click="addPayment()"><i class="el-icon-plus"></i> добавить платеж</el-button>
          </div>

          <div class="step-btn">
            <el-button
                class="asosy-btn-y"
                @click="prevStep"
                icon="el-icon-back"
            >
              {{ $t('message.prev') }}

            </el-button>
            <el-button
                class="asosy-btn-d"
                @click="nextStep"
                type="primary"
                icon="el-icon-right"
            >
                {{ $t('message.next') }}
            </el-button>
          </div>
        </div>

        <div v-show="currentStep === 3" class="step-itme-1">
          <el-row :gutter="10">
             <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
              <el-form-item :label="$t('message.file')" class="label_mini mb-4">
                <el-upload
                  v-model="form.files"
                  class="upload-demo"
                  action="/"
                  :limit="file_limit"
                  :accept="fileAccepts"
                  :on-exceed="handleExceed"
                  :on-change="createImageList"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  :auto-upload="false"
                >
                  <el-button size="small" type="primary"> {{ $t('message.loading') }} </el-button>
                </el-upload>
              </el-form-item>
            </el-col>
            <!-- end col -->
          </el-row>
          <!-- end row -->

          <div class="step-btn">
            <el-button
                class="asosy-btn-y"
                @click="prevStep"
                icon="el-icon-back"
            >
                {{ $t('message.prev') }}
            </el-button>
          </div>
        </div>

      </el-form>
    </div>
  </div>
</template>


<script>
import CountryPhoneInput from "@/components/CountryPhoneInput";
import selectFilial from "@/components/inventory/select-filial";
import selectSource from "@/components/inventory/select-source";
import selectDealStage from "@/components/inventory/select-dealStage";
import selectCurrency from "@/components/inventory/select-currency";
import selectCompany from "@/components/selectsWithCreate/select-company-and-create";
import selectClient from "@/components/selectsWithCreate/select-company-client-and-create";
import selectDealType from "@/components/inventory/select-dealType";
import selectDealCondition from "@/components/inventory/select-deal-condition";
import selectShipmentTerm from "@/components/inventory/select-shipment-term";
import selectExhibition from "@/components/inventory/select-exhibition";
import DealProducts from "../dealProduct/deal-products";
import CrmOnlyNumberInput from '../../../../components/crm/crm-only-number-input';
import selectBoard from "@/components/inventory/select-deal-board";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import { formatNumber } from "@/filters/index";

import _ from "lodash";

export default {
  mixins: [form, drawer, show],

  components: {
    CountryPhoneInput,
    selectFilial,
    selectSource,
    selectDealStage,
    selectCompany,
    selectCurrency,
    selectClient,
    selectDealType,
    selectDealCondition,
    selectShipmentTerm,
    selectExhibition,
    DealProducts,
	 CrmOnlyNumberInput,
	 selectBoard
  },
  props: {
    board_id: {
      default: null,
    },
  },
  data() {
    return {
      currentStep: 1,
      fetchDealProducts: false,
      newDeal: false,
      secondLastStageId: null,
      usersList: [],
      firstOpen: false,
      selectedUsersList: [],
      selectedUsersContainer: false,
      clientForm: [],
      fileList: [],
      updateFile: [],
      paymentFileList: [],
      updatePaymentFile: [],
      paymentPercentage: 0,
      isCurrencyActive: false,
      file_limit: 5,
      customColors: [
        {color: '#f56c6c', percentage: 20},
        {color: '#e6a23c', percentage: 40},
        {color: '#6f7ad3', percentage: 60},
        {color: '#1989fa', percentage: 80},
        {color: '#5cb87a', percentage: 100},
      ],
      fileAccepts: "image/png, image/jpeg, application/pdf, .doc,.docx,application/msword,.xls,.xlsx,application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/plain,",
		min_profit: 0,
		client_status: false,
    };
  },
  computed: {
    ...mapGetters({
      getUserSearch: "users/search",
      getInventoryUsers: "users/inventory",
      authUser: "auth/user",
      model: "deals/model",
      getForm: "deals/form",
      rules: "deals/rules",
      columns: "deals/columns",
      getClient: "clients/model",
      mode: "MODE",
		type_rules: 'managerTypes/type_rules'
    }),
    calculateProfit() {
      let currency_symbol = ''
      if(this.$refs['currencySelect']){
        currency_symbol = this.$refs['currencySelect'].currency_symbol;
      }
      return  formatNumber((parseFloat(this.form.money_amount) * parseFloat(this.form.profit) / 100), 1) + ' ' + currency_symbol;
    },
	 calculateProfitManager() {
      let currency_symbol = '';
		let self = this;
		let total = 0;

		if(!_.isEmpty(this.type_rules)){
			if (this.$refs['currencySelect']) currency_symbol = this.$refs['currencySelect'].currency_symbol;
			if (this.form.profit) {
				let profit = _.find(this.type_rules, rule => rule.from <= this.form.profit && rule.until >= this.form.profit && rule.first_deal == this.client_status);
        let bonus = profit ? profit.bonus : 0;
				total = formatNumber((parseFloat(this.form.money_amount) * parseFloat(this.form.profit) * parseFloat(bonus) / 10000), 1) + ' ' + currency_symbol;
			} 
		}

		return total;
    },
    totalPaymentPercentage(){
      if(this.form.deal_payments.length > 0){
        this.paymentPercentage = this.form.deal_payments.map(item => parseFloat(item.percentage)).reduce((prev, next) => prev + next);
      }
      if(this.paymentPercentage > 100){
        this.$message({
          type: "warning",
          message: this.$t('message.Payment cannot be more than 100%')
        });        
        this.paymentPercentage = 100;
      }
      return Math.round(this.paymentPercentage);
    },
    steps() {
        return [
            {
                step: 1,
                stepText: "Qadam 1",
            },
            {
                step: 2,
                stepText: "Qadam 2",
            },
            {
                step: 3,
                stepText: "Qadam 3",
            },
        ];
    },
  },
  watch: {
    "form.client_id": function (val) {
      if (this.firstOpen === true) {
        this.firstOpen = false;
      } else {
        if (val) {
          this.showClient(val).then(() => {
            this.clientForm = JSON.parse(JSON.stringify(this.getClient));
            this.form.phone_country_code = this.clientForm.phone_country_code;
            this.form.phone = this.clientForm.phone;
            this.form.email = this.clientForm.email;
          });
        } else {
          this.form.phone_country_code = 'UZ';
          this.form.email = "";
          this.form.phone = "";
        }
      }
    },
    'form.profit': {
        handler: async function(newVal, oldVal) {
            if(newVal > 100){
              this.form.profit = 100;
            }
        },
        deep: true,
        immediate: true
    },

    "form.currency_id": {
        handler: function(newVal, oldVal) {
          setTimeout(() => {
            if(this.$refs['currencySelect'] && this.$refs['currencySelect'].currency_selected){
              this.isCurrencyActive = this.$refs['currencySelect'].currency_selected.active
            }
            if(newVal && (!this.getForm.currency_rate || newVal != this.getForm.currency_id) && this.$refs['currencySelect'] && this.$refs['currencySelect'].currency_selected && this.$refs['currencySelect'].currency_selected.rate){
              this.form.currency_rate = this.$refs['currencySelect'].currency_selected.rate;
            }
            if((newVal === this.getForm.currency_id) && this.getForm.currency_rate){
              this.form.currency_rate = JSON.parse(JSON.stringify(this.getForm.currency_rate))
            }
          }, 200);
        },
        deep: true,
        immediate: true
    },
    
  },
  methods: {
	   ...mapActions({
			save: "deals/update",
			show: "deals/show",
			showInventoryUsers: "users/inventory",
			showClient: "clients/show",
			search: "users/search",
			empty: "deals/empty",
			deleteFile: "deals/deleteFile",
			getManagerTypeRules: "managerTypes/getManagerTypeRules",
			checkClientIsNew: "deals/checkClientIsNew"
    	}),
	checkMinProfit(profit){
		if ((parseFloat(profit) < this.min_profit) || !profit){
			this.form.profit = this.min_profit;
		}
	 },
	  checkClientID(client_id){
		if (client_id){
			this.checkClientIsNew(client_id).then(res => this.client_status = res.client_status);
		}
	 },
    setStep(step) {
        this.currentStep = step;
    },
    prevStep() {
        if (this.currentStep !== 1) {
            this.currentStep--;
        }
    },
    nextStep() {
        if (this.steps.length > this.currentStep) {
            this.currentStep++;
        }
    },
    getPhoneStatus({is_valid, countryCode}){
        this.is_phone_valid = is_valid;
        this.form.phone_country_code = countryCode;
    },
    updateProdsOnClose(){
      this.fetchDealProducts = true;
    },
    afterOpen() {
      this.fetchData();
		  this.getManagerTypeRules(this.authUser.id);
    },
    fetchData() {
      if (!this.loadingData) {
        this.loadingData = true;
        if(this.$refs.dealProducts){
          this.$refs.dealProducts.updateList();
        }
        this.show(this.selectedItem.id)
          .then((res) => {
            this.form = JSON.parse(JSON.stringify(this.getForm));
				    this.min_profit = res.deal.min_profit;
				    if (this.form.profit < this.min_profit) this.form.profit = this.min_profit;
            this.firstOpen = true;
            this.fileList = this.form.files;
            this.updateFile = this.form.files;
            this.paymentFileList = this.form.paymentFiles;
            this.updatePaymentFile = this.form.paymentFiles;
            this.selectedUsersList = JSON.parse(JSON.stringify(this.form.spectators));
            this.showClient(this.form.client_id).then(() => {
              this.clientForm = JSON.parse(JSON.stringify(this.getClient));
            });
            this.setUsers();
            this.$refs["phoneInput"].setPhoneNumber(this.form.phone);
            this.loadingData = false;
            if(this.form.deal_payments.length === 0){
              this.addPayment()
            }
          })
          .catch((err) => {
            this.loadingData = false;
          });
      } 
    },

    addPayment(){
      if(this.form.deal_payments.filter(item => item.percentage === 0).length > 0){
        this.$message({
          type: "warning",
          message: this.$t('message.One of the payments is 0, please enter the percentage for the existing payment first')
        }); 
      }else{
        let payment = {
          id: null,
          percentage: 0,
          begin_date: '',
          end_date: '',
          comment: '',
          payment_condition_id: null
        }
        this.form.deal_payments.push(payment);
      }
      
    },
    removePayment(index){
       this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
            this.form.deal_payments.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
    },
    calculatePaymentPercentage(payment) {
      let currency_symbol = ''
      if(this.$refs['currencySelect']){
        currency_symbol = this.$refs['currencySelect'].currency_symbol;
      }
      return  (this.form.money_amount * payment.percentage / 100).toFixed(2) + ' ' + currency_symbol;
    },


    getStageId(val){
      this.secondLastStageId = val;
    },
    checkBeforeSubmit(close = true){
      let money = this.form.calculate_money === 'deal' ? this.form.money_amount : this.getForm.product_total;
      if(this.getForm.calculate_money !== this.form.calculate_money && this.getForm.paid_money > 0 && this.getForm.paid_money > money){
        this.$confirm(
          this.$t('message.paid_money_will_be_changed_are_you_sure'),
          this.$t('message.warning'),
          {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning",
          }
        )
        .then(() => {
          this.submit(close);
        })
        .catch(() => {
        })
      }else{
        this.submit(close);
      }
    },
    submit(close = true) {
      if(this.form.products && this.form.products.filter(el => el.price <= 0).length > 0){
        this.$notify({
            title: this.$t('message.warning'),
            type: "warning",
            offset: 130,
            message: this.$t('message.set_product_price'),
        });
      }else{
        if(!this.form.user_id){
          this.form.user_id = this.authUser.id;
        }
        if(this.form.deal_payments.length > 0 && this.form.deal_payments.map(item => item.percentage).reduce((prev, next) => prev + next) > 100){
          this.$message({
            type: "warning",
            message: this.$t('message.Payment cannot be more than 100%, please correct before saving')
          });   
        }else{
          let formData = new FormData();
          
          for (const key in this.form) {
            if (this.form[key]) {
              if(key != 'deal_payments' && key != 'products'){
                formData.append(key, this.form[key]);
              }else{
                formData.append(key, JSON.stringify(this.form[key]));
              }
            } else {
              formData.append(key, "");
            }
          }

          if (_.size(this.updateFile) > 0) {
            for (const key in this.updateFile) {
              if (this.updateFile.hasOwnProperty(key)) {
                const element = this.updateFile[key];
                formData.append(`files[${key}]`, element);
              }
            }
          }
          
          if (_.size(this.updatePaymentFile) > 0) {
            for (const key in this.updatePaymentFile) {
              if (this.updatePaymentFile.hasOwnProperty(key)) {
                const element = this.updatePaymentFile[key];
                formData.append(`paymentFiles[${key}]`, element);
              }
            }
          }

          formData.append('_method', 'PUT');
          this.$refs["form"].validate((valid) => {
            if (valid) {
              this.loadingButton = true;
              this.save(formData)
                .then((res) => {
                  this.loadingButton = false;
                  this.$alert(res);
                  this.parent().listChanged();
                  this.parent().fetchData(this.form.id);
                  if (close) this.close();
                })
                .catch((err) => {
                  this.loadingButton = false;
                  this.$alert(err);
                });
            }
          });
        }
      }
    },
    findResponsibleUsers() {
      const { usersDropdown } = this.$refs;
      let value = event.target.value;
      if (value) {
        this.searchUsers(value, this);
      }

      if (value) {
        usersDropdown.classList.add("active");
      } else {
        usersDropdown.classList.remove("active");
      }
    },
    searchUsers: _.debounce((search, self) => {
      if (_.isFunction(self.search)) {
        self
          .search(search)
          .then((res) => {
            self.usersList = JSON.parse(JSON.stringify(self.getUserSearch.filter(item => item.id !== self.form.user_id)));
          })
          .catch((err) => {});
      }
    }, 1000),
    getSelectedUsers() {
      const { usersDropdown } = this.$refs;
      usersDropdown.classList.toggle("active");
      if (usersDropdown.classList.contains("active")) {
        this.showInventoryUsers().then(() => {
          this.usersList = JSON.parse(JSON.stringify(this.getInventoryUsers.filter(item => item.id !== this.form.user_id)));
        });
      }
    },
    selectUsers(user) {
      let $el = event.target.closest(".users-dropdown-items");
      if (!this.selectedUsersList.some((person) => person.id === user.id)) {
        this.selectedUsersList.push(user);
        $el.classList.add("active");
      } else {
        this.selectedUsersList = this.selectedUsersList.filter(
          (item) => item.id !== user.id
        );
        $el.classList.remove("active");
      }
    },
    setUsers() {
      this.form.spectators = this.selectedUsersList.map(item => item.id);
      const { usersDropdown, responsibleUsersInput } = this.$refs;

      this.selectedUsersContainer = true;
      usersDropdown.classList.remove("active");
      responsibleUsersInput.value = "";
    },
    removeFromSelectedUsers(user) {
      this.selectUsers(user);
      this.form.spectators = this.selectedUsersList.map(item => item.id);
    },
    afterLeave() {
      if(this.fetchDealProducts){
        this.parent().fetchDealProducts(this.form.id);
      }
      this.fetchDealProducts = false;
      this.empty();
      this.currentStep = 1;
      this.usersList = [];
      this.clientForm = [];
      this.selectedUsersList = [];
		  this.min_profit = 0;
    },
    
    handleExceed(files, fileList) {
      this.$message.warning(this.$t('message.file_limit_warning', {number: this.file_limit}));
    },
    handleRemove(file, fileList){
      let file_index = '';
      this.updateFile.forEach((element, index) => {
        if(element.name == file.name ){
          file_index = index;
        }
      });
      if(file.id){
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          this.deleteFile({deal_id: this.selectedItem.id, file: file.name, folder: 'dealFiles'})
            .then(res => {
              file_index ?  this.updateFile.splice(file_index, 1) : '';
            }).catch(err => {
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
        })
        .catch(() => {
          fileList.splice(file_index, 0, file);
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
      }else{
        file_index ?  this.updateFile.splice(file_index, 1) : '';
      }
      
    },
    createImageList(file, fileList) {
      if (fileList.length > this.file_limit) {
        fileList.pop();
        return this.$message({
          type: "warning",
          message: this.$t('message.file_limit_warning', {number: this.file_limit}),
        });
      }else if (file.size > 5000000) {
        fileList.pop();
        return this.$message({
          type: "warning",
          message: this.$t('message.file_size_limit_warning', {number: 5}),
        });
      }else if(this.updateFile.some(item => item.name == file.name)) {
        this.updateFile.forEach((element, index) => {
          if(element.name == file.name){
            fileList.pop();
            return this.$message({
              type: "warning",
              message: this.$t('message.file_already_exists'),
            });
          } 
        });
      }else{
        this.updateFile.push(file.raw);
      }
    },

    handlePaymentFileRemove(file, fileList){
      let file_index = '';
      this.updatePaymentFile.forEach((element, index) => {
        if(element.name == file.name){
          file_index = index;
        }
      });
      if(file.id){
        this.$confirm(
          this.$t('message.do_you_really_want_to_do_this'),
          this.$t('message.warning'), {
            confirmButtonText: this.$t('message.yes'),
            cancelButtonText: this.$t('message.no'),
            type: "warning"
          }
        )
        .then(() => {
          this.deleteFile({deal_id: this.selectedItem.id, file: file.name, folder: 'dealPaymentFiles'})
            .then(res => {
              file_index ? this.updatePaymentFile.splice(file_index, 1) : '';
            }).catch(err => {
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
        })
        .catch(() => {
          fileList.splice(file_index, 0, file);
          this.$message({
            type: "warning",
            message: this.$t('message.operation_canceled')
          });
        });
      }else{
        file_index ? this.updatePaymentFile.splice(file_index, 1) : '';
      }
    },
    createPaymentImageList(file, fileList) {
      if (fileList.length > this.file_limit) {
        fileList.pop();
        return this.$message({
          type: "warning",
          message: this.$t('message.file_limit_warning', {number: this.file_limit}),
        });
      }else if (file.size > 5000000) {
        fileList.pop();
        return this.$message({
          type: "warning",
          message: this.$t('message.file_size_limit_warning', {number: 5}),
        });
      }else if(this.updatePaymentFile.some(item => item.name == file.name)) {
        this.updatePaymentFile.forEach((element, index) => {
          if(element.name == file.name){
            fileList.pop();
            return this.$message({
              type: "warning",
              message: this.$t('message.file_already_exists'),
            });
          } 
        });
      }else{
        this.updatePaymentFile.push(file.raw);
      }       
    },
  },
};
</script>


<style>
.el-form--label-top .el-form-item__label {
    margin: 0px !important;
    padding: 0px !important;
}
</style>
