export const getters = {
    list: state => state.list,
    inventory: state => state.inventory,
    filialProducts: state => state.filialProducts,
    model: state => state.model,
    rules: state => state.rules,
    columns: state => state.columns,
    filter: state => state.filter,
    pagination: state => state.pagination,
    sort: state => state.sort, 
    images_list: state => state.images_list 
  };
  