export default [
  {
    path: '/settings-category',
    name: 'settingsCategory',
    meta: {
      layout: 'main',
      title: 'Настройки'
    },
    
    component: () => import('@/views/settingsCategory/index'),
  },
  {
    path: '',
    name: 'settingsCategorySlug',
    meta: {
      layout: 'main',
      
    },
    component: () => import('@/views/settingsCategory/_slug'),
    children:[
      {
        path: '/systeamNotification',
        name: 'systeamNotification',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Системные уведомлени... '
        },
        component: () => import('@/views/systeamNotification/index')
      },
      {
        path: '/notificationTypes',
        name: 'notificationTypes',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Типы уведомлений '
        },
        component: () => import('@/views/notificationTypes/index')
      },
      {
        path: '/kpTemplates',
        name: 'kpTemplate',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Шаблоны КП '
        },
        component: () => import('@/views/kpTemplates/index')
      },
      {
        path: '/dealStage',
        name: 'dealStage',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Стадия '
        },
        component: () => import('@/views/dealStage/index')
      },
      {
        path: '/paymentTypes',
        name: 'paymentTypes',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Тип оплаты '
        },
        component: () => import('@/views/paymentTypes/index')
      },
      {
        path: '/sources',
        name: 'sources',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Источники '
        },
        component: () => import('@/views/sources/index')
      },
      {
        path: '/companies',
        name: 'companies',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Компании клиентов '
        },
        component: () => import('@/views/companies/index')
      },
      {
        path: '/filial',
        name: 'filial',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Компания пользовател... '
        },
        component: () => import('@/views/filial/index')
      },
      {
        path: '/country',
        name: 'country',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Страны '
        },
        component: () => import('@/views/country/index')
      },
      {
        path: '/city',
        name: 'city',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Города '
        },
        component: () => import('@/views/city/index')
      },
      {
        path: '/category',
        name: 'category',
        meta: {
          layout: 'main',
          category:'categoryA',
          title: ' Категории '
        },
        component: () => import('@/views/category/index')
      },

      {
        path: '/clientTypes',
        name: 'clientTypes',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: ' Типы клиентов '
        },
        component: () => import('@/views/clientTypes/index')
      },
      {
        path: '/dealGoals',
        name: 'dealGoals',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: ' Цели сделок '
        },
        component: () => import('@/views/dealGoals/index')
      },
      {
        path: '/dealConditions',
        name: 'dealConditions',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: ' Условия оплат '
        },
        component: () => import('@/views/dealConditions/index')
      },
      {
        path: '/shipmentTerms',
        name: 'shipmentTerms',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: ' Условия поставок '
        },
        component: () => import('@/views/shipmentTerms/index')
      },
      {
        path: '/colors',
        name: 'colors',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: ' Цвета '
        },
        component: () => import('@/views/colors/index')
      },
      {
        path: '/packages',
        name: 'packages',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: ' Упаковки '
        },
        component: () => import('@/views/packages/index')
      },
      {
        path: '/typeOfProjectWorks',
        name: 'typeOfProjectWorks',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: ' Тип проектной работы '
        },
        component: () => import('@/views/typeOfProjectWorks/index')
      },
      {
        path: '/managerTypes',
        name: 'managerTypes',
        meta: {
          layout: 'main',
          category:'categoryB',
          title: 'Типы менеджеров'
        },
        component: () => import('@/views/managerTypes/index')
      }
    ]
  }
]
  