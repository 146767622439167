import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    sending_type : {
        show: true,
        title: i18n.t('message.sending_type'),
        sortable: true,
        column: 'sending_type'
    },
    message_receivers : {
        show: true,
        title: i18n.t('message.message_receivers'),
        sortable: true,
        column: 'message_receivers'
    },
    sending_date : {
        show: true,
        title: i18n.t('message.sending_date'),
        sortable: true,
        column: 'sending_date'
    },
    // companies : {
    //     show: true,
    //     title: i18n.t('message.companies'),
    //     sortable: true,
    //     column: 'companies'
    // },
    // clients : {
    //     show: true,
    //     title: i18n.t('message.clients'),
    //     sortable: true,
    //     column: 'clients'
    // },

    // departments : {
    //     show: true,
    //     title: i18n.t('message.departments'),
    //     sortable: true,
    //     column: 'departments'
    // },
    // users : {
    //     show: true,
    //     title: i18n.t('message.users'),
    //     sortable: true,
    //     column: 'users'
    // },
    message : {
        show: true,
        title: i18n.t('message.message'),
        sortable: true,
        column: 'message'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
