import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/fileProtocol',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/fileProtocol',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/fileProtocol/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/fileProtocol/${id}`,
        method: 'get',
    })
}
export function update(data) {
    return request({
        url: `/fileProtocol/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/fileProtocol/${id}`,
        method: 'delete',
    })
}
