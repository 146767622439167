import {i18n} from '@/utils/i18n';

export const rules = {
    name: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.name')}), trigger: 'change' },
    ],
	 leader_ids: [
		{ required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.project_manager')}), trigger: 'change' },
  	],
	  filial_ids: [
		{ required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.filials')}), trigger: 'change' },
  	],
};
