export const model = {
    id: null,
	 type_id: null,
	 user_ids: [],
	 text: '',
	 activity_time: '',
	 notificationable_type: null,
	 notificationable_id: null,
	 filials: [],
	 role_ids: [],
    created_at: '',
    updated_at: '',
  };
  