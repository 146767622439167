<template>
  <el-select
    :value="selected"
    multiple
    :collapse-tags="collapse_tags"
    @input="dispatch"
    :placeholder="placeholder || $t('message.filial')"
    :size="size"
    class="w-100"
    filterable 
    clearable
	 @change="Cchange"
  >
    <el-option
      v-for="(filial, index) in filials"
      :key="'filial-' + index"
      :label="filial.name"
      :value="filial.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    filial_ids: {
      default: Array,
    },
	 collapse_tags: {
		default: null
	 }
  },
  watch: {
    filial_ids: {
      handler: function () {
        this.selected = this.filial_ids;
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
    };
  },

  mounted() {
    if (this.filials && this.filials.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      filials: "filials/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "filials/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
	 Cchange(e){
		this.$emit('c-change', e);
		this.selected = e;
	 }
  },
};
</script>