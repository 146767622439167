export const model = {
  id : null,  
  company: null, 
  company_id: null,
  full_name: '', 
  date_of_birth: '', 
  passport_serial_number: '', 
  type: null, 
  phone: '', 
  phone_country_code: 'UZ',
  email: '', 
  passport_validity: '', 
  actual_address: '', 
  inn: '', 
  avarage_volume: '',
  gender: null, 
  gender_id: null, 
  country_id: null, 
  city_id: null, 
  exhibition_ids: [], 
  clientTypes: [], 
  clientType_ids: [],
  has_client: true,
  has_provider: false, 
  created_at: '',
  updated_at: ''  
};
  