export const model = {
    id: null,
    name: '', 
    user_id: null, 
    country_id: null, 
    city_id: null, 
    status: null,
    main_type: null,
    date: [],
    comment: '', 
    participants: [], 
    products: [], 
    clients: [], 
    costTransactions: [],
	 notify: false,
	 notification_type_id: null,
	 notification_time: '', 
    created_at: '',
    updated_at: ''  
  };
  