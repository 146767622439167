export const filter = {
    id : null,  
    user_id : null,  
    name_title_id: null, 
    name: '', 
    surname: '', 
    patronymic: '', 
    date_of_birth: '', 
    company_id: null, 
    position: '', 
    phone_number: '', 
    email: '', 
    website: '', 
    contact_type_id: null, 
    source_id: null, 
    created_at: '',
    updated_at: ''  
  };
  