import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/calendarNotifications',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/calendarNotifications',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/calendarNotifications/inventory",
        method: "get",
        params
    });
}
export function getAllFullNotificationList(params) {
	return request({
		 url: "/calendarNotifications/getAllFullNotificationList",
		 method: "get",
		 params
	});
}
export function show(id) {
    return request({
        url: `/calendarNotifications/${id}`,
        method: 'get'
    })
}

export function update(data) {
    return request({
        url: `/calendarNotifications/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/calendarNotifications/${id}`,
        method: 'delete'
    })
}

export function dailyNotifications(params) {
    return request({
        url: '/calendarNotifications/dailyNotifications',
        method: 'get',
        params
    })
}