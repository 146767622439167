<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">
            {{
              $t("message.new_m", {
                m: $t("message.client"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0 mt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.full_name')"
                  prop="full_name"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.full_name"
                    v-model="form.full_name"
                    :placeholder="$t('message.full_name')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.company')"
                  prop="company"
                  class="label_mini"
                >
                  <select-company
                    :size="'medium'"
                    :placeholder="columns.company_id.title"
                    :id="form.company_id"
                    v-model="form.company_id"
                  >
                  </select-company>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.date_of_birth')"
                  prop="date_of_birth"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.date_of_birth"
                    type="date"
                    :placeholder="$t('message.date_of_birth')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.passport_serial_number')"
                  prop="passport_serial_number"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.passport_serial_number"
                    v-model="form.passport_serial_number"
                    :placeholder="$t('message.passport_serial_number')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.type')"
                  prop="type_id"
                  class="label_mini"
                >
                  <select-client-type
                    :size="'medium'"
                    :placeholder="columns.type_id.title"
                    :id="form.type_id"
                    v-model="form.type_id"
                  >
                  </select-client-type>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <crm-phone-input
                    :inputValue="form.phone" 
                    v-model="form.phone"
                    @getPhoneStatus="getPhoneStatus"
                    :form_prop="'phone'"
                    :country_code="form.phone_country_code"
                ></crm-phone-input>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.passport_validity')"
                  prop="passport_validity"
                  class="label_mini"
                >
                  <el-date-picker
                    class="w-100"
                    size="medium"
                    v-model="form.passport_validity"
                    type="date"
                    :placeholder="$t('message.passport_validity')"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.actual_address')"
                  prop="actual_address"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.actual_address"
                    v-model="form.actual_address"
                    :placeholder="$t('message.actual_address')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.email')"
                  prop="email"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.email"
                    v-model="form.email"
                    :placeholder="$t('message.email')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.inn')"
                  prop="inn"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.inn"
                    v-model="form.inn"
                    :placeholder="$t('message.inn')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.gender')"
                  prop="gender"
                  class="label_mini"
                >
                  <select-client-gender
                    :size="'medium'"
                    :placeholder="columns.gender_id.title"
                    :id="form.gender_id"
                    v-model="form.gender_id"
                  >
                  </select-client-gender>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.clientTypes')"
                  class="label_mini"
                >
                  <select-client-types
                    ref="clientTypes"
                    v-model="form.clientType_ids"
                    :clientType_ids="form.clientType_ids"
                    :size="'medium'"
                    :placeholder="$t('message.clientTypes')"
                  >
                  </select-client-types>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.country_id')"
                  class="label_mini"
                >
                  <select-country
                    :size="'medium'"
                    :placeholder="columns.country_id.title"
                    :id="form.country_id"
                    v-model="form.country_id"
                  >
                  </select-country>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.city_id')"
                  class="label_mini"
                >
                   <select-city
                    :size="'medium'"
                    :placeholder="columns.city_id.title"
                    :id="form.city_id"
                    :country_id="form.country_id"
                    v-model="form.city_id"
                  >
                  </select-city>
                </el-form-item>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.exhibition')"
                  class="label_mini"
                >
                  <select-exhibition
                    :size="'medium'"
                    :placeholder="$t('message.exhibition')"
                    :multiple_select="true"
                    :id="form.exhibition_ids"
                    v-model="form.exhibition_ids"
                  >
                  </select-exhibition>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.avarage_volume')"
                  class="label_mini"
                >
                  <crm-input
                    :size="'medium'"
                    :inputValue="form.avarage_volume"
                    v-model="form.avarage_volume"
                    type="number"
                    :placeholder="$t('message.avarage_volume')"
                  ></crm-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

               <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                  <el-form-item
                    :label="$t('message.measurement_id')"
                    class="label_mini"
                    prop="measurement_id"
                  >
                    <select-measurement
                      :size="'medium'"
                      :id="form.measurement_id"
                      v-model="form.measurement_id"
                    >
                    </select-measurement>
                  </el-form-item>
                </el-col>

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.status')">
                  <el-checkbox v-model="form.has_client" :label="$t('message.client')" border></el-checkbox>
                  <el-checkbox v-model="form.has_provider" :label="$t('message.provider')" border></el-checkbox>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-upload
                      class="avatar-uploader"
                      :show-file-list="false"
                      action="/"
                      :on-change="updateImageList"
                      list-type="picture-card"
                      accept="image/jpeg, image/png"
                      :auto-upload="false"
                    >
                      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                      <i v-else class="el-icon-plus"></i>
                </el-upload>
              </el-col>

            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->

      <contact-people ref="contactPeople"></contact-people>
      
      <client-account ref="clientAccount"></client-account>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import clients from "@/utils/mixins/models/clients";

export default {
	props: {
		company_id: {
			default: null,
		},
	},
  	mixins: [form, drawer, clients],
	methods: {
		...mapActions({
			save: "clients/store",
			updateInventory: "clients/inventory",
			updatePersonClients: 'clients/personClients',
			companyClients: "clients/companyClients",
		}),
		afterOpen(){
			if (this.model != null && this.exhibition_id && this.exhibition_id !== NaN && this.exhibition_id !== undefined) {           
			this.form.exhibition_ids = [parseInt(this.exhibition_id)];
			}
			this.$set(this.form, 'company_id', this.company_id); 
		}
	},
};
</script>
