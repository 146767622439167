<template>
    <div class="task-home-two-right h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer  h__full" v-loading="loadingUsersDeals">
            <div class="mb-5">
                <div class="filterblock_s">
                    <h4 class="dashboard-tile__item-title short">
                        <span class="dashboard-tile__item-title-txt text-white">
                            {{ $t('message.deals_by_managers') }}
                        </span>
                    </h4>
                    <div class="filter_home flex-center">
                        <el-radio-group @change="($emit('vmodel', filterByDate))" v-model="filterByDate" size="medium">
                            <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                            <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                            <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                        </el-radio-group>
                        <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                            style="font-size: 18px; color: #dc2626; cursor: pointer;">
                        </i>
                    </div>
                </div>
                <div v-if="usersDeals.deals_count && usersDeals.deals_count > 0" class="d-flex f-between align-center">
                    <div class="sausage-chart__item_body_top text-white mt-2 mb-2">
                        {{ usersDeals.deals_count }}
                    </div>
                    <div>
                        <div class="dashboard-tile__item-bottom-small-count js-resize-txt mt-0">
                            {{ usersDeals.deal_money_amount | formatMoney }}
                        </div>
                        <div class="dashboard-tile__item-bottom-subcaption mt-0">
                            {{ $t("message.per") }}
                            {{ filterByDate === "weekly" ? $t("message.weekly") : "" }}
                            {{ filterByDate === "monthly" ? $t("message.monthly") : "" }}
                            {{ filterByDate === "yearly" ? $t("message.yearly") : "" }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="usersDeals.users && usersDeals.users.length > 0" class="task-scroll-khan">
                <div class="itme-task-menjra mb-4 progress-color-lin" v-for="user in usersDeals.users" :key="user.id">
                    <span class="input--label d-block">
                        {{ user.name }}
                        <span style="margin-left: 10px"></span>
                        - {{ user.money_amount | formatMoney }}
                        <span style="margin-left: 10px"></span>
                        - ( {{ user.profit | formatMoney }} )
                    </span>
                    <el-progress :percentage="user.percentage"></el-progress>
                </div>
            </div>
            <div v-else class="eror">
                <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                <h1>
                    {{ $t('message.no_data_yet') }}
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import { formatMoney } from "@/filters/index";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {},
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        allPeriodChange: {
            type: String,
            default: () => "weekly"
        },
        filterForm: {
            type: Object,
            default: () => Object
        },
    },
    data() {
        return {
            filterByDate: 'weekly',
            loadingUsersDeals: false,
        }
    },
    watch: {
        allPeriodChange(val) {
            this.filterByDate = val;
        },
        filterByDate: {
            handler: async function(newVal, oldVal) {
                this.loadingUsersDeals = true;
                await this.debouncedUpdateUsersDeals({period: newVal, filter: this.filterForm}, this);
            },
            deep: true,
            immediate: true
        },
        filterForm: {
            handler: async function(newVal, oldVal) {
                this.loadingUsersDeals = true;
                await this.debouncedUpdateUsersDeals({period: this.filterByDate, filter: this.filterForm}, this);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        ...mapGetters({
            usersDeals: "home/usersDeals",
        }),
    },
    methods: {
        ...mapActions({
            updateUsersDeals: "home/usersDeals",
        }),
        debouncedUpdateUsersDeals: _.debounce((filter, self) => {
            if (_.isFunction(self.updateUsersDeals)) {
                self.updateUsersDeals(filter)
                    .then((res) => {
                        self.loadingUsersDeals = false;
                    })
                    .catch((err) => {
                        self.loadingUsersDeals = false;
                    });;
            }
        }, 500),
    }
}
</script>

<style lang="scss" scoped></style>