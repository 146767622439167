<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="mb-5">
            <div class="filterblock_s">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt text-white">
                        Ожидаемые дни рождения
                    </span>
                </h4>
            </div>
        </div>

        <div class="itme-task-menjra mb-4 progress-color-lin">
            <div class="c-titme mt-3" v-for="item in clients_birth_day" :key="'client-'+item.id">
                <div class="flex-1 d-flex">
                    <div class="birthday-user--img">
                        <img :src="item.avatar[0] ? item.image_url + '/' + item.avatar[0].path : '/img/avatar.jpeg'" alt="" />
                    </div>
                    <div class="c-Info">
                        <div class="c-Info-name">{{ item.full_name }}</div>
                        <div class="c-Info-company">{{ item.company ? item.company.name : '' }}</div>
                        <div class="c-Info_type">{{ item.type ? item.type.name : '' }}</div>
                    </div>
                </div>
                <div>
                    <div class="data-birthday"><img class="mr-3" src="/img/confetti.svg" alt=""> {{ item.date_of_birth }}
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { env } from 'echarts/core'
export default {
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        clients_birth_day: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
			 appUrl: process.env.VUE_APP_BASE_URL
        }
    },
}
</script>

<style lang="scss">
.data-birthday img {
    width: 38px;
    transform: rotate(-93deg);
}

.data-birthday,
.c-titme {
    display: flex;
    align-items: center;
}

.c-Info-name {
    color: #323248;
    font-size: 15px;
    display: block;

}

.c-Info_type,
.c-Info-company {
    font-size: 12px;
    color: #92929f;
    margin-top: 4px;
    display: block;
}

.d-modal-style.c-Info-name,
.d-modal-style.c-Info-company,
.d-modal-style.c-Info_type {
    color: #fff;
}

.birthday-user--img img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    margin-right: 13px;
    -o-object-fit: cover;
    object-fit: cover;
}
</style>
