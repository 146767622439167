<template>
  <div>
    <el-select  
      popper-class="mobil-text-el-select-dropdown__item"
      :value="selected" @input="dispatch" 
      :placeholder="placeholder || $t('message.taskBoard')" filterable clearable
      :size="size" class="w-100">
      <el-option v-for="(taskBoard,index) in taskBoards" :key="'taskBoards-' + index" :label="taskBoard.name" :value="taskBoard.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  export default {
    props: {
      size: {
        default: 'small'
      },
      placeholder: {
        default: null,
      },
      id:{
        default: null
      },
		project_id: {
			default: null
		}
    },
    watch:{
      id:{
        handler: function() {
           setTimeout(() => {this.selected = this.id}, 500);
        },
        immediate: true
      },
		project_id: {
			handler: function (){
				if (this.project_id)
					this.updateInventory({project_id: this.project_id});
				else 
					this.$store.commit('taskBoards/EMPTY_INVENTORY');
			},
			deep: true,
			immediate: true
		}
    },
    data() {
      return {
        selected: null,
      }
    },
   //  mounted() {
   //    if (this.taskBoards && this.taskBoards.length === 0) this.updateInventory()
   //  },
    computed: {
      ...mapGetters({
        taskBoards: 'taskBoards/inventory'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'taskBoards/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
