<template>
  <div>
    <v-chart class="e-chart" :option="option" autoresize />
  </div>
</template>
<script>
import ApexCharts from "apexcharts";
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from "vue-echarts";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer
]);

export default {
  components: {
    ApexCharts, VChart
  },
  provide() {
    return {
      [THEME_KEY]: "dark"
    };
  },
  props: {
    period: {
      default: "",
    },
    filterForm: {
      default: [],
    },
  },
  data: () => ({
    option: {
      backgroundColor: "transparent",
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        },
        formatter: (evt) => {
          return formatMoney(evt[0].value);
        }
      },
      legend: {
        show: false,
        data: []
      },
      xAxis: [
        {
          type: 'category',
          data: [],
          axisPointer: {
            type: 'shadow'
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          // name: 'Precipitation',
          min: 0,
          max: null,
          // interval: 50,
          axisLabel: {
            formatter: '{value} $'
          }
        }
      ],
      series: [
        {
          name: '4654',
          type: 'bar',
          itemStyle: {
              color: function (param) {
                const color = ['#ff6633', '#ff33ff', '#ffff99', '#00b3e6', '#e6b333', '#3366e6'];
                return color[param.dataIndex % color.length]
              }
          },
          tooltip: {
            valueFormatter: function (value) {
              return value + ' ml';
            }
          },
          data: []
        }
      ],
      color: ['#ff6633', '#ff33ff', '#ffff99', '#00b3e6', '#e6b333', '#3366e6']
    }
  }),

  watch: {
    watchAll: {
      handler: async function (val) {
        setTimeout(() => {
          this.updateChartData({
            period: this.period,
            filter: this.filterForm,
          });
        }, 500);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      clientStatistics: "home/clientStatistics",
    }),
    watchAll() {
      return (this.period + JSON.stringify(this.filterForm));
    }
  },
  methods: {
    ...mapActions({
      updateClientStatistics: "home/clientStatistics",
    }),

    updateChartData(data) {
      this.updateClientStatistics(data).then((res) => {
        this.$emit('getClientColors', this.option.color);

        // Echart
        this.option.series[0]['data'] = this.clientStatistics.map(item => item.money_amount)
        this.option.yAxis[0]['max'] = Math.max(...this.clientStatistics.map(item => item.money_amount))
        this.option.xAxis[0]['data'] = this.clientStatistics.map(item => item.full_name)
      });
    },
  },


}
</script>
