import { index, inventory, show, store, update, destroy, serviceCategoryTree, getSelectedClient, downloadPDF } from "@/api/settings/productServices";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    if(params.for_price_list){
                        commit("SET_SERVICE_LIST", res.data.result.data.productServices);
                    }else{
                        commit("SET_LIST", res.data.result.data.productServices);
                        commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    }
                    resolve(res.data.result.data.productServices);
                })
                .catch(err => {
                    reject(err.data.error);
                });
        }); 
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.productServices);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    serviceCategoryTree({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            serviceCategoryTree(params).then(res => {
                commit("SET_TREE_INVENTORY", res.data.result.data);
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

	 getSelectedClient({ commit }, params = {}) {
		return new Promise((resolve, reject) => {
			getSelectedClient(params).then(res => {
				  resolve(res.data.result.data)
			 }).catch(err => {
				  reject(err.response.data)
			 })
		})
 	 },

	  downloadPDF({ commit }, params = {}) {
			return new Promise((resolve, reject) => {
				downloadPDF(params).then(res => {
					resolve(res);
				}).catch(err => {
					reject(err)
				})
			})
 	 },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.productServices);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
