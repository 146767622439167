<template>
    <div class="task-home-two-center h__responssive handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="d-flex" :class="mode ? 'style-scuerday' : 'style-scuernight'" style="border-radius: 10px;"
            :style="{ 'padding-right': editDashboard ? '14px' : '' }">
            <div class="style-scuer mb-3 h__full">
                <iframe
                    src="https://ru.widgets.investing.com/live-currency-cross-rates?theme=darkTheme&pairs=1,152,950996,962660,2148,2186,962663,18,2211,2111"
                    width="100%" height="460px" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0">
                </iframe>
            </div>
            <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                style="font-size: 18px; color: #dc2626; cursor: pointer; margin-top: 14px;">
            </i>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
    },
}
</script>

<style lang="scss" scoped></style>