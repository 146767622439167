<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="task-home-two-center h__responssive">
            <div class="filterblock_s mb-5">
                <h4 class="dashboard-tile__item-title short">
                    <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.top_deals') }}</span>
                </h4>
                <div class="filter_home flex-center">
                    <el-radio-group @change="$emit('vmodel', filterByDate)" v-model="filterByDate" size="medium">
                        <el-radio-button label="weekly">{{ $t("message.weekly") }}</el-radio-button>
                        <el-radio-button label="monthly">{{ $t("message.monthly") }}</el-radio-button>
                        <el-radio-button label="yearly">{{ $t("message.yearly") }}</el-radio-button>
                    </el-radio-group>
                    <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
                </div>
            </div>
            <div v-if="topSuccessfulDeals.length > 0" class="task-scroll-khan">
                <div class="itme-task-menjra mb-4 progress-color-lin" v-for="(deal, index) in topSuccessfulDeals"
                    :key="deal.id">
                    <div class="input--label user__box">
                        <div class="d-flex">
                            <div class="circle__user"> {{ index + 1 }} </div>
                            <div class="user__deal">
                                <span>{{ deal.name }}</span>
                                <span>
                                    {{ deal.money_amount | formatMoney(2) }}
                                    <span class="ml-5" v-if="deal.profit">({{ deal.profit | formatMoney(2) }} )</span>
                                </span>
                            </div>
                        </div>
                        <div class="user__name"><span>{{ deal.username }}</span></div>
                    </div>
                    <div class="user__footer">
                        <span> {{ $t('message.company_y') }}: {{ deal.filial_name }}</span>
                        <span v-if="deal.deal_type_name">{{ $t('message.deal_type') }}: {{ deal.deal_type_name }}</span>
                    </div>
                    <hr style="color: #fff">
                </div>
            </div>
            <div v-else class="eror">
                <img src="/img/dashboard.png" alt="Logo" class="d-block" />
                <h1>{{ $t('message.no_data_yet') }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        topSuccessfulDealsPeriod: String,
        // topSuccessfulDeals: Array
    },
    data() {
        return {
            filterByDate: "weekly",
            // topSuccessfulDealsPeriod: "weekly",
        }
    },
    computed: {
        ...mapGetters({
            topSuccessfulDeals: "home/topSuccessfulDeals",
        })
    }
}
</script>

<style lang="scss" scoped></style>