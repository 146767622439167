import {i18n} from '@/utils/i18n';

export const rules = {
    meeting_goal: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.meeting_goal')}), trigger: 'change' },
    ],
    type_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.type_id')}), trigger: 'change' },
    ],
    notification_type_id: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.notificationTypes')}), trigger: 'change' },
    ],
};
