const mode = window.localStorage.getItem('mode')
const oldVersion = window.localStorage.getItem('oldVersion')
const leftVersion = window.localStorage.getItem('leftVersion')
const background = window.localStorage.getItem('background')
const radio = window.localStorage.getItem('radio')
import {i18n} from '@/utils/i18n';

export const state = {
    mode: mode ? JSON.parse(mode) : true,
    oldVersion: oldVersion ? JSON.parse(oldVersion) : false,
    leftVersion: leftVersion ? JSON.parse(leftVersion) : true,
    links: [
      {
        id: 3,
        is_show: 'dashboardConstruct.index',
        name: i18n.t('message.dashboard'),
        link: 'Home'
      },
      {
        id: 0,
        name: i18n.t('message.deals'),
        is_show: 'deals.index',
        link: 'deals'
      },
      {
        id: 2,
        name: i18n.t('message.tasks'),
        is_show: 'tasks.index',
        link: 'tasks'
      },
    ],
    radio: radio ? JSON.parse(radio) : 1,
    is_expanded: false,
    background: background ? JSON.parse(background) : {
        id: 55,
        check: true,
        url: '/img/background/22.png',
        name: 'White',
        mode: true
    },

    lock: {
        password: 0, status: false
    },
    dateToday: '',
    getLockTimer: {
        isIdle: true,
        isTime: 0,
        disable: true
    }
}
