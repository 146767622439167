<template>
  <!-- <el-tab-pane label="Товары" name="second"> -->
  <div class="product-add s w-100">
    <div v-if="!change_in_deal_modal">
      <el-form ref="form" :label-position="'left'" :model="form" :rules="rules">
        <el-row  :gutter="20">
          <el-col  :span="24" style="text-align: right;">
            <el-form-item
                :label="$t('message.columns')"
                prop="columns"
              >
              
              <el-dropdown style="display:block" class="setting-cheek" :hide-on-click="false">
                  <el-button
                      class="padding_none"
                      size="small"
                      icon="el-icon-open"
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                          v-for="(column, index) in columns"
                          :key="'drop' + index"
                      >
                          <el-checkbox
                              :checked="column.show"
                              @change="check(column.column, $event)"
                              >{{ column.title }}
                          </el-checkbox>
                      </el-dropdown-item>
                  </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-row v-loading="loadingData">    
        <el-col :span="24" class="mt-2">
          <el-table 
            :data="dealProductsList" 
            show-summary 
            :row-class-name="tableRowClassName"
            :summary-method="getSummaries" 
            border 
            style="width: 100%">
            <el-table-column :label="columns.name.title" width="180" v-if="columns.name.show">
              <template slot-scope="scope">
                {{ scope.row.product.name }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('message.image')" width="70" v-if="columns.name.show">
              <template slot-scope="scope">
                <el-image 
                  v-if="scope.row.image_list"
                  style="width: 49px; height: 49px"
                  :src="scope.row.image_list[0]"
                  :preview-src-list="scope.row.image_list">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column :label="columns.quantity.title" v-if="columns.quantity.show">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  type="number"
                  size="mini"
                  v-model="scope.row.quantity"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.measurement_id.title" width="60" v-if="columns.measurement_id.show">
              <template slot-scope="scope">
                {{ scope.row.product.measurement.name }}
              </template>
            </el-table-column>
            <el-table-column :label="columns.price.title" v-if="columns.price.show">
              <template slot-scope="scope">
                <el-input
                  :disabled="(scope.row.production && scope.row.production > 0)"
                  :min="0"
                  type="number"
                  size="mini"
                  v-model="scope.row.price"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.currency_rate.title" v-if="columns.currency_rate.show">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  type="number"
                  size="mini"
                  v-model="scope.row.currency_rate"
                  :disabled="checkCurrency(scope.row)"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.currency_id.title" v-if="columns.currency_id.show">
              <template slot-scope="scope">
                <select-currency
                  :size="'mini'"
                  :placeholder="columns.currency_id.title"
                  :id="scope.row.currency_id"
                  v-model="scope.row.currency_id"
                  @input="changeCurrency(scope.row)"
						      ref="currencySelect"
                ></select-currency>
              </template>
            </el-table-column>

            <el-table-column :label="columns.profit.title + ' %'" v-if="columns.profit.show" width="80">
              <template slot-scope="scope">
                <crm-only-number-input
                  v-model="scope.row.profit"
                  :inputValue="scope.row.profit"
                  @c-change="checkMinProfit(scope.row.profit, scope.row)"
                  :size="'mini'"
                  :maxValue="99"
                ></crm-only-number-input>
              </template>
            </el-table-column>

            <el-table-column :label="columns.discount.title + ' %'" v-if="columns.discount.show" width="80">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  :max="99"
                  type="number"
                  size="mini"
                  @change="noMoreThan(scope.$index)"
                  v-model="scope.row.discount"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.nds.title + ' %'" v-if="columns.nds.show" width="80">
              <template slot-scope="scope">
                <el-input
                  :min="0"
                  :max="99"
                  type="number"
                  size="mini"
                  @change="noMoreThan(scope.$index)"
                  v-model="scope.row.nds"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="columns.color.title" v-if="columns.color.show">
              <template slot-scope="scope">
                  <select-color
                    :size="'mini'"
                    :placeholder="columns.color.title"
                    :id="scope.row.color_id"
                    :allowed_colors="scope.row.colors"
                    :model_id="scope.row.product_model_id"
                    v-model="scope.row.color_id"
                  >
                  </select-color>
              </template>
            </el-table-column>

            <el-table-column width="80">
              <template slot="header" slot-scope="scope">
                <i class="el-icon-s-tools"></i>
              </template>
              <template slot-scope="scope">
                  <el-button @click="childProductListOpen(scope.row, scope.$index)" type="primary" icon="el-icon-s-tools" size="mini"></el-button>
                  <el-button @click="downloadDealProduct(scope.row, scope.$index)" type="primary" icon="el-icon-download" size="mini"></el-button>
              </template>
            </el-table-column>

            <el-table-column :label="columns.package.title" v-if="columns.package.show">
              <template slot-scope="scope">
                <select-package
                  :size="'mini'"
                  :placeholder="columns.package.title"
                  :id="scope.row.package_id"
                  :allowed_packages="scope.row.packages"
                  v-model="scope.row.package_id"
                ></select-package>
              </template>
            </el-table-column>

            <el-table-column :label="columns.total.title" v-if="columns.total.show">
              <template slot-scope="scope">
                {{ productRowTotal(scope.row) | formatMoney(2)}}
              </template>
            </el-table-column>
            
            <el-table-column width="50" v-if="columns.delete.show">
              <template slot-scope="scope">
                <i @click="removeFromDealProductsList(scope.row.id, scope.$index)" class="el-icon-delete icon__delete"></i>
              </template>
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mt-2">
					<div class="manager__profit">
						<span style="text-indent:50px; font-size:18px;">{{ $t('message.profit_user') }}: </span>
						<span style="text-indent:15px; font-size:18px; font-weight:bold;">{{calculateProfitManagerForProduct}}</span>
					</div>
			  </el-col>

		 	  <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="mt-2">
					<div class="filial__product">
						<select-filial-product
              :filial_id="filial_id"
              :size="'medium'"
              :placeholder="$t('message.search_by_product_and_category')"
              :id="product_id"
              v-model="product_id"
              @get_selected_product="get_selected_product"
              :class="'w-100'"
              >
						</select-filial-product>
					</div>
			  </el-col>
    </el-row>
       
    <el-row class="mt-5" v-if="!change_in_deal_modal">
      <el-button type="primary" @click="submit()" size="small" round v-loading="loadingButton"
        v-if="permissions.some(per => per.slug == 'dealProducts.create')">
        {{$t('message.save')}}
      </el-button>
      <el-button
        type="success"
        @click="dealOffersCreate"
        size="small"
        round
        >{{ $t('message.kp') }}
      </el-button>
    </el-row>

    <div class="app-modal app-modal__full modal-color-bg">
      <el-drawer
        :with-header="false"  
        :visible.sync="drawerServices" 
        size="50%"
        :append-to-body="true"
        >
          <div class="app-modal__box">
            <div class="app-modal__in">
              <div class="app-modal__header d-flex f-between p-5 m-title-modal">
                <p class="large--title m-0"> {{ $t("message.services") }}</p>
                <div>
                  <crm-store-update-close
                      :permission="$options.name"
                      :button_type="'store'"
                      :loading="loadingButton"
                      @c-submit="submitService(true)"
                      @c-close="closeService()"
                  ></crm-store-update-close>
                </div>
              </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
              <div class="timeline-items__right rounded-sm w-100 p-4">
                  <el-table
                    :data="selectedProductServices"
                    show-summary 
                    :summary-method="getSummariesForServices" 
                    border
                    style="width: 100%">
                      <el-table-column
                        prop="name"
                        :label="$t('message.name')"
                        width="180">
                        <template slot-scope="scope">
                          {{ scope.row.name }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="money_amount"
                        :label="$t('message.price')">
                        <template slot-scope="scope">
                          <el-input
                            :min="0"
                            type="number"
                            size="mini"
                            v-model="scope.row.money_amount"
                          ></el-input>
                        </template>
                        
                      </el-table-column>
                      <el-table-column
                        prop="currency_rate"
                        :label="$t('message.rate')">
                        <template slot-scope="scope">
                          <el-input
                            :min="0"
                            type="number"
                            size="mini"
                            v-model="scope.row.currency_rate"
                            :disabled="checkCurrency(scope.row)"
                          ></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="currency_id"
                        :label="$t('message.currency')">
                        <template slot-scope="scope">
                          {{ getCurrencyCodeById(scope.row) }}
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="currency_id"
                        :label="$t('message.money_amount')">
                        <template slot-scope="scope">
                          {{ (scope.row.money_amount * scope.row.currency_rate) | formatMoney(3)}}
                        </template>
                      </el-table-column>
                  </el-table>
              </div>
            </div>
            <!-- end app-modal__body -->
          </div>
      </el-drawer>
    </div>

    <el-drawer
        :class="mode ? 'l-modal-style' : 'd-modal-style'"
        :with-header="false"
        :visible.sync="drawerChildProduct"
        :append-to-body="true"
        ref="drawerChildProduct"
        size="100%"
        @opened="drawerOpened('drawerChildProductChild')"
        @closed="drawerClosed('drawerChildProductChild')"
    >
        <div>
            <child-products
                :deal_id="deal_id"
                :filial_id="filial_id"
                :selectedRow="selectedRow"
                @setChildProducts="setChildProducts"
                @updateParentPriceAndRemoveProduct="updateParentPriceAndRemoveProduct"
                :product_calculation_type_id="product_calculation_type_id"
                ref="drawerChildProductChild"
                drawer="drawerChildProduct"
            >
            </child-products>
        </div>
    </el-drawer>
  </div>
  <!-- </el-tab-pane> -->
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
import child_list from "@/utils/mixins/child_list";
import dealProducts from "@/utils/mixins/models/dealProducts";
import { formatMoney, formatNumber } from "@/filters/index";

export default {
  components: {
  },
  mixins: [show, form, drawer, child_list, dealProducts],
  props: {
    
  },
  data() {
    return {
      
    };
  },
  
  computed: {
    ...mapGetters({
      mode: "MODE",
      permissions: "auth/permissions",
      getProduct: "products/model",
      model: "dealProducts/model",
      dealProducts: "dealProducts/dealProducts",
      rules: "dealProducts/rules",
      columns: "dealProducts/columns",
      currencies: "currency/inventory",
      services: "productServices/inventory",
      getFeeList: "dealProductAdditionalFeeType/list",
    }),

  },
  methods: {
    ...mapActions({
        save: "dealProducts/store",
        downloadProductRecipe: "dealProducts/downloadProductRecipe",
        update: "dealProducts/update",
        updateColumn: "dealProducts/updateColumn",
        show: "dealProducts/show",
        showProduct: "products/show",
        empty: "dealProducts/empty",
        destroy: "dealProducts/destroy",
        updateCurrencyInventory: "currency/inventory",
        updateFeeInventory: "dealProductAdditionalFeeType/index",
    }),
    downloadDealProduct(row, index) {
      this.downloadProductRecipe(row)
        .then(res => {
            const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            WinPrint.document.write(res.data);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        })
        .catch(err => {
          this.$alert(err);

        });
    }

  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.custom__flex{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
.filial__product{
	width: 100%;
}
.manager__profit{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.el-table .success-row {
  background: #99da76;
}
.el-table .danger-row {
  background: #e9a5a9;
}

</style>
