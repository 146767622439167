import { columns } from './properties/columns'
import { filter } from './properties/filter';
import { pagination } from "@/store/modules/properties/pagination";

export const state = {
    list: [],
    filter: filter,
    columns: columns,
    totalStatistics: [],
    trunckDiagrams: [],
    userDiagrams: [],
    phoneCallList: [],
    pagination: JSON.parse(JSON.stringify(pagination)),
};
