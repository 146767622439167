import {index, show, store, update, showLast, inventory, changeDealStage, deleteSpectator, dealStages, downloadDealFile, deleteFile, 
    dealTasks, archivateDeal, restoreDeal, archivedDeals, deletedDeals, changeIndex, 
    destroy, deleteDeal, getDealsForCalendar, phoneNumberDeals, getUnpaidDeals, excelFileDownload, checkClientIsNew } from '@/api/deals/deals';

export const actions = {
    
    index({commit}, params = {}){
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                commit('SET_LIST', res.data.result.data.deals)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    getUnpaidDeals({commit}, params = {}){
        return new Promise((resolve, reject) => {
            getUnpaidDeals(params).then(res => {
                commit('SET_UNPAID_DEALS_LIST', res.data.result.data.deals)
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    excelFileDownload({commit}){
        return new Promise((resolve, reject) => {
            excelFileDownload().then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    archivedDeals({commit}, params = {}){
        return new Promise((resolve, reject) => {
            archivedDeals(params).then(res => {
                commit('SET_ARCHIVED_DEALS_LIST', res.data.result.data.deals)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    phoneNumberDeals({commit}, number){
        return new Promise((resolve, reject) => {
            phoneNumberDeals(number).then(res => {
                commit("PHONE_NUMBER_DEALS", res.data.result.data.deals);
                resolve(res)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    deletedDeals({commit}, params = {}){
        return new Promise((resolve, reject) => {
            deletedDeals(params).then(res => {
                commit('SET_DELETED_DEALS_LIST', res.data.result.data.deals)
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })  
        })
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.deals);
                resolve(res.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    changeDealStage({ commit }, data) {
        return new Promise((resolve, reject) => {
            changeDealStage(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    deleteSpectator({ commit }, data) {
        const { deal_id, user_id } = data;
        return new Promise((resolve, reject) => {
            deleteSpectator(deal_id, user_id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    downloadDealFile({ commit }, params){
        return new Promise((resolve, reject) => {
            downloadDealFile(params).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteFile({ commit }, params){
        return new Promise((resolve, reject) => {
            deleteFile(params).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id).then(res => {
                commit("SET_MODEL", res.data.result.data.deal);
                commit("SET_FORM", res.data.result.data.deal);
                resolve(res.data.result.data)
            }).catch(err => {
                reject(err)
            })
        })
    },

    dealTasks({ commit }, id) {
        return new Promise((resolve, reject) => {
            dealTasks(id).then(res => {
                commit("SET_DEAL_TASKS", res.data.result.data.dealTasks);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

	 checkClientIsNew({ commit }, id) {
		return new Promise((resolve, reject) => {
			checkClientIsNew(id).then(res => {
				  resolve(res.data.result.data);
			 }).catch(err => {
				  reject(err)
			 })
		})
   },

   getDealsForCalendar({commit}, params) {
        return new Promise((resolve, reject) => {
            getDealsForCalendar(params).then(res => {
                commit('GET_CALENDAR_DEALS', res.data.result.data.deals)
                resolve(res)
            }).catch(err => {
                reject(err)
            })

        })
    },

    archivateDeal({ commit }, id) {
        return new Promise((resolve, reject) => {
            archivateDeal(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    restoreDeal({ commit }, id) {
        return new Promise((resolve, reject) => {
            restoreDeal(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    showLast({ commit }) {
        return new Promise((resolve, reject) => {
            showLast().then(res => {
                commit("SET_FORM", res.data.result.data.deal);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    dealStages({ commit }, filial_id) {
        return new Promise((resolve, reject) => {
            dealStages(filial_id).then(res => {
                commit("SET_DEAL_STAGES", res.data.result.data);
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    changeIndex({ commit }, data) {
        return new Promise((resolve, reject) => {
            changeIndex(data).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err)
            })
        })
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    deleteDeal({ commit }, data) {
        const { deal_id, reason_id } = data;
        return new Promise((resolve, reject) => {
            deleteDeal(deal_id, reason_id).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    emptyDealTask({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_DEAL_TASKS");
            resolve()
        })
    },
    
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
    

}

