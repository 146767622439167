<template>
  <div class="product-add">
    <div class="product-add-table  card-table-header mt-5  table-crm-smart">
      <table class="table-my-code table-bordered borde-l-r " v-loading="loadingData">
        <thead>
          <tr>
            <th class="w50p" v-if="columns.id.show">
              {{ columns.id.title }}
            </th>

            <th v-if="columns.name.show">
              {{ columns.name.title }}
            </th>

            <th v-if="columns.date.show">
              {{ columns.date.title }}
            </th>
            
            <th v-if="columns.end_date.show">
              {{ columns.end_date.title }}
            </th>

            <th v-if="columns.count_product.show">
              {{ columns.count_product.title }}
            </th>

            <th v-if="columns.status.show">
              {{ columns.status.title }}
            </th>

            <th v-if="columns.total_price.show">
              {{ columns.total_price.title }}
            </th>

            <th v-if="columns.currency.show">
              {{ columns.currency.title }}
            </th>

            <th v-if="columns.comment.show">
              {{ columns.comment.title }}
            </th>

            <th v-if="columns.created_at.show">
              {{ columns.created_at.title }}
            </th>

            <th v-if="columns.updated_at.show">
              {{ columns.updated_at.title }}
            </th>
            <th v-if="columns.download.show">
              {{ columns.download.title }}
            </th>
          </tr>

          <tr class="filter_sorche">
            <th v-if="columns.id.show">
              <el-input
                clearable
                size="mini"
                v-model="filterForm.id"
                :placeholder="columns.id.title"
                class="id_input"
              ></el-input>
            </th>

            <th v-if="columns.name.show">
              <crm-input
                :placeholder="columns.name.title"
                v-model="filterForm.name"
              ></crm-input>
            </th>

            <th v-if="columns.date.show">
              <crm-input
                :placeholder="columns.date.title"
                v-model="filterForm.date"
              ></crm-input>
            </th>

            <th v-if="columns.end_date.show">
              <crm-input
                :placeholder="columns.end_date.title"
                v-model="filterForm.end_date"
              ></crm-input>
            </th>

            <th v-if="columns.count_product.show">
              <crm-input
                :placeholder="columns.count_product.title"
                v-model="filterForm.count_product"
              ></crm-input>
            </th>

            <th v-if="columns.status.show">
              <crm-input
                :placeholder="columns.status.title"
                v-model="filterForm.status"
              ></crm-input>
            </th>

            <th v-if="columns.total_price.show">
              <crm-input
                :placeholder="columns.total_price.title"
                v-model="filterForm.total_price"
              ></crm-input>
            </th>

            <th v-if="columns.currency.show">
              <crm-input
                :placeholder="columns.currency.title"
                v-model="filterForm.currency"
              ></crm-input>
            </th>

            <th v-if="columns.comment.show">
              <crm-input
                :placeholder="columns.comment.title"
                v-model="filterForm.comment"
              ></crm-input>
            </th>

            <th v-if="columns.created_at.show">
              <crm-date-picker
                :placeholder="columns.created_at.title"
                v-model="filterForm.created_at"
              ></crm-date-picker>
            </th>

            <th v-if="columns.updated_at.show">
              <crm-date-picker
                :placeholder="columns.updated_at.title"
                v-model="filterForm.updated_at"
              ></crm-date-picker>
            </th>

            <th v-if="columns.download.show"></th>
          </tr>
        </thead>

        <!-- <tbody> -->
        <transition-group name="flip-list" tag="tbody">
          <tr
            v-for="(offer, index) in getList"
            :key="offer.id"
            class="cursor-pointer"
          >
            <td v-if="columns.id.show">
              {{ (getList.length - index) }}
            </td>

            <td v-if="columns.name.show">
              {{ offer.user_name }}
            </td>

            <td v-if="columns.date.show">
              {{ offer.date }}
            </td>

            <td v-if="columns.end_date.show">
              {{ offer.end_date }}
            </td>

            <td v-if="columns.count_product.show">
              {{ offer.count_product }}
            </td>

            <td v-if="columns.status.show">
              <div>
                <el-switch
                  size="small"
                  v-model="offer.status"
                  @change="changeStatus(offer)"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
                <span class="data-deal-task-start ml-2" :class="offer.status ? 'coloa2' : 'coloa3'">
                  {{ offer.status ? $t('message.accepted') : $t('message.not_accepted') }}
                </span>
              </div>
              
            </td>

            <td v-if="columns.total_price.show">
              {{ offer.total_price | formatMoney(1) }}
            </td>

            <td v-if="columns.currency.show">
              {{ offer.currency }}
            </td>

            <td v-if="columns.comment.show">
              {{ offer.comment }}
            </td>

            <td v-if="columns.created_at.show">
              {{ offer.created_at }}
            </td>

            <td v-if="columns.updated_at.show">
              {{ offer.updated_at }}
            </td>
            <td v-if="columns.download.show">
              <el-button @click="downloadOffer(offer.id, index)" type="primary" size="mini" icon="el-icon-download"></el-button>
            </td>
          </tr>
        </transition-group>
        <!-- </tbody> -->
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from 'lodash';

export default {
  name: "dealOffer",
  props: {
    deal_id: {
      default: null,
    },
  },
  data() {
    return {
      loadingData: false,
      filterForm: {},
      getList: [],
      activeOfferId: null,
    };
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
  },
  watch: {
    deal_id(val) {
      if (val) {
        this.loadingData = true;
        this.updateList(val).then(() => {
          this.getList = JSON.parse(JSON.stringify(this.list));
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        })
      }
    },
    list() {
      this.getList = JSON.parse(JSON.stringify(this.list));
    },
  },
  computed: {
    ...mapGetters({
      list: "dealOffers/dealOfferList",
      columns: "dealOffers/columns",
      pagination: "dealOffers/pagination",
      filter: "dealOffers/filter",
      sort: "dealOffers/sort",
    }),
    actions: function () {
      return ["delete"];
    },
  },
  methods: {
    ...mapActions({
      downloadDealOffer: "dealOffers/downloadDealOffer",
      updateStatus: "dealOffers/update",
      updateList: "dealOffers/getDealOffers",
      setPagination: "dealOffers/setPagination",
      updateSort: "dealOffers/updateSort",
      updateFilter: "dealOffers/updateFilter",
      updateColumn: "dealOffers/updateColumn",
      updatePagination: "dealOffers/updatePagination",
      show: "dealOffers/show",
      empty: "dealOffers/empty",
      delete: "dealOffers/destroy",
      refreshData: "dealOffers/refreshData",
    }),
    changeStatus(offer) {
          this.$confirm(
              this.$t('message.do_you_really_want_to_do_this'),
              this.$t('message.warning'), {
                confirmButtonText: this.$t('message.yes'),
                cancelButtonText: this.$t('message.no'),
                type: "warning"
              }
            )
            .then(() => {
              this.$emit('listChanged');
              this.debouncedUpdateStatus(offer, this);
            })
            .catch(() => {
              offer.status = !offer.status
              this.$message({
                type: "warning",
                message: this.$t('message.operation_canceled')
              });
            });
    },
    debouncedUpdateStatus: _.debounce((offer, self) => {
      if (_.isFunction(self.updateStatus)) {
        self.updateStatus(offer)
      }
    }, 500),
    downloadOffer(id, index) {
      let fileName = this.deal_id + "-" + (this.getList.length - index) + " offer.pdf";
      this.downloadDealOffer(id)
        .then((res) => { 
          const url = window.URL.createObjectURL(new Blob([res.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>



<style>
</style>
