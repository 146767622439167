import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    user : {
        show: true,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user'
    },
	 ip_address : {
		show: false,
		title: i18n.t('message.ip_address'),
		sortable: true,
		column: 'ip_address'
   },
	source : {
		show: true,
		title: i18n.t('message.source'),
		sortable: true,
		column: 'source'
   },
	deal_id : {
		show: true,
		title: i18n.t('message.deal'),
		sortable: true,
		column: 'deal_id'
   },
	edit : {
		show: true,
		title: i18n.t('message.edit_m', {m: i18n.t('message.deal')}),
		sortable: true,
		column: 'edit'
   },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
