<template>
  <div class="app-modal__body p-5 pb-0">
    <div class="timeline-items__right w-100 rounded-sm p-4">
      <el-collapse accordion class="collapseno-border">
        <el-collapse-item name="1">
          <template slot="title">
            <span> {{ $t('message.contact_person') }}</span>
            <i class="ml-2 el-icon-user-solid"></i>
          </template>
          <div v-for="(value, index) in object" :key="index">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2"> {{ $t('message.name') }} </span>
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="value.full_name"
                    size="medium"
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">{{ $t('message.position') }}</span>
                  <el-input
                    :placeholder="$t('message.position')"
                    v-model="value.position"
                    size="medium"
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
					<!-- end col -->
					<el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
						<div class="app-form__group mb-4">
							<span class="input--label d-block mb-2">{{ $t('message.date_of_birth') }}</span>
							<el-date-picker
								class="w-100"
								size="medium"
								v-model="value.birth_date"
								type="date"
								:placeholder="$t('message.date_of_birth')"
								:format="date_format"
								:value-format="date_format"
                  	>
							</el-date-picker>
						</div>
					</el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">{{ $t('message.phone') }} </span>
                  <el-input
                    :placeholder="$t('message.phone')"
                    v-model="value.phone"
                    size="medium"
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2">{{ $t('message.email') }}</span>
                  <el-input
                    :placeholder="$t('message.email')"
                    v-model="value.email"
                    size="medium"
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2"> {{ $t('message.comment') }} </span>
                  <el-input
                    :placeholder="$t('message.comment')"
                    v-model="value.comment"
                    size="medium"
                  ></el-input>
                </div>
              </el-col>
              <!-- end col -->
              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" class="right-block">
                <el-button
                  @click="deleteForm(index)"
                  type="danger"
                  size="medium"

                >
                  {{ $t('message.delete_field') }}
                </el-button>
                <el-button
                  @click="addForm()"
                  type="primary"
                  size="medium"

                >
                  {{ $t('message.add_field') }}
                </el-button>
              </el-col>
              <!-- end col -->
            </el-row>
            <hr />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "contact-people",

  mixins: [],
  data() {
    return {
      object: [],
    };
  },
  computed: {
  },
  created: function () {
    let value = {
      id: "",
      full_name: "",
      position: "",
		birth_date: "",
      phone: "",
      email: "",
      comment: "",
      created_at: "",
      updated_at: "",
    };
    this.object.push(value);
  },
  methods: {
    addForm() {
      let value = {
        id: "",
        full_name: "",
        position: "",
		  birth_date: "",
        phone: "",
        email: "",
        comment: "",
        created_at: "",
        updated_at: "",
      };
      this.object.push(value);
    },
    deleteForm(index) {
      if (this.object.length === 1) {
        this.$message({
          type: "warning",
          message: this.$t("message.unable_to_delete_last_form"),
        });
      } else {
        this.object.splice(index, 1);
      }
    },
  },
};
</script>

