import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },

    price_list_id : {
        show: true,
        title: i18n.t('message.price_list'),
        sortable: true,
        column: 'price_list_id'
    },

    services : {
        show: true,
        title: i18n.t('message.services'),
        sortable: true,
        column: 'services'
    },

    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },

    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },

    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
