export const filter = {
    id : '',  
    name: '', 
    user_id: '', 
    country_id: '',
    main_type: null,
    city_id: '', 
    status: '',
    begin_date: '', 
    end_date: '', 
    created_at: '',
    updated_at: ''  
  };
  