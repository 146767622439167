import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
	workMeeting: {
		show: true,
        title: i18n.t('message.workMeeting'),
        sortable: true,
        column: 'workMeeting'
	},
    user : {
        show: true,
        title: i18n.t('message.user'),
        sortable: true,
        column: 'user'
    },
	participants : {
        show: true,
        title: i18n.t('message.participants'),
        sortable: false,
        column: 'participants'
    },
	begin_time : {
        show: true,
        title: i18n.t('message.from'),
        sortable: false,
        column: 'begin_time'
    },
	end_time : {
        show: true,
        title: i18n.t('message.before'),
        sortable: false,
        column: 'end_time'
    },
	description : {
        show: true,
        title: i18n.t('message.description'),
        sortable: false,
        column: 'description'
    },
	conclusion : {
        show: true,
        title: i18n.t('message.conclusion'),
        sortable: false,
        column: 'conclusion'
    },
	files : {
        show: true,
        title: i18n.t('message.files'),
        sortable: false,
        column: 'files'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
