import _ from 'lodash';
export default {
  data() {
      return {
          drawerCreate: false,
          drawerShow: false,
          drawerUpdate: false,
          filterForm: {},
          loadingData: false,
          excel_data: [],
          excel_fields: {},
          checkAll: false,
          reloadList: false,
          loadingComments: false,         
      }
  },
  methods: {
      closeDrawer(drawer) {       
          if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
            this.$refs[drawer].closeDrawer();
          }
      },
      drawerClosed(ref) {
          if (this.$refs[ref]) {
              this.$refs[ref].closed()
          }
      },
      drawerOpened(ref) {        
          if (this.$refs[ref]) {
              if (_.isFunction(this.$refs[ref].opened)) {
                  this.$refs[ref].opened()
              }
          }
      },
      listChanged() {
          this.reloadList = true;
      },
      afterFetchData() {
          this.reloadList = false;
      },
      fetchData() {
          const query = {...this.filter, ...this.pagination, ...this.sort,};
          if (!this.loadingData) {
              this.loadingData = true;
              this.updateList(query).then(res => {
                  this.loadingData = false
              }).catch(err => {
                  this.loadingData = false
              });
          }
      },
      refresh() {
          this.refreshData()
              .then(res => {
                  this.filterForm = JSON.parse(JSON.stringify(this.filter))
              })
              .catch(err => {})
      },
      edit(model) {         
          this.drawerUpdate = true;
      },                
      controlExcelData() {
          this.excel_fields = {};
          for (let key in this.columns) {
              if (this.columns.hasOwnProperty(key)) {
                  let column = this.columns[key];
                  if (column.show && column.column !== 'settings') {
                      this.excel_fields[column.title] = column.column;
                  }
              }
          }
      },      
      destroy(model) {          
          this.delete(model.id)
              .then(res => {
                  this.$alert(res);
                  this.fetchData()
              })
              .catch(err => {
                this.$alert(err);
              })
      },
      print(model) {
          this.printModel({ id: model.id })
              .then(res => {
                  const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                  WinPrint.document.write(res.data);
                  WinPrint.document.close();
                  WinPrint.focus();
                  WinPrint.print();
                  WinPrint.close();
              })
              .catch(err => {
                  this.$alert(err);
              });
      },
  },
  destroyed() {

  },
}
