<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header f-wrap d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0 mb-2">
            {{
              $t("message.new_m", {
                m: $t("message.company"),
              })
            }}
          </p>
          <div>
            <crm-store-update-close
              :permission="$options.name"
              :button_type="'store'"
              :loading="loadingButton"
              @c-submit="submit(true)"
              @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <el-form ref="form" :model="form" :rules="rules" label-position="top">
            <el-row :gutter="20">
              <el-col :span="24">
                <div
                  class="timeline-date2 d-flex justify-center p-relative mb-5"
                >
                  <div class="timeline-date__text text-white p-2 rounded-pill">
                    <span> {{$t('message.individual_info')}} </span>
                  </div>
                </div>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.name')"
                  prop="name"
                  class="label_mini"
                >
                  <el-input
                    :placeholder="$t('message.name')"
                    v-model="form.name"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.mfo')" class="label_mini">
                  <el-input
                    :placeholder="$t('message.mfo')"
                    v-model="form.mfo"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.okonx')" class="label_mini">
                 <el-input
                    :placeholder="$t('message.okonx')"
                    v-model="form.okonx"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.oked')" class="label_mini">
                   <el-input
                    :placeholder="$t('message.oked')"
                    v-model="form.oked"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.rkp_nds')" class="label_mini">
                  <el-input
                    :placeholder="$t('message.rkp_nds')"
                    v-model="form.rkp_nds"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <crm-phone-input
                    :inputValue="form.work_phone" 
                    v-model="form.work_phone"
                    @getPhoneStatus="getPhoneStatus"
                    :form_prop="'work_phone'"
                    :country_code="form.phone_country_code"
                ></crm-phone-input>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.country_id')"
                  class="label_mini"
                >
                  <select-country
                    :size="'medium'"
                    :placeholder="columns.country_id.title"
                    :id="form.country_id"
                    v-model="form.country_id"
                  >
                  </select-country>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.city_id')"
                  class="label_mini"
                >
                   <select-city
                    :size="'medium'"
                    :placeholder="columns.city_id.title"
                    :id="form.city_id"
                    :country_id="form.country_id"
                    v-model="form.city_id"
                  >
                  </select-city>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.work_place')"
                  class="label_mini"
                >
                   <el-input
                    type="textarea"
                    :placeholder="$t('message.work_place')"
                    v-model="form.work_place"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.legal_address')"
                  class="label_mini"
                >
                 <el-input
                    type="textarea"
                    :placeholder="$t('message.legal_address')"
                    v-model="form.legal_address"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.landmark')"
                  class="label_mini"
                >
                 <el-input
                    type="textarea"
                    :placeholder="$t('message.landmark')"
                    v-model="form.landmark"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item
                  :label="$t('message.residence_place')"
                  class="label_mini"
                >
                   <el-input
                    type="textarea"
                    :placeholder="$t('message.residence_place')"
                    v-model="form.residence_place"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->

              <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
                <el-form-item :label="$t('message.comment')" class="label_mini">
                   <el-input
                    type="textarea"
                    :placeholder="$t('message.comment')"
                    v-model="form.comment"
                    size="medium"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- end col -->
            </el-row>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";

export default {
  mixins: [form, drawer],
  components: {
    selectCountry,
    selectCity,
  },
  data() {
    return {
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "company/rules",
      model: "company/model",
      columns: "company/columns",
    }),
  },
  methods: {
    ...mapActions({
      save: "company/store",
      updateInventory: "company/inventory",
    }),
    getPhoneStatus({is_valid, countryCode}){
        this.form.phone_country_code = countryCode;
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              this.updateInventory()
                .then(respon => {
                  this.$emit('createdCompany', res.data.result.data.company);
                });
              if (close) this.close();
            })
            .catch((err) => {
              console.log(err, 'errrr');
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
  },
};
</script>
