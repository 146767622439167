import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/productServices',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/productServices',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/productServices/inventory",
        method: "get",
        params
    });
}

export function getSelectedClient(params) {
	return request({
		 url: "/productServices/getSelectedClient",
		 method: "get",
		 params
	});
}

export function serviceCategoryTree(params) {
    return request({
        url: "/productServices/serviceCategoryTree",
        method: "get",
        params
    });
}

export function downloadPDF(params) {
	return request({
		 url: "/productServices/downloadPDF",
		 method: "get",
		 params
	});
}

export function show(id) {
    return request({
        url: `/productServices/${id}`,
        method: 'get'
    })
}

export function update(data) {
    return request({
        url: `/productServices/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/productServices/${id}`,
        method: 'delete'
    })
}