<template>
    <div :class="mode ? 'style-scuerday' : 'style-scuernight'" class=" style-scuer mb-3 h__full handle-draggable">
        <div v-if="editDashboard" class="vue-draggable-handle"></div>
        <div class="filterblock_s">
            <h4>
                <span class="dashboard-tile__item-title-txt text-white">{{ $t('message.dealConditions') }}</span>
            </h4>
            <div class="d-flex">
                <div class="filter_column chart-f">
                    <div class="d-flex">
                        <select-country :size="'mini'" :placeholder="$t('message.country')" :id="filterPayment.country_id"
                            v-model="filterPayment.country_id">
                        </select-country>
                        <el-date-picker class="w-100" size="mini" v-model="filterPayment.day" type="date" placeholder="По дням"
                            format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
                    </div>
                    <div class="d-flex">
                        <el-date-picker class="w-100" size="mini" v-model="filterPayment.month" type="month"
                            placeholder="По месяцам" format="yyyy-MM" value-format="yyyy-MM"></el-date-picker>
                        <el-date-picker class="w-100" size="mini" v-model="filterPayment.year" type="year"
                            placeholder="По годам" format="yyyy" value-format="yyyy"></el-date-picker>
                    </div>
                </div>
                <i v-if="editDashboard" @click="$emit('remove-chart')" class="fa-solid fa-trash-can ml-3"
                    style="font-size: 18px; color: #dc2626; cursor: pointer;">
                </i>
            </div>
        </div>
        <MoneyType :filterForm="filterPayment"></MoneyType>
    </div>
</template>

<script>
import MoneyType from "@/components/chart/moneyType";
import selectCountry from "@/components/inventory/select-country";

export default {
    components: {
        MoneyType, selectCountry
    },
    props: {
        mode: {
            type: Boolean,
            default: () => false
        },
        editDashboard: {
            type: Boolean,
            default: () => false
        },
        filterPayment: Object
    },

}
</script>

<style lang="scss" scoped></style>