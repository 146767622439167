<template>
  <div>
    <el-form-item
      :label="$t('message.client')"
      class="label_mini"
      prop="client_id"
    >
    <div class="clent-pulus d-flex">
      <el-select
        :value="selected"
        @input="dispatch"
        :placeholder="$t('message.client_with_search')"
        filterable
        clearable
        :size="size"
        class="w-100"
		    @change="Cchange"
        remote 
        :remote-method="filterByType"
      >
        <el-option
          v-for="(client, index) in getPersonClients"
          :key="'clients-' + index"
          :label="client.full_name"
          :value="client.id"
        >
          <span class="mm_select_additional_container">
              {{ client.full_name }} <span class="mm_select_additional_info">{{ client.company ? client.company.name : '' }}</span>
          </span>
        </el-option>
      </el-select>
      <el-button :size="size_button" class="ml-2" type="primary" icon="el-icon-plus" @click="drawerCreate = true" ></el-button>
    </div>
    
    </el-form-item>
    <div>
      <el-drawer
          :class="mode ? 'l-modal-style' : 'd-modal-style'"
          :with-header="false"
          :visible.sync="drawerCreate"
          :append-to-body="true"
          ref="drawerCreate"
          size="70%"
          class="body_scroll_70 bg-se" 
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
      >
          <div>
              <create
                  ref="drawerCreateChild"
                  drawer="drawerCreate"
                  :company_id="company_id"
                  @createdClient="afterCreated"
              >
              </create>
          </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import create from "@/views/clients/components/crm-create";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/child_list";

export default {
  mixins: [list],
  components: { create },
  props: {
    size: {
      default: "medium",
    },
    size_button: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    company_id: {
      default: null,
    },
    model: {
      default: null,
    },
  },
  watch: {
    company_id: {
      handler: function (newVal, oldVal) {
        if (this.company_id) {
          this.companyClients(this.company_id).then((res) => {
            this.clients = this.getCompanyClients;
            let self = this;
            if (this.selected && this.model && this.company_id != this.model.company_id) {
              this.$emit("input", null);
            }
          });
        } else {
          this.personClients().then((res) => {
            this.clients = this.getPersonClients;
            if (this.selected && this.selected != this.model.client_id) {
              this.$emit("input", null);
            }
          });
        }
      },
      immediate: true,
      deep: true,
    },
    id: {
      handler: function (newVal, oldVal) {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      clients: [],
    };
  },
  computed: {
    ...mapGetters({
        getCompanyClients: "clients/companyClients",
        getPersonClients: "clients/personClients",
        mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      companyClients: "clients/companyClients",
      personClients: "clients/personClients",
      filteredClients: "clients/filterClientsByType",
    }),
    afterCreated(client) {
        this.dispatch(client.id);
    },
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    filterByType(filterValue){
        let company = null;
        if(this.company_id){
          company = this.company_id
        }

        let filter = null;
        if(filterValue){
          filter = filterValue
        }
        let data = {typeName: filter, company_id: company};
        this.debouncedFilter(data, this);

    },

    debouncedFilter: _.debounce((data, self) => {
      if (_.isFunction(self.filteredClients)) {
        self.filteredClients(data)
          .then((res) => {
             self.clients = self.getPersonClients;
          })
          .catch((err) => {});
      }
    }, 1000),
	 Cchange(e){
		this.$emit('c-change', e);
		this.selected = e;
	 }
  },
};
</script>
