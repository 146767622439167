import {i18n} from '@/utils/i18n';

export const rules = {
    sending_type: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.sending_type')}), trigger: 'change' },
    ],
    message_receivers: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.message_receivers')}), trigger: 'change' },
    ],
    message: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.message')}), trigger: 'change' },
    ],
    clients: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.clients')}), trigger: 'change' },
    ],
    users: [
        { required: true, message: i18n.t('message.please_select_input', {input: i18n.t('message.users')}), trigger: 'change' },
    ],
    
};
