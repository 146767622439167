import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/managerTypes',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/managerTypes',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/managerTypes/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/managerTypes/${id}`,
        method: 'get'
    })
}
export function getManagerTypeRules(id) {
	return request({
		 url: `/managerTypes/getManagerTypeRules/${id}`,
		 method: 'get'
	})
}
export function update(data) {
    return request({
        url: `/managerTypes/${data.id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/managerTypes/${id}`,
        method: 'delete'
    })
}