<template>
    <div class="khan-chart-a">
        <apexchart type="area" height="410" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { formatMoney } from "@/filters/index";
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        period: {
            default: "weekly",
        },
        filterForm: {
            default: null,
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                    name: ''
                }
            ],
            chartOptions: {
                chart: {
                    height: 388,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    title: {
                        text: "",
                    },
                    categories: []
                },
                yaxis: {
                    labels: {
                        show: true,
                        formatter: function (value) {
                            return formatMoney(value, 2);
                        },
                    },
                },
                colors: ['#00FF00', '#FFFF00', '#FF0000', '#00FFFF', '#0000FF', '#FF00FF', '#FFA500', '#D0D3D4', '#808000', '#0B5345'],
                tooltip: {
                    //   x: {
                    //     format: 'dd/MM/yy'
                    //   },
                },
            },
        };
    },
    watch: {
        watchAll: {
            handler: async function (val) {
                setTimeout(() => {
                    this.updateChartData({ period: this.period });
                }, 500);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapGetters({
            mode: "MODE",
            dealPaymentTerms: "home/dealPaymentTerms",
        }),
        watchAll() {
            return (this.period + JSON.stringify(this.filterForm));
        }
    },
    methods: {
        ...mapActions({
            updatedealPaymentTerms: "home/dealPaymentTerms",
        }),

        updateChartData(data) {
            this.updatedealPaymentTerms(data).then((res) => {
                this.series = this.dealPaymentTerms.payments;
                this.chartOptions.xaxis.categories = this.dealPaymentTerms.dates;
                let self = this;
                this.chartOptions = {
                    ...this.chartOptions,
                    ...{
                        chartOptions: {
                            xaxis: {
                                categories: self.dealPaymentTerms.dates,
                            },
                        },
                    },
                };
            });
        },
    },
};
</script>

<style></style>
