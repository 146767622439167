export default [{
    path: '/projectPortfolio',
    name: 'projectPortfolio',
    meta: {
      layout: 'main',
      title: 'Проект'
    },
    component: () => import('@/views/projectPortfolio/overview/index')
  }]

