<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item
        :label="$t('message.kpTemplate')"
        prop="kpTemplateId"
      >
        <select-kp-template 
          :size="'medium'"
          :placeholder="$t('message.kpTemplate')"
          :id="form.kpTemplateId"
          :filial_id="filial_id"
          v-model="form.kpTemplateId"
        ></select-kp-template>
      </el-form-item>
      <el-form-item :label="$t('message.date')" prop="date">
        <el-date-picker
          type="datetime"
          :placeholder="$t('message.date')"
          v-model="form.date"
          style="width: 100%"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('message.end_date')" prop="end_date">
        <el-date-picker
          type="datetime"
          :placeholder="$t('message.end_date')"
          v-model="form.end_date"
          style="width: 100%"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item :label="$t('message.comment')">
        <el-input type="textarea" v-model="form.comment" :placeholder="$t('message.comment')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dealOffersClose"> {{ $t('message.cancel') }}</el-button>
      <el-button @click="submit(true)" type="primary" v-loading="loadingButton"
        v-if="permissions.some(per => per.slug == 'dealOffers.create')"
      >{{ $t('message.confirm') }}</el-button>
    </span>
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import selectKpTemplate from "@/components/inventory/select-kp-template";

export default {
  components: {
    selectKpTemplate,
  },
  mixins: [form, drawer],
  props: {
    filial_id: {
      default: null,
    },
  },
  data() {
    return {
        productList: [],
        product: null,
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          permissions: "auth/permissions",
          rules: "dealOffers/rules",
          model: "dealOffers/model",
          columns: "dealOffers/columns",
          dealProductcolumns: "dealProducts/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "dealOffers/store",
          updateDealOfferList: "dealOffers/getDealOffers",
      }),
      dealOffersClose() {
        this.$emit('dealOffersClose', true)
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                  this.form.deal_id = this.productList[0].deal_id;
                  this.form.products = this.productList;
                  this.form.columns = JSON.parse(JSON.stringify(this.dealProductcolumns));
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.updateDealOfferList(this.form.deal_id);
                          this.form = JSON.parse(JSON.stringify(this.model))
                          this.$emit('dealOffersClose', true)
                          this.$emit('listChanged');
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
              }
          });
      },
  },
};
</script>


<style>
</style>
