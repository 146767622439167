<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.currency')"
      filterable
      :size="size"
      class="w-100"
    >
      <el-option
        v-for="(currency, index) in currencies"
        :key="'currencies-' + index"
        :label="currency.symbol"
        :value="currency.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    board_id: {
      default: null,
    },
  },
  watch: {
    board_id: {
      handler: async function () {
         if(this.currencies.length === 0) {
            await this.updateInventory();
         }
         if (!this.id && this.currencies.length !== 0) {
          this.selected = this.currencies.find((currency) => currency.active).id;
          this.currency_symbol = this.currencies.find((currency) => currency.active).symbol;
          this.$emit("input", this.selected);
        }
      },
      immediate: true,
    },
    id: {
      handler: async function () {
        this.selected = this.id;
        
        if(this.selected){
          if(this.currencies.length === 0) {
            await this.updateInventory();
          }
          this.currency_symbol = this.currencies.find((currency) => currency.id === this.selected).symbol;
          this.currency_selected = this.currencies.find((currency) => currency.id === this.selected);
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
      currency_symbol: '',
      currency_selected: {},
    };
  },
  computed: {
    ...mapGetters({
      currencies: "currency/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "currency/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    selectedForCreate(){
      if(this.currencies && this.currencies.length > 0){
        this.selected = this.currencies.find((currency) => currency.active).id;
      }
      this.$emit("input", this.selected);
    }
  },
};
</script>
