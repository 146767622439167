export const homeDashboard = [
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "0", componentName: "TodoStatus", name: 'СТАТУСЫ', },
    { icons: 'fa-solid fa-handshake', x: 8, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "1", componentName: "SourcesOfTransactions", name: 'ИСТОЧНИКИ СДЕЛОК', },
    { icons: 'fa-solid fa-clipboard-user', x: 16, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 112, i: "2", componentName: "DealsByManager", name: 'СДЕЛКИ ПО МЕНЕДЖЕРАМ', },
    { icons: 'fa-solid fa-bullseye', x: 0, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "3", componentName: "Goals", name: 'ЦЕЛИ', },
    { icons: 'fa-solid fa-earth-asia', x: 8, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "4", componentName: "DealsByCountry", name: 'СДЕЛКИ ПО СТРАНАМ', },
    { icons: 'fa-solid fa-money-bill-trend-up', x: 16, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "5", componentName: "SaleByPeriod", name: 'ПРОДАЖА ПО ПЕРИОДУ', },
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "6", componentName: "ActivityPerWeek", name: 'АКТИВНОСТЬ ЗА НЕДЕЛЯ', },
    { icons: 'fa-solid fa-file-invoice', x: 8, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "7", componentName: "LatestFiles", name: 'ПОСЛЕДНИЕ ФАЙЛЫ', },
    { icons: 'fa-solid fa-check', x: 16, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "8", componentName: "ReasonsForRemovingDeal", name: 'ПРИЧИНЫ УДАЛЕНИЯ СДЕЛКИ', },
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 16, h: 12, minW: 16, maxW: 24, minH: 12, maxH: 12, i: "9", componentName: "PaymentsByPeriod", name: 'ВЫПЛАТЫ ПО ПЕРИОДАМ', },
    { icons: 'fa-solid fa-check', x: 16, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "10", componentName: "TermsOfPayment", name: 'УСЛОВИЯ ОПЛАТЫ', },
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 16, h: 12, minW: 16, maxW: 24, minH: 12, maxH: 12, i: "11", componentName: "PaymentsByPeriodArea", name: 'ВЫПЛАТЫ ПО ПЕРИОДАМ', },
    { icons: 'fa-solid fa-check', x: 16, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "12", componentName: "StatisticsOnSalesOfGoods", name: 'СТАТИСТИКА ПО ПРОДАЖАМ ТОВАРОВ', },
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 12, h: 12, minW: 12, maxW: 24, minH: 12, maxH: 12, i: "13", componentName: "DeliveryConditions", name: 'УСЛОВИЯ ПОСТАВКИ', },
    { icons: 'fa-solid fa-check', x: 12, y: 0, w: 12, h: 12, minW: 12, maxW: 24, minH: 12, maxH: 12, i: "14", componentName: "TransactionType", name: 'ТИП СДЕЛКИ', },
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 24, h: 12, minW: 12, maxW: 24, minH: 12, maxH: 12, i: "15", componentName: "Contractor", name: 'КОНТРАГЕНТ', },
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 24, h: 12, minW: 12, maxW: 24, minH: 12, maxH: 12, i: "16", componentName: "TaskMap", name: 'КАРТА ЗАДАЧ', },
    { icons: 'fa-solid fa-check', x: 0, y: 0, w: 8, h: 12, minW: 8, maxW: 24, minH: 12, maxH: 12, i: "17", componentName: "SuccessfullTransactions", name: 'ТОП 5 УСПЕШНЫХ СДЕЛОК', },
    { icons: 'fa-solid fa-check', x: 8, y: 0, w: 16, h: 12, minW: 16, maxW: 24, minH: 12, maxH: 12, i: "18", componentName: "Categories", name: 'КАТЕГОРИИ', },
    { icons: 'fa-solid fa-wallet', x: 0, y: 0, w: 24, h: 12, minW: 12, maxW: 24, minH: 12, maxH: 12, i: "19", componentName: "Currency", name: 'ВАЛЮТА', },
    { icons: 'fa-solid fa-wallet', x: 0, y: 0, w: 24, h: 16, minW: 24, maxW: 24, minH: 12, maxH: 16, i: "20", componentName: "ManagersProfit", name: 'ПРИБЫЛЬ МЕНЕДЖЕРОВ', },
    { icons: 'fa-solid fa-wallet', x: 0, y: 0, w: 24, h: 28, minW: 24, maxW: 24, minH: 28, maxH: 28, i: "21", componentName: "Calendar", name: 'Календарь', },
    { icons: 'fa-solid fa-check', x: 12, y: 0, w: 12, h: 12, minW: 12, maxW: 24, minH: 12, maxH: 12, i: "22", componentName: " ClientBirthday", name: 'День рождения клиента', },
]

