import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/exhibitions',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/exhibitions',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/exhibitions/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/exhibitions/${id}`,
        method: 'get'
    })
}

export function update(data) {
    return request({
        url: `/exhibitions/${data.id}`,
        method: 'put',
        data
    })
}
export function updateParticipants(data) {
    return request({
        url: `/exhibitions/updateParticipants/${data.exhibition_id}`,
        method: 'put',
        data
    })
}
export function destroy(id) {
    return request({
        url: `/exhibitions/${id}`,
        method: 'delete'
    })
}

export function dealsByParticipants(params) {
    return request({
        url: `/exhibitions/dealsByParticipants/${params.id}`,
        method: 'get',
        params
    })
}

export function dealsByClients(params) {
    return request({
        url: `/exhibitions/dealsByClients/${params.id}`,
        method: 'get',
        params
    })
}

export function getStatuses(params) {
    return request({
        url: '/exhibitions/getStatuses',
        method: 'get',
        params
    })
}